/* ============================================================
  RADIO BUTTONS
============================================================ */


input[type=radio] {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    height: 20px;
    border: 1px solid $light-red;
}

.radio-buttons {
    label {
        position: relative;
        &::after {
            display: block;
            position: absolute;
            border: 2px solid $light-red;
            border-radius: 100%;
            height: 17px;
            width: 17px;
            top: 2px;
            left: 0;
            z-index: 5;
            transition: border .1s linear;
            -webkit-transition: border .1s linear;
            content: '';
        }

        &::before {
            display: block;
            position: absolute;
            content: '';
            border-radius: 100%;
            height: 9px;
            width: 9px;
            top: 6px;
            left: 4px;
            margin: auto;
            transition: background 0.1s linear;
            -webkit-transition: background 0.1s linear;
            background:#fcd9d8;

        }
    }


}

input[type=radio]:checked + label.form-check-label::after {
    border: 2px solid $light-red;
}

input[type=radio]:checked + label.form-check-label::before {
    background: $light-red;
}

input[type=radio]:checked + label.form-check-label {
    color: $black;
    background: transparent;
}

.radio-buttons label {
    padding-left: 2rem;
}

.radio-hint-text {
    font-size: 0.85rem;
    display: block;
    color: $dove-grey;
}


/* ============================================================
  RADIO BUTTONS
============================================================ */