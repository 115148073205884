/*------------------------------------------------------------
    Sort by
------------------------------------------------------------*/
@import '../../../components/common/base/colors';
@import '../../../components/common/base/mixins';

$iconsprite: '../../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../../components/common/images/webapp-icons-sprite@2x-v3.png';

.sort-results {
	font-weight: 400;
	font-size: 0.85rem;
	width: 121px;

	.dropdown {
		border: 0 none;
		font-size: 0.7rem;
		width: 90px;

		.btn {
			font-size: 0.85rem;
			box-shadow: 0 0 5px $grey;
			padding: 4px;
			border: 0 none;
			width: 115px;
			text-align: left;
			height: 26px;
			font-weight: 400;
			line-height: 1.0rem;
			border-radius: 4px;
		}

		.btn-secondary {
			&:active {
				background-color: $white;
			}
		}

		.btn-secondary.active {
			background-color: $white;
		}

		.dropdown-menu {
			left: 0;
			min-width: 80px;
			padding: 0;
			right: 0;
			width: 115px;
			border-radius: 0;
			box-shadow: 0px 5px 10px $grey;
			border: 0 none;
			top: 29px;
		}

		.dropdown-item {
			font-size: 0.85rem;
			padding: 7px 5px 5px;
			line-height: 1.2em;
			color: $abi-blue;
			font-weight: 400;
			cursor: pointer;
			background-color: $white;

			&:disabled {
				opacity: 0.2;
				cursor: default;
			}

			&.active,
			&:active {
				color: $white;
			}
		}

		.dropdown-divider {
			margin: 0px;
		}

		.dropdown-toggle.btn {
			background: $white;
			color: $abi-blue;
			box-shadow: none;
			border: 1px solid $lighter-gray;
			position: relative;
			border-radius: 0;
			letter-spacing: inherit;
			border-radius: 4px;

			&::after {
				border: 0 none;
				background: $transparent url($iconsprite) no-repeat scroll -220px -73px;
				height: 15px;
				width: 11px;
				right: 4px;
				top: 5px;
				position: absolute;
			}

			&:hover {
				background: $white;
				color: $abi-blue;
			}

			&:focus {
				background: $white;
				color: $abi-blue;
			}
		}

		.dropdown-item.disabled {
			opacity: 0.2;
		}
	}

	.dropdown.show {
		>.btn-secondary.dropdown-toggle {
			background-color: $white;
		}
	}
}

.sort-result-section {
	font-weight: 400;
	font-size: .85rem;
	height: 30px;
}

.search-result {
	.page-title-bar {
		.top-5 {
			.sort-result-section {
				.dropdown-menu {
					button {
						font-size: .85rem;
						padding: 7px 5px 5px;
						line-height: 1.2em;
						color: $abi-blue;
						font-weight: 400;
						cursor: pointer;
						background-color: $wildsand;
						text-decoration: none;
						margin: 0px;
					}
				}
			}
		}
	}
}

.sort-result-section>.dropdown {
	top: 0;
}

.sort-results.sort-result-section>.dropdown>button {
	height: 30px;
	top: 1px;
	padding-left: 8px;
	font-size: 0.8rem;
	margin: 0px;
}