.rc-slider-wrapper {
    height: 40px;
    padding: 0 10px;
}

.rc-slider {
    direction: rtl;
    width: auto;
    margin: 10px 0;
    z-index: 2;
}

.rc-slider-step,
.rc-slider-rail {
    height: 6px;
}

.rc-slider-handle {
    height: 20px;
    width: 20px;
    margin-top: -7px;
    z-index: 1;
    position: absolute;
    border-radius: 50%;
    top:0;
    background: $white;
    border: solid 2px $white;
    box-shadow: 0 0 5px $black;
    &:hover {
        border: solid 2px transparent;
        box-shadow: 0 0 5px #000;
    }
}

.rc-slider-rail {
    background: $gray-dark;
    width: 100%;
}

.rc-slider-rail,
.rc-slider-track {
    height: 6px;
    position: absolute;
    border-radius: 6px;
}

/*CustomSLider Handle*/
.customSliderHandles {
    position: relative;
    z-index: 2;
    .rc-slider-step{}
    .rc-slider-track{transition: all 0.35s linear; -moz-transition: all 0.35s linear; -webkit-transition: all 0.35s linear;background: $abi-blue;}
    .rc-slider-handle {
        transition: all 0.4s linear; -moz-transition: all 0.4s linear; -webkit-transition: all 0.4s linear;
        &:focus,&:active{outline: none; border-color: transparent;}
        z-index: 5;
        opacity: 1;
        &.rc-slider-handle-1 {
            border-radius: 50% 0 0 50%;
            box-shadow: -2px 0px 3px 0px #666;
            margin-left: -15px;
        }

        &.rc-slider-handle-2 {
           box-shadow: 2px 0px 3px 0px #666;
           border-radius: 0 50% 50% 0;
           margin-left: 0;
        }

    }
    .rc-slider-disabled .rc-slider-dot, 
    .rc-slider-disabled .rc-slider-handle{border-color:transparent;}
}

/*Customslier Handle */