#message-container {
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    width: 300px;
    margin: 0 auto;
    z-index: 1100;
    .alert-dismissible {
        // line-height: 1em;
        // min-height: 50px;
        display: table;
        width: 100%;
        opacity: 0.8;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        background-color: $black;
        color: $white;
        border-radius: 12px;
        border: 1px solid $grey;
        top: 0;
        padding: 20px 10px 20px 50px;
        position: relative;

        &:before {
            background: $red url($iconsprite) no-repeat scroll -270px -360px;
            bottom: 0;
            content: "";
            top: 15px;
            left: 10px;
            height: 30px;
            position: absolute;
            width: 30px;
            overflow: hidden;
            display: block;
            border-radius: 50%;
        }
        .close {
            //font-size: 2.5em;
            font-weight: 600;
            top: 18px;
            right: 8px;
            padding: 0;
            opacity: inherit;
            position: absolute;
            color: $red;
            display: none;
            span {
                display: block;
                height: 20px;
                width: 20px;
                line-height: 0.5em;
                text-shadow: none;
            }
        }
    }
    .message {
        display: table-cell;
        line-height: 21px;
        word-break: break-word;
        position: relative;
        top: -5px;
        strong {
            color:$white;
            font-weight: 600;
            display: block;
        }
    }
    .alert-success {
        opacity: 0.8;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        background-color: $black;
        color: $white;
        border-radius: 12px;
        border: 1px solid $grey;
        top: 0;
        padding: 20px 10px 20px 50px;
        position: relative;
        &:before {
            background: $green url($iconsprite) no-repeat scroll -270px -90px;
            bottom: 0;
            content: "";
            top: 15px;
            left: 10px;
            height: 30px;
            position: absolute;
            width: 30px;
            overflow: hidden;
            display: block;
            border-radius: 50%;
        }
    }
}