
$retina-abi-icon-sprite:'../images/webapp-icons-sprite@2x-v3.png';

// .show-full{
// 	.map-view{
// 		.map-search-here{
// 			left: 50%;
// 		}
// 		// .no-result-map{
// 		// 	left: 50%;
// 		// }
// 	}
// }
.map-panel {
	.map-view{
		.pull-left{
			left: 65px;
		}
	}
}
.mapboxgl-user-location-dot{
	&:after{
		left:-2px;
		top:-2px;
	}
}

.sidebar-mini {
	.show-full{
		.map-view{
			.map-search-here{
				left:50%;
				transform: translateX(-50%);
				-moz-transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
			}
		}
	}
}
.sidebar-collapse{
	.show-full{
		.map-view{
			.map-search-here{
				left:50%;
			}
			// .mapboxgl-map{
			// 		left: 0;
			// 	}
			// }
		}
	}
	.map-view{
		.map-search-here{
			left:50%;
			transform: translateX(-50%);
			-moz-transform: translateX(-50%);
			-webkit-transform: translateX(-50%);
		}
	}
}
.map-view {
	min-height: 200px;
	background: $grey url("../images/map-bg.png") no-repeat scroll center center;
	background-size: 1200px;
	width: 100%;
	.map-search-no-result{
		left: 50% !important;
	}
	.map-search-here{
		position: absolute;
		z-index: 99999;
		top: 90%;
		left: calc(60% + 200px);
		display: block;
	}
	.no-result-map{
		position: absolute;
		padding: 5px 10px;
		font-size: 1rem;
		z-index: 99999;
		top: 8%;
		left: 45%;
		background-color:$white;
		display: block;
		.btn-pop-cancel{
			height: 30px;
			margin: 0;
			width: 30px;
			background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
			border: 0 none;
			position: absolute;
			text-indent: -999px;
			overflow: hidden;
			cursor: pointer;
			box-shadow: none;
			top: 3px;
			right: 0;
		}
	}
	.visible{
		visibility: visible;
		opacity: 1;
		transition-delay: 0s;
	}
	.hidden{
		visibility: hidden;
		opacity: 0;
		transition: visibility 0s linear 0.33s, opacity 0.33s linear;
	}
	.map-style-toggle{
		position: absolute;
		z-index: 99999;
		height: 26px;
		width: 26px;
		right: 11px;
		border: 1px solid #e3e3e3;
		background-color: #f8f8f6;
		top: 103px;
		box-shadow: #989393 0px 1px 4px;
		cursor: pointer;
		// &:hover,&:focus, &:active, &:visited{
		// 	cursor: pointer;
		// 	border: 0;
		// 	color:$black;
		// 	-webkit-box-shadow: none;
		// 	box-shadow: none;
		// }
	}
	.satellite{
		&:after{
			background: $transparent url($iconsprite) no-repeat scroll -30px -360px;
			content: "";
			height: 30px;
			right: -3px;
			position: absolute;
			top: -2px;
			width: 30px;
			overflow: hidden;
			display: block;
		}
	}
	.basic{
		&:after{
			background: $transparent url($iconsprite) no-repeat scroll -60px -360px;
			content: "";
			height: 30px;
			right: -3px;
			position: absolute;
			top: -2px;
			width: 30px;
			overflow: hidden;
			display: block;
		}
	}
	.mapboxgl-popup-anchor-top{
		top: 60px !important;
	}
	.mapboxgl-popup-anchor-right{
		top: 20px !important;
		left: -33px;
	}
	.mapboxgl-popup-anchor-left{
		top: 20px !important;
		left: 35px;
	}
	.mapboxgl-popup{
		top:-20px;
		width: 350px;
		.mapboxgl-popup-content{
			cursor: pointer;
			padding: 10px 0px 0;
			.btn-pop-cancel{
				height: 30px;
				margin: 0;
				width: 30px;
				background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
				border: 0 none;
				position: absolute;
				text-indent: -999px;
				overflow: hidden;
				cursor: pointer;
				box-shadow: none;
				top: 3px;
				right: 0;
			}
			.result-row-overview{
				padding: 0 35px 0 50px;
				.result-title-name{
					font-size: 1.12rem;
					color: $black;
				}
				.update-title-name{
					color: $black;
					font-size: .9rem;
					padding: 2px 0;
				}
			}
			.map-switch{
				bottom: 0;
				border-top: 1px solid $lighter-gray;
				padding: 7px 0 0 10px;
				span{
					position: absolute;
					left: 60px;
					bottom: 8px;
					font-size: 0.95rem;
				}
			}
		}
		&.read-popup{
			.mapboxgl-popup-content{
				background: $read-block;
			}
		}
	}
	.mapboxgl-map{
		// width: 77vw !important;
		//margin-left: 385px;
		position: fixed;
		top: 169px;
		left: 395px;
		right: 0;
		bottom: 0;
		//transition: all .2s linear;
		// -webkit-transition: all .2s linear;
		// -webkit-transform: all .2s linear;
		// -moz-transition: all .2s linear;
		// -ms-transition: all .2s linear;
		// -o-transition: all .2s linear;
		.mapboxgl-user-searchHere-location-dot-custom{
			background: $transparent url("../images/drop-pin-icon@1x.png") no-repeat scroll center center;
			width: 36px;
			height: 36px;
			position: relative;
		}
		.mapboxgl-user-location-dot-custom{
			background-color:$cerulean;
			width: 16px;
			height: 16px;
			border-radius: 50%;
			box-shadow: 0 0 2px rgba(0,0,0,0.25);
			border: 2px solid $white;
			&:after{
				content: '';
				display: block;
				box-shadow: $cerulean 0 0 0 2px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				position: relative;
				z-index: -1;
				-webkit-animation: mapboxgl-user-location-dot-pulse 2s;
				-moz-animation: mapboxgl-user-location-dot-pulse 2s;
				-ms-animation: mapboxgl-user-location-dot-pulse 2s;
				animation: mapboxgl-user-location-dot-pulse 2s;
				-webkit-animation-iteration-count: infinite;
				-moz-animation-iteration-count: infinite;
				-ms-animation-iteration-count: infinite;
				animation-iteration-count: infinite;
				left:-2px;
				top:-2px;
			}
		}
	}
	.map-GeoLocate{
		position: absolute;
		z-index: 99999;
		top: 220px;
		right: 0;
	}
	.map-switch-top{
		position: absolute;
		right: 309px;
		top: 8px;
		border-top: 1px solid $alto;
		padding: 7px 0 0 10px;
		background: $white;
		width: 185px;
		height: 43px;
		z-index: 99;
		span{
			position: relative;
			font-size: .95rem;
			top: -27px;
			left: 51px;
		}
	}
	.map-preloader{
		width: 100%;
		height: 100%;
		background: #e3e3e3;
		z-index: 999;
		position: absolute;
		opacity: 0.8;
		img{
			width: 100px;
			position: relative;
			z-index: 999;
			left: 45%;
			top: 30%;
		}
	}
}

.JSmap-view{
    .mapboxgl-popup-anchor-top{
        top: 60px !important;
    }
    .mapboxgl-popup-anchor-right{
        top: 20px !important;
        left: -33px;
    }
    .mapboxgl-popup-anchor-left{
        top: 20px !important;
        left: 35px;
    }
    .mapboxgl-popup{
        top:-20px;
        width: 350px;
        .mapboxgl-popup-content{
            cursor: pointer;
            padding: 10px 0px 0;
            .btn-pop-cancel{
                height: 30px;
                margin: 0;
                width: 30px;
                background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                border: 0 none;
                position: absolute;
                text-indent: -999px;
                overflow: hidden;
                cursor: pointer;
                box-shadow: none;
                top: 3px;
                right: 0;
            }
            .result-row-overview{
                padding: 0 35px 0 50px;
                .result-title-name{
                    font-size: 1.12rem;
                    color: $black;
                }
                .update-title-name{
                    color: $black;
                    font-size: .9rem;
                    padding: 2px 0;
                }
            }
            .map-switch{
                bottom: 0;
                border-top: 1px solid $lighter-gray;
                padding: 7px 0 0 10px;
                span{
                    position: absolute;
                    left: 60px;
                    bottom: 8px;
                    font-size: 0.95rem;
                }
            }
        }
        &.read-popup{
            .mapboxgl-popup-content{
                background: $read-block;
            }
        }
    }
}

.closePopup{
	display: none !important;
}

.map-company, .map-project{
	width: 35px;
    height: 35px;
	display: flex;
	cursor: pointer;
	.hide{
		display: none !important;
	}
}
.map-project{
	background: $transparent url("../images/abi-project-pin@1x-v2.png") no-repeat center bottom;
	&:hover{
		width: 50px;
		height: 50px;
		background: $transparent url("../images/abi-project-pin-lg@1x-v2.png") no-repeat center bottom;
		-webkit-transition: .3s;
		transition: .3s;
		-moz-transition: 0s;
		-ms-transition:0s;
		z-index: 4 !important;
		.pro-related{
			top:50px;
		}

	}
	.pro-related{
		background: rgba(255, 255, 255, 0.86);
		color: $abi-blue;
		padding: 3px;
		text-align: center;
		top: 40px;
		position: absolute;
		left: -75%;
		right: -50%;
		line-height: 1.0em;
		border-radius: 5px;
		font-weight: 600;
		display: block;
		min-width: 100px;
	}
}
.project-cluster.active{
	width: 50px;
	height: 50px;
	background: $transparent url("../images/abi-project-cluster-lg@1x-v2.png") no-repeat center bottom;
	z-index: 4 !important;
	// -webkit-transition: .3s;
	// transition: .3s;
    // transition-property: all;
    // transition-duration: 0.3s;
    // transition-timing-function: ease;
	// transition-delay: 0s;
	.pro-related-com {
		top: 55px
	}
	// div{
	// 	font-size: 1.5rem;
	// }
}
.company-cluster.active{
	width: 50px;
	height: 50px;
	background: $transparent url("../images/abi-company-cluster-lg@1x-v2.png") no-repeat center bottom;
	z-index: 4 !important;
	// -webkit-transition: .3s;
	// transition: .3s;
    // transition-property: all;
    // transition-duration: 0.3s;
    // transition-timing-function: ease;
	// transition-delay: 0s;
	.pro-related-com {
		top: 55px
	}
	// div{
	// 	font-size: 1.5rem;
	// }
}

.map-project.active{
	width: 50px;
	height: 50px;
	background: $transparent url("../images/abi-project-pin-lg@1x-v2.png") no-repeat center bottom;
	z-index: 4 !important;
	// -webkit-transition: .3s;
	// transition: .3s;
    // transition-property: all;
    // transition-duration: 0.3s;
    // transition-timing-function: ease;
    // transition-delay: 0s;
	.hover-pro-info{
		background: rgba(255, 255, 255, 0.86);
		color: $abi-blue;
		padding: 3px;
		text-align: center;
		top: 51px;
		position: absolute;
		left: -50%;
		right: -50%;
		line-height: 1.0em;
		border-radius: 5px;
		font-weight: 600;
		display: block;
		min-width: 100px;
	}
	.pro-related{
		top: 51px;
		left: -50%;
	}
}
.map-company{
	background: $transparent url("../images/abi-company-pin@1x-v2.png") no-repeat center bottom;
	&:hover{
		width: 50px;
		height: 50px;
		background: $transparent url("../images/abi-company-pin-lg@1x-v2.png") no-repeat center bottom;
		-webkit-transition: .3s;
		transition: .3s;
		-moz-transition: 0s;
		-ms-transition:0s;
		z-index: 4 !important;
		.pro-related-com{
			top:50px;
		}
	}
	.pro-related-com{
		background: rgba(255, 255, 255, 0.86);
		color: $red;
		padding: 3px;
		text-align: center;
		top: 40px;
		position: absolute;
		left: -75%;
		right: -50%;
		line-height: 1.0em;
		border-radius: 5px;
		font-weight: 600;
		display: block;
		min-width: 100px;
	}
}
.map-company.active{
	width: 50px;
	height: 50px;
	background: $transparent url("../images/abi-company-pin-lg@1x-v2.png") no-repeat center bottom;
	z-index: 4 !important;
	// -webkit-transition: .3s;
	// transition: .3s;
    // transition-property: all;
    // transition-duration: 0.3s;
    // transition-timing-function: ease;
    // transition-delay: 0s;
	.hover-com-info{
		background: rgba(255, 255, 255, 0.86);
		color: $red;
		padding: 3px;
		text-align: center;
		top: 51px;
		position: absolute;
		left: -50%;
		right: -50%;
		line-height: 1.0em;
		border-radius: 5px;
		font-weight: 600;
		display: block;
		min-width: 100px;
	}
	.pro-related-com{
		top: 51px;
		left: -50%;
	}
}
.company-cluster, .project-cluster{
	width: 35px;
    height: 35px;
	color: $white;
	cursor: pointer;
	align-items: center;
    justify-content: center;
	font-size: 16px;
	display: flex;
	div{
		margin-top: -5px;
	}
	.pro-related-com{
		background: rgba(255, 255, 255, 0.86);
		color: $red;
		padding: 3px;
		text-align: center;
		top: 40px;
		position: absolute;
		left: -75%;
		right: -50%;
		line-height: 1.0em;
		border-radius: 5px;
		font-weight: 600;
		display: block;
		min-width: 100px;
		font-size: 0.75rem;
	}
}
.project-cluster{
	background: $transparent url("../images/abi-project-cluster@1x-v2.png") no-repeat;
	&:hover{
		font-size: 18px;
		width: 50px;
		height: 50px;
		background: $transparent url("../images/abi-project-cluster-lg@1x-v2.png") no-repeat center bottom;
		-webkit-transition: .3s;
		transition: .3s;
		-moz-transition: 0s;
		-ms-transition:0s;
		z-index: 4 !important;
	}
}
.company-cluster{
	background: $transparent url("../images/abi-company-cluster@1x-v2.png") no-repeat;
	&:hover{
		font-size: 18px;
		width: 50px;
		height: 50px;
		background: $transparent url("../images/abi-company-cluster-lg@1x-v2.png") no-repeat center bottom;
		-webkit-transition: .3s;
		transition: .3s;
		-moz-transition: 0s;
		-ms-transition:0s;
		z-index: 4 !important;
		.pro-related-com{
			top: 55px;
		}
	}
}
.maplist-container {
	float: left;
	padding-left: 0;
	position: absolute;
	right: 45px;
    width: 145px;
    top: 8px;
	.mapview {
		padding-left: 25px;
		height: 30px;
		width: 75px;
		background-color: $grey;
		color: $white;
		cursor: pointer;
		border: 0 none;
		&:before{
			content: '';
			background: $transparent url($iconsprite) no-repeat scroll  -30px -120px;
			height: 30px;
			position: absolute;
			right: 40px;
			top: 0px;
			width: 30px;
		}
		&.active {
			// background: $transparent url($iconsprite) no-repeat scroll -35px -120px;
			// width: 35px;
			// height: 44px;
			// border: 0 none;
			// text-indent: -999px;
			// overflow: hidden;
			background-color: $cerulean;
			color:$black;
		}
	}
	.listview {
		padding: 0.2rem 2rem 0.2rem 0;
		opacity: 1;
    	width: 115px;
    	padding-right: 15px;
    	height: 30px;
    	color: $red !important;
		border: 1px solid $lighter-gray;
		border-radius: 4px;
		&.active {
			//background: $transparent url($iconsprite) no-repeat scroll -105px -120px;
			//width: 35px;
			//height: 44px;
			//border: 0 none;
			//text-indent: -999px;
			//overflow: hidden;
			background-color: $white;
			color:$red;
			opacity: 1;
			border: 1px solid $red;
			&:before{
				content: '';
				background: $transparent url($iconsprite) no-repeat scroll -90px -120px;
				height: 30px;
				position: absolute;
				left:5px;
				top: 0px;
				width: 30px;
			}
		}
		&:hover,
		&:focus {
			background: $white;
			color:$red;
			border: 1px solid $red;
		}
	}
	a {
		border: none;
		color: $black;
		background: $white;
		box-shadow: 0 0 5px $grey;
		&:hover,
		&:focus,
		&.active {
			background: $black;
			color: $white;
		}
	}
}
.sidebar-mini.sidebar-collapse{
	.map-view{
		.mapboxgl-map{
			margin-left: 0;
			.mapboxgl-ctrl-top-right{
				top:46px;
				z-index: 999;
				right:0;
				position: absolute;
				.mapboxgl-ctrl-group{
					margin: 30px 11px 0 0;
					border-radius: 0 0 2px 2px;
					button {
						width: 26px;
						height: 26px;
						background-color: rgb(249, 249, 249);
					}
				}
			}
		}
	}
	.show-half{
		.map-view{
			.mapboxgl-map{
				left: 513px;
			}
		}
	}
}
.sidebar-mini {
	.mapboxgl-map{
		// width: 100vw !important;
		//margin-left: 395px;
		.mapboxgl-ctrl-top-right{
			position: fixed;
			top: 215px;
			z-index: 999;
			right: 0;
			.mapboxgl-ctrl-group{
				margin: 30px 11px 0 0;
				border-radius: 0 0 2px 2px;
				button {
					width: 26px;
					height: 26px;
					background-color: rgb(249, 249, 249);
				}
			}
		}
	}
	.show-half{
		.map-view{
			.mapboxgl-map{
				left: 745px;
			}
		}
	}
}
.zoomCtrlclass{
	// position: fixed !important;
	// top: 181px !important;
	// right: 9px !important;
}

.direction-map-wrapper{
	display: block;
	position: relative;
	height: 400px;
	.map-view{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		.mapboxgl-map{
			text-align: left;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			text-align: left;
		}
		.mapboxgl-ctrl-top-right{
			position: absolute;
			top:10px;
			right: 0;
			z-index: 99;
		}
	}
	.mapbox-directions-origin, .mapbox-directions-destination{
		.mapbox-form-label{
			background-color:$abi-blue;
		}
	}	
}
.sidebar-mini.sidebar-collapse .direction-map-wrapper .map-view .mapboxgl-map .mapboxgl-ctrl-top-right{
	position: relative;
    top: 19px;
}

@media (min-width: 1920px) {
	.direction-map-wrapper {
		height: 100vh;
	}
}

@media (max-width: 900px) {
	.maplist-container {
		position: absolute;
		left: 105px;
		right: auto;
	}
	.main-nav-container {
		padding-bottom: 40px;
	}
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
	.map-view .satellite{
		background: #f8f8f6 url("../images/satelliteview-icon@2x-v2.png") no-repeat scroll center center;
		background-size: 18px;
	}
	.map-view .basic{
		background: #f8f8f6 url("../images/streetview-icon@2x-v2.png") no-repeat scroll center center;
		background-size: 18px;
	}

	.project-cluster{
		background: $transparent url("../images/abi-project-cluster@2x-v2.png") no-repeat;
		background-size: 35px;
		&:hover{
			background: $transparent url("../images/abi-project-cluster-lg@2x-v2.png") no-repeat center bottom;
			background-size: 50px;
		}
	}
	.company-cluster{
		background: $transparent url("../images/abi-company-cluster@2x-v2.png") no-repeat;
		background-size: 35px;
		&:hover{
			background: $transparent url("../images/abi-company-cluster-lg@2x-v2.png") no-repeat center bottom;
			background-size: 50px;
		}
	}
	.project-cluster.active{
		background: $transparent url("../images/abi-project-cluster@2x-v2.png") no-repeat center bottom;
		background-size: 50px;
	}

	.map-project{
		background: $transparent url("../images/abi-project-pin@2x-v2.png") no-repeat center bottom;
		background-size: 35px;
		&:hover{
			background: $transparent url("../images/abi-project-pin-lg@2x-v2.png") no-repeat center bottom;
			background-size: 50px;
		}
	}
	.map-project.active{
		background: $transparent url("../images/abi-project-pin@3x-v2.png") no-repeat center bottom;
		background-size: 50px;
	}

	.map-company{
		background: $transparent url("../images/abi-company-pin@2x-v2.png") no-repeat center bottom;
		background-size: 35px;
		&:hover{
			background: $transparent url("../images/abi-company-pin-lg@2x-v2.png") no-repeat center bottom;
			background-size: 50px;
		}
	}
	.map-company.active{
		background: $transparent url("../images/abi-company-pin@3x-v2.png") no-repeat center bottom;
		background-size: 50px;
	}
	.map-view .mapboxgl-popup .mapboxgl-popup-content .btn-pop-cancel,
	.JSmap-view .mapboxgl-popup .mapboxgl-popup-content .btn-pop-cancel{
		background: $transparent url($retina-abi-icon-sprite) no-repeat scroll -210px -90px;
		background-size: 300px;
	}
	.map-view .mapboxgl-map .mapboxgl-user-searchHere-location-dot-custom,
	.JSmap-view .mapboxgl-map .mapboxgl-user-searchHere-location-dot-custom{
		background: $transparent url("../images/drop-pin-icon@2x-v2.png") no-repeat scroll center center;
		background-size: 36px;
	}
	// .map-view .mapboxgl-map .mapboxgl-user-location-dot-custom{
	// 	background: $transparent url("../images/drop-pin-icon@2x-v2.png") no-repeat scroll center center;
	// 	background-size: 35px;
	// }
}

.map-panel{
	&.with-project-info{
		.map-view{
			.mapboxgl-map{
				top: 203px;
			}
		}
	}
}