@import '../../common/base/colors';
@import '../../common/base/mixins';

$iconsprite: '../../common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../common/images/webapp-icons-sprite@2x-v3.png';

.people-container {
    .linkWrapper {
        a.result-block {
            padding-right: 0;
            padding-top: 0;
        }
    }
}