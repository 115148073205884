@import '../../components/common/base/colors';
@import '../../components/common/base/mixins';

$iconsprite: '../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../components/common/images/webapp-icons-sprite@2x-v3.png';


.league-table-page {
    top: 55px;
    padding-left: 45px !important;
    margin-left: 0;
    left: 13px;
    position: fixed;
    .search-result{

        &.list-view-panel {
            .result-wrapper{
                left: 65px;
            }
            ul.list-group{
                top:236px;
            }
            .league-empty-state{
                img{
                    max-width: 300px;
                }
            }
        }
    }
    .search-selector{
        margin-bottom: 0;
        position: relative;
        .select-drop-count{
            button.dropdown-toggle{
                height: 37px;
                padding: 5px;
                &.active{
                    &:before{
                        top:3px
                    }
                }
                &:before{
                    top:3px
                }
                &:after{
                    top:12px;
                }
                &.active:after{
                    right: 29px;
                }
                svg {
                    left: -2px;
                    position: absolute;
                    top: 6px;
                }
            }
            &.search-selector-position {
                position: static;
                top: -10px;
                margin-left: 0;
            }
            // &.show{
            //     button.dropdown-toggle{
            //         &:after{
            //             right: 29px;
            //         }
            //     }
            // }
            .select-drop-list:before{
                right: 26px;
            }
        }
        .search-selector-position {
            position: absolute;
            margin-left: 0;
            right: 0;
            top: 0;
            left: auto;
            height: 37px;
            &.select-actions{
                right: 2px;
                top: 2px;
                background: $transparent;
                border:0 none;
                .action-list{
                    z-index: 10001;
                    min-width: 172px;
                }
                button.dropdown-toggle{
                    margin: 2px;
                }
            }
        }
    } 
    .curr-loc-msg{
        position: absolute;
        top: 10px;
        left: 230px;
        right: auto;
        box-shadow: 0 0 3px $grey;
        line-height: 1rem;
    }
    @media screen and (max-width:1450px) {
        .curr-loc-msg{
            right: 180px;
        }
        .sort-league{
            position: absolute;
            right: 0;
            top: 10px;
        }
    }


    .sort-league{
        .dropdown{
            .dropdown-menu{
                width: 100%;
                min-width: inherit;
            }
        }
    }
    .dropdown{

        button{
            font-weight: normal;
            font-size: 0.90rem;
            position: relative;
            padding:8px 30px 7px 8px;
            background: #fff;
            color: #292b2c;
            border: 1px solid #ccc;
            &.btn:focus,
            &.btn:hover{
                background: $grey;
                color: $black;
            }
            &.dropdown-toggle{
                width: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
            }
        }
        .dropdown-menu{
            max-height: 250px;
            overflow: auto;
            box-shadow: 0 2px 5px $dark-grey;
            border-radius: 0;
            top: 33px;
            border: 0 none;
            padding: 0;
            .dropdown-item{
                border: none;
            }
        } 
        .dropdown-toggle:after {
            border: 0 none;
            background: $transparent url($iconsprite) no-repeat scroll -220px -73px;
            height: 15px;
            width: 11px;
            right: 8px;
            top: 10px;
            position: absolute;
        }
    }
    .nav-tabs,
    .nav-link {
      border: none;
    }
    
    .nav-tabs {
      display: inline-block;
      background: $white;
      box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      overflow: hidden;
    
      .nav-item {
        display: inline-block;
        margin-bottom: 0;
        border-radius: 0;
    
        .nav-link {
          color: rgba(4, 171, 234, 0.6);
          border-radius: 0;
          padding: .5rem 1rem .5rem 1rem;
          cursor: pointer;
          position: relative;
          border: 0 none;
    
          i.tickIcon {
            position: absolute;
            left: 5px;
            top: 50%;
            width: 15px;
            height: 15px;
            margin-top: -7px;
            background: transparent url($iconsprite) no-repeat scroll -157px -428px;
            display: none;
          }
    
          &.active {
            background: $cerulean;
            color: $white;
    
            i {
              background-position: -185px -428px;
            }
          }
    
    
    
          font-weight: 700;
        }
      }
    }

    
    .map-back{
        position: relative;
        display: inline-block;
        left: 3px;
        top: 1px;
        color: $black;
        font-size: 0.8rem;
        cursor: pointer;
        font-weight: 400;
        border: 1px solid $grey;
        padding: 15px 13px 0px;
        z-index: 999;
        background: $white;
        height: 37px;
        float: left;
        &:before{
            content: '';
            background: $transparent url($iconsprite) no-repeat scroll 0px -240px;
            height: 30px;
            position: absolute;
            right: 15px;
            top: -6px;
            width: 30px;
        }
    }
    .result-list-container li.list-group-item .company-container .linkedin-list-view{
        left: 33%;
        top: 21px;
    }
    .linkedin-list-view.entity-linkedin-view {
        left: 29%;
    }
    .switch-panel-filter{
        .switch-filter{
            position: static;
            align-items: center;
            span{
                width: 100px;
            }
        } 
    }
    .cmn-toggle+label{
        margin: 0;
    }

    .league-header {
        background: #e0e3e9;
    }

    .calendar-container{
        width: 100%;
        .react-add-to-calendar{
            margin: 0;
        }
        .list-table{
            .list-tablerow{
                min-height: 0;
                .list-col{
                    padding: 15px 10px;
                    .dropdown{
                        button{
                            width: 100%;
                            min-width: 140px;
                            text-align: left;
                            font-weight: 400;
                            position: relative;
                            color:#464a4c;
                            padding: .4rem .75rem;
                            font-size: .825rem;
                            border-radius: 3px;
                            &:hover,&:focus{
                                background: $white;
                            }
                            &:after {
                                position: absolute;
                                right: 10px;
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                        .dropdown-menu {
                            border-radius: 0;
                            button {
                                padding: .1rem .75rem;
                            }
                        }
                        a{
                            color: $black;
                        }
                    }
                }
            }
        }  
        .add-to-calendar-link{
            color: #053874 !important;
            text-decoration: underline !important;
            font-size: .75rem;
            font-weight: 700;
            padding: 5px 0 0;
            display: inline-block;
            cursor: pointer;
        }
        .item-event-time{
            p{
                margin: 0;
                font-size: 0.825rem;
            }
            span{
                display: block;
                font-size: 0.75rem;
            }
        }  
        .priority-tag{
            padding: 3px 5px;
            line-height: 1;
            &.high-priority{
                background: #FECECE;
              }
              &.normal-priority{ background: #C3C8DD;}
              &.low-priority{
                background: #EAEDFA;
              }
        }
        .calendar-description{
            margin-bottom: 0;
            padding-left: 30px;
            font-size: 0.75rem;
        }
        .calendar-name{
            margin-bottom: 0;
            padding-left: 30px;
            color: $black;
            a{
                color: $black;
                cursor: pointer;
            }
        }
        .result-title-name{
            color: $black;
            font-size: 1rem !important;
        }
        .edit-button{
            background: transparent;
            border: none;
            cursor: pointer;
            &:after{
              background: $transparent url($iconsprite) no-repeat scroll -157px -88px;
              content: "";
              height: 24px;
              width: 24px;
              margin-right: 5px;
              display: block;
              @media (-webkit-min-device-pixel-ratio: 2),
                (min-resolution: 192dpi) {
                    background: $transparent  url($retina-icon-sprite) no-repeat scroll -157px -88px;
                    background-size: 300px;
                }
            @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                background: $transparent url($retina-icon-sprite) no-repeat scroll -157px -88px;
                background-size: 300px;
            }
            }
          }
    }
    
    .event-table-page{
        li{
            &.list-group-item {
                input[type=checkbox]:checked{
                    ~.checkmark{
                        &:after{
                            left: 9px !important;
                            top: 5px !important;
                            width: 6px !important;
                            height: 13px !important;
                        }
                    }
                }
                .checkmark{
                    width: 26px !important;
                    height: 26px !important;
                    left: 13px !important;
                    top: -1px !important;
                    &:hover{
                        &:after{
                            left: 9px !important;
                            top: 5px !important;
                            width: 6px !important;
                            height: 13px !important;
                        }
                    }
                    &:checked{
    
                    }
                }
                .clock-icon{
                    background:url($iconsprite) no-repeat scroll -123px -542px;
                    content: "";
                    height: 24px;
                    width: 24px;
                    margin-right: 5px;
                    @media (-webkit-min-device-pixel-ratio: 2),
                          (min-resolution: 192dpi) {
                              background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                              background-size: 300px;
                          }
                      @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                          background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                          background-size: 300px;
                      }
                  }
            }
        }
        &.main-container{
            top: 55px;
            padding-left: 45px;
            margin-left: 0;
            padding-right: 0;
            left: 13px;
        }
        .search-result{
            &.list-view-panel {
                .result-wrapper{
                    left: 65px;
                    top:205px;
                }
                ul.list-group{
                    top:266px;
                }
                .event-empty-state{
                    img{
                        max-width: 300px;
                    }
                }
            }
            
        }
        .multiselect-container{
            button{
                width:170px;
                font-weight: 400;
                font-size: .9rem;
                position: relative;
                padding: 6px 8px;
                border: 1px solid $border-grey;
                box-shadow: none;
                & + div{
                    border:1px solid rgba(0,0,0,.15);
                    .css-1vr111p-option{
                        &:hover{
                            background-color: $wildsand-light;
                            cursor: pointer;
                        }
                    }
                    & + div{
                        display: none;
                    }
                } 
            }
        }
        .event-filter{
            .dropdown{
                button{
                    width:170px;
                    font-weight: 400;
                    font-size: .9rem;
                    position: relative;
                    padding: 9px 30px 9px 8px;
                    font-weight: 400;
                    text-align: left;
                    &:hover,&:focus{
                        background: $white;
                        color: $black-grey;
                    }
                    &:after{
                        border: 0;
                        background: transparent url($iconsprite) no-repeat scroll -220px -73px;
                        height: 15px;
                        width: 11px;
                        right: 8px;
                        top: 10px;
                        position: absolute;
                    }
                }
                .dropdown-menu{
                    button{
                        cursor: pointer;
                        &:hover{
                            background-color: $wildsand-light;
                            color: #1d1e1f;
                        }
                    }
                }
            } 
        }
        
        
        .react-add-to-calendar__button{
            display: block;
            background: #fff;
            border-color: $border-grey;
            border-radius: 2px;
            span{
                font-size: 0.825rem;
            }
        }
    
        .filter-button-cnt{
            padding-top: 30px;
            .delete-selected{
                background: none;
                border: none;
                color: $black-grey;
                position: relative;
                cursor: pointer;
                padding-left: 25px;
                &:before{
                    content:"";
                    height: 20px;
                    width: 20px;
                    background: transparent url($iconsprite) no-repeat scroll -183px -95px;
                    position: absolute;
                    left:0;
                    top: 9px;
                    @media (-webkit-min-device-pixel-ratio: 2),
                    (min-resolution: 192dpi) {
                        background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                        background-size: 300px;
                    }
                    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                        background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                        background-size: 300px;
                    }
                }
            }
        }
    
        
    
    }
    
    .mr-auto {
        margin-right: auto;
    }
    
    .ml-auto, .mx-auto {
        margin-left: auto!important;
    }
    
    .mr-2 {
        margin-right: 0.5rem!important;
    }
    
    .pl-4, .px-4 {
        padding-left: 1.5rem!important;
    }
    
    .pr-4 {
        padding-right: 1.5rem!important;
    }
    
    .btn-button-dropdown {
        button {
            background-color: #053874 !important;
            border-color: #053874 !important;
            color: $white !important;
            &:after {
                    background: transparent url($iconsprite) no-repeat scroll -220px -397px !important;
            }
        }
    }
    
    .select-drop-count {
        button {
            background: #fff !important;
            height: 37px !important;
            &.dropdown-toggle {
                svg {
                    position: absolute;
                    left: 4px;
                    top: 8px;
                }
                &.active {
                    padding-left: 30px;
                    p {
                        svg {
                            left: -25px;
                            top: 0;
                        }
                    }
                }
            }
            &:after{
                top: 12px;
            }
            
        }
    }
    
    .searchList-header {
        h1.show {
            display: none;
        }
        .result-count {
            display: none;
        }
    }

    .result-list-container {
        padding: 10px 24px !important;
    }
        
}

/*.calendar-container{
    width: 100%;
    .react-add-to-calendar{
        margin: 0;
    }
    .list-table{
        .list-tablerow{
            min-height: 0;
            .list-col{
                padding: 15px 10px;
                .dropdown{
                    button{
                        width: 100%;
                        min-width: 140px;
                        text-align: left;
                        font-weight: 400;
                        position: relative;
                        color:#464a4c;
                        padding: .4rem .75rem;
                        font-size: .825rem;
                        border-radius: 3px;
                        &:hover,&:focus{
                            background: $white;
                        }
                        &:after {
                            position: absolute;
                            right: 10px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    .dropdown-menu {
                        border-radius: 0;
                        button {
                            padding: .1rem .75rem;
                        }
                    }
                    a{
                        color: $black;
                    }
                }
            }
        }
    }  
    .add-to-calendar-link{
        color: #053874 !important;
        text-decoration: underline !important;
        font-size: .75rem;
        font-weight: 700;
        padding: 5px 0 0;
        display: inline-block;
        cursor: pointer;
    }
    .item-event-time{
        p{
            margin: 0;
            font-size: 0.825rem;
        }
        span{
            display: block;
            font-size: 0.75rem;
        }
    }  
    .priority-tag{
        padding: 3px 5px;
        line-height: 1;
        &.high-priority{
            background: #FECECE;
          }
          &.normal-priority{ background: #C3C8DD;}
          &.low-priority{
            background: #EAEDFA;
          }
    }
    .calendar-description{
        margin-bottom: 0;
        padding-left: 30px;
        font-size: 0.75rem;
    }
    .calendar-name{
        margin-bottom: 0;
        padding-left: 30px;
        color: $black;
        a{
            color: $black;
            cursor: pointer;
        }
    }
    .result-title-name{
        color: $black;
        font-size: 1rem !important;
    }
    .edit-button{
        background: transparent;
        border: none;
        cursor: pointer;
        &:after{
          background: $transparent url($iconsprite) no-repeat scroll -157px -88px;
          content: "";
          height: 24px;
          width: 24px;
          margin-right: 5px;
          display: block;
          @media (-webkit-min-device-pixel-ratio: 2),
            (min-resolution: 192dpi) {
                background: $transparent  url($retina-icon-sprite) no-repeat scroll -157px -88px;
                background-size: 300px;
            }
        @media only screen and (-webkit-min-device-pixel-ratio: 2) {
            background: $transparent url($retina-icon-sprite) no-repeat scroll -157px -88px;
            background-size: 300px;
        }
        }
      }
}

.event-table-page{
    li{
        &.list-group-item {
            input[type=checkbox]:checked{
                ~.checkmark{
                    &:after{
                        left: 9px !important;
                        top: 5px !important;
                        width: 6px !important;
                        height: 13px !important;
                    }
                }
            }
            .checkmark{
                width: 26px !important;
                height: 26px !important;
                left: 13px !important;
                top: -1px !important;
                &:hover{
                    &:after{
                        left: 9px !important;
                        top: 5px !important;
                        width: 6px !important;
                        height: 13px !important;
                    }
                }
                &:checked{

                }
            }
            .clock-icon{
                background:url($iconsprite) no-repeat scroll -123px -542px;
                content: "";
                height: 24px;
                width: 24px;
                margin-right: 5px;
                @media (-webkit-min-device-pixel-ratio: 2),
                      (min-resolution: 192dpi) {
                          background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                          background-size: 300px;
                      }
                  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                      background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                      background-size: 300px;
                  }
              }
        }
    }
    &.main-container{
        top: 55px;
        padding-left: 45px;
        margin-left: 0;
        padding-right: 0;
        left: 13px;
    }
    .search-result{
        &.list-view-panel {
            .result-wrapper{
                left: 65px;
                top:205px;
            }
            ul.list-group{
                top:266px;
            }
            .event-empty-state{
                img{
                    max-width: 300px;
                }
            }
        }
        
    }
    .multiselect-container{
        button{
            width:170px;
            font-weight: 400;
            font-size: .9rem;
            position: relative;
            padding: 6px 8px;
            border: 1px solid $border-grey;
            box-shadow: none;
            & + div{
                border:1px solid rgba(0,0,0,.15);
                .css-1vr111p-option{
                    &:hover{
                        background-color: $wildsand-light;
                        cursor: pointer;
                    }
                }
                & + div{
                    display: none;
                }
            } 
        }
    }
    .event-filter{
        .dropdown{
            button{
                width:170px;
                font-weight: 400;
                font-size: .9rem;
                position: relative;
                padding: 9px 30px 9px 8px;
                font-weight: 400;
                text-align: left;
                &:hover,&:focus{
                    background: $white;
                    color: $black-grey;
                }
                &:after{
                    border: 0;
                    background: transparent url($iconsprite) no-repeat scroll -220px -73px;
                    height: 15px;
                    width: 11px;
                    right: 8px;
                    top: 10px;
                    position: absolute;
                }
            }
            .dropdown-menu{
                button{
                    cursor: pointer;
                    &:hover{
                        background-color: $wildsand-light;
                        color: #1d1e1f;
                    }
                }
            }
        } 
    }
    
    
    .react-add-to-calendar__button{
        display: block;
        background: #fff;
        border-color: $border-grey;
        border-radius: 2px;
        span{
            font-size: 0.825rem;
        }
    }

    .filter-button-cnt{
        padding-top: 30px;
        .delete-selected{
            background: none;
            border: none;
            color: $black-grey;
            position: relative;
            cursor: pointer;
            padding-left: 25px;
            &:before{
                content:"";
                height: 20px;
                width: 20px;
                background: transparent url($iconsprite) no-repeat scroll -183px -95px;
                position: absolute;
                left:0;
                top: 9px;
                @media (-webkit-min-device-pixel-ratio: 2),
                (min-resolution: 192dpi) {
                    background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                    background-size: 300px;
                }
                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                    background-size: 300px;
                }
            }
        }
    }

    

}

.mr-auto {
    margin-right: auto;
}

.ml-auto, .mx-auto {
    margin-left: auto!important;
}

.mr-2 {
    margin-right: 0.5rem!important;
}

.pl-4, .px-4 {
    padding-left: 1.5rem!important;
}

.pr-4 {
    padding-right: 1.5rem!important;
}

.btn-button-dropdown {
    button {
        background-color: #053874 !important;
        border-color: #053874 !important;
        color: $white !important;
        &:after {
                background: transparent url($iconsprite) no-repeat scroll -220px -397px !important;
        }
    }
}

.select-drop-count {
    button {
        background: #fff !important;
        height: 37px !important;
        &.dropdown-toggle {
            svg {
                position: absolute;
                left: 4px;
                top: 8px;
            }
            &.active {
                padding-left: 30px;
                p {
                    svg {
                        left: -25px;
                        top: 0;
                    }
                }
            }
        }
        
    }
}

.searchList-header {
    h1.show {
        display: none;
    }
    .result-count {
        display: none;
    }
}*/

@media screen and (max-width:1265px) {
    .league-table-page .search-result.list-view-panel .result-wrapper{
        top:205px;
    }
    .league-table-page .search-result.list-view-panel ul.list-group{
        top: 276px;
    }
    .league-table-page .list-view-panel .result-wrapper .result-list-container{
        width: auto;
    }
    .league-table-page .dropdown button.dropdown-toggle {
        width: 145px;
    }
}

.hierarchy-row{
    .linkedin-list-view{
        top: 20px !important;
        left: 30% !important;
    }
}