.search-selector {
  margin-bottom: 30px;
  position: relative;
  display: inline-block;

  .select-drop-count {
    position: relative;
    top: 0px;
    display: inline-block;
    background-color: $white;
    // float: left;

    button.active {
      min-width: 175px;
      width: auto;

      // &::after {
      //   right: 29px;
      // }
    }

    button {
      position: relative;
      background: none;
      color: $black;
      border: 1px solid $lighter-gray;
      width: 50px;
      height: 30px;
      padding: 0;
      margin: 0;
      border-radius: 0;
      text-align: left;
      p {
        font-size: 0.9rem;
        color: $abi-blue;
        top: 2px;
        position: inherit;
        font-weight: 600;
        text-align: left;
        padding-right:25px;
        letter-spacing: normal;
      }

      &::after {
        content: "";
        position: absolute;
        top: 8px;
        right: 2px;
        width: 15px;
        height: 15px;
        border: 0;
        background: $transparent url($iconsprite) no-repeat scroll -220px -75px;
      }
    }

    .select-drop-list {
      z-index: 100000;
      left: 1px;
      top: 30px;
      margin: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: 1px 1px 5px $dove-grey;
      border: 0 none;
      width: 100%;
      right: 0;
      // min-width: 175px;

      &:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
        top: -18px;
        left: 0;
        z-index: 1;
        right: auto;
      }

      button {
        position: relative;
        display: block;
        width: 100%;
        border: none;
        border-bottom: 1px solid $mercury;
        font-size: 0.8rem;
        font-weight: 400;
        color: $black-grey;
        min-width: inherit;

        &::before {
          left: 0;
        }

        &::after {
          background: none;
        }

        &:hover {
          background-color: $mercury;
        }
      }
    }

    &.search-selector-position {
      // top: -10px;
      // margin-left: 20px;
    }
  }

  .select-actions {
    position: relative;
    top: 0px;
    left: 0px;
    display: inline-block;
    border: 1px solid $lighter-gray;
    right: 2px;
    background: $white;
    z-index: 3;
    // left: 14px;
    height: 30px;
    border-radius: 3px;

    button,
    button.active {
      color: $transparent;
      background-color: $transparent;
      background-image: none;
      border-color: $transparent;
      overflow: hidden;
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: 0;
      z-index: 9999;
      position: relative;
      margin: 0;
    }

    .action-list {
      margin: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: 1px 1px 5px $dove-grey;
      border: 0 none;
      top: 35px;
      right: 0;
      left: auto!important;

      &:before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
        top: -18px;
        left: auto;
        z-index: 1;
        right: 0;
      }

      button {
        color: $black-grey;
        text-indent: 0;
        background: none;
        border-bottom: 1px solid $mercury;
        width: 100%;
        margin: 0;
        padding: 5px 8px;
        font-size: 0.9rem;
        font-weight: 400;
        letter-spacing: normal;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: $mercury;
        }
      }

      .disabled {
        color: $grey;
        cursor: default;

        &:hover {
          background-color: $white;
        }
      }
    }

    &.search-selector-position {
      // top: -10px;
    }
  }

  button {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    padding: 1px 5px;
    color: $white;
    border-radius: 3px;
    background: $red;
    border: 0;
    font-size: 11px;
    cursor: pointer;
    font-weight: 400;
    vertical-align: middle;
    letter-spacing: 1px;

    span {
      text-indent: -999px;
      background: $transparent url($iconsprite) no-repeat scroll -120px -120px;
      position: absolute;
      top: -6px;
      overflow: hidden;
      width: 24px;
      height: 21px;
      left: -7px;
      display: none;
    }
  }
}

.selector-container {
  .search-selector {
    margin-bottom: 0;
  }

  &.no-show-more {
    .search-selector {
      .select-drop-count.search-selector-position {
        top: 10px;
        margin-left: 15px;
      }

      .select-actions.search-selector-position {
        top: 10px;
      }
    }
  }
}

/*input type select dropdown*/
.select-input-type {
  .btn-group {
    button {

      &:hover,
      &:focus,
      &:active {
        background: $white;
        color: $black;
      }
      &:after {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
}

/*input type select dropdown*/