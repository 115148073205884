.modal-dialog {
  top: 60px;
  .modal-content{
    border-radius:0;
    .modal-header {
      display: block;
      border: 0 none;
      padding-bottom: 0;
      .btn-cancel {
        height: 30px;
        margin: 0;
        width: 30px;
        background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
        border: 0 none;
        position: absolute;
        text-indent: -999px;
        overflow: hidden;
        cursor: pointer;
        box-shadow: none;
        right: 5px;
        top: 12px;
        &:focus,
        &:active,
        &:hover {
          background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
        }
      }
    }
  }
}