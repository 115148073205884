.sidebar {
    position: fixed;
    top: 58px;
    bottom: 50px;
    left: 0;
    z-index: 1000;
    padding: 0px;
    width: 350px;
    @include box-shadow(0, 0, 5px, $black);
    .sidebar-wrapper {
        //overflow: hidden;
    }
    .nav {
        margin-bottom: 20px;
    }
    .nav-item {
        width: 100%;
    }
    .nav-item+.nav-item {
        margin-left: 0;
    }
    .nav-link {
        border-radius: 0;
    }
}

.tool-tip-sidebar {
    font-size: .7rem;
}

.exclude-stages {
    background: $whisper;
    color: $black;
    margin: 0 -10px;
    padding: 10px;
    .form-check {
        margin-bottom: 0;
        line-height: 1.25rem;
    }
}

#sidebar-wrapper {
    background: $white;
    border-right: 1px solid $alto;
}

.indicator {
    color: $dark-black-gray;
}

.sidebar-left-container {
    padding: 0;
    margin-bottom: 5px;
    a {
        display: block;
        padding: 6px 5px 6px 40px;
        position: relative;
        text-decoration: none;
        color: $black;
        .indicator {
            font-size: 26px;
            left: auto;
            position: absolute;
            right: 10px !important;
            top: -4px;
            color: $white;
        }
        i.indicator {
            font-size: 26px;
            left: auto;
            position: absolute;
            right: 10px !important;
            top: -4px;
            color: $black;
        }
    }
    h3 {
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: $white;
    }
    h3,
    h4 {
        position: relative;
    }
    .fa.icon {
        top: 9px;
        position: absolute;
        left: 10px;
    }
}

.filters-container a .indicator {
    top: 10px;
}

.adv-src-filter {
    width: 329px;
    a {
        padding: 6px 5px 6px 0;
    }
}

.sidebarDropPanel {
    padding: 0;
}

.card-header a {
    position: relative;
}

#searchfilters_accordion {
    .src-filter-list {
        .card {
            border-bottom: 0 none;
        }
    }
    .card-header {
        border: 0;
        padding: 0;
        a {
            padding: 10px 5px 10px 35px;
            i.indicator {
                left: 10px;
                right: auto;
                top: 5px;
            }
        }
        .fa.icon {
            left: 30px;
            top: 9px;
        }
    }
    .subsearch-filters a i.indicator {
        top: 3px;
    }
}

#heading_searchfilter a h5 {
    text-transform: uppercase;
}

.subsearch-filters a h3 {
    text-transform: capitalize;
}

.d-block-link a.d-block {
    padding: 4px 5px 2px 40px !important;
    position: relative;
}

.side-navigation {
    position: fixed;
    left: 0;
    top: 55px;
    background: $white;
    bottom: 0;
    height: 100%;
    z-index: 1001;
    width: 65px;
    transition: all $time2sec linear;
    .side-nav-button {
        background: $abi-blue;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 65px;
        z-index: 1001;
        .side-bottom-navigation{
            position: fixed;
            bottom: 0;
        }
        button {
            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            border-color: currentcolor currentcolor $grey;
            border-image: none;
            border-style: none none solid;
            border-width: 0;
            clear: both;
            display: block;
            height: 60px;
            text-indent: -999px;
            width: 65px;
            cursor: pointer;
            outline: none;
            position: relative;
            &.nav-active{
                &:before{
                    width: 5px;
                    background: $cerulean !important;
                    left: 0;
                    top: 0;
                    position: absolute;
                    content: "";
                    height: 100%;
                    border: none;
                }
            }
            &.justask-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -120px -570px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 15px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -150px -570px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 15px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.search-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -180px -60px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 15px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll 0 0px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 15px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.saved-search-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -60px -60px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -90px -60px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.mylead-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -120px -60px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -150px -60px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.userAdmin-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -180px -150px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -180px -120px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.leagueTable-nav{
                position: relative;
                background: $transparent none repeat scroll 0 0;
                &:after {
                    background: $transparent url($iconsprite) no-repeat scroll -150px -480px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -180px -480px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.intercom_nav{
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -240px -240px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
            }
            &.teams-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -150px -150px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -150px -120px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.settings-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -30px -360px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -60px -360px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.calendar-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -0px -540px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -30px -540px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.nav-active {
                &:before {
                    border-left:5px solid $transparent;
                    background: $transparent url($iconsprite) no-repeat scroll -30px -540px;
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 0px;
                    overflow: hidden;
                    display: block;
                }
            }
            &.export-nav {
                position: relative;
                background: $abi-blue none repeat scroll 0 0;
                &:after {
                    background: $abi-blue url($iconsprite) no-repeat scroll -30px -300px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll 0px -300px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.nav-active {
                &:before {
                    border-left:5px solid $transparent;
                    background: $transparent url($iconsprite) no-repeat scroll 0px -300px;
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 0px;
                    overflow: hidden;
                    display: block;
                }
            }
            &.help-nav {
                position: relative;
                background: transparent none repeat scroll 0 0;
                &:after {
                    background: $transparent url($iconsprite) no-repeat scroll -60px -90px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 17px;
                    position: absolute;
                    top: 17px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }
                &.nav-active {
                    background: $transparent none repeat scroll 0 0;
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll 0px -300px;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        left: 17px;
                        position: absolute;
                        top: 17px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                    }
                }
            }
            &.nav-active {
                &:before {
                    border-left:5px solid $transparent;
                    background: $transparent url($iconsprite) no-repeat scroll 0px -300px;
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    top: 0px;
                    overflow: hidden;
                    display: block;
                }
            }
        }
        .sidebar_divider{
            border-top: 1px solid $white;
            width: 55px;
            left: 4px;
            position: relative;
            margin:10px 0;
            display: none;
        }
        a {
            -moz-border-bottom-colors: none;
            -moz-border-left-colors: none;
            -moz-border-right-colors: none;
            -moz-border-top-colors: none;
            border-color: currentcolor currentcolor $grey;
            border-image: none;
            border-style: none none solid;
            border-width: 0;
            clear: both;
            display: block;
            height: 60px;
            text-indent: -999px;
            width: 65px;
            cursor: pointer;
            outline: none;
            position: relative;
            &:after {
                background: $transparent url($iconsprite) no-repeat scroll -60px -90px;
                bottom: 0;
                content: "";
                height: 30px;
                left: 17px;
                position: absolute;
                top: 17px;
                width: 30px;
                overflow: hidden;
                display: block;
            }
        }
    }
    .sidebar-left-container h3 {
        color: $black;
    }
}

@keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
.sub-menu{
    animation: fade-in 1s;
    position: fixed;
    background: $white;
    bottom: 10px;
    left: 65px;
    z-index: 100000;
    padding: 10px;
    box-shadow: 1px 0px 6px $dark-black-gray;
    min-width: 130px;
    border-radius: 5px;
    &:before{
        content: "";
        width: 0;
        height: 0;
        border-top: 10px solid $transparent;
        border-bottom: 10px solid $transparent;
        border-right: 10px solid $white;
        position: absolute;
        left: -10px;
        bottom: 10px;
    }
    a{
        display: block;
        color: $dark-black-gray;
        padding:5px 0;
        cursor: pointer;
    }
}
.dismiss-tour{
    position: fixed;
    z-index: 1000000;
    right:30px;
    bottom:30px;
    box-shadow: none;
    font-size: 1.2rem;
}
.side-collapse {
    margin-left: 16px;
}

.custom-sidebar.shown {
    left: 0px !important;
    -webkit-transition: all $time2sec;
    transition: all $time2sec;
}

// .filter-navigation {
//     position: fixed;
//     left: 65px;
//     top: 58px;
//     background: $white;
//     bottom: 0;
//     height: 100%;
//     z-index: 1001;
//     width: 330px;
//     border-right: 1px solid $mercury;
//     transition: all $time2sec linear;
//     .custom-sidebar {
//         .sidebar {
//             left: 65px;
//             width: 329px;
//             // background: $white;
//             box-shadow: none;
//             bottom: 125px;
//             transition: all $time2sec linear;
//         }
//         button.toggle-filters.active {
//             background: $red url($iconsprite) no-repeat scroll -273px -182px;
//             height: 25px;
//             position: absolute;
//             right: -10px;
//             top: 18px;
//             width: 25px;
//             z-index: 1000000;
//             left: auto;
//             border: 0 none;
//             @include border-radius(40px); //overflow: hidden;
//             text-indent: -999px;
//             outline: none;
//             cursor: pointer; // 
//             &:hover {
//                 background: $red url($iconsprite) no-repeat scroll -273px -182px;
//                 height: 25px;
//                 position: absolute;
//                 right: -10px;
//                 top: 18px;
//                 width: 25px;
//                 z-index: 1000000;
//                 left: auto;
//                 border: 0 none;
//                 @include border-radius(40px); //overflow: hidden;
//                 text-indent: -999px;
//                 outline: none;
//                 cursor: pointer;
//                 &:before {
//                     content: '';
//                     background: $transparent url($iconsprite) no-repeat scroll -240px -150px;
//                     animation: slide_right 0.3s ease-in-out 0.2s infinite alternate;
//                     height: 25px;
//                     position: absolute;
//                     right: 22px;
//                     top: -3px;
//                     width: 25px;
//                     z-index: 1000000;
//                     left: auto;
//                 }
//             }
//         }
//         button.toggle-filters {
//             background: $red url($iconsprite) no-repeat scroll -273px -182px;
//             height: 25px;
//             position: absolute;
//             right: -10px;
//             top: 18px;
//             width: 25px;
//             z-index: 1000000;
//             left: auto;
//             border: 0 none;
//             @include border-radius(40px); //overflow: hidden;
//             text-indent: -999px;
//             outline: none;
//             cursor: pointer;
//             &:hover {
//                 background: $red url($iconsprite) no-repeat scroll -273px -182px;
//                 height: 25px;
//                 position: absolute;
//                 right: -10px;
//                 top: 18px;
//                 width: 25px;
//                 z-index: 1000000;
//                 left: auto;
//                 border: 0 none;
//                 @include border-radius(40px); //  overflow: hidden;
//                 text-indent: -999px;
//                 outline: none;
//                 cursor: pointer;
//                 &:before {
//                     content: '';
//                     background: $transparent url($iconsprite) no-repeat scroll -270px -150px;
//                     animation: slide_left 0.3s ease-in-out 0.2s infinite alternate;
//                     height: 25px;
//                     position: absolute;
//                     left: 18px;
//                     top: -3px;
//                     width: 25px;
//                     z-index: 1000000;
//                 }
//             }
//         }
//     }
//     .filter-panel-heading {
//         padding: 10px;
//         border-bottom: 1px solid $light-grey;
//         position: relative;
//         z-index: 1;
//         height: 61px;
//         width: 100%;
//         overflow: hidden;
//         background: $white;
//         .heading-row {
//             h3 {
//                 font-size: 1.20rem;
//                 padding: 0px 0 0px;
//                 margin: 0;
//             }
//             span {
//                 font-size: 0.85rem;
//                 color: $dove-grey;
//                 display: block;
//                 text-overflow: ellipsis;
//                 overflow: hidden;
//                 white-space: nowrap;
//             }
//         }
//         .icon-searchfilter {
//             right: 5px;
//             top: 42px;
//         }
//     }
// }

.side-navigation.side-navigation-without-filter {
    border-right: 0px none $white;
    width: 65px;
}

.sidebar-collapse {
    .filter-navigation {
        width: 0px;
        border-right: 0px none $white;
        .custom-sidebar button.toggle-filters {
            right: -10px;
        }
        .sidebar {
            transition: all $time2sec linear;
            width: 0px;
            overflow: hidden;
            .filter-button-block {
                transition: all $time2sec linear;
                width: 0px;
                overflow: hidden;
                padding: 0;
                opacity: 0;
            }
        }
    }
    .custom-sidebar {
        // display: none;
        transition: all $time2sec linear;
    }
    .main-container {
        margin-left: 0;
        transition: all .2s linear;
    }
    .result-wrapper {
        left: 66px;
        //transition: all $time2sec linear;
        .result-panel-scrollbar {
            display: flex;
            .scroll-area{padding-right: 15px;}
            .empty-message {
                padding: 30px;
            }
        }
    }
    .detail-page-wrapper {
        top: 58px;
        left: 63px;
        transition: all $time2sec linear;
        #slideshow{
            .react-slideshow-container {
                .nav{
                    &.disabled{
                        opacity: 0.3;
                    }
                }
            }
            .react-slideshow-wrapper{
                height: 220px;
                .each-slide{
                    position: relative;
                    .img-src-attribution{
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: auto;
                        z-index: 1;
                        background: rgba(0,0,0,0.6);
                        color: $white;
                        padding: 10px;
                        text-align: left;
                        line-height: 1.0rem;
                        &:empty{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .hide-searchBox {
        top: -25px;
        #textSearch{
            display: none;
        }
    }
}

.sidebar-nav {
    li {
        h4 {
            color: $black;
        }
        a {
            color: $dark-grey;
        }
    }
}

.catering-filter-block h3 {
    margin-bottom: 10px;
}

.timing-filter-wrapper {
    // box-shadow: 0 0 5px $grey inset;
    // padding: 10px;

    h3 {
        text-transform: none;
        padding: 3px 0;
        font-size: 0.75rem;
        margin: 0;
    }
}


/* media query code  */

@media (max-width: 990px) {
    .sidebar {
        position: fixed;
        top: 58px;
        bottom: 50px;
        left: 0;
        z-index: 1000;
        padding: 0;
        width: 290px;
    }
}

@media (max-width: 767px) {
    .sidebar {
        left: -350px;
    }
}


/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .sidebar-collapse {
        .filter-navigation {
            .sidebar {
                left: 65px;
            }
        }
    }
    .sidebarDropPanel {
        .filter-button-block {
            bottom: 20px;
        }
    }
}
.cursor-pointer{
    cursor:pointer;
}