.content-panel {
  padding: 0 15px;
  position: relative;
  top: 0px;
}

.show-full {
  max-width: 100%;
  position: absolute;
  left: 37px;
}

.hide-list {
  .result-wrapper {
    opacity: 0;
    //display: none;
    z-index: -1;
  }
}

.sidebar-collapse {
  .show-full {
    .result-wrapper {
      left: 60px;
    }
  }

  // .search-result {
  // 	.top-5 {
  // 		h1 {
  // 			margin-left: 15px;
  // 		}
  // 	}
  // }
  // .map-panel{
  // 	.map-view{
  // 		left:0;
  // 	}
  // }
}

.map-panel {
  padding-right: 0;
}

.hide-map {
  display: none;
}

.map-panel {
  .map-view {
    border: 1px solid $mercury;
    bottom: 0;
    position: fixed;
    right: 0;
    top: 169px;
    //transition: all 0.2s linear;
    width: 100%;
    z-index: 1;
    background-size: cover;
    left: 65px;
  }

  &.with-project-info {
    .map-view {
      top: 203px;
    }
  }
}

#justAskContainer {


  .result-list-container {
    padding: 0px 0px 10px 10px;

    .listviewtable-block {
      .listNameIcon {
        padding-left: inherit;

        .result-row-icon-placeholder {
          left: inherit;
        }
      }
    }

    .list-group {
      .company-container {
        border: none;
        padding-left: 0px !important;
      }
    }
  }

  .list-view-panel {
    .listviewtable-block {
      &.leftright-space {
        padding-left: 0px;
        padding-right: 15px;
      }

      &.leftright-bothspace {
        padding-left: 0px;
        // padding-right: 15px;
        padding-right: 0;
        margin-right: 0px;
        margin-left: 0;
        margin-bottom: 0 !important;
      }

      .list-col {
        

        .rs-client-name,
        .rs-value,
        .rs-stages,
        span {
          font-weight: 600;
          color: $dark-black-gray;
          font-size: .85rem;
          display: inline-block;
          color: #292b2c;

        }

        .updatesTxt {
          display: inline-block;
          font-weight: 600;

          &.font-weight-bold {
            padding-left: 5px;
            font-weight: bold;
          }
        }

        &.list-col.align-self-center.w-30 {
          padding-left: 55px;
        }
      }
    }


    li.list-group-item {

      .checkmark {
        left: 22px;
        top: 2px;
      }

      /*Read and Unread Bold*/
      .read-block {
        .result-client-name-value {
          font-weight: 600;

          span.rs-client-name,
          span.rs-value {
            color: $dark-black-gray;
          }

          .updatesTxt {
            color: $black;
            display: inline-block;
            font-weight: 600;

            &.font-weight-bold {
              padding-left: 5px;
            }
          }
        }

        .src-date,
        .src-location-placeholder,
        .rs-start-date,
        .rs-end-date {
          font-weight: 600;
        }

        .sub-resultdetails-content {

          .rs-roles span,
          .rs-address,
          .rs-phone {
            font-weight: 600;
            color: $dark-black-gray;
          }

          .updatesTxt {
            color: $black;
            display: inline-block;
            font-weight: 600;

            &.font-weight-bold {
              padding-left: 5px;
            }
          }
        }

        .rs-address,
        .rs-phone,
        .src-date {
          font-weight: 600;
        }

        .list-col {

          .rs-client-name,
          .rs-value,
          .rs-stages,
          span {
            font-weight: 600;
            color: $dark-black-gray;
            font-size: .85rem;
            display: inline-block;
            color: #292b2c;

          }

          .updatesTxt {
            display: inline-block;
            font-weight: 600;

            &.font-weight-bold {
              padding-left: 5px;
              font-weight: bold;
            }
          }
        }

      }
    }
  }


  .map-panel {
    .JSmap-view {
      border: 1px solid $mercury;
      bottom: 0;
      position: fixed;
      right: 0;
      top: 103px;
      //transition: all 0.2s linear;
      width: calc(100vw - 25vw - 28%);
      z-index: 1;
      background-size: cover;
      left: unset;

      .map-search-here {
        position: absolute;
        bottom: 2%;
        left: 50%;
        transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
      }

    }

    &.with-project-info {
      .map-view {
        top: 203px;
      }
    }
  }
}

.detail-page-wrapper {
  .nav-link {
    .notification-pop {
      position: absolute;
      top: -10px;
      background: $red;
      padding: 3px 5px;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      font-size: 0.7rem;
      margin-left: 47px;
      color: $white;
      text-align: center;
      font-weight: 700;
    }
  }

  .updates-details {
    border: 1px solid $grey;

    .update-list {
      padding: 10px;
      border-bottom: 1px solid $grey;

      .time {
        position: absolute;
        right: 40px;
      }
    }
  }
}

.showmore-result {
  .btn.btn-primary {
    max-width: 250px;
    cursor: pointer;
  }

  .btn:focus,
  .btn:active,
  .btn-primary:focus,
  .btn-primary:active {
    background: $abi-blue;
    color: $white;
    @include box-shadow(0, 0, 5px, $abi-blue);

  }
}

.list-view-panel {
  .people-container {
    .listviewtable-block {
      .list-table {
        .list-tablerow {
          .user-icon {
            background-color: $alto;
            padding: 5px 0;
            border-radius: 50%;
            width: 30px;
            text-align: center;
            font-weight: 600;
            font-size: 12px;
            height: 30px;
            display: inline-block;
            margin-right: 10px;
          }

          .result-title-name {
            padding: 0px;
            margin-top: 7px;
            word-break: break-word;
            display: inline-block;
          }

        }
      }
    }
  }
}

.result-list-container.nestedListView {
  padding-left: 10px;
  padding-top: 0 !important;

  .result-list-wrapper {
    border-left: solid 1px $alto;
    border-right: solid 1px $alto;
    height: calc(100vh - 210px);

    .box+div {
      display: none;
    }
  }
}

.result-list-container {
  padding: 10px 12px 10px 10px;
  background: $white;

  li {
    padding: 0;
    border: 0;
    list-style: none;
    position: relative;
    @include border-radius(0px);
  }

  li.list-group-item {
    border: 1px solid $alto;
    border-top: none;
    margin: 0 0 0px;
    @include border-radius(0px);

    &:hover {
      @include box-shadow(0, 0, 10px, $grey);
      border: 1px solid $alto;
      border-top: none;
      position: relative;
    }

    &:first-child {
      border-top: 1px solid $alto;
    }

    .project-container {
      width: 100%;
      position: relative;

      &.mapViewRow {
        padding-left: 0 !important;

        .linkWrapper {
          padding-right: 15px;
        }
      }
    }

    .company-container {
      width: 100%;
      position: relative;

      &.mapViewRow {
        padding-left: 0 !important;

        .linkWrapper {
          padding-right: 15px;
        }
      }
    }

    .people-container {
      width: 100%;
      padding-left: 26px;
      position: relative;
    }

    .container {
      display: block;
      position: absolute;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      top: -12px;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 19px;
      width: 25px;
      height: 25px;
      left: 32px;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 24px;
      left: 12px;
      height: 30px;
      width: 30px;
      background-color: $gallery;
      border-radius: 50%;
      z-index: 9;
      opacity: 0;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input~.checkmark {
      background-color: $grey;
      opacity: 0;

      &:hover {
        background-color: $white;
        opacity: 1;
        border: 1px solid $red;

        // transition: transform 0.8s;
        // transform: rotateY(180deg);
        &::after {
          display: block;
          left: 10px;
          top: 5px;
          width: 8px;
          height: 15px;
          border: solid $red;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked~.checkmark {
      background-color: $cerulean;
      opacity: 1;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked~.checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 10px;
      top: 5px;
      width: 8px;
      height: 15px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .list-group-item {
    -moz-box-align: flex-start;
    align-items: flex-start;
    -webkit-align-items: flex-start;

    &.read-block {
      background-color: $read-block;

      .showIconCounts {
        ul {
          li {
            background: $white;
          }
        }
      }
    }
  }

  .result-block {
    border-radius: 0;
    border-style: none;
    border-width: 0;
    padding: 0.75rem 30px 0.5rem 0; // min-height: 100px;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;

    &.nestedRoles {
      padding-right: 0;
    }

    .result-title-name {
      color: $black-grey;
      font-size: 1.12rem;
      padding: 2px 0;
    }

    .update-title-name {
      color: $black-grey;
      font-size: 0.9rem;
      padding: 2px 0;
    }
  }

  .full-results-block {
    border-radius: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    padding: 0.5rem;
  }

  .related-Company-btn {
    position: absolute;
    right: 16px;
    z-index: 9;
    top: 0;
    border: 0;
    cursor: pointer;
    bottom: 0;
    outline: none;
    width: 22px;
    background-color: transparent;

    &::after {
      background: url($iconsprite) no-repeat scroll -60px -240px;
      content: "";
      height: 30px;
      left: 0;
      position: absolute;
      top: 40%;
      width: 30px;
    }

    &:hover,
    &:focus,
    &:active {
      background: $light-grey;
    }

    &:focus {
      outline: none;
      border: 0;
      box-shadow: none;
    }

    &.active {
      background-color: $whisper;
    }
  }

  .project-show-roles {
    background: $white;
    border: 1px solid $alto;

    &.read-block {
      background: $read-block;

      .showIconCounts {
        ul {
          li {
            background: $white;
          }
        }
      }
    }
  }

  .related-projects,
  .related-Company {
    >div {
      >div {
        border-left: 2px solid $grey;
      }
    }

    &:last-child {
      >div {
        >div {
          border-left: 2px solid $grey;

          &:last-child {
            border-left: 2px solid $grey;
          }
        }
      }
    }

    .project-show-roles {
      margin: 0 0 0px 20px;
      border: 0 none;
      padding: 0;
      border: 1px solid $alto;
      border-bottom: none;

      &:last-child {
        border-bottom: 1px solid $alto;
      }
    }

    .company-container {
      margin: 0 0 0px;
      border: 1px solid $mercury;
    }

    .pro-related-company {
      border-radius: 0;
      margin-bottom: 0px;

      .result-block {
        // background: $white;
        margin-bottom: 0;
        padding-bottom: 4px;
      }

      >span {
        background: $light-grey;
        padding: 0px 5px 5px;
      }
    }

    .project-group-name,
    .company-group-name {
      padding: 5px 0 10px;
      display: block;
      position: relative;
      margin-left: 20px;
      border-left: 2px solid $grey;
      padding-left: 15px;
      font-weight: 600;
      text-decoration: none;
      color: $black-grey;

      &:before {
        background: $white;
        content: "";
        height: 16px;
        left: -9px;
        position: absolute;
        top: 10px;
        width: 16px;
        border: 2px solid $grey;
        border-radius: 50%;
      }

      span {
        display: inline-block;
        padding: 2px 5px 2px 0;
        background: $white;
        border: 1px solid $grey;
        margin-left: 5px;
        position: relative;
        height: 27px;

        &::before {
          background: url($iconsprite) no-repeat scroll -90px -270px;
          content: "";
          position: absolute;
          width: 15px;
          height: 30px;
          left: -15px;
          top: -2px;
        }
      }
    }

    .company-role-name {
      padding: 0 0 0;
      display: block;
      position: relative;
      font-weight: 600;

      &:before {
        background: $red;
        content: "";
        height: 12px;
        left: -22px;
        position: absolute;
        top: 5px;
        width: 12px;
        border: 2px solid $white;
        border-radius: 50%;
      }
    }

    >div {
      >div {
        border-left: 2px solid $grey;
        padding: 0 0 4px 15px;
        margin: -5px 0 0 20px;
      }
    }
  }

  .list-group {
    .company-container {
      border: none;
      padding-left: 26px;
    }
  }
}

.related-projects {
  >div {
    >div {
      border-left: 1px solid $grey !important;
    }
  }

}

.project-container {
  padding-left: 26px;

  &.read-block {
    background-color: $read-block;

    .showIconCounts {
      ul {
        li {
          background: $white;
        }
      }
    }
  }
}

.result-row-icon-placeholder {
  left: 10px;
  top: 10px;
  position: absolute;
  width: 30px;
}

.result-row-block {
  min-height: 75px;
}

.result-row-overview {
  display: inline-block;
  padding: 0 0 0 50px;
  width: 100%;

  // min-height: 115px;
  .rs-address {
    color: $black;

    &::after {
      background: $black;
      content: "";
      height: 4px;
      position: static;
      display: inline-block;
      margin: 3px 10px;
      width: 4px;
      right: 8px;
      bottom: 7px;
      border-radius: 100px;
    }
  }

  .rs-phone {
    color: $black;
    display: inline-block;
  }
}

.result-row-button-block {
  //  border-top: 1px solid $blue-grey;
  //  width: 100%;
  //  text-align: right;
  float: none;
}

.right-block {
  float: none;
}

.sub-resultdetails-content {
  font-size: 0.85rem;
  color: $black;

  .showIconCounts {
    margin: 5px 0;
  }

  strong {
    margin: 0 5px 0 0;
    display: none;
  }

  .rs-roles {
    color: $emperor-gray;
    padding: 0 0 1px 0;

    span {
      margin: 0 2px 0 0;

      &:after {
        content: ",";
      }
    }

    span:last-child {
      &:after {
        content: "";
      }
    }

    .roles-count {
      font-weight: 600;

      .plusIcon {
        margin-left: 4px;
        margin-right: -1px;
      }
    }

    .rs-address {
      color: $emperor-gray;
      padding: 0 0 1px 0;

      span {
        margin: 0 2px 0 0;

        &:after {
          background: $black;
          content: "";
          height: 4px;
          position: static;
          display: inline-block;
          margin: 3px 10px;
          width: 4px;
          right: 8px;
          bottom: 7px;
          border-radius: 100px;
        }
      }
    }
  }

  .rs-address {
    &::after {
      background: $black;
      content: "";
      height: 4px;
      position: static;
      display: inline-block;
      margin: 3px 10px;
      width: 4px;
      right: 8px;
      bottom: 7px;
      border-radius: 100px;
    }
  }

  .rs-phone {
    display: inline-block;
    word-break: break-word;
  }
}

.rs-stages,
.result-client-name-value {
  margin: 0 0 2px 0;
}

.result-stages {
  margin: 5px 0;
}

.result-client-name-value {
  span.rs-client-name {
    padding-right: 0;
    color: $black-grey;
    position: relative;

    &:after {
      background: $black;
      content: "";
      height: 4px;
      position: static;
      display: inline-block;
      margin: 3px 10px;
      width: 4px;
      right: 8px;
      bottom: 7px;
      @include border-radius(100px);
    }

    color: $emperor-gray;
    padding: 0 0 1px 0;

    span {
      margin: 0 2px 0 0;

      &:after {
        content: ",";
      }
    }

    span:last-child {
      &:after {
        content: "";
      }
    }

    .roles-count {
      font-weight: 600;

      .plusIcon {
        margin-left: 4px;
        margin-right: -1px;
      }
    }
  }

  span.rs-opening-date {
    padding-left: 0;
    color: $black-grey;
  }

  span.rs-value {
    padding-right: 0;
    position: relative;
    color: $black-grey;

    &:after {
      background: $black;
      content: "";
      height: 4px;
      position: static;
      display: inline-block;
      margin: 3px 10px;
      width: 4px;
      right: 8px;
      top: 7px;
      @include border-radius(100px);
    }
  }

  span {
    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.result-stages,
.rs-stages {
  position: relative;

  >span {
    // background: $blue-grey none repeat scroll 0 0;
    // border: 1px solid $alto;
    // border-radius: 5px;
    // margin: 0 5px 5px 0;
    padding: 0 2px;
    font-size: 0.85rem;
    display: inline-block;
    color: $black-grey;
  }

  // &::before {
  // 	background: $black;
  // 	content: "";
  // 	height: 4px;
  // 	position: absolute;
  // 	width: 4px;
  // 	left: 8px;
  // 	top: 6px;
  // 	border-radius: 50%;
  // }
}

.day-badge .badge-default {
  background: $green;
}

.main-container {
  margin-left: 0;
  transition: all 0.2s linear;
  z-index: 0;
  padding: 0 0 0 65px;
}

#justAskContainer {

  .main-container {
    height: 100%;
    margin-left: 0;
    transition: all 0.2s linear;
    z-index: 0;
    padding: 0 0 0 0px;

    .search-result,
    .result-wrapper,
    .result-list-wrapper {
      height: 100%;
    }

    .search-list-content.hierarchy-row,
    .forNestedprojects.hierarchy-row  {
      height: calc(100vh - 104px);

      .result-list-container.content-panel,
      .result-list-container.content-panel.nestedListView  {
        padding-right: 16px;
      }
    }
  }

}

.maplist-container {
  position: relative;
  left: 16px;
  top: 12px;

  a {
    display: inline-block;
    font-size: 0.875rem;
    padding: 0.15rem 0.5rem;
    text-decoration: none;
  }
}

.day-badge {
  position: absolute;
  top: -10px;
  left: 15px;
}

.day-badge .badge {
  padding: 5px 10px;
  font-family: arial, helvetic, sans-serif;
  font-weight: bold;
  font-size: 0.7rem;
  border-radius: 20px;
}

.src-location-placeholder {
  padding: 4px 15px 5px 20px;
  position: relative;
  font-size: 0.8rem;
  margin: 0 0 0 -1px;
}

.src-location-placeholder:before {
  background: $transparent url($iconsprite) no-repeat scroll 0px -210px;
  content: "";
  height: 30px;
  left: -8px;
  position: absolute;
  top: -2px;
  width: 30px;
}

.src-date {
  padding: 4px 5px 5px 23px;
  position: relative;
  font-size: 0.8rem;
  display: inline-block;

  .src-latest {
    &.role-added {
      padding-left: 27px;

      &:before {
        display: inline-block;
        content: 'Added';
        border: 1px solid #000;
        padding: 4px 5px;
        background: none;
        width: auto;
        line-height: 1;
        color: #000;
        font-weight: normal;
        margin-right: 10px;
        height: auto;
        top: 1px;
      }
    }

    &:before {
      background: $transparent url($iconsprite) no-repeat scroll -30px -210px;
      content: "";
      height: 30px;
      left: -4px;
      position: absolute;
      top: -2px;
      width: 30px;
    }

  }

  .src-miles {}

  .src-count {
    &:before {
      background: $transparent url($iconsprite) no-repeat scroll -240px -300px;
      content: "";
      height: 30px;
      left: -4px;
      position: absolute;
      top: -2px;
      width: 30px;
    }
  }

  .src-value {
    &:before {
      background: $transparent url($iconsprite) no-repeat scroll -270px -300px;
      content: "";
      height: 30px;
      left: -4px;
      position: absolute;
      top: -2px;
      width: 30px;
    }
  }
}

.rs-start-date {
  padding: 0 10px 0 30px;
  position: relative;
  color: $dove-grey;
  line-height: 1rem;
  font-size: .8rem;

  &:before {
    background: $transparent url($iconsprite) no-repeat scroll -30px -480px;
    content: "";
    height: 30px;
    left: -4px;
    position: absolute;
    top: -5px;
    width: 30px;
  }
}

.rs-end-date {
  padding: 0 10px 0 30px;
  position: relative;
  color: $dove-grey;
  line-height: 1rem;
  font-size: .8rem;

  &:before {
    background: $transparent url($iconsprite) no-repeat scroll -60px -480px;
    content: "";
    height: 30px;
    left: -4px;
    position: absolute;
    top: -5px;
    width: 30px;
  }
}

.sub-resultdetails-content strong {
  color: $azure-radiance;
}

.sub-resultdetails-content .contact-name strong {
  color: $dark-black-gray;
  font-weight: 600;
}

.sub-resultdetails-content strong {
  font-weight: 600;
}

/*List View result*/
//   .listviewtable-block {
//     display: none;
//   }
.list-view-panel {
  .listviewtable-block {
    margin-left: 4px;
    display: block;

    &.list-header-fix {
      position: fixed;
      z-index: 9999;
      width: calc(100% - 83px);
    }

    &.bggray {
      background: $alto;
      margin-left: 0;
    }

    &.leftright-space {
      padding-left: 26px;
      padding-right: 0px;
    }

    &.leftright-bothspace {
      padding-left: 26px;
      // padding-right: 15px;
      padding-right: 0;
      margin-right: 0px;
      margin-left: 0;
      margin-bottom: 0 !important;
    }

    &.visible {
      // visibility: visible;
      //width: 100%;
      opacity: 1;
      transition: width 0.2s linear;
    }

    &.hidden {
      // visibility: hidden;
      width: 0px;
      opacity: 0;
      transition: width 0.2s linear;
    }

    .list-table {
      .list-header {
        background: $alto;

        &::after {
          content: "";
          display: block;
          float: none;
          clear: both;
        }
      }

      .list-col {
        color: $black-grey;
        font-weight: bold;
        padding: 15px 8px;
      }

      .list-tablerow {
        &::after {
          content: "";
          display: block;
          float: none;
          clear: both;
        }

        .result-title-name {
          font-size: 0.9rem;
          padding-left: 35px;
          margin-bottom: 0;

          strong {
            font-weight: 500;
          }
        }

        .showIconCounts {
          padding-left: 30px;
        }

        .list-col {
          font-weight: 400;
          padding: 10px 8px;
          word-break: break-word;

          // position: relative;
          &.list-contact-name {
            padding: 10px 7px;
          }
        }

        .list-flag {
          padding: 0;
        }

        .result-row-icon-placeholder {
          //margin-top: -15px;
          top: 10px;
        }
      }

      .truncate-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .result-wrapper {
    .result-list-container {
      .related-Company-btn {
        right: auto;
        left: 0;
        background: transparent;
        box-shadow: none !important;
        height: 100%;
        margin-top: 0px;

        &::after {
          top: 10px;
          margin-top: 0;
        }
      }

      li.list-group-item {

        .company-container,
        .project-container {
          padding-left: 26px;

          .result-row-icon-placeholder {
            // margin-top: -25px;
          }
        }

        .checkmark {
          left: 38px;
          top: 2px;
        }

        .container,
        .result-row-icon-placeholder {
          top: 10px;
          //margin-top: -15px;
        }

        .icon-read {
          margin: 0;
          display: inline-block;
          vertical-align: middle;
        }

        .src-location-placeholder {
          padding: 0;

          &::before {
            display: none;
          }
        }

        .src-date {
          padding: 0;

          .src-latest:before,
          .src-date .src-miles:before {
            display: none;
          }
        }
      }

      .showmore-result {

        // margin-bottom: 40px;
        .btn.btn-primary {
          max-width: 250px;
          cursor: pointer;
        }
      }

      .company-container {
        .listNameIcon {
          margin-right: 50px;
        }
      }

      .result-block {
        padding-right: 0;
        padding-top: 0;

        .favourite-icon {
          position: static;
        }

        .unfavourite-icon {
          position: static;
        }

        button {
          vertical-align: middle;
          border: none;
          height: 18px;
          width: 18px;
          margin-right: 2px;
          display: inline-block;
          margin-top: -5px;

          &.share-icon {
            background-position: -66px -36px;
          }

          &.tag-icon {
            background: $transparent url(#{$iconsprite}) no-repeat scroll -185px -186px;
            margin-right: 0;
          }

          &.edit-icon {
            background: $transparent url(#{$iconsprite}) no-repeat scroll -276px -276px;
            margin-right: 0;
          }
        }
      }

      .result-row-button-block,
      .right-block {
        float: none;

        >div {
          display: inline-block;
          vertical-align: middle;
          min-width: 12px;
          color: $abi-blue;

          button+span {
            font-size: 12px;
          }

          .new-pro-flag,
          .project-build-phase-pro-flag,
          .archive-badge-flag {
            color: $white;
            font-size: 13px;
          }
        }
      }

      @include for-medium-desktop {
        width: 1300px;
      }
    }
  }

  .project-show-roles {
    position: relative;
    border-top: 0;
    border-left: 0;
    border-right: 0;

    a {
      .listviewtable-block {
        padding-left: 20px;

        &.leftright-space {
          padding-left: 30px !important;
        }

        .result-row-icon-placeholder {
          left: 30px;
        }
      }
    }

    .related-Company-btn:after {
      top: 46%;
      transform: rotate(90deg);
    }
  }

  .forNestedprojects {
    &.hierarchy-row {
      height: calc(100vh - 175px);
      overflow-y: auto;

      &.with-project-info {
        height: calc(100vh - 195px);
      }
    }

    .people-container {
      position: relative;
      border-top: 0;
      border-left: 0;
      border-right: 0;

      a {
        .listviewtable-block {
          padding-left: 20px;

          &.leftright-space {
            padding-left: 30px !important;
          }

          .result-row-icon-placeholder {
            left: 30px;
          }
        }
      }

    }

    .result-list-wrapper {
      height: auto !important;
    }
  }

  .nestedListView {
    .listviewtable-block {
      &.bggray {
        background: #eeeff2;
        border-bottom: #d8d8d8 solid 1px;
      }

      &.lr-spaceHierachy {
        margin: 0px;
        padding: 0px;
      }

      .list-table {
        .list-header {
          background: transparent;
          padding-left: 22px;
          margin-left: 4px;
        }

        // .list-col {
        //   color: #9b9b9b;
        // }
      }
    }

    .hierachyHeight {
      height: calc(100vh - 350px);
      overflow-y: auto;
    }

    .related-Company-list {

      // background: #eeeff2;
      .company-group-name {
        display: inline-block;
        padding-left: 10px;
        color: $black-grey;
        margin-top: 11px;
        margin-bottom: 11px;

        span {
          font-weight: 700;
        }
      }

      .company-role-name {
        color: #959595;
        display: inline-block;
        padding-left: 35px;
        font-weight: 700;
        margin-top: 4px;
      }

      .nestedListInside {
        &.pro-related-company {
          a {
            border-bottom: #d8d8d8 solid 1px;

            .listviewtable-block {
              padding-left: 22px;

              .result-row-icon-placeholder {
                left: 30px;
              }
            }

            &.result-block {
              padding-left: 0;
              padding-right: 0;
            }
          }
        }
      }
    }

    .company-container {
      border: none;
    }

    .linkedin-list-view {
      top: 85px;
    }
  }

  .src-location-placeholder {
    padding: 0;

    &:before {
      display: none;
    }
  }

  .src-date {
    padding: 0;

    .src-miles:before,
    .src-latest:before,
    .src-value::before {
      display: none;
    }
  }

  .src-count {
    &:before {
      display: none;
    }
  }

  .rs-address {
    &:after {
      display: none;
    }
  }

  .icon-read {
    margin-left: 0;
  }

  .result-list-wrapper {
    background: $white;
    padding-top: 10px;
  }

  /*project and company name hover icon*/
  .result-list-container.projectNameIcon,
  .project-show-roles,
  .pro-related-company,
  .company-container,
  .simpleListSearch {

    .result-row-icon-placeholder,
    .container {
      // margin-top: -25px !important;
    }
  }

  .list-tablerow.d-flex {
    min-height: 65px;
  }
}

.linkedin-icon {
  position: absolute;
  right: 16px;
  z-index: 10;
  top: 10px;
  border: 0;
  cursor: pointer;
  bottom: 0;
  height: 30px;
  width: 30px;

  &::after {
    background: url($iconsprite) no-repeat scroll 0 -420px;
    content: "";
    height: 30px;
    right: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }
}

.related-linkdin {
  right: 12px;
}

.linkedin-list-view {
  left: 31%;
  top: 75px;
  margin-left: -15px;
  margin-top: -15px;

  &.entity-linkedin-view {
    left: 31%;
  }
}

.result-list-container li.list-group-item .people-container .linkedin-list-view,
.result-list-container li.list-group-item .company-container .linkedin-list-view {
  top: 24px;
}

.contact-linkedin-view {
  left: 316px;
  top: 14px;
}

.list-linkedinview {
  position: relative;
}

.padding-left0 {
  padding-left: 0 !important;
}

.listviewtable-block.innerListView+.company-container {
  .result-row-icon-placeholder {
    left: 30px;
  }

  .listviewtable-block {
    padding-left: 20px;
  }
}

/*List view result end*/
.search-list-content {
  &.hierarchy-row {
    height: calc(100vh - 175px);
    overflow-y: auto;

    &.with-project-info {
      height: calc(100vh - 195px);
    }
  }

  .result-list-container {
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }
}

/* media query code  */

@media (max-width: 990px) {
  .main-nav-container {
    left: 305px;
  }
}

@media (max-width: 767px) {
  .main-container {
    margin-left: 0px;
  }

  .main-nav-container {
    left: 0;
  }
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 980px) {
  .main-container {
    margin-left: 45px;
  }

  .result-wrapper {
    left: 60px;
  }

  .detail-page-wrapper {
    left: 52px;
  }

  .result-list-container.content-panel {
    padding: 0 15px 0 15px;
  }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {
  .src-date {
    .src-latest {
      &:before {
        background-image: url($retina-icon-sprite);
        background-size: 300px;
      }
    }

    .rs-start-date {
      &:before {
        background-image: url($retina-icon-sprite);
        background-size: 300px;
      }
    }

    .rs-end-date {
      &:before {
        background-image: url($retina-icon-sprite);
        background-size: 300px;
      }
    }
  }

  .sort-results .dropdown .dropdown-toggle::after {
    background-image: url($retina-icon-sprite);
    background-size: 300px;
  }
}

.switch-panel-filter {
  .switch-filter {
    position: absolute;
    top: 10px;
    right: 400px;

    span {
      padding: 0 10px 0 0;
      margin-top: -4px;
    }
  }
}

.search-result.list-view-panel {
  .result-list-wrapper {
    .scroll-area {
      margin-bottom: 0 !important;
      overflow-y: scroll !important;
      overflow-x: hidden !important;
    }
  }
}

.content-linkedIn {
  .linkedin-icon {
    right: 10px;
  }
}

.forNestedprojects {
  &.hierarchy-row {
    height: calc(100vh - 155px);
  }
}

.switch-panel-filter {
  .switch-filter {
    position: absolute;
    top: 10px;
    right: 400px;

    span {
      padding: 0 10px 0 0;
      margin-top: -4px;
    }
  }
}