/* ============================================================
  COMMON - SWITCH COMPONENT
============================================================ */

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.cmn-toggle+label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}


/* ============================================================
  SWITCH 1 - ROUND
============================================================ */

input.cmn-toggle-round+label {
    padding: 2px;
    width: 30px;
    height: 17px;
    background-color: $alto;
    @include border-radius(60px);

    &:before {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        content: "";
        right: 1px;
        background-color: $wildsand;
        @include border-radius(60px);
        -webkit-transition: background 0.4s;
        -moz-transition: background 0.4s;
        -o-transition: background 0.4s;
        transition: background 0.4s;
    }

    &:after {
        display: block;
        position: absolute;
        top: 1px;
        left: 1px;
        bottom: 1px;
        content: "";
        width: 15px;
        background-color: $white;
        @include border-radius(100%);
        @include box-shadow(0, 2px, 5px, rgba(0, 0, 0, 0.3));
        -webkit-transition: margin 0.4s;
        -moz-transition: margin 0.4s;
        -o-transition: margin 0.4s;
        transition: margin 0.4s;
    }
}

input.cmn-toggle-round:checked+label:after {
    margin-left: 15px;
}

.pcs-filter-block,
.preset-filter-block {
    position: relative;
}

.switch {
    position: absolute;
    top: 6px;
    right: 10px;
}

.timing-filter-panel {
    .preset-filter-block {
        border-top: 1px solid $grey;
        padding: 10px 0;
    }

    .switch {
        right: 0px;
    }
}

.construction-ends-filter-block,
.construction-starts-filter-block {
    border-top: 1px solid $grey;
    padding: 10px 0;
}


.rc-slider-tooltip {
    z-index: 1000;
}


/* ============================================================
  TOGGLE SWITCH - ROUND
============================================================ */

input.cmn-toggle-switch+label {
    padding: 0;
    width: 100%;
    @include border-radius(60px);

    &:before {
        display: block;
        position: absolute;
        top: 0;
        left: auto;
        bottom: 1px;
        content: "";
        right: 1px;
        width: 30px;
        background-color: $wildsand;
        height: 17px;
        border: 1px solid $alto;
        background-color: $light-grey;
        @include border-radius(60px);
        -webkit-transition: background 0.4s;
        -moz-transition: background 0.4s;
        -o-transition: background 0.4s;
        transition: background 0.4s;
        // @include box-shadow(0, 0, 2px, $black, inset);
    }

    &:after {
        display: block;
        position: absolute;
        top: 0;
        right: 15px;
        bottom: 1px;
        content: "";
        width: 15px;
        margin: 1px;
        height: 15px;
        background-color: $white;
        @include border-radius(100%);
        @include box-shadow(0, 2px, 5px, rgba(0, 0, 0, 0.3));
        -webkit-transition: margin 0.4s;
        -moz-transition: margin 0.4s;
        -o-transition: margin 0.4s;
        transition: margin 0.4s;
    }
}

input.cmn-toggle-switch:checked+label:after {
    right: 0px;
}

.pcs-filter-block,
.preset-filter-block {
    position: relative;
}

.st-label-text {
    padding: 0 40px 0 0;
    display: block;
    line-height: 1.25rem;
    font-size: 0.75rem;
}

.st-hint-text {
    font-size: 0.75rem;
    padding: 0 40px 0 0;
    display: block;
    color: $dark-grey;
    margin-top: -5px;
}

.rc-slider-tooltip {
    z-index: 1000;
}

.rc-slider-disabled {
    background-color: $transparent;
}

.role-filter-block {
    .select-toggle {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 0.8rem;
    }
}

.select-toggle .toggle-link {
    display: inline-block;
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline !important;

    &.active {
        opacity: 1;
    }

    &.disabled {
        opacity: 0.5;
    }
}

.select-toggle .toggle-link:first-child {
    margin-right: 10px;
}


.switch-with-text {
    position: relative;
    height: 26px;
    width: 120px;
    margin: 10px;
    background: $abi-blue;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);

    &.disabled{
        opacity: 0.3;
        cursor: default;
        pointer-events: none;
    }

    .switch-label {
        position: relative;
        z-index: 2;
        float: left;
        width: 58px;
        line-height: 26px;
        font-size: 13px;
        color: $white;
        text-align: center;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
        cursor: pointer;
    }

    .switch-label:active {
        font-weight: bold;
    }

    .switch-label-off {
        padding-left: 2px;
    }

    .switch-label-on {
        padding-right: 2px;
    }


    .switch-input {
        display: none;
    }

    input[type=radio]:checked~label {
        background: $transparent;
    }

    .switch-input:checked+.switch-label {
        font-weight: bold;
        color: $abi-blue;
        text-shadow: 0 1px rgba(255, 255, 255, 0.25);
        -webkit-transition: 0.15s ease-out;
        -moz-transition: 0.15s ease-out;
        -ms-transition: 0.15s ease-out;
        -o-transition: 0.15s ease-out;
        transition: 0.15s ease-out;
        -webkit-transition-property: color, text-shadow;
        -moz-transition-property: color, text-shadow;
        -ms-transition-property: color, text-shadow;
        -o-transition-property: color, text-shadow;
        transition-property: color, text-shadow;
    }

    .switch-input:checked+.switch-label-on~.switch-selection {
        left: 60px;
        /* Note: left: 50%; doesn't transition in WebKit */
    }

    .switch-selection {
        position: absolute;
        z-index: 1;
        top: 2px;
        left: 2px;
        display: block;
        width: 58px;
        height: 22px;
        border-radius: 3px;
        background-color: $cerulean;
        -webkit-box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
        -webkit-transition: left 0.15s ease-out;
        -moz-transition: left 0.15s ease-out;
        -ms-transition: left 0.15s ease-out;
        -o-transition: left 0.15s ease-out;
        transition: left 0.15s ease-out;
    }
}
