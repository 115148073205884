@import '../common/base/colors';

#justAskContainer {
    background-color: #fff;
    display: flex;
    height: 100vh;
    padding-top: 57px;
    padding-left: 77px;

    .allowOveflowY {
        overflow-y: auto;
    }


    .leftPanel,
    .rightPanel {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        /* Align items at the top and bottom */
    }

    .leftPanel {
        background: #dbeafb;

        .leftPanel-content {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        .examples {
            margin-top: 5px;
        }

        .example-btn {
            width: 100%;
            font-size: 0.82rem;
            /* Inherit the font size */
            color: $abi-blue;
            /* $abi-blue font color */
            border-color: $abi-blue;
            /* $abi-blue border color */
            border-radius: 20px;
        }

        .example-btn:hover {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            /* Minimalistic shadow on hover */
            background-color: darken(#dbeafb, 10%);
        }

        .example-btn:focus {
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
            /* Minimalistic shadow on hover */
            background-color: darken(#dbeafb, 10%);
        }


        .chat-screen {
            overflow-y: auto;
            max-height: calc(100vh - 250px);
            /* Adjust as needed to fit the layout */
        }

        .chat-container {
            display: flex;
            flex-direction: column;
        }

        .chat-bubble {
            max-width: 80%;
            padding: 10px;
            border-radius: 15px;
            margin-bottom: 10px;
        }

        .user-bubble {
            align-self: flex-start;
            background-color: white;
            color: black;
            border: 1px solid #ccc;
            cursor: pointer;
            position: relative;
        }

        .ai-bubble {
            align-self: flex-end;
            background-color: #00b1eb;
            color: white;
            position: relative;
        }

        .ai-bubble-latest {
            background-color: #053874 !important;
        }

        .emoji-section {
            align-self: flex-end;
            position: relative;
            margin-top: -26px;
            margin-right: 20px;
            font-size: 20px;
        }

        .unselected-emoji {
            filter: grayscale(100%);
        }

        .user-bubble:before {
            border-color: #fff #fff #0000 #0000;
            border-style: solid;
            border-width: 7px;
            bottom: auto;
            content: " ";
            height: 0;
            left: -13px;
            position: absolute;
            right: auto;
            top: 12px;
            width: 0;
        }

        // .ai-bubble:after{
        //     content: ' ';
        //     position: absolute;
        //     width: 0;
        //     height: 0;
        //     left: auto;
        //     right: 38px;
        //     bottom: -20px;
        //     border-style: solid;
        //     border-width: 7px;
        //     border-color: transparent transparent #053874 #053874;
        // }

        .icon-background {
            background-color: white;
            /* Set background color of the icon */
        }

        .search-bar .search-btn {
            margin-left: 10px;
        }

        // .new-search-btn {
        //     font-size: inherit;
        //     background: transparent;
        //     /* Transparent background */
        //     color: #CFCFD0;
        //     /* Initial font color */
        //     border-color: #CFCFD0;
        //     /* Initial border color */
        // }

        // .new-search-btn:enabled {
        //     background: #00b1eb;
        //     /* #00b1eb background when enabled */
        //     color: white;
        //     /* White font color when enabled */
        //     border-color: #00b1eb;
        //     /* #00b1eb border color when enabled */
        // }

        .loading {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 10px;
            width: 70px;
            margin-left: 12px;
        }

        .dot {
            width: 7px;
            /* Dot size */
            height: 7px;
            margin-right: 5px;
            background-color: white;
            /* Dot color */
            border-radius: 50%;
            opacity: 0;
            /* Start invisible */
            animation: fade 1.2s infinite;
        }

        .dot:nth-child(1) {
            animation-delay: 0s;
        }

        .dot:nth-child(2) {
            animation-delay: 0.4s;
        }

        .dot:nth-child(3) {
            animation-delay: 0.8s;
        }

        @keyframes fade {

            0%,
            100% {
                opacity: 0;
                transform: scale(0.8);
            }

            50% {
                opacity: 1;
                transform: scale(1);
            }
        }

        .new-search-btn {
            border: 1px solid $cerulean;
            background-color: #dbeafb;
            color: $cerulean;
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            line-height: 14px;
            margin: 0px;
            height: 38px;
            border-radius: inherit;
            min-width: 111px;
            position: relative;
            border-radius: 4px;

            span {
                position: absolute;
                right: -5px;
                top: -5px;
                border: $abi-red solid 1px;
                width: 21px;
                height: 21px;
                border-radius: 50%;
                line-height: 18px;
                text-align: center;
                background-color: $abi-red;
                font-size: 11px;
                color: $white;
                font-weight: 600;
            }

            &:hover {
                box-shadow: none;

                span {
                    color: $abi-red;
                    background-color: $white;
                }
            }

            &:active,
            &:focus,
            &:hover {
                background: $cerulean;
                color: $white;
            }
        }

        .form-control {
            // border-radius: 0 !important;
            border-top-left-radius: 0.375rem !important;
            border-bottom-left-radius: 0.375rem !important;
            border-right: none;
            font-weight: 400;
            color: #464a4c;
        }

        .form-control:focus {
            color: #464a4c;
            background-color: #fff;
            //    border: 1px solid #5cb3fd !important;
            border-right: none;
            //    box-shadow: none !important;
            outline: none;
            //    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
        }

        .react-icons {
            color: $cerulean;
        }

    }

    .rightPanel {

        .mapboxgl-canvas {
            // width: calc(100vw - 450px - 25%) !important;
        }

        background-color: #ffffff;
        // text-align: center;
        justify-content: center;

        .content {
            width: 75%;
            margin: 0 auto;
            /* Center align the container horizontally */
            text-align: left;
            /* Ensure text is left-aligned */

            .logo {
                display: block;
                margin: 0 auto 20px auto;
                /* Center the logo and add bottom margin */
            }

            h3 {
                font-size: inherit;
                /* Same font size as list items */
                font-weight: bold;
                /* Make the h3 bold */
            }

            ul {
                list-style-type: disc;
                /* Default list style */
                padding-left: 20px;
                /* Ensure list items have left indentation */
            }

            ul li {
                list-style-type: disc;
                margin-bottom: 10px;
                /* Increase gap between list items */
            }

            ul li::marker {
                color: #508EE4;
                /* Cyan color for list markers */
            }
        }

    }

}