.company-details {
    .location-name-distance {
      top: 159px;
    }
    .top-info {
      position: relative;
    }
    .company-overview {
        bottom: 0;
        min-height: 50px;
        position: relative;
        background: rgba(255, 255, 255, 0.85);
        padding: 5px 0 0 50px;
        left: 0;
        right: 0;
        display: block;

        h2 {
            color: #292b2c;
            font-size: 1.5rem;
            margin: 0 0 5px;
            font-weight: 700;
        }

        .contacts-company, .contacts-subheader{
            font-size: 1rem;
        }
        .contacts-company{
            position: relative;
            padding-left: 20px;
            &:before{
                content: "";
                width: 30px;
                height: 30px;
                background: $transparent url($iconsprite) no-repeat scroll -120px -360px;
                left: -8px;
                position: absolute;
                top: -6px;
            }
            .company-redirection{
                cursor: pointer;
                text-decoration: none;
                color: inherit;
            }
        }
    }
  }

  .icon-contact {
    background: $transparent url($iconsprite) no-repeat scroll -120px -210px;
    height: 30px;
    left: 13px;
    position: absolute;
    width: 30px;
}