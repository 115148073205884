%icon-position {
    height: 30px;
    position: absolute;
    width: 30px;
}

.icon-addon {
    position: relative;
    .fa {
        font-size: 1.2em;
        left: 10px;
        position: absolute;
        top: 8px;
    }
    input {
        padding-left: 30px;
    }
}

.icon-cog {
    display: inline-block;
    float: right;
    height: 30px;
    margin: -5px 5px 0;
    width: 30px;
    background: $transparent url($iconsprite) no-repeat scroll -60px 0;
}

.icon-searchfilter {
    background: $transparent url($iconsprite) no-repeat scroll -30px 0;
}

.icon-white-bg-round {
    background-color: $white;
    border-radius: 30px;
}

.icon-mainsearch {
    @extend %icon-position;
    left: 0;
    top: 0;
}

.icon-searchfilter {
    @extend %icon-position;
}

.icon-project {
    display: block;
    height: 30px;
    width: 30px;
}

.icon-company {
    display: block;
    height: 30px;
    width: 30px;
    margin-top: 0;
}

.icon-resultlist-favourite {
    display: block;
    height: 50px;
    width: 50px;
}

.icon-unfavourite {
    display: none;
}

.icon-read {
    display: block;
    height: 30px;
    width: 30px;
    margin-left: 8px;
}

.icon-read {
    // background: $transparent url($iconsprite) no-repeat scroll -180px 0;
    background-color: $cerulean;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-position: center;
    margin-left: 10px;
    margin-top: 5px;
}

.icon-unfavourite {
    background: $transparent url($iconsprite) no-repeat scroll -150px 0;
}

.__react_component_tooltip.place-right {
    z-index: 9999;
}

.sidebar-mini .__react_component_tooltip.show {
    opacity: 1;
}

.w-30px{
    width: 30px;
    height: 30px;
    border-radius: 2px;
}
.blue-bg{
    background: $abi-blue;
}
.icon-placeholder{
    height: 30px;
    display: inline-block;
    width: 30px;
    &.ico-src{
        background: $transparent url($iconsprite) no-repeat scroll 0 -510px;
    }
    &.ico-savedsrc{
        background: $transparent url($iconsprite) no-repeat scroll -30px -510px;
    }
    &.ico-league{
        background: $transparent url($iconsprite) no-repeat scroll -60px -510px;
    }
    &.ico-myleads{
        background: $transparent url($iconsprite) no-repeat scroll -90px -510px;
    }
    &.ico-export{
        background: $transparent url($iconsprite) no-repeat scroll -120px -510px;
    }
    &.ico-teams{
        background: $transparent url($iconsprite) no-repeat scroll -150px -510px;
    }
    &.ico-admin{
        background: $transparent url($iconsprite) no-repeat scroll -180px -510px;
    }
    &.ico-chat{
        background: $transparent url($iconsprite) no-repeat scroll -210px -510px;
    }
    &.ico-help{
        background: $transparent url($iconsprite) no-repeat scroll -240px -510px;
    }
    &.ico-feed{
        background: $transparent url($iconsprite) no-repeat scroll -90px -420px;
    }
    &.ico-default{
        background: $transparent url($iconsprite) no-repeat scroll -30px -330px;
    }
    &.ico-edit{
        background: $transparent url($iconsprite) no-repeat scroll -150px -90px;
    }
    &.ico-share{
        background: $transparent url($iconsprite) no-repeat scroll -90px -450px;
    }
    &.ico-delete{
        background: $transparent url($iconsprite) no-repeat scroll -180px -90px;
    }
    &.ico-projectss{
        background: $transparent url($iconsprite) no-repeat scroll -180px -270px;
    }
    &.ico-companyss{
        background: $transparent url($iconsprite) no-repeat scroll -210px -270px;
    }
    &.ico-contactss{
        background: $transparent url($iconsprite) no-repeat scroll -240px -270px;
    }
    &.ico-fav{
        background: $transparent url($iconsprite) no-repeat scroll 0px -30px;
    }
    &.ico-tag{
        background: $transparent url($iconsprite) no-repeat scroll -90px -240px;
    }
    &.ico-share{
        background: $transparent url($iconsprite) no-repeat scroll -60px -30px;
    }
    &.ico-note{
        background: $transparent url($iconsprite) no-repeat scroll -270px -270px;
    }
    &.ico-back{
        background: $transparent url($iconsprite) no-repeat scroll 0px -240px;
    }
    &.ico-next{
        background: $transparent url($iconsprite) no-repeat scroll -60px -240px;
    }
    &.ico-prev{
        background: $transparent url($iconsprite) no-repeat scroll -30px -240px;
    }
    &.ico-sat{
        background: $transparent url($iconsprite) no-repeat scroll -30px -360px;
    }
    &.ico-curloc{
        background: $transparent url($iconsprite) no-repeat scroll -30px -60px;
    }
    &.ico-archive{
        background: $transparent url($iconsprite) no-repeat scroll -90px -480px;
    }
    &.ico-calendar{
        background: $transparent url($iconsprite) no-repeat scroll -60px -540px;
    }
}

//$retina-abi-icon-sprite:'../../assets/images/webapp-icons-sprite@2x-v3.png';

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .icon-placeholder {
        &.ico-src,
        &.ico-savedsrc,
        &.ico-myleads,
        &.ico-export,
        &.ico-teams,
        &.ico-admin,
        &.ico-chat,
        &.ico-help,
        &.ico-feed,
        &.ico-default,
        &.ico-edit,
        &.ico-share,
        &.ico-delete,
        &.ico-projectss,
        &.ico-companyss,
        &.ico-contactss,
        &.ico-fav,
        &.ico-tag,
        &.ico-share,
        &.ico-note,
        &.ico-back,
        &.ico-next,
        &.ico-prev,
        &.ico-sat,
        &.ico-curloc,
        &.ico-archive,
        &.ico-calendar,
        &.ico-league{
            background-image: url($retina-icon-sprite);
            background-size: 300px;
        }
    }
}