@import '../../components/common/base/colors';
@import '../../components/common/base/mixins';

$iconsprite: '../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../components/common/images/webapp-icons-sprite@2x-v3.png';

//user tab\
.left {
    text-align: left;
}

.center {
    text-align: center;
}

.right {
    text-align: right;
}

.main-container {
    max-width: 100% !important;
}

.no-user-found {
    padding: 25px;
}

.invalid-feedback {
    font-weight: 600;
    font-size: 13px !important;
    color: #292b2c !important;
}

.form-control:focus {
    border: 0 !important;
    border-bottom: 1px solid #e7e7e7 !important;
}

.info-patch {
    background-color: $light-yellow;
    margin: 30px 0 0;
    padding: 10px 63px 10px;
    position: relative;

    &::before {
        content: "";
        width: 30px;
        height: 30px;
        background: transparent url($iconsprite) no-repeat scroll -210px -360px;
        left: 20px;
        top: 17px;
        position: absolute;
    }
}

.admin {
    padding: 20px 57px;
}

.user-details .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label {
    padding-left: 0;
}

.user-admin-page {
    top: 40px;
    padding-left: 50px !important;
    margin-left: 0;
    padding-right: 0;
    left: 13px;
    position: absolute;

    .form-control {
        font-size: 0.75rem;
    }

    .sort-results {
        top: 25px;
        right: 11px;
    }

    .top-10 {
        left: 17px;
        color: $abi-red;
        font-weight: 600;

        p {
            height: 20px;
            padding-top: 10px;
        }
    }

    .content-wrapper {
        position: relative;
        bottom: 0;
        left: 60px;
        position: fixed;
        right: 0;
        top: 135px;
        transition: all 0.2s linear;
        bottom: 50px;

        h4 {
            margin: 5px 0 10px;
            font-weight: 600;
        }

        .content-panel {
            padding: 0 25px 0 30px;
        }

        .user-tab {
            margin: 0 5px 0 25px;
            position: relative;
            height: 100%;

            .table {
                width: 100%;
                max-width: 100%;
                margin-bottom: 1rem;

                .table-head {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }

                .main-body {
                    overflow: auto;
                    bottom: 0;
                    top: 180px;
                    width: 100%;
                    
                    padding: 0 30px 0 0;

                    .details-panel-scrollbar {
                        position: relative;
                        overflow: hidden;
                        width: 100%;
                        height: 100%;

                        .scroll-area {
                            position: absolute;
                            inset: 0px;
                            overflow: scroll;
                            margin-right: -17px;
                            margin-bottom: -17px;
                        }
                    }
                }
            }

            .media {
                padding: 10px 10px 25px;
            }

            .hide {
                display: none;
                overflow: hidden;
                opacity: 0;
            }

            .show {
                display: block;
                opacity: 1;
            }

            .list-header {
                background: $alto;
                margin: 0 15px 2px 0;
                font-weight: 700;
                padding: 15px 0;
                line-height: 0.85rem;
                display: flex;
                justify-content: space-between;  /* Adjust spacing */

                .user-details {
                    // width: 20%;
                }

                span {
                    display: block;
                    font-size: 11px;
                }

                .column-1 {
                    max-width: 11%;
                    flex: 0 0 15%;
                }

                .center {
                    flex: 1;  /* Allow columns to take up equal space */
                    padding: 5px; /* Customize padding to reduce space */
                  }
            }

            .list-wrapper {
                width: 100%;
                margin: 0;
                display: block;
                border-collapse: collapse;
                &.users-list-wrapper{
                    height: calc(100vh - 265px) !important;
                    display: block;
                  }
                &.users-activity-wrapper{
                    height: calc(100vh - 370px) !important;
                    display: block;
                  }
            }

            .user-list-container {
                padding: 0;
                margin: 0 15px 10px 0;
                position: relative;

                .user-list-block {
                    margin: 0 0 2px 0;
                    font-size: 13px;
                    position: relative;

                    &:last-child {
                        .dropdown-menu-right {
                            top: -85px;
                        }
                    }

                    &:first-child {
                        .dropdown-menu-right {
                            top: 100%;
                        }
                    }

                    .row {
                        background: $white;
                        padding: 8px 0 10px 0;
                        min-height: 54px;
                        display: flex;
                        justify-content: space-between;

                        .center {
                            flex: 1;  /* Allow columns to take up equal space */
                            padding: 5px; /* Customize padding to reduce space */
                        }

                        .column-1 {
                            max-width: 11%;
                            flex: 0 0 15%;
                        }

                        .col-12 {
                            &.user-names {
                                position: relative;
                                line-height: 1.0rem;

                                .user-icon {
                                    position: absolute;
                                    // top: 3px;
                                    left: 15px;
                                    height: 30px;
                                    width: 30px;
                                    background: $alto;
                                    display: block;
                                    text-align: center;
                                    border-radius: 22px;
                                    padding: 7px 2px;
                                    font-weight: 600;
                                }

                                .fl-name {
                                    padding: 3px 20px 0 0px;
                                    display: block;
                                    font-weight: 700;
                                    min-height: 9px;
                                    float: left;
                                    position: relative;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        top: 10px;
                                        right: 8px;
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50px;
                                        background: $dove-grey;
                                        display: block;
                                    }



                                }

                                .user-name {
                                    padding: 3px 0 0 0px;
                                    display: block;
                                    color: $dove-grey;
                                    float: left;
                                    // position: absolute;
                                }

                                .user-lastseen {
                                    clear: both;
                                    display: block;
                                }

                                .userActivityInfo {
                                    padding-left: 40px;
                                }

                                .in-progress {
                                    position: relative;
                                    left: 10px;
                                    top: 3px;
                                    color: $yellow;
                                    font-weight: 400;

                                    &:before {
                                        content: "";
                                        height: 7px;
                                        position: absolute;
                                        width: 7px;
                                        border-radius: 50%;
                                        left: -10px;
                                        top: 6px;
                                        background-color: $yellow;
                                    }
                                }

                                .deleted {
                                    position: relative;
                                    left: 10px;
                                    top: 3px;
                                    color: $green;
                                    font-weight: 400;

                                    &:before {
                                        content: "";
                                        height: 7px;
                                        position: absolute;
                                        width: 7px;
                                        border-radius: 50%;
                                        left: -10px;
                                        top: 6px;
                                        background-color: $green;
                                    }
                                }

                                .scheduled {
                                    position: relative;
                                    left: 10px;
                                    top: 3px;
                                    color: $yellow;
                                    font-weight: 400;

                                    &:before {
                                        content: "";
                                        height: 7px;
                                        position: absolute;
                                        width: 7px;
                                        border-radius: 50%;
                                        left: -10px;
                                        top: 6px;
                                        background-color: $yellow;
                                    }
                                }

                                .error {
                                    position: relative;
                                    left: 10px;
                                    top: 3px;
                                    color: $error-red;
                                    font-weight: 400;

                                    &:before {
                                        content: "";
                                        height: 7px;
                                        position: absolute;
                                        width: 7px;
                                        border-radius: 50%;
                                        left: -10px;
                                        top: 6px;
                                        background-color: $error-red;
                                    }

                                    .info-pop {
                                        content: "";
                                        width: 30px;
                                        height: 30px;
                                        background: transparent url($iconsprite) no-repeat scroll -180px -360px;
                                        right: -28px;
                                        top: -4px;
                                        position: absolute;
                                        cursor: pointer;

                                        .error-msg {
                                            border: 1px solid $red;
                                        }
                                    }
                                }

                            }

                            &.user-contacts {
                                padding-top: 6px;

                                .user-mobile {
                                    margin-left: 15px;
                                    margin-top: 0;
                                    margin-bottom: 5px;
                                    position: relative;
                                    font-weight: 400;
                                    display: block;

                                    &:before {
                                        background: $transparent url($iconsprite) no-repeat scroll 0 -150px;
                                        content: "";
                                        height: 30px;
                                        position: absolute;
                                        width: 30px;
                                        left: -25px;
                                        top: -5px;
                                    }
                                }

                                .user-landline {
                                    margin-left: 15px;
                                    position: relative;
                                    margin-top: 0;
                                    font-weight: 400;
                                    display: block;

                                    &:before {
                                        background: $transparent url($iconsprite) no-repeat scroll -60px -150px;
                                        content: "";
                                        height: 30px;
                                        position: absolute;
                                        width: 30px;
                                        left: -25px;
                                        top: -5px;
                                    }
                                }
                            }

                            &.user-email {
                                padding-top: 6px;

                                a.email-address {
                                    text-decoration: underline;
                                    color: $black;
                                    margin-left: 25px;
                                    position: relative;

                                    &:before {
                                        background: $transparent url($iconsprite) no-repeat scroll -30px -150px;
                                        content: "";
                                        height: 30px;
                                        position: absolute;
                                        width: 30px;
                                        left: -30px;
                                        top: -5px;
                                    }
                                }
                            }

                            &.user-licenses {
                                padding-top: 6px;
                                padding-right: 30px;
                                position: relative;

                                .user-license {
                                    span {
                                        display: inline-block;
                                        margin: 0 5px 0 0;
                                        color: #292b2c;

                                        &:after {
                                            content: ",";
                                        }

                                        &:last-child:after {
                                            content: "";
                                        }
                                    }
                                }

                                .btn-group {
                                    position: absolute;
                                    right: 10px;
                                    top: 0;

                                    >.btn:first-child {
                                        margin-left: 0;
                                        text-indent: -999px;
                                        padding: 5px;
                                        overflow: hidden;
                                        border: 0 none;
                                        width: 30px;
                                        height: 30px;
                                        cursor: pointer;
                                        background: transparent url($iconsprite) no-repeat scroll -210px -240px;
                                    }

                                    &.show {
                                        >.dropdown-menu {
                                            display: block;
                                            box-shadow: 0px 3px 5px $dark-grey;
                                            border-radius: 0;
                                            padding: 0;
                                            position: relative;
                                            right: -32px;
                                            top: 30px;
                                            transform: translate3d(-215px, 30.4px, 0px) !important;
                                            width: 250px;

                                            &:before {
                                                content: "";
                                                position: absolute;
                                                width: 30px;
                                                height: 30px;
                                                background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                                                top: -18px;
                                                right: 2px;
                                            }

                                            .dropdown-item {
                                                padding: 10px;
                                                font-weight: 600;
                                                font-size: .85rem;
                                            }

                                            .dropdown-divider {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .user-button {
                        position: absolute;
                        right: 0px;
                        margin-top: 0;
                        top: 5px;

                        .edit-button.btn.btn-secondary {
                            background: transparent none repeat scroll 0 0;
                            border: 0 none;
                            color: $white;
                            font-size: 0.75rem;
                            position: relative;
                            cursor: pointer;
                            line-height: 1.35em;
                            height: 30px;
                            width: 30px;
                            margin: 0;
                            text-indent: -9999px;
                            left: 0px;

                            &:after {
                                content: "";
                                width: 30px;
                                height: 30px;
                                background: transparent url($iconsprite) no-repeat scroll -150px -90px;
                                left: 0;
                                top: 0;
                                position: absolute;
                            }
                        }

                        .delete-button.btn.btn-secondary {
                            background: transparent none repeat scroll 0 0;
                            border: 0 none;
                            color: $white;
                            font-size: 0.75rem;
                            position: relative;
                            cursor: pointer;
                            line-height: 1.35em;
                            height: 30px;
                            width: 30px;
                            margin: 5px 0 0;
                            text-indent: -9999px;
                            left: -5px;

                            &:after {
                                content: "";
                                width: 30px;
                                height: 30px;
                                background: transparent url($iconsprite) no-repeat scroll -180px -90px;
                                left: 0;
                                top: 0;
                                position: absolute;
                            }
                        }
                    }
                }

                .edit-new-user {
                    display: block;
                    background: $white;
                    border: 2px dashed $grey;
                    overflow: hidden;

                    .mb-3 {
                        margin-bottom: 5px !important;
                    }

                    .edit-button {
                        display: none;
                    }

                    .container {
                        top: 0;
                        margin-left: 55px;
                        margin-bottom: 10px;

                        .form-control:disabled {
                            background: none;
                        }
                    }

                    input {
                        border: 0;
                        border-bottom: 1px solid $mercury;
                        padding: 0.5rem 0;
                        margin: 0;
                        background-image: none;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: $alto;
                            font-weight: 400;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: $alto;
                            font-weight: 400;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: $alto;
                            font-weight: 400;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: $alto;
                            font-weight: 400;
                        }
                    }

                    .row {
                        height: 56px;
                        padding-bottom: 0;

                        .col-12 {
                            &.user-names {
                                .fl-name {
                                    font-size: 1.4em;
                                    padding: 10px 0 0 40px;
                                    font-weight: 600;
                                }

                                .user-icon {
                                    &:after {
                                        background: $white url($iconsprite) no-repeat scroll -210px -120px;
                                        content: "";
                                        height: 30px;
                                        position: absolute;
                                        width: 30px;
                                        left: 0;
                                        top: 0;
                                    }
                                }
                            }

                            &.user-licenses {
                                padding-top: 8px;
                                display: block;
                                line-height: 1.2em;
                            }
                        }
                    }

                    .email,
                    .mobile,
                    .company-phone {
                        padding-left: 25px;
                    }

                    .email-icon {
                        position: relative;
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll -30px -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }

                        input {
                            padding-left: 25px;
                            background: none !important;
                        }
                    }

                    .mobile-icon {
                        position: relative;
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll 0 -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }

                        input {
                            padding-left: 25px;
                            position: relative;
                        }
                    }

                    .company-icon {
                        position: relative;
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll -60px -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }

                        input {
                            padding-left: 25px;
                        }
                    }

                    form {
                        padding: 0;

                        .form-group {
                            //padding: 0;
                            margin-bottom: 0;

                            .form-control-feedback {
                                font-size: .85em;
                                position: absolute;
                                bottom: -11px;
                                right: 15px;
                                z-index: 999;
                                color: $error-red;
                                float: right;
                                top: 30px;
                                text-align: right;
                                top: 25px;
                                text-align: right;
                            }
                        }
                    }

                    .btn-edit-user {
                        margin-right: 15px;
                        width: auto;
                    }

                    .outline-btn {
                        width: auto;
                    }

                    .btn-cancel-user {
                        cursor: pointer;
                        text-decoration: underline;
                        padding: 5px 0;
                        line-height: 1.7rem;
                    }

                    .form-button-row {
                        margin-top: 10px;
                        padding-left: 15px;
                    }
                }

                .user-admin-block {
                    .placeholder {
                        width: 100%;
                        background: $white;
                        padding: 0;

                        .list-group {
                            li {
                                border: 0;

                                .result-block {
                                    .result-row-overview {
                                        min-height: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .media {
                background: $white;
                padding: 10px 10px 15px;

                .media-left {
                    &:after {
                        background: $transparent url($iconsprite) no-repeat scroll -210px -120px;
                        content: "";
                        height: 30px;
                        position: absolute;
                        width: 30px;
                    }
                }

                .media-body {
                    margin-left: 45px;

                    .media-heading {
                        font-size: 1.4em;
                        font-weight: 600;

                        span {
                            font-size: .65em;
                            margin-left: 5px;
                            font-weight: 400;

                            &.user-license {
                                float: none;
                                display: inline;
                                margin-left: 20px;
                                font-weight: 400;

                                span {
                                    padding: 3px 8px;
                                }
                            }
                        }

                        .email-address {
                            color: $emperor-gray;
                            font-size: 0.75rem;
                            margin: 5px 0 0 30px;
                            font-weight: 400;
                            position: relative;

                            &:before {
                                background: $transparent url($iconsprite) no-repeat scroll -30px -150px;
                                content: "";
                                height: 30px;
                                position: absolute;
                                width: 30px;
                                left: -25px;
                                top: -8px;
                            }
                        }

                        .user-name {
                            margin-left: 30px;
                            margin-top: 5px;
                            position: relative;
                            font-weight: 400;

                            &:before {
                                background: $dark-grey;
                                content: "";
                                height: 6px;
                                position: absolute;
                                width: 6px;
                                left: -15px;
                                top: 5px;
                                border-radius: 50%;
                            }
                        }

                        .user-mobile {
                            margin-left: 30px;
                            margin-top: 5px;
                            position: relative;
                            font-weight: 400;

                            &:before {
                                background: $transparent url($iconsprite) no-repeat scroll 0 -150px;
                                content: "";
                                height: 30px;
                                position: absolute;
                                width: 30px;
                                left: -25px;
                                top: -8px;
                            }
                        }

                        .user-landline {
                            margin-left: 30px;
                            position: relative;
                            margin-top: 5px;
                            font-weight: 400;

                            &:before {
                                background: $transparent url($iconsprite) no-repeat scroll -60px -150px;
                                content: "";
                                height: 30px;
                                position: absolute;
                                width: 30px;
                                left: -25px;
                                top: -8px;
                            }
                        }
                    }

                    .user-license {
                        display: block;
                        color: $cerulean;
                        margin-top: 10px;
                        float: left;
                        font-size: 0.75rem;

                        span {
                            background: $alice-blue none repeat scroll 0 0;
                            border: 1px solid $alto;
                            border-radius: 5px;
                            margin: 0 5px 5px 3px;
                            padding: 0px 8px;
                            font-size: 0.70rem;
                            display: inline-block;
                            color: $black-grey;
                        }
                    }
                }
            }

            .user-search-panel {
                background: none;
                margin-bottom: 0;
                padding: 10px 0;

                .media-body {
                    margin: 0;

                    .textfield-wrapper {
                        float: left;

                        &:after {
                            border-left: none;
                        }
                    }
                }

                .search-user-textbox {
                    float: left;
                    height: 35px;
                    font-weight: 400;
                    font-size: .80em;
                    line-height: 2.8em;

                    input {
                        width: 100%;
                        border: 0;
                        margin: 0;
                        top: 0;
                        height: 35px;
                        position: absolute;
                        left: 0;
                        padding: 0 10px;
                        border: 1px solid $alto;
                        padding-left: 30px;
                        line-height: 2.0em;

                        &::placeholder {
                            color: $grey;
                            font-weight: 400;
                            line-height: 2.0em;
                        }

                        &::-webkit-input-placeholder {
                            color: $grey;
                            font-weight: 400;
                            line-height: 2.0em;
                        }

                        &::-moz-placeholder {
                            color: $grey;
                            font-weight: 400;
                            line-height: 2.0em;
                        }

                        &:-moz-placeholder {
                            color: $grey;
                            font-weight: 400;
                            line-height: 2.0em;
                        }

                        &:-ms-input-placeholder {
                            color: $grey;
                            font-weight: 400;
                            line-height: 2.0em;
                        }
                    }

                    &:before {
                        background: $transparent url($iconsprite) no-repeat scroll -90px 0;
                        bottom: 0;
                        content: "";
                        height: 30px;
                        position: absolute;
                        top: 2px;
                        width: 30px;
                        overflow: hidden;
                        display: block;
                        z-index: 999;
                        left: 2px;
                    }
                }

                .clear-textfield {
                    right: 2px;
                    top: 2px;
                }

                .src-button {

                    // button {
                    //     height: 34px;
                    //     text-indent: 15px;
                    //     overflow: hidden;
                    //     position: relative;
                    //     background: $red;
                    //     color: $white;
                    //     padding: 0 10px 0 16px;
                    //     cursor: pointer;
                    //     text-transform: capitalize;
                    //     font-size: 0.8rem;
                    //     @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
                    //     float: left;
                    //     border: 1px solid $abi-blue;
                    //     &:focus {
                    //         border: 1px solid $abi-blue;
                    //     }
                    // }
                    .user-search {
                        margin-right: 10px;

                        &:after {
                            background: $transparent url($iconsprite) no-repeat scroll -180px -60px;
                            bottom: 0;
                            content: "";
                            height: 30px;
                            left: 5px;
                            position: absolute;
                            top: 0;
                            width: 30px;
                            overflow: hidden;
                            display: block;
                        }
                    }

                    .add-user {
                        font-size: .8rem;
                        margin-left: 10px;
                        padding: 9px 20px;
                    }
                }
            }

            .user-activity {
                background-color: $white;
                margin: 30px 0;
                margin-right: 15px;
                padding: 9px 20px 5px;

                .col-panel-1 {
                    width: 300px;
                }

                .col-panel-2 {
                    width: 480px;
                    position: relative;
                }

                .col-panel-3{
                    width: 20%;
                }

                .period-panel {

                    // border: 1px solid green;
                    p {
                        font-weight: 600;
                    }

                    .src-button {
                        button {
                            margin-top: 33px;
                        }
                    }

                    .divide {
                        position: relative;
                        float: right;
                        top: -30px;
                        font-weight: 600;
                        left: 8px;
                    }

                    .dropdown {
                        width: 250px;

                        .dropdown-menu {
                            width: 100%;
                            position: absolute;
                            will-change: transform;
                            top: 0px;
                            left: 0px;
                            transform: translate3d(0px, 33px, 0px);

                        }

                        .dropdown-toggle {
                            background: none;
                            color: $black;
                            width: 100%;
                            text-align: left;
                            border: 1px solid $grey;
                            height: 33px;
                            font-size: 0.75rem;

                            &::after {
                                right: 9px;
                                position: absolute;
                                top: 14px;
                            }
                        }
                    }

                    .reset {
                        position: absolute;
                        right: 8px;
                        z-index: 99;
                        color: $abi-red;
                        text-decoration: underline;
                        top: 10px;
                        cursor: pointer;
                    }

                    .date-container {
                        position: relative;
                        display: flex;
                        background: $white;

                        .InputFromTo {
                            background: $white;
                            padding: 0;
                            display: flex;
                            position: relative;

                            .reset {
                                top: -26px;
                                position: absolute;
                                left: 170px;
                                color: $red;
                                text-decoration: underline;
                                font-size: 0.7rem;
                                cursor: pointer;
                            }

                            .DayPickerInput {
                                font-size: 0.85rem;
                                position: relative;

                                input {
                                    min-width: 220px;
                                    padding: .5rem .75rem;
                                    margin: 0;
                                    font-size: 0.75rem;
                                    line-height: 1.25;
                                    color: $black-grey;
                                    background-color: $transparent;
                                    background-image: none;
                                    background-clip: padding-box;
                                    border: 1px solid $grey;
                                    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                                    z-index: 1;
                                    position: relative;
                                }

                                &:after {
                                    content: "";
                                    background: $white url($iconsprite) no-repeat scroll -30px -210px;
                                    position: absolute;
                                    right: 6px;
                                    top: 2px;
                                    width: 30px;
                                    height: 30px;
                                    z-index: 0;
                                }
                            }

                            .required {
                                font-size: .85em;
                                text-align: right;
                                color: $red;
                                float: left;
                                position: absolute;
                                top: 50px;
                                display: block;
                                left: 253px;
                                bottom: 0;
                            }
                        }
                    }

                }

            }

            .add-new-user-wrapper {
                margin: 10px 10px 10px 0;
                border: 2px dashed $grey;
                -webkit-transition: width 0.6s;

                .add-new-user {
                    .container {
                        margin-left: 0;
                    }
                    .mb-3 {
                        margin-bottom: 5px !important;
                    }
                    input {
                        border: 0;
                        border-bottom: 1px solid $mercury !important;
                        padding: 0.5rem 0;
                        background: none;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: $alto;
                            font-weight: 400;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: $alto;
                            font-weight: 400;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: $alto;
                            font-weight: 400;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: $alto;
                            font-weight: 400;
                        }
                    }

                    .email,
                    .mobile,
                    .company-phone {
                        padding-left: 25px; // width: inherit;
                    }

                    .email-icon {
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll -30px -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }
                    }

                    .mobile-icon {
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll 0 -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }
                    }

                    .company-icon {
                        position: relative;
                        &:before {
                            background: $transparent url($iconsprite) no-repeat scroll -60px -180px;
                            content: "";
                            height: 30px;
                            left: 9px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                        }
                    }

                    form {
                        padding: 0;

                        .form-group {
                            //padding: 0;
                            margin-bottom: 5px !important;
                            position: relative;

                            .form-control-feedback {
                                font-size: .85em;
                                position: absolute;
                                bottom: -11px;
                                right: 15px;
                                float: right;
                                top: 30px;
                                text-align: right;
                                z-index: 999;
                                color: $error-red;
                            }
                        }
                    }

                    .btn-add-user {
                        margin-right: 15px;
                        width: auto;
                    }

                    .btn-cancel-user {
                        width: auto;
                    }
                    .has-danger {
                        &:after {
                            background: $transparent url($iconsprite) no-repeat scroll -90px -180px;
                            content: "";
                            height: 30px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                            top: 5px;
                            right: 9px;
                        }
                    }

                    .user-exists {
                        &:after {
                            background: $transparent url($iconsprite) no-repeat scroll -90px -180px;
                            content: "";
                            height: 30px;
                            position: absolute;
                            z-index: 9999;
                            width: 30px;
                            top: -30px;
                            right: -5px;
                        }
                    }

                    .form-button-row {
                        margin-top: 10px;
                        padding-left: 15px;
                    }
                }
            }

            .save-button-row {
                padding: 5px 5px;
                top: 10px;
                position: absolute;
                right: 0;
            }

            .assign-user-panel {
                background: $white;
                bottom: 0;
                left: 85px;
                position: fixed;
                right: 10px;
                text-align: center;
                top: 198px;
                padding: 10px 32px 33px 30px;
                font-size: 13px;

                .main-header {
                    font-size: 14px;
                    font-weight: 600;
                    margin-left: 0;
                    padding: 10px;
                    position: static;
                    top: 0;
                    width: 100%;
                    height: 40px;
                    line-height: 1.0em;

                    .left {
                        width: 20%;
                    }
                }

                .inside-header {
                    font-weight: 600;
                    color: $black;
                    margin: 0;
                    padding: 10px;
                    position: static;
                    top: 50px;
                    vertical-align: middle;
                    width: auto;
                    z-index: 999;
                    background-color: $wildsand-light;
                    border-top: 1px solid $light-grey;
                    border-bottom: 1px solid $light-grey;
                    line-height: 1em;

                    .circle {
                        background: $transparent;

                    }

                    .center {
                        background-color: $wildsand-light;
                    }

                    .left {
                        // padding-top: 5px;
                        font-size: 12px;
                        vertical-align: middle;
                        background-color: $wildsand-light;
                    }
                }

                .inside-footer {
                    border-top: 1px solid $light-grey;
                    background: $wildsand-light;
                    color: $black;
                    bottom: 0;
                    font-weight: 600;
                    left: 0;
                    margin: 0;
                    padding: 10px;
                    position: static;
                    right: 0;
                    line-height: 1em;

                    .circle {
                        background: $dark-yellow;
                    }

                    .left {
                        padding-top: 10px;

                    }
                }

                .circle {
                    font-weight: 700;
                    display: block;
                    width: 100px;
                    height: 25px;
                    padding: 5px 0;
                    text-align: center;
                    font-size: 12px;
                    margin: 0 auto;
                    border-radius: 5px;
                    color: $black-grey;
                }

                // .details-panel-scrollbar {
                //     bottom: 0;
                //     left: 0;
                //     margin: 0 170px 0 0;
                //     right: 180px;
                //     top: 0;                    
                .main-body {
                    overflow: auto;
                    bottom: 10px;
                    top: 120px;
                    width: 100%;
                    position: absolute;
                    padding: 0 30px 0 0;

                    .assign-row {
                        background: $white;
                        padding: 10px;
                        width: 100%;
                        margin-left: 0;
                        display: table;
                        table-layout: fixed;
                        position: relative !important;
                        // border-bottom: 1px solid $mercury;
                        min-height: 45px;

                        .left {
                            width: 20%;
                        }

                        .src-filter-field {
                            width: 20px;
                            margin: 0 auto;
                            float: none;
                        }

                        .user-names {
                            position: relative;
                            line-height: 1.0rem;
                            word-wrap: break-word;

                            .user-icon {
                                position: absolute;
                                //   top: 3px;
                                //left: 15px;
                                height: 30px;
                                width: 30px;
                                background: $alto;
                                display: block;
                                text-align: center;
                                border-radius: 22px;
                                padding: 7px 2px;
                                font-weight: 600;
                            }

                            .user-detail-wrapper {
                                padding: 0 0 0 40px;

                                .fl-name {
                                    padding: 3px 20px 0 0;
                                    display: inline-block;
                                    font-weight: 700;
                                    position: relative;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        top: 10px;
                                        right: 8px;
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50px;
                                        background: $dove-grey;
                                        display: block;
                                    }
                                }

                                .user-name {
                                    padding: 0;
                                    display: inline-block;
                                    color: $dove-grey;
                                }

                                a.email-address {
                                    padding: 0 0 0 0;
                                    color: $dove-grey;
                                    display: block;
                                    text-decoration: underline;
                                }
                            }
                        }

                        // .licenses-holder {
                        //     margin-left: 35px;
                        //     font-size: 1.0em;
                        //     font-weight: 600;
                        //     line-height: 1em;
                        //     color: $dove-grey;
                        //     &:before {
                        //         background: $transparent url($iconsprite) no-repeat scroll -210px -120px;
                        //         content: "";
                        //         height: 30px;
                        //         position: absolute;
                        //         width: 30px;
                        //         left: 13px;
                        //         top: -3px;
                        //     }
                        //     .user-email {
                        //         font-size: 0.8em;
                        //         display: block;
                        //     }
                        // }
                    }
                }

                //}
            }

            .table {
                thead {
                    display: table;
                    width: 100%;
                    table-layout: fixed;

                    th {
                        border: 0 none;
                    }
                }
            }

            .download-report {
                position: absolute;
                right: 22px;
                margin-top: -23px;
                color: $black;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .user-list-body {
            .drag-down {
                .main-body {
                    top: 410px !important;
                }
            }

            .table {
                .main-body {
                    top: 115px;
                }
            }
        }
    }

    .company-tab-wrapper {
        box-shadow: none;
        border: 0 none;
        background: #fff;
        color: #292b2c;

        .nav-tabs {
            border-bottom: 5px solid #053874;

            li:first-child {
                margin-left: 25px;
            }

            .nav-item {
                margin-bottom: -1px;
            }

            .nav-link {
                color: #292b2c;
                display: block;
                padding: 5px 15px;
                cursor: pointer;

                &:hover {
                    background: transparent;
                    border: 1px solid transparent;
                }
            }

            .nav-link.active {
                background-color: #053874;
                border: 0;
                color: #fff;
                font-weight: 600;
                border-radius: 0;
            }
        }
    }

    .page-title-bar {
        margin: 0;
        background: $white;
        padding: 10px 0;

        div:first-child {
            display: block;
        }
    }
}

.css-b62m3t-container {
    width: 100%;
}

.share-details-wrapper {
    .css-13cymwt-control {
        padding-right: 15px !important;
        padding-left: 2px;
    }
    .css-t3ipsp-control {
        padding-right: 15px !important;
        padding-left: 2px;
    }
}

.add-new-team-user,
.Labels-details,
.share-details {
    .Select-control {
        .Select-multi-value-wrapper,
        .css-hlgwow {
            &:before {
                background: transparent url($iconsprite) no-repeat scroll -240px -90px;
                content: "";
                height: 30px;
                left: -23px;
                position: absolute;
                top: 6px;
                width: 30px;
            }
        }
    }
}

.transfer-selectshare {
    .share-details-wrapper {
        .css-hlgwow {
            padding: 2px 8px 2px 30px !important;
        
            &:before {
                background: transparent url($iconsprite) no-repeat scroll -240px -90px;
                content: "";
                height: 30px;
                left: 0;
                position: absolute;
                top: 6px;
                width: 30px;
            }
        }
    }
}


.modal-header .modal-title .save-cancel-top {
    content: "";
    width: 30px;
    height: 30px;
    background: transparent url($iconsprite) no-repeat scroll -210px -90px;
    text-indent: -999px;
    right: 10px;
    top: 10px;
    position: absolute;
    cursor: pointer;
    overflow: hidden;
}

.react-datepicker__input-container {
    input {
        border: 1px solid #ccc;
        height: 33px;
        padding: 0.5rem 0.75rem;
        font-size: .75rem;
        position: relative;
        min-width: 220px;
    }
    &:after {
        content: "";
        background: transparent url($iconsprite) no-repeat scroll -30px -210px;
        position: absolute;
        right: 6px;
        top: 2px;
        width: 30px;
        height: 30px;
        z-index: 0;
    }
}

.user-activity {
    &.showmore-result {
        height: 250px;
        margin: 0 auto;

        .btn.btn-primary {
            max-width: 250px;
            height: 35px;
        }
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .user-admin-page {
        .content-wrapper {
            .user-tab {
                .assign-user-panel {
                    .main-header {
                        font-size: 1.0em;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .user-admin-page .content-wrapper .user-tab .user-list-container {
        padding: 0;

        .user-list-block .row {

            .col-12.user-email,
            .col-12.user-contacts,
            .col-12.user-licenses {
                padding: 0 0 0 55px;
                position: static;

                .user-mobile {
                    margin-top: 5px;
                }
            }

            .center {
                flex: initial;
            }
        }

        .edit-new-user {
            form {
                .row {
                    height: auto;
                }
            }
        }

        .col-12.user-names {
            padding-top: 5px;
        }
    }

    .user-admin-page .content-wrapper .user-tab .list-header {
        display: none;
    }

    .list-header.row {
        display: none;
    }
}

@media only screen and (max-width:990px) {
    .user-admin-page .content-wrapper .user-tab .user-list-container {
        .edit-new-user {
            form {
                .row {
                    height: auto;
                }
            }
        }
    }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {

    .user-admin-page .content-wrapper .user-tab .user-list-container .user-list-block .row .col-12.user-licenses .btn-group>.btn:first-child,
    .user-admin-page .content-wrapper .user-tab .user-search-panel .search-user-textbox:before,
    .user-admin-page .content-wrapper .user-tab .user-list-container .user-list-block .row .col-12.user-contacts .user-landline:before,
    .user-admin-page .content-wrapper .user-tab .user-list-container .user-list-block .row .col-12.user-contacts .user-mobile:before {
        background-image: url($retina-icon-sprite);
        background-size: 300px;
    }
}