@import '../../components/common/base/colors';
@import '../../components/common/base/mixins';

$iconsprite: '../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../components/common/images/webapp-icons-sprite@2x-v3.png';

/*------------------------------------------------------------
    React Mapbox autocomplete menu
------------------------------------------------------------*/

.react-mapbox-ac-menu {
    border: 0 none;
    @include box-shadow(0, 10px, 15px, $dove-grey);
    margin-top: 0rem;
    width: 100%;
    max-height: 400px;
    overflow: auto;
    position: absolute;
    left:0;
    background: $white;
    z-index: 4;
  }
  
  .react-mapbox-ac-suggestion {
    border-bottom: 1px solid $grey;
    cursor: pointer;
    font-size: 0.85rem;
    font-weight: 400;
    padding: 5px 5px 5px 30px;
    line-height: 1.3em;
    position: relative;
    min-height: 30px;
  
    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: 30px;
      height: 30px;
      background: $transparent url($iconsprite) no-repeat scroll 0px -210px;
    }
  }
  
  .search-panel-scrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute !important;
    top: 65px;
    bottom: 50px;
    right: 0;
    padding: 10px 10px 0;
    left: 0;
    // background: $white;
  }
  
  .scrollbar-handle {
    background-color: $black;
    z-index: 1003; //@include border-radius(10px);
  }
  
  .autofill-drop span {
    width: 100%;
    max-width: 100%;
    display: block;
    position: relative;
  }
  
  .form-control {
    font-size: 0.85rem;
  }
  
  .btn {
  
    &.get-location-icon,
    &.get-location-icon:hover,
    &.get-location-icon:visited,
    &.get-location-icon:focus {
      height: 30px;
      margin: 0;
      width: 30px;
      border: 0 none;
      position: absolute;
      text-indent: -999px;
      overflow: hidden;
      right: 2px;
      top: 4px;
      cursor: pointer;
      background: transparent url($iconsprite) no-repeat scroll -30px -60px;
    }
  }
  
  .navbar.top-navigation {
    z-index: 1060;
  }
  
  .form-control.search.react-mapbox-ac-input {
    padding-right: 60px;
  }
  
  .location-field.textfield-wrapper .btn.clear-textfield {
    right: 60px !important;
    top:2px !important
  }
  
  .textSearchClearSection {
    right: 2px !important;
  }
  
  .location-field.textfield-wrapper .btn.radial-textfield {
    right: 33px !important;
    top:3px;
    &:hover,&:focus{
      top:3px;
    }
  }
  
  .textfield-wrapper .roles-search-list .roles-search-filed-btn {
    right: 11px;
    top: 43px;
  
    &:focus,
    &:hover,
    &:visited {
      right: 11px;
      top: 45px;
    }
  }
  
  .location-field.textfield-wrapper {
    &:after {
      border-right: 0 none;
      border-left: 1px solid $grey;
      left: 0;
      z-index: 1000;
    }
  }
  
  .location-field.textfield-wrapper input[type="text"] {
    padding-right: 95px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /*.contacts-field.textfield-wrapper{
          padding: 10px 10px 0px 10px;
          .btn.clear-textfield{
              right: 50px;
              &:hover,
              :visited
              :focus{
                  top: 10px;
              }
          }
  }*/
  
  .search-container {
    //background: $whisper none repeat scroll 0 0;
    //border-bottom: 1px solid $light-grey;
    left: 370px;
    padding: 0 0 6px 0; // position: fixed;
    right: 0;
    z-index: 10;
  
    .new-filter-backgrop {
      background: rgba(0, 0, 0, 0.5);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1105;
  
      .new-filter-section-panel {
        top: 112px;
        background: $white;
        padding: 10px;
        transition: all 0.2s linear;
        position: relative;
        height: auto;
        margin: 0px;
        width: 980px;
        left: 80px;
  
        .advance-filter-close-button {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 30px;
          height: 30px;
          background: transparent url($iconsprite) no-repeat scroll -240px -360px;
          cursor: pointer;
          z-index: 999;
        }
      }
    }
  
    //background-color: rgba(255, 255, 255, 0.8);
    .search-container-wrapper {
      position: relative; //@include box-shadow(0, 0, 5px, $grey);
      min-height: 47px;
      background: $alto;
      padding: 10px;
      .form-container{
        flex-basis: 100%;
      }
      .search-selection-type-section {
        width: 96px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
  
      .search-control-textboxes {
        left: 130px;
        top: 0;
        bottom: 0;
        right: 150px;
        position: absolute;
  
        .search-control-textboxes-text {
          width: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
        }
  
        .search-control-textboxes-location {
          width: 50%;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
  
          button.clear-location-field-basic-search {
            right: 60px;
          }
  
          button.get-location-icon.btn.btn-secondary {
            top: 8px;
            right: 2px;
            z-index: 1002;
          }
  
          button.radial-textfield.btn.btn-secondary {
            top: 8px;
            right: 33px;
            z-index: 1003;
          }
        }
      }
  
      .searchtype-selection-type-section {
        width: 150px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
  
      .searchtype-description-section {
        top: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 111px;
        padding-top: 4px;
        padding-right: 10px;
      }
  
      .textfield-wrapper>.dropdown {
        position: relative;
      }
  
      .advanced-search-form-field {
        width: 100%;
        z-index: 1004;
        height: 40px;
  
        .dropdown {
          .Projects-icon {
            background: $transparent;
          }
        }
  
        .advanced-filters-tags {
          color: $abi-blue;
          position: absolute;
          top: 0;
          right: 261px;
          z-index: 1000;
          height: 38px;
          max-height: 400px;
          overflow: hidden;
          left: 145px;
          padding-left: 5px;
          min-height: 37px;
          background: $white;
          display: flex;
          justify-content: start;
          align-self: flex-start;
          flex-wrap: wrap;
          border:1px solid $border-grey;
          border-radius: 3px;
          margin-top: 2px;
          padding-right: 261px !important;
          .tag-element {
            // text-transform: capitalize;
            padding: 3px 27px 2px 10px;
            position: relative;
            background: $alice-blue;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 2px;
            height: 24px;
            margin-top: 7px;
            font-size: 13px;
            span.tag-title {
              font-weight: bold;
              padding-right: 3px;
  
              &:after {
                content: ":";
                width: 5px;
                margin-left: 1px;
                margin-right: 2px;
              }
  
              &.onlyTitle {
                &:after {
                  display: none;
                }
              }
            }
  
            span.tag-content {
              max-width: 175px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              line-height: 20px;
            }
  
            span.tag-close-button {
              width: 29px;
              height: 29px;
              display: block;
              top: -3px;
              right: 0;
              background: transparent url($iconsprite) no-repeat scroll -240px -390px;
              cursor: pointer;
              position: absolute;
            }
          }
  
          &.less-than-2{
            display: flex;
            .tag-element {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
              position: relative;
              padding-right: 30px;
              * {
                align-self: baseline;
              }
              span.tag-close-button {
                background-color: $alice-blue;
                position: absolute;
                right: 0px ;
                top: 0px;
              }
            }
          }
  
        }
  
        .advanced-filters-tags.show-more {
          height: auto;
          overflow: auto;
          border: 1px solid $alto;
          border-top: none;
          margin-left: -1px;
          padding: 5px;
          padding-top: 0px;
        }
      }
  
      .advanced-search-add-button {
        background: $white;
        border: 1px solid $abi-blue;
        border-radius: 4px;
        color: $abi-blue;
        cursor: pointer;
        font-weight: 700;
        height: 30px;
        font-size: 14px;
        line-height: 14px;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 130px;
        margin-right: 10px;
        margin-top: 8px;
        position: absolute;
        left: 0px;
        z-index: 1002;
      }
  
      .advanced-show-more {
        width: 75px;
      }

      .advancedShow-btn{
        position: absolute;
        right: 10px;
        top: 0;
        width:150px;
      }
  
      .advanced-secondary-btn {
        color: $black;
        background: $white;
        text-decoration: underline;
        cursor: pointer;
        margin: 8px 5px;
        font-size: 0.8rem;
        display: block;
  
        &:focus,
        &:active {
          border: none;
          outline: none;
        }
      }
  
      .right-save-col {
        width: 115px;
  
        .primary-btn {
          width: 100%;
          height: 38px;
          font-size: 1rem;
          font-weight: 400;
          position: relative;
          min-width: 110px;
          padding: 3px 10px;
          border-radius: 4px;
        }
  
        .over-map-panel {
          position: relative;
          top: auto;
          right: auto;
          background: none;
          padding: 0px;
          width: auto;
  
          .maplist-container {
            width: auto;
            position: relative;
  
            button.listview.outline-btn {
              color: $abi-blue;
              opacity: 1;
            }
  
            button.listview.outline-btn,
            button.mapview.outline-btn {
              height: 45px;
            }
  
            button.listview.outline-btn {
              color: $abi-red;
  
              &:before {
                background: transparent url($iconsprite) no-repeat scroll -91px -112px;
              }
            }
          }
  
          .save-search-panel {
            float: right;
            margin-right: -2px;
  
            .save-search-form {
              position: relative;
              right: auto;
              top: auto;
              width: 108px;
  
              form.visible-save-search-form.save-src-form-group {
                top: 45px;
                right: 0px;
  
                .button-blocks {
                  top: 12px;
                }
              }
            }
  
            button.btn-primary-save.outline-btn.btn.btn-secondary {
              height: 45px;
              width: 100%;
            }
          }
        }
      }
  
      input.form-control {
        height: 45px;
        border: 0 none;
        font-weight: 400;
        font-size: 1em; //line-height: 2.5em;
      }
  
      .src-button {
        height: 100%;
        // border-left: 1px solid $alto;
        // border-right: 1px solid $alto;
        margin-right: 10px;
        margin-top: 1px;
        .btn-default {
          border: 1px solid $cerulean;
          color: $cerulean;
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          line-height: 14px;
          margin: 0px;
          height: 38px;
          border-radius: inherit;
          min-width: 111px;
          position: relative;
          border-radius: 4px;
          span {
            position: absolute;
            right: -5px;
            top: -5px;
            border: $abi-red solid 1px;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            line-height: 18px;
            text-align: center;
            background-color: $abi-red;
            font-size: 11px;
            color: $white;
            font-weight: 600;
          }
  
          &:hover {
            box-shadow: none;
  
            span {
              color: $abi-red;
              background-color: $white;
            }
          }
  
          &:active,
          &:focus,
          &:hover {
            background: $cerulean;
            color: $white;
          }
        }
      }
      .pro-comp-field {
        position: relative;
  
        // border-right: none;
        .objectType-dropdown{
          .dropdown {
            .btn.active,
            .btn:focus,
            .btn:hover {
              background: $white !important;
            }
    
            .btn-secondary {
              border: 1px solid $grey;
              font-size: 1em;
              background: $white;
            }
    
            .dropdown-toggle {
              &:after {
                border: 0;
                width: 10px;
                height: 10px;
                content: "";
                position: absolute;
                top: 17px;
                right: 11px;
                background: transparent url($iconsprite) no-repeat scroll -220px -77px;
              }
            }
    
            &.show {
              .dropdown-toggle {
                &:after {
                  border: 0;
                  width: 10px;
                  height: 10px;
                  content: "";
                  position: absolute;
                  top: 17px;
                  right: 6px;
                  background: transparent url($iconsprite) no-repeat scroll -220px -65px;
                }
              }
            }
    
            .Projects-icon {
              position: relative;
              color: $blue;
              cursor: pointer;
    
              &:before {
                width: 30px;
                height: 30px;
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                background: $transparent url($iconsprite) no-repeat scroll -120px -240px;
              }
            }
    
            .Companies-icon {
              position: relative;
              color: $abi-red;
              cursor: pointer;
    
              &:before {
                width: 30px;
                height: 30px;
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                background: $transparent url($iconsprite) no-repeat scroll -150px -240px;
              }
            }
    
            .Contacts-icon {
              position: relative;
              color: $dark-black-gray;
              cursor: pointer;
    
              &:before {
                width: 30px;
                height: 30px;
                content: "";
                position: absolute;
                top: 6px;
                left: 0;
                background: $transparent url($iconsprite) no-repeat scroll -150px -270px;
              }
            }
    
            .dropdown-menu {
              border: 0 none;
              @include box-shadow(0, 0, 5px, rgba(0, 0, 0, 0.2));
              padding: 10px 10px 0;
              @include border-radius(0px);
              top: 96%;
              font-size: 1em;
    
              &:before {
                content: "";
                position: absolute;
                top: -19px;
                left: 8px;
                width: 30px;
                height: 30px;
                background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
              }
    
              .dropdown-item {
                margin: 0 0 10px 0;
                width: 140px;
                padding: 5px 5px;
                @include border-radius(5px);
                @include border("top", 1px, solid, $alto);
                @include border("left", 1px, solid, $alto);
                @include border("bottom", 1px, solid, $alto);
                @include border("right", 1px, solid, $alto);
    
                &.active,
                &:active {
                  background-color: $wildsand;
                }
              }
    
              .dropdown-divider {
                display: none;
              }
            }
          }
        }
  
        // &.textfield-wrapper:after{
        //     border-left: 0 none;
        //     border-right: 1px solid $grey;
        //     right: 0;
        //     z-index: 1000;
        // }
        input.form-control {
          padding-left: 10px;
          height:37px;
          font-size: 0.85rem;
          line-height: 1;
          color: $dark-grey;
          border-bottom: 1px solid $grey;
          &:focus,&:active{
            border-bottom: 1px solid $grey;
          }
        }

        .objectType-dropdown{
          width: 140px;
          .btn-secondary{
            padding: 6px 10px;
            width: 100%;
            border: 1px solid $grey;
            border-radius: 4px;
            text-align: left;
            color: $abi-blue;
            font-size: 1rem;
            font-weight: 600;
            height: 38px;
            svg{
              margin-top: -2px;
            }
          }
        }
        .project-search-box{
          width: calc(100% - 145px);
          input.form-control{
            padding-left: 10px;
            height:38px;
            font-size: 0.85rem;
            line-height: 1;
            border-radius: 3px !important;
            color: $dark-grey;
            border: 1px solid $grey !important;
            margin: 0;
            ::placeholder { 
              opacity: 1; /* Firefox */
            }
            
            :-ms-input-placeholder { /* Internet Explorer 10-11 */
              opacity: 1;
            }
            
            ::-ms-input-placeholder { /* Microsoft Edge */
              opacity: 1;
            }
            &:focus,&:active{
              box-shadow: none;
              outline: none;
            }
          }
          .css-hlgwow{
            padding: 2px 8px;
          }
          .css-13cymwt-control, .css-t3ipsp-control {
            height: auto !important;
            border-radius: 0 !important;
            padding-right: 0 !important
          }
        }
        .radial-search{
          flex-basis: 40%;
          .radial-search-input-container{
            width:68%;
            position: relative;
            border: 1px solid $grey;
            border-left: 0;
            background: $white;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            height: 38px;
          }
          input.form-control{
            border-left: 0;
            &:focus,&:active{
              box-shadow: none;
            }
          }
          .dropdown{
            width: 32%;
            .dropdown-toggle{
              padding: 7px 10px;
              min-width: 140px;
              background: $white;
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px;
              text-align: left;
              border: 1px solid $grey;
              height: 38px;
              color: $abi-blue;
              font-size: 0.9rem;
              border-right: 0;
              width: 100%;
            }
          }
        }
        .search-action{
        }

        .css-13cymwt-control{
          border: 1px solid $grey;
          overflow: hidden;
          background: #fff;
          border-radius: 4px;
          border-left: none;
          width: 100%;
          height: 38px;
        }
      }
  
      .Project-field {
        &:before {
          width: 30px;
          height: 30px;
          content: "";
          position: absolute;
          top: 8px;
          left: 8px;
          background: $transparent url($iconsprite) no-repeat scroll -120px -270px;
        }
      }
  
      .Company-field {
        &:before {
          width: 30px;
          height: 30px;
          content: "";
          position: absolute;
          top: 8px;
          left: 8px;
          background: $transparent url($iconsprite) no-repeat scroll -180px -240px;
        }
      }
  
      .Company-field,
      .Project-field {
        position: relative;
  
        .role-dropdown {
          position: absolute;
          right: 2px;
          left: 5px;
          top: 40px;
          box-shadow: 0 0 5px $grey;
          z-index: 1;
  
          &:before {
            content: "";
            position: absolute;
            top: -19px;
            left: 8px;
            width: 30px;
            height: 30px;
            background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
          }
  
          .role-filter-block {
            padding: 10px;
  
            .roles-search-list {
              input {
                height: 35px;
              }
            }
          }
  
          input.form-control {
            border: 1px solid $grey;
            padding-left: 10px;
          }
        }
  
        input.form-control {
          padding-left: 40px;
        }
  
        .top-text-roles {
          position: absolute;
          left: 40px;
          font-size: 0.75rem;
        }
      }
  
      .search-controls-section {
        max-width: 100px;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
  
        .right-save-col {
          width: 100px;
  
          .over-map-panel {
            width: 100px;
          }
        }
      }
    }
  }
  
  .search-container input.form-control::placeholder {
    color: $grey;
    font-weight: 400;
    // @extend %reference-font;
  }
  
  .search-container input.form-control::-webkit-input-placeholder {
    color: $grey;
    font-weight: 400;
    // @extend %reference-font;
  }
  
  .search-container input.form-control::-moz-placeholder {
    color: $grey;
    font-weight: 400;
    // @extend %reference-font;
  }
  
  .search-container input.form-control:-moz-placeholder {
    color: $grey;
    font-weight: 400;
    // @extend %reference-font;
  }
  
  .search-container input.form-control:-ms-input-placeholder {
    color: $grey;
    font-weight: 400;
    // @extend %reference-font;
  }
  
  .page-title-bar>div:first-child {
    .page-title-bar>div:first-child {
      display: block;
    }
  }
  
  .radius-src-filter {
    position: absolute;
    width: 250px;
    right: 0;
    background: $white;
    box-shadow: 1px 1px 5px 1px $grey;
    font-weight: 500;
    z-index: 999;
  
    .selected-radius {
      background: $grey;
      padding: 5px 6px;
      color: $black;
  
      span {
        &:first-child {
          font-size: 0.75rem;
          font-weight: 500;
        }
  
        &:last-child {
          float: right;
          display: inline-flex;
          font-weight: 600;
  
          select {
            top: 0;
            position: relative;
            height: auto;
            line-height: 12px;
            padding: 0px;
            margin-left: 7px;
          }
        }
      }
  
      .unit {
        padding-left: 5px;
      }
    }
  
    .rc-slider-wrapper {
      height: auto;
      padding: 7px 10px 0 10px;
  
      .rc-slider {
        margin: 10px 5px 5px;
        position: relative;
      }
  
      .range-start-label {
        color: $black;
      }
  
      .range-end-label {
        float: right;
        color: $black;
      }
  
      .rc-slider-track {
        background-color: $abi-blue;
      }
    }
  
    &:before {
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
      top: -18px;
      right: 32px;
    }

    .rc-slider-handle{
      margin-top: -3px;
      opacity: 1;
      &:focus{
        box-shadow: 0 0 5px #000;
      }

    }
  }
  
  /*Listview Search Panel*/
  
  /* iPads (portrait and landscape) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {}
  
  /*New Filter UI*/
  .search-container {
    &.new-filter {
      .newCloseFilter {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
  
      .new-filter-wraper {
        position: absolute;
        left: 75px;
        top: 110px;
        height: calc(100% - 120px);
        width: calc(100% - 85px);
        background: $white;
        padding: 1rem;
  
        .advance-filter-close-button {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 30px;
          height: 30px;
          background: transparent url($iconsprite) no-repeat scroll -240px -360px;
          cursor: pointer;
          z-index: 999;
        }
  
        .filter-page-wrapper {
          width: 100%;
          border: none;
          height: calc(100% - 100px);
  
          .filter-menu-section {
            height: 100%;
            float: none;
            border: $filter-border-color solid 1px;
            border-right: none;
            width: 15%;
  
            @include for-1400-desktop {
              width: 20%;
            }
  
            .FiltermenuListlinks {
              ul {
                li {
                  border: none;
                  padding: 0;
                  
                  margin-bottom: 0;
  
                  &:last-child {
                    border-bottom: none;
                  }
  
                  a {
                    display: block;
                    padding: .45rem 0.75rem;
                    width: 98%;
                    border-left: transparent solid 4px;
                    color: $black;
                    cursor: pointer;
                    font-weight: 400;
                    border-bottom: $filter-border-color solid 1px;
  
                    &.currentFilter {
                      border-left-color: $fiter-active;
                    }
  
                    &.active {
                      background-color: $filter-active-bg;
                      border-bottom-color: $white;
                      font-weight: 700;
                    }
  
                    &:hover {
                      //border-color: $fiter-active;
                    }
  
                    &.disabled-link {
                      opacity: 0.5;
                      cursor: initial;
                      pointer-events: none;
                    }
                  }
                }
              }
            }
          }
  
          .filter-menu-conten-section {
            height: 100%;
            float: none;
            border: $filter-border-color solid 1px;
            border-left: none;
            border-right: none;
            width: 45%;
  
            @include for-1400-desktop {
              width: 50%;
            }
  
            .advanced-filter-form-wrapper {
              margin-right: 1rem;
  
              .advanced-filter-grid {
                .filterHeading {
                  h5 {
                    font-weight: 700;
                  }
                }
  
                .filterBlock {
                  background: $whisper;
                  border: $filter-border-color solid 1px;
                  padding: 1rem;
  
                  &.activeBlock {
                    background: $light-blue;
                  }
  
                  .select-input-type {
                    button.btn-secondary {
                      font-weight: 400;
                      font-size: 0.85rem;
                      line-height: 1.5;
  
                      .dropdown-item {
                        font-weight: 400;
                      }
                    }
                  }
  
                  .textcontent {
                    color: $black;
                    font-weight: 400;
  
                    .switch {
                      position: static;
                      top: 0;
                      right: 0px;
                    }
                  }
  
                  .form-check-label {
                    font-weight: 400;
                    font-size: 0.9rem;
                  }
  
                  .radio-buttons {
                    label {
                      padding-left: 1.5rem;
                    }
  
                    input[type=radio]:disabled {
                      cursor: default;
                    }
  
                    input[type=radio]:disabled+label {
                      opacity: 0.7;
                      cursor: default;
                    }
  
                    .form-check {
                      margin-bottom: 0;
                    }
                  }
  
                  .disableBoxfilter {
                    cursor: not-allowed;
                    opacity: 0.7;
                  }
  
                  .disableBlock {
                    pointer-events: none;
                    cursor: not-allowed;
                    opacity: 0.7;
  
                  }
  
                  .slider-frames {
                    .textcontent {
                      color: $black;
  
                      .col-12 {
                        span {
                          font-weight: 700;
                          color: $blue;
                        }
                      }
                    }
                  }
  
                  .textfield-wrapper {
                    border: none;
                  }
                }
  
                .form-control {
                  line-height: 1.5;
                  border-color: $filter-input-border;
                }
  
                .rangeIndicator {
                  .centerIndicator {
                    position: relative;
  
                    &::before {
                      content: '|';
                      overflow: hidden;
                      height: 21px;
                      line-height: 20px;
                      font-size: 20px;
                      top: -28px;
                      color: $gray-dark;
                      position: absolute;
                      left: 50%;
                    }
                    &.centerIndicatorforConsEnd{
                      left: -4px;
                      &::before {
                        left: 21%;
                      }
                    }
                  }
                }
  
                .filterTabs {
  
                  .nav-tabs,
                  .nav-link {
                    border: none;
                  }
  
                  .nav-tabs {
                    display: inline-block;
                    background: $white;
                    box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.15);
                    border-radius: 7px;
                    overflow: hidden;
  
                    .nav-item {
                      display: inline-block;
                      margin-bottom: 0;
                      border-radius: 0;
  
                      .nav-link {
                        color: rgba(4, 171, 234, 0.6);
                        border-radius: 0;
                        padding: .5rem 1rem .5rem 1rem;
                        cursor: pointer;
                        position: relative;
  
                        i.tickIcon {
                          position: absolute;
                          left: 5px;
                          top: 50%;
                          width: 15px;
                          height: 15px;
                          margin-top: -7px;
                          background: transparent url($iconsprite) no-repeat scroll -157px -428px;
                          display: none;
                        }
  
                        &.active {
                          background: $cerulean;
                          color: $white;
  
                          i {
                            background-position: -185px -428px;
                          }
                        }
  
  
  
                        font-weight: 700;
                      }
                    }
                  }
                }
  
                .withcollapsCheckboxes {
                  margin: 0 -14px;
  
                  .tree-filter {
                    >div.card {
                      border-left: none;
                      border-right: none;
                      border-color: $filter-border-color;
                      border-bottom: none;
                    }
                  }
                }
              }
            }
  
  
  
          }
  
          .summeryTags {
            height: 100%;
            float: none;
            border: $filter-border-color solid 1px;
            border-right: none;
            width: 39%;
            margin-left: 1%;
            position: relative;
  
            @include for-1400-desktop {
              width: 35%;
            }
  
            .filterTagsSummery {
              padding: 1rem;
  
              .summeryTagsList {
                color: $abi-blue;
  
                .tag-element {
                  cursor: pointer;
                  // text-transform: capitalize;
                  padding: 2px;
                  background: $alice-blue;
                  border-radius: 5px;
                  float: none;
                  margin-right: 10px;
                  margin-bottom: 2px;
                  padding-left: 10px;
                  height: auto;
                  margin-top: 9px;
                  padding-top: 3px;
                  // display: grid;
                  // grid-template-columns: fit-content(350px) fit-content(490px) 1fr;
                  position: relative;
                  padding-right: 30px;
  
                  span.tag-title {
                    font-weight: bold;
                    padding-right: 3px;
                    float: none;
  
                    &:after {
                      content: ":";
                      width: 5px;
                      margin-left: 1px;
                      margin-right: 2px;
                    }
  
                    &.onlyTitle {
                      &:after {
                        display: none;
                      }
                    }
                  }
  
                  span.tag-content {
                    //padding-right: 25px;
                  }
  
                  span.tag-close-button {
                    width: 29px;
                    height: 29px;
                    display: block;
                    top: 0%;
                    right: 0;
                    background: transparent url($iconsprite) no-repeat scroll -240px -390px;
                    cursor: pointer;
                    position: absolute;
                    margin-top: 0px;
                  }
  
                  &.bigSummaryTruncated {
                    .tag-content {
                      width: 200px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                    .tag-title{
                    }
                  }
                }
              }
            }
  
            .summeryActions {
              position: absolute;
              left: 0;
              width: 100%;
              z-index: 1;
              top: -40px;
  
              .summeryHeadingTxt {
                h3 {
                  margin: 0px;
                  font-size: 1.5rem;
                  font-weight: 400;
                  color: $black;
                }
              }
  
              .clearFilterBtn {
                button.btn-secondary {
                  position: relative;
                  padding: 5px 1rem;
  
                  span {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    border: $abi-red solid 1px;
                    width: 21px;
                    height: 21px;
                    border-radius: 50%;
                    line-height: 18px;
                    text-align: center;
                    background-color: $abi-red;
                    font-size: 11px;
                    color: $white;
                    font-weight: 600;
                  }
  
                  &:hover {
                    box-shadow: none;
  
                    span {
                      color: $abi-red;
                      background-color: $white;
                    }
                  }
                }
              }
              .closeFilterBtn{
                  button.btn.btn-close {
                    text-indent: -9999px;
                    overflow: hidden;
                    display: block;
                    width: 30px;
                    height: 30px;
                    background: transparent url($iconsprite) no-repeat scroll -210px -450px;
                    background-size: 300px;
                    border:0 none;
                }
              }
            }
          }
        }
        .bottomFilterCount{
          position: relative;
        }
        .bottomFilterCountBox {
          padding-top: 1rem;
  
          .buttonCntrBFltr {
            position: relative;
  
            .src-button {
              button.btn-primary-save {
                line-height: 28px;
                border-color: $abi-blue;
                color: $abi-blue;
                cursor: pointer;
                height: auto;
                width: auto;
                padding: 6px 12px;
                font-size: 1rem;
                border-radius: 4px;
                &:hover,
                &:focus {
                  background: $abi-blue;
                  color: $white;
                  box-shadow: none;
                }
              }
            }
  
            .save-search-form {
              position: static;
              float: none;
              width: auto;
              height: auto;
  
              .btn-primary-save {
                position: static;
              }
  
              .hide-save-search-form {
                overflow: hidden;
              }
  
              .visible-save-search-form {
                right: auto;
                left: 0;
                overflow: hidden;
                top: -2px;
  
                .btn-secondary-save {
                  padding-top: .57rem;
                  padding-bottom: .57rem;
                }
  
                .btn-cancel {
                  top: 2px;
                }
              }
            }
          }
  
          .shortSearchInfo {
            p {
              color: $dark-black-gray;
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
            }
  
            h6 {
              color: $abi-blue;
            }
          }
  
          .saveButtonBottonFilter {
            ul {
              border: $cerulean solid 1px;
  
              li {
                cursor: pointer;
                color: $cerulean;
                padding: 0.25rem 0.75rem;
                border-right: $cerulean solid 1px;
                min-width: 160px;
  
                &:last-child {
                  border-right: none;
                }
  
                &.active {
                  color: $white;
                  background: $cerulean;
                }
  
                .viewTxtcount {
                  font-size: 0.75rem;
                  font-weight: 600;
                  line-height: 0.75rem;
                  margin-bottom: -3px;
                }
  
                span {
                  font-size: 1.15rem;
                }
  
                abbr {
                  font-size: 0.85rem;
                }
              }
            }
          }
        }
      }
  
  
    }
  }
  
  .location-field{
    position: relative;
    .dropdown{
      .btn,
      .btn-secondary{
        font-size: 13px;
        height: 41px;
        border:0 none;
        width: 145px;
        color: $abi-blue;
        text-align: left;
        &:hover,
        &:focus,
        &:active{
          background: $blue-grey;
          box-shadow: none;
        }
      }
      .dropdown-toggle{
        &:after {
          border: 0;
          width: 10px;
          height: 10px;
          content: "";
          position: absolute;
          top: 16px;
          right: 12px;
          background: transparent url($iconsprite) no-repeat scroll -220px -77px;
        }
      }
      .dropdown-menu{
        border:0 none;
        box-shadow: 0 0 5px $grey;
        padding: 0;
        border-radius: 0;
      }
      .dropdown-item{
        font-size: 13px;
        color: $abi-blue;
        padding: 10px 17px 8px; 
        width: 145px;
        cursor: pointer;
        &:hover,
        &:focus,
        &:active{
          background: $blue-grey;
        }
      }
    }
  
    .input-group {
      &.has-more{
        .Select.is-clearable.is-searchable.Select--multi {
          .Select-control{
            &:after{
              content: "";
            }
          }
          &.is-focused{
            &:after{
              background: $transparent url($iconsprite) no-repeat scroll -220px -65px;
              width: 10px;
              height: 10px;
              overflow: hidden;
              text-indent: -999px;
              position: absolute;
              right: 17px;
              top: 20px;
              z-index: 1003;
              border: 0 none;
              content: "";
              cursor: pointer;
            }
            &:before{
              background: $white;
              width: 30px;
              height: 45px;
              overflow: hidden;
              text-indent: -999px;
              position: absolute;
              right: 0px;
              top: 0px;
              z-index: 1002;
              border: 0 none;
              content: "";
              cursor: pointer;
            }
          }
        }
      }
      .Select.is-clearable.is-searchable.Select--multi {
          width: 100%;
          position: relative;
          z-index: 1000;
        
          .Select-menu-outer{
            display: none;
          }
          &.is-focused{
            .Select-control{
              overflow: visible;
              height: auto;
              transition: height 0.25s ease-in-out;
              padding:10px 25px 0 7px;
              box-shadow: 0 0 5px $alto;
              min-height: 45px;
              &:after{
                transform: rotate(90deg);
              }
              .Select-multi-value-wrapper{
                min-height: 35px;
                height: auto;
              }
            }
          }
  
          .Select-control {
              border: 0 none;
              display: flex;
              height: 45px;
              overflow: hidden;
              padding:10px 25px 8px 7px;
              background: $white;
              border-radius: 0;
              position: absolute;
              align-items:flex-start; 
              &:after{
                background: transparent url($iconsprite) no-repeat scroll -220px -77px;
                width: 10px;
                height: 10px;
                overflow: hidden;
                text-indent: -999px;
                position: absolute;
                right: 17px;
                top: 20px;
                z-index: 1001;
                border: 0 none;
                content: none;
                cursor: pointer;
              }
              &:before{
                background: $white;
                width: 30px;
                height: 45px;
                overflow: hidden;
                text-indent: -999px;
                position: absolute;
                right: 0px;
                top: 0px;
                z-index: 1000;
                border: 0 none;
                content: "";
                cursor: pointer;
              }
  
              .Select-placeholder {
                  span {
                      font-size: 14px;
                      color: $grey;
                      font-weight: 400;
                      top: 5px;
                      left: 5px;
                      position: relative;
                  }
              }
  
              .Select-arrow-zone,
              .Select-clear-zone {
                  display: none;
              }
              .Select-multi-value-wrapper {
                height: 25px;
                overflow: hidden;
                margin: 0;
                width: 100%;
                  .Select-input > input{
                    height: 25px;
                    padding: 0;
                  }
                  .Select-value {
                      color: $white;
                      border: 1px solid $grey;
                      background-color: $grey;
                      margin: 0 5px 5px 0;
                      display: inline-flex;
                      flex-direction: row-reverse;
                      height: 25px;
  
                      .Select-value-label {
                          color: $black;
                          font-size: 13px;
                          font-weight: 600;
                          position: relative;
  
                      }
  
                      .Select-value-icon {
                          color: $black;
                          border-right: 0 none;
                          font-size: 13px;
                          font-weight: 600;
                          margin: 0;
                          padding: 5px;
                          position: static;
                          display: flex;
                          align-items: center;
  
                          &:hover,
                          &:focus {
                              color: $black;
                              background-color: $transparent;
                          }
                      }
                  }
              }
  
              .Select-clear-zone {
                  width: 15px;
                  top: 5px !important;
                  position: absolute;
                  right: 9px;
  
                  .Select-clear {
                      font-size: 0.85rem;
                      color: $white;
                      background: $emperor-gray;
                      border-radius: 50%;
                      width: 15px;
                      height: 15px;
                      font-weight: 700;
                  }
              }
          }
      }
    }
    .css-b62m3t-container{
      width: 68%;
      position: absolute;
      left: 32%;
      z-index: 4;
      .css-13cymwt-control,.css-t3ipsp-control{
        padding-right: 10%;
      }
    }
  }

  .object-info-container{
    flex-basis: 60%;
    margin-top:1px;
    position: relative;
    &.full-width{
      flex-basis: 100%;
      .project-search-box{
        width: calc(100% - 152px) !important;
      }
    }
  }
  .selected-info-label-list{
    padding: 3px 130px 3px 10px;
    background: $white;
    width: 70% !important;
    position: relative;
    border-radius:2px;
    font-size: 13px;
    height: 31px;
    &.single-info{
    }
    .switch-with-text{
      margin: 3px;
    }
    .label{
      font-size: 0.85rem;
      min-width: 85px;
    }
    .any-all-comp{
      position: absolute;
      top:-1px;
      right: 0;
    }
    .Select-value-tag{
      background: $alto;
      border: 0;
      color: #000;
      margin-bottom: 3px;
      display: inline-flex;
      flex-direction: row-reverse;
      margin-top: 0;
      // overflow: hidden;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      height: 24px;
      .Select-value-label{
        padding: 2px 7px;
        font-size: 13px;
      }
      .Select-value-icon{
        color: $black;
        border: 0;
        padding-top: 3px;
        cursor: pointer;
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        padding: 1px 5px 3px;
      }
    }
  }

  .term-error {
    width: 30%;
    position: relative;
    color: #f04047;
    padding: 5px 10px;
    font-size: 0.85em;
    margin-top: 4px;
    background-color: #f9d7dc;
    line-height: 1.2;
    height: 31px;
    margin-left: 10px;
  }

  .filter-term-error {
    width: 103%;
    position: relative;
    color: #f04047;
    padding: 0px 10px;
    font-size: 0.85em;
    margin-top: 4px;
    line-height: 1.2;
    height: 31px;
    margin-left: 0px;
  }

  .page-title-bar{
    padding: 2px 10px;
    .sort-result-section {
      .dropdown{
        .dropdown-toggle {
          &:after{
            right: 7px !important;
            top: 9px !important;
          }
        }
      }
      &.sort-results {
        .dropdown-toggle {
          margin-left: 0;
          background: $white;
          color: $abi-blue;
          box-shadow: none;
          border: 1px solid $alto;
          font-size: 0.9rem;
          height: 36px;
          text-align: left;
          width: 115px;
        }
      }
    }
    .maplist-container{
      position: relative;
      left: 0;
      top: 1px;
      width:auto;
      border-radius: 4px;
      .listview{
        position: relative;
        height: 36px;
      }
    }
    .select-drop-count{
      position: relative;
      display: block;
       .dropdown-toggle{
        position: relative;
        background: none;
        color: $black;
        border: 1px solid $alto;
        height: 36px;
        padding: 0 5px 0;
        margin: 0;
        border-radius: 0;
        min-width: 55px;
        text-align: left;
        background-color: $white;
        font-size: 0.9rem;
        border-radius: 4px;
        &:hover,&:active{
          background: $white;
        }
        p{
          font-size: .9rem;
          color: $abi-blue;
          position: inherit;
          font-weight: 600;
          text-align: left;
          padding-right: 25px;
          margin-bottom: 0;
        }
        &:after{
          content: "";
          position: absolute;
          top: 12px;
          right: 2px;
          width: 15px;
          height: 15px;
          border: 0;
          background: transparent url($iconsprite) no-repeat scroll -220px -75px;
        }
        
      }
    }
  }


  .searchList-header{
    h1.show{
        font-size: 0.95rem;
        margin-bottom: 0px;
        span{
          font-size: .75rem;
          display: inline-block;
          max-width: 200px;
          line-height: 14px;
          padding: 0;
          vertical-align: middle;
          white-space: nowrap;
        }
    }
    .result-count{
      font-size: .75rem;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin-left: 15px;
      
      .applied-miles{
        color: $red;
      }
    }
    h1.Header-withback{
      margin-left: 55px;
  }
  .hide {
      display: none !important;
  }
  .map-back{
      display: inline-block;
      color: $black;
      font-size: 0.8rem;
      cursor: pointer;
      font-weight: 400;
      border: 1px solid $grey;
      padding: 14px 13px 0;
      background: $white;
      position: relative;
      text-decoration: none;
      border-radius: 4px;
      &:before{
        content: '';
        background: $transparent url($iconsprite) no-repeat scroll 0px -240px;
        height: 30px;
        position: absolute;
        right: 11px;
        top: -6px;
        width: 30px;
    }
    }
  }

  .result-list-container{
    .result-list-wrapper{
      .searchlist-wrapper{
        height: calc(100vh - 230px);
        &.with-project-info{
          height: calc(100vh - 265px);
          &.mapViewMode{
            height: calc(100vh - 204px);
          }
        }
        .box + div{
          display: none;
        }
      }
    }
    
  }

  .select-actions{
    position: relative;
    background: $white;
    border-left: 0 none;
    z-index: 1001;
    height:36px;
    border: 1px solid $alto;
    button.btn{
        color: $transparent;
        overflow: hidden;
        width: 30px;
        cursor: pointer;
        border: 0;
        position: relative;
        padding: 7px 0 6px;
        background: $white;
        &:after{
          display: none;
        }
        svg{
          margin-left: 2px;
          margin-top: -5px;
        }
        &:hover, &:active{
          background: $white;
        }
    }
    .action-list{
        margin: 0;
        padding: 0;
        border-radius:0;
        box-shadow: 1px 1px 5px $dove-grey;
        border: 0 none;
        position: absolute;
        top: 35px;
        right: 3px;
        width: 175px;
        &:before{
            content:"";
            position: absolute;
            width: 30px;
            height: 30px;
            background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
            top: -18px;
            left: 0;
            z-index: 1;
            left: auto;
        }
        button {
            color: $black-grey;
            text-indent: 0;
            background: none;
            border-bottom: 1px solid $mercury;
            width: 100%;
            margin: 0;
            padding: 5px 8px;
            font-size: .9rem;
            font-weight: 400;
            letter-spacing: normal;
             &:last-child{
                 border-bottom: none;
             }
             &:hover{
                background-color: $mercury;
             }
        }
        .disabled {
            color: $grey;
            cursor: default;
            &:hover{
                background-color: $white;
             }
        }
    }
}

.css-13cymwt-control{
  height: 40px;
  border: 1px solid $grey;
  input{
    outline: none;
    &:focus{
      outline: none;
      box-shadow: none;
    }
  }
}
.css-t3ipsp-control{
  outline: none;
  border: 1px solid $grey;
  box-shadow: none;
}

.show-more-text{
  .react-town-select-container{
    .css-1p3m7a8-multiValue{
      margin: 4px 2px;
    }
    .css-13cymwt-control{
       height: 38px !important;
    }
  }
  
}

.show-more-town-arrow{
  width: 38px;
  height: 38px;
  right: 10px;
  top: 0px;
  position: absolute;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  z-index: 4;
  &:after {
    border: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: "";
    background: transparent url($iconsprite) no-repeat scroll -220px -77px;
  }
}
.show-less-town-arrow{
  width: 38px;
  height: 38px;
  right: 10px;
  top: 0px;
  position: absolute;
  text-align: center;
  line-height: 38px;
  cursor: pointer;
  z-index: 4;
  position: absolute;
  &:after {
    border: 0;
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    transform: rotate(180deg);
    background: transparent url($iconsprite) no-repeat scroll -220px -77px;
  }
}

.show-more-postCode-arrow{
  width: 20px;
  height: 20px;
  right: 10px;
  top: 9px;
  position: absolute;
  z-index: 4;
  &:after {
    border: 0;
    width: 10px;
    height: 10px;
    display: inline-block;
    content: "";
    background: transparent url($iconsprite) no-repeat scroll -220px -77px;
  }
}
.show-less-postCode-arrow{
  width: 20px;
  height: 20px;
  right: 10px;
  top: 9px;
  z-index: 4;
  position: absolute;
  &:after {
    border: 0;
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    transform: rotate(180deg);
    background: transparent url($iconsprite) no-repeat scroll -220px -77px;
  }
}
.project-information-wrapper {
  .tag-list-wrapper{
    overflow: hidden;
    flex-wrap: wrap;
    &.auto-height{
      overflow: auto;
      position: absolute;
      left: 100px;
      z-index: 4;
      width: 78%;
      background: #fff;
      box-shadow: 0 3px 10px $dark-grey;
    }
    
  }
  .Select-value-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.showmore-tiles{
  color: $abi-blue;
  cursor: pointer;
  background-color: $gray-dark;
  font-size: .75rem;
  padding: 4px 15px;
  align-self: flex-start;
  margin-top: 7px;
}