.print{
    overflow: unset !important;
    background: $dove-grey;
    .main-content{
        padding: 0;
    }

    .side-navigation{
        display: none;
    }
    #print-container{
        width: 21cm;
        margin: 0 auto;
        box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
        background:$white;
        margin-top: 10px;
        padding: 0;
        .detail-page-wrapper {
            position: static;
            margin-top: -20px;
            height: auto !important;
            .top-info{
                margin: 0;
                .map-view {
                    min-height: inherit;
                    background: none;
                    img {
                        height: auto;
                    }
                }
            }
            .copyright {
                margin: 0 25px;
                display: block;
                div {
                    position: relative;
                    padding: 5px 0 15px;
                    left: 0;
                    margin: 0;
                    border-bottom: 5px solid $mercury;
                    color: $black;
                    font-weight: 400;
                    font-size: .85rem;
                    span{
                        font-weight: 400;
                        font-size: 0.85rem;
                        color: $black;
                    }
                }
                .site-progression{
                    position: absolute;
                    border: 0;
                    right: 0;
                    left: auto;
                    width: 150px;
                    top: 0;
                    span{
                        color: $black;
                        font-size: .9rem;
                        font-weight: 700;
                        margin-bottom: 5px;
                        display: block;
                    }
                    div{
                        border:0 none;
                        color: $dark-green;
                        font-weight: 700;
                    }
                    .donutchart{
                        position: absolute;
                        right: 15px;
                        top: 10px;
                    }
                }
            }
            .logo-images{
                display: block;
                position: absolute;
                top: 85px;
                margin: 0 15px;
                z-index: -1;
                width: 202px;
                height: auto;
            }
            .op{
                width: 202px;
                top: 85px;
            }
        }
    }
    .dp-nav-buttons-wrapper {
        position: fixed;
        right: 50px;
        left: auto;
        width: auto;
    }
    .search-container-wrapper, .toggle-filters, .filter-navigation {
        display:none;
    }
    #print-container{
        .company-overview, .project-overview{
            position: relative;
            padding: 5px 0 5px 0;
            left: 0;
            margin: 0 25px;
            //border-bottom: 5px solid $grey;
            h2{
                float: inherit;
                margin:10px 100px 10px 0;
                line-height: 1;
            }
            .proj-location{
                padding-left: 0;
                &::before{
                    background: none;
                }
            }
            .proj-ref{
                color: $black;
                font-weight: 400;
                font-size: .85rem;
                display: inline-block;
                position: relative;
                margin: 0 0 0 25px;
                strong{
                font-weight: 700;
                }
                &.proj-location{
                    margin-left: 0;
                    &::before{display: none;}
                }
                &::before{
                    content: "|";
                    background: none;
                    top:0;
                }
            }
            .updated-date, .distance{
                // margin: 0 10px;
                padding: 0 5px;
                font-weight: 700;
                &::before{
                    content: "|";
                    background: none;
                    top:0;
                }
            }
            .archive-badge-flag, 
            .new-pro-flag, 
            .project-build-phase-pro-flag{
                position: static;
                margin: 0 1rem 0 0; 
            }
        }
        .company-information, .project-information{
            .company-info-wrapper, .project-info-wrapper{
                border: none;
                border-bottom: 5px solid $mercury;
                margin: 0 25px;
                width: auto;
                position: relative;
                // padding-bottom: 15px;
                .field-name{
                    color: $black;
                    font-size: .9rem;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
                // .row{
                    .col{
                        border: none;
                        padding: 10px 0;
                        .field-name{
                            font-size: 0.95rem;
                            color: $black;
                            margin-bottom: .5rem;
                            font-weight: 700;
                        }
                    }
                    .col.proj-contractstages{
                        min-height: auto;
                    }
                    .proj-value{
                        position: absolute;
                        top: -138px;
                        right: 0;
                        text-align: right;
                        .field-value{
                            margin-bottom: 0;
                            font-size: 1.3rem;
                        }
                    }
                // }
            }
        }
        .project-information{
            .field-value{
                font-size: .9rem;
                margin-bottom: 25px;
            }
            .project-info-wrapper{
                .proj-address, .proj-scheme{
                    width: 100%;
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                .proj-timing{
                    .field-value{
                        margin-bottom: 0px;
                    }
                }
                .list-block {
                    width: 100%;
                    margin: 0;
                    min-height: inherit;
                    span{
                        // padding-left: 10px;
                        // padding-right: 10px;
                        padding: 0;
                        display: inline;
                        font-size: .9rem;
                    }
                }
                .proj-sizes .nested-values .list-group-wrapper label{
                    width: auto;
                    margin-right: 5px;
                    margin-bottom: 0;
                    float: none;
                    font-size: .9rem;
                }
                .nested-values .list-group-wrapper label{
                    width: auto;
                    margin-right: 5px;
                    margin-bottom: 0;
                    float: none;
                    font-size: .9rem;
                }
            }
        }
        .project-tab-panel{
            width: 100%;
            background: $white;
                .print-contact-people{
                    width: 100%;
                    padding: 0 10px;
                    .Project-print-wrapper{
                        // width: 100%;  padding: 0 10px;
                        padding: 0 15px;
                        margin-bottom: 10px;
                        //border-bottom: 5px solid $grey;
                        > strong{
                            padding: 10px 10px 5px;
                            display: block;
                            font-size: 1.2rem;
                        }
                        .tab-pane{
                            margin-bottom: 10px;
                            .project-role-wrapper{
                                padding: 0;
                                background: none;
                                box-shadow: none;
                                margin: 0;
                                .prw-heading{
                                    background: $mercury;
                                    position: relative;
                                    top: 0;
                                    color: $black;
                                    font-size: 1rem;
                                    padding: 5px 10px;
                                    border-radius: 0;
                                    margin: 0 0 15px;
                                }
                                .project-role-name{
                                    background: none;
                                    position: relative;
                                    top:0;
                                    color: $black;
                                    font-size: 1rem;
                                    padding: 0;
                                    font-weight:700; 
                                }
                                .people-on-proj-title{
                                    color: $black;
                                    font-size: 1rem;
                                    padding: 0;
                                    font-weight:700;
                                }
                                .result-list-container{
                                    margin: 0;
                                    padding: 0;
                                    .company-container.read-block{
                                        background: $white;
                                    }
                                    #searchlistAccordion{
                                        margin: 0;
                                        padding: 0;
                                        li.list-group-item{
                                            border: none;
                                            margin-bottom: 15px;
                                            border-bottom: 1px solid $mercury;
                                            &:last-child{
                                                border-bottom: 0 none;
                                            }
                                            &:hover{
                                                box-shadow: none;
                                                border: none;
                                            }
                                            .checkmark{
                                                display: none;
                                            }
                                            .company-container{
                                                .result-block{
                                                    cursor: default;
                                                    pointer-events: none;
                                                    padding: 0;
                                                    .result-title-name{
                                                        font-size: 0.9rem;
                                                        color: $black;
                                                        font-weight: 700;
                                                    }
                                                }
                                                .related-Company-btn{
                                                    display: none;
                                                }
                                                .result-row-overview{
                                                    padding: 0 0 10px 0;
                                                    min-height: auto;
                                                    .showIconCounts{
                                                        display: none;
                                                    }
                                                    .com-address-print{
                                                        margin: 0;
                                                        padding: 0;
                                                    }
                                                    .comp-data{
                                                        padding-right: 0;
                                                        color: $black;
                                                        // border-right: 1px solid $black;
                                                        padding-left: 0;
                                                        &::after{
                                                            content: "|";
                                                            position: relative;
                                                            margin:0 10px
                                                        }
                                                        &:last-child{
                                                            &::after{
                                                                content: "";
                                                            }
                                                        }
                                                        &:first-child{
                                                            padding-left: 0;
                                                        }
                                                    }
                                                    .comp-address{
                                                        padding: 0;
                                                        color: $black;
                                                    }
                                                }
                                                .team-contact-list{
                                                    strong{
                                                        font-size: 0.95rem;
                                                        color: $dark-grey;
                                                    }
                                                    .row{
                                                        margin: 0;
                                                        padding: 0;
                                                    }
                                                    .user-contact-details{
                                                        background: $white;
                                                        border: none;
                                                        display: flex;
                                                        flex-direction: column;
                                                        width: 100%;
                                                        color: $black;
                                                        margin: 0 0 10px;
                                                        min-height: auto;
                                                        padding: 3px 0;
                                                        font-weight: 700;
                                                        font-size: .85rem;
                                                        word-wrap: break-word;
                                                        &:before{
                                                            content: none;
                                                        }
                                                        p{
                                                            font-size: .85rem;
                                                            font-weight: 700;
                                                        }
                                                        div{
                                                            padding:0;
                                                            margin:0;
                                                            font-weight: 400;
                                                            font-size: .85rem;
                                                            &:last-child{
                                                                margin-right:0;
                                                                // max-width: 30%;
                                                            }
                                                        }
                                                        .ucd-email{
                                                            &:after{
                                                                content: "|";
                                                                margin: 0 10px;
                                                            }
                                                            &:empty{
                                                                display: none;
                                                                &:after{
                                                                    content: none;
                                                                }
                                                            }
                                                        }
                                                        .ucd-mobile,
                                                        .ucd-phone{
                                                            &:empty{
                                                                display: none;
                                                            }
                                                        }


                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .material-print-wrapper{
                        border-bottom: 5px solid $mercury;
                        padding-bottom: 10px;
                        width: 100%;
                        strong{
                            font-size: 1.2rem;
                            padding: 10px 0 5px;
                            display: block;
                        }
                        .material-list-group{
                            margin: 15px 0 30px;
                            padding: 0;
                            border-top: 1px solid #e7e7e7;
                            border-bottom: 0 none;
                            border-left: 0 none;
                            border-right: 0 none;
                            li{
                                padding:0px;
                                border-bottom:1px solid $mercury;
                                page-break-inside: avoid;
                                .hr-material{
                                    font-size: .9rem;
                                    margin: 10px;
                                    color: $black;
                                    font-weight: 700;
                                }
                                .material-brand{
                                    background: $wildsand;
                                    font-size: .9rem;
                                    padding:10px;
                                    margin-bottom: 0;
                                    &:empty{
                                        display: none;
                                    }
                                }
                            }
                        
                        }
                        .material-list{
                            -webkit-column-count: 1; /* Chrome, Safari, Opera */
                            -moz-column-count: 1; /* Firefox */
                            column-count: 1;
                            padding: 0;
                            margin: 0;
                            color: $black;
                            li{
                                border-bottom: 1px solid $mercury;
                                list-style-position: inside;
                                margin: 0;
                                list-style: none;
                                &.d-flex{
                                    padding:5px 0 10px 0;
                                }
                                .level-one{
                                    width: 150px;
                                }
                                .material-list-first{
                                    margin: 0;
                                    padding: 0;
                                    li{
                                        padding-bottom: 0;
                                        border: 0 none;
                                        list-style: none;
                                        .level-two{
                                            width: 150px;
                                        }
                                        .material-list-second{
                                            display: block;
                                            margin: 0;
                                            padding: 0;
                                            width: 430px;
                                            li{
                                                display: inline-block;
                                                &:last-child{
                                                    &::after{
                                                        content: none;
                                                    }
                                                }
                                                &::after{
                                                    content: ", ";
                                                    display: inline-block;
                                                    background: none;
                                                    margin-right: 5px
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            &:last-child{
                                li{
                                    border-bottom:0 none;
                                }
                            }
                        }
                    }
                }
        }
        .company-contact-print{
            width: 100%;
            .people-empty-message{
                display: none;
            }
            .print-contact-people{
                width: 100%;
                padding: 0 25px;
                margin-bottom: 10px;
                strong{
                    font-size: 1.2rem;
                }
                .sub-title{
                    font-size: .95rem;
                    font-weight: 700;
                    color: $dark-grey;
                }
                .row{
                    margin: 0;
                    padding: 0;
                    .user-contact-details{
                        background: $white;
                        border: none;
                        display: inline-flex;
                        width: 100%;
                        margin: 0;
                        min-height: auto;
                        border-top: 1px solid $mercury;
                        padding: 3px 0;
                        font-weight: 400;
                        font-size: .85rem;
                        word-wrap: break-word;
                        &:before{
                            background: none;
                        }
                        p{
                            font-size: .75rem;
                            font-weight: 400;
                        }
                        div{
                            padding:5px 0;
                            margin:0;
                            margin-right: 8px;
                            font-weight: 400;
                            &:last-child{
                                margin-right:0;
                                max-width: 30%;
                            }
                        }

                    }
                }
            }
        }
    }

    .list-tablerow{
        .list-name{
            width: 50%;
        }
    }
    .team-contact-list{
        width: 50%;
        padding: 0 15px !important;
    }
    
    .prw-heading{
        background: #e7e7e7;
        position: relative;
        top: 0;
        color: #000;
        font-size: 1rem;
        border-radius: 0;
        margin: 0 0 15px;
    }
}
.overlay-print{
    position: fixed;
    width: 100%;
    padding: 10px 17px;
    margin: 0;
    right: 0;
    padding-left: 80px;
    top: 55px;
    .print-btn , .back-btn{
        color: $white;
        height: 27px;
        padding: 0 16px;
        font-size: .95em;
        top: 65px;
        border: 0;
        margin: 0;
        height: 30px;
        cursor: pointer;
        position: fixed;
    }
    .print-btn{
        background: $cerulean;
        left: 170px;
        line-height: 30px;
    }
    .back-btn{
        background: $white none repeat scroll 0 0;
        border: 1px solid $alto;
        color: $emperor-gray;
        padding-left: 30px;
        line-height: 26px;
        &::before{
            background: transparent url($iconsprite) no-repeat scroll 0 -240px;
            content: "";
            position: absolute;
            left: 0;
            width: 30px;
            height: 30px;
            top: 0;
        }
    }
    .back-to-resultpage-button{
        display: none;
    }
}


@media print {
    @page {
        size: A4;
        //margin: 0;
        margin-left: -10px;
        margin-right: 0;
    }
    #page-topbar{
       display: none;
    }
    @page:first {
        // margin-top: -0.5cm;
      }

    .overlay-print, .side-navigation, .top-navigation, .filter-navigation {
        display: none;
    }

    .logo-images {
        z-index: 999 !important;
    }

    .print {
        background: $white;
        margin-top: -50px;
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    .print .main-container #print-container .company-contact-print .print-contact-people,
    .print .main-container #print-container .project-tab-panel .print-contact-people {
         position: absolute;
    }
    .print #print-container .detail-page-wrapper{
        padding: 0;
    }

    .print #print-container .detail-page-wrapper .top-info .map-view img{
        padding-left: 5px;
    }

    .print .main-container #print-container .project-tab-panel .print-contact-people {
        width: 97%;
    }

    @-moz-document url-prefix() {
        .print .main-container #print-container .project-tab-panel .print-contact-people .material-print-wrapper .material-list{
            width: 98% !important;
            border: 1px solid $white;
        }
    }
    .print .main-container #print-container .project-tab-panel .print-contact-people .Project-print-wrapper .tab-pane .project-role-wrapper .prw-heading{
        background: $mercury;
    }

    .print .details{padding-left:0;}

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .print {
                // margin-left: 0;
                // margin-right: -20px;
                width: 100%;
                margin-top: -80px !important;
            }
            .print .main-container #print-container .project-tab-panel .print-contact-people {
                width: 100% !important;
            }
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .print{
        overflow-y: auto
    }
}

