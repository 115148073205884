// @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');

/*=======================================================
     Placeholder primitives
=======================================================*/

// Placeholder primitives allow for optimal code reuse
//=======================================================
// font family reference use throughout
$font-name: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
"Droid Sans", "Helvetica Neue",
"Source Sans Pro",
arial,
sans-serif;
%reference-font {
    font-family: $font-name;
}

//=======================================================
// regular font family
%regular-font {
    @extend %reference-font;
    font-weight: 400;
}

//=======================================================
// medium font family
%medium-font {
    @extend %reference-font;
    font-weight: 600;
}

//=======================================================
// bold font family
%bold-font {
    @extend %reference-font;
    font-weight: 700;
}

body {
    @extend %reference-font;
    font-size: 0.90rem;
}

* {
    font-family: $font-name;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    @extend %medium-font;
}

.btn {
    @extend %medium-font;
}

label,
form,
input,
button {
    @extend %medium-font;
}

.adv-src-filter h3 {
    @extend %medium-font;
}

.btn-green,
.btn-blue,
.btn-red,
.btn-white {
    @extend %medium-font;
    font-size: 1rem;
    text-transform: uppercase;
}

.topmenu-dropdown .username,
.st-label-text {
    @extend %medium-font;
}
.note-list .note-details-wrapper .notes-list,
.st-hint-text {
    @extend %regular-font;
}

.src-location-placeholder,
.hint-label,
.show-stages-label,
.show-month-label {
    @extend %regular-font;
    font-size: 0.75rem;
}

small,
.small {
    @extend %regular-font;
}

.updated-src-filter .range-now-label,
.updated-src-filter .range-past-label {
    font-size: 0.75rem;
    @extend %medium-font;
}

b,
strong {
    @extend %bold-font;
}

.nav-sort,
.breadcrumb-container .breadcrumb {
    font-size: 0.75rem;
    @extend %medium-font;
}

.timing-filter-wrapper h3 {
    font-size: 0.75rem;
    @extend %medium-font;
}

.btn .fa.fa-4x {
    font-size: 0.75rem;
    margin-right: 5px;
}

small,
.small {
    font-size: 0.75rem;
}

@media (max-width: 980px) {
    small,
    .small {
        font-size: 0.65rem;
    }
}

.btn,
button,
input,
optgroup,
select,
textarea {
    font-family: $font-name;
}