/*-----------------------------------------------------------------------------------------
    404 page not found
-----------------------------------------------------------------------------------------*/
@import "../base/colors";


.not-found-msg {
    margin: 50px auto;
    position: relative;
    text-align: center;
    top: 100px;
    padding: 0 0 20px;
    h3 {
        font-size: 2.5rem;
    }
    p {
        font-size: 1.75rem;
        margin: 15px auto 30px;
        width: 50%;
    }
    .btn {
        font-size: 1.25rem;
        color: $white !important;
    }
    .red-btn {
        background-color: $red;
        border-color: $abi-red;
        &:hover {
            background-color: $abi-red;
            border-color: $abi-red;
        }
    }

}

@media (max-width: 767px) {
    .not-found-msg {
        margin: 10px auto;
        p {
            font-size: 1.25rem;
            margin: 15px auto 30px;
            width: 90%;
        }
    }
}