@import '../../common/base/colors';
@import '../../common/base/mixins';

$iconsprite: '../../common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../common/images/webapp-icons-sprite@2x-v3.png';
/*----------------------------------------------------------------------------------
   Company page
----------------------------------------------------------------------------------*/

.three-col-container {

   .card {
      background: #eee none repeat scroll 0 0;
      border: 1px solid #ddd;
      height: 100%;
      &:hover {
         border: 1px solid #000;
         cursor: pointer;
      }

      .card-body {
          height: 100%;
         .card-title {
            font-size: .95rem;
            font-weight: 700;
            margin-bottom: 3px;
        }
        .otherinfo {
            font-size: .85rem;
            font-weight: 400;
            word-wrap: break-word;
        }
        
         .user-contact-details{
            &:before {
                content: "";
                position: absolute;
                top: 15px;
                left: 10px;
                width: 30px;
                height: 30px;
                background: transparent url($iconsprite) no-repeat scroll -120px -210px;
            }
        }
         .card-text {
               a {
                  color: $black;
               }
         }
        .btn {
            border: 1px solid #000;
            padding: 4px 5px;
            background: none;
            line-height: 1;
            font-weight: 400;
            margin-right: 5px;
        }
        .btn:hover {
            color: $black;
        }
      }
   }

   .management-people{
        .contact-panel{
            border: 1px solid $alto;
            display: block;
            width: 32%;
            position: relative;
            min-height: 90px;
            line-height: 1.3em;
            margin: 6px;
            .contact-wrapper{height: 100%;}
            .card{
                cursor:default;
            }
        }
   }
}

/**** Works with companies tab ****/

.contact-details {
    .co-phone {
        padding: 0 10px 5px 0;
        position: relative;
        font-size: 12px;
        
        a {
            color: #292b2c;
            text-decoration: underline;
            font-size: 12px;
        }
    }

    .co-website {
        padding: 0 10px 0 0;
        position: relative;
        font-size: 12px;
        
        a {
            color: #292b2c;
            text-decoration: underline;
            font-size: 12px;
        }
    }

    .co-email {
        padding: 0 10px 0 0;
        position: relative;
        font-size: 12px;
        
        a {
            color: #292b2c;
            text-decoration: underline;
            font-size: 12px;
        }
    }

    .co-houseNo {
        a {
            border: 1px solid #000;
            padding: 0 5px;
            text-decoration: none;
            color: #000;
            display: inline-block;
            line-height: 1rem;
            margin-top: 1px;
            font-size: 12px;
        }
    }

    .linkedin-icon {
        position: relative !important;
        border: 0 !important;
        right: 2px !important;
        top: -4px !important;
    }
}

.group-companies-container{
    .content-panel{
        .company-container{
            .linkedin-icon{
                left:auto;
                right:10px;
            }
        }
    }
}

.search-selector{
    button{
        background: $white;
    }
    .select-drop-count{
        .dropdown-toggle{
            &:hover,&:active{
                background-color: $white;
            }
        }
    }
}

.remove-filter-container{
    width: 280px;
}

.height-400{
    height: 400px;
}


.height-350{
    height: 350px;
}

.scroll-wrapper{
    .box{
        margin-bottom: 0 !important;
        padding-left: 0 !important;
    }
}

.btn.btn-secondary
{
    &.view-joint-projects{
        font-size: 13px;
        padding: 2px 5px;
        margin: 0 0 3px 10px;
        border-radius: 3px;
        background: #f3f3f3;
        border: 1px solid #ccc;
        color: #333;
        cursor: pointer;
        font-weight: 400;
    }
}

.company-groups-listing{
    .company-list-scrollbar{
        height: 280px;
        &.project-list{
            height: 250px;
        }
    }
}

.company-tab-wrapper{
    .select-roles-placeholder {
        position: absolute;
        right: 0;
        background: #fff;
        top: 0;
        bottom: 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        span{
            text-align: center;
            color: #6d6a69;
        }
    }
}

.joint-proj-heading {
    line-height: 1rem;
    padding: 0 0 10px;
    margin: 0;
    .back-btn {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        color: $dark-black-gray;
        &:before{
            background: transparent url($iconsprite) no-repeat scroll 0 -240px;
            content: "";
            position: absolute;
            left: 0;
            width: 30px;
            height: 30px;
            top: -5px;
        }
        }
    }


    .flex-people{
        .contact-panel{
            background: #eee none repeat scroll 0 0;
            border: 1px solid #ddd;
            display: block;
            width: 32%;
            position: relative;
            min-height: 90px;
            line-height: 1.3em;
            margin: 6px;
            .contact-wrapper{
                height: 100%;
            }
        }
    }


    .dropdown-toggle.btn {
        &:after{
            top: 5px;
        }
    }

    .details-page .details-panel-scrollbar .box{
        padding-right: 0;
        padding-left: 0;
    }

  //-------responsive css start---------//
  @media screen and (max-width: 820px) {
    .management-people,.flex-people{
        .contact-panel{
            width: 48% !important;
        }
    }
  }


