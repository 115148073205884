@import '../../common/base/colors';
@import '../../common/base/mixins';

$login-bg: '../../common/images/backdrop-login2.jpg';
$login-back: '../../common/images/backdrop-sep.png';
$iconsprite: '../../common/images/webapp-icons-sprite-v3.png';
$ios: '../../common/images/appstore.png';
$android: '../../common/images/playstore.png';
$abi-logo: '../../common/images/logo-v2.png';
$abi-logo2x: '../../common/images/logo@2x-v2.png';

.filterModalBox {
    position: absolute !important;
    left: 75px;
    top: 80px !important;
    height: calc(100% - 120px);
    max-width: calc(100% - 85px) !important;
    width: calc(100% - 85px) !important;
    background: $white;
    padding: 1rem;

    .modal-content {
        height: 100%;
        border: none;

        .modal-title {
            width: 100%;
            font-weight: 400;
            h3 {
                margin-right: 5px;
                font-weight: 400;
                font-size: 1.5rem;
            }
            span {font-size: .8rem;}
            button {
                background: $white;
                padding: 0px 1rem;

                &:hover {
                    background: $abi-red;
                    color: $white;
                }
            }
        }

        .common-btn {
            text-align: left;
            cursor: pointer;
            padding: .25rem .75rem;
            min-width: 160px;
            border: 1px solid $cerulean;
            .viewTxtcount {
                justify-content: left;
                font-size: .75rem;
                font-weight: 600;
                line-height: .75rem;
                margin-bottom: -3px;
            }
            .abbr {
                font-size: .85rem;
            }
          &:hover {box-shadow:none}
        }
        .first-btn {
            background: $cerulean;
            color: $white;
        }
        .second-btn {
            color: $cerulean;
        }

        .modal-body {

            .filter-menu-section {
                height: 100%;
                float: none;
                border: 1px solid $filter-border-color;
                border-right: none;

                .list-group {

                    .list-group-item {

                        &:first-child {
                            border-top-right-radius: .25rem;
                            border-top-left-radius: .25rem;
                        }

                        a {
                            width: 98%;
                            padding: .45rem .75rem;
                            color: $black;
                            cursor: pointer;
                            font-weight: 400;
                            border-bottom: 1px solid $filter-border-color;

                            &.active {
                                background-color: $filter-active-bg;
                                border-bottom-color: $white;
                                font-weight: 700;
                            }
                        }
                        .currentFilter {
                            border-left: 4px solid $fiter-active;
                        }
                    }
                }
            }
        }
    }
}

.filter-page-wrapper {
    width: 100%;
    border: none;
    height: 100%;

    .filter-col-wrapper {
        padding-left: 0;
        padding-right: 0;
        .box + div{
          display: none;
        }
    }
    .col-two, .col-three {border: 1px solid $filter-border-color;}
    input, select, textarea {
        border: 1px solid $filter-input-border;
        border-radius: 3px;
    }
    input.cmn-toggle-round:checked+label:before, input.cmn-toggle-switch:checked+label:before {
        background-color: $cerulean;
    }
    .nav-tabs {
        display: inline-block;
        background: #fff;
        box-shadow: 0 3px 9px 1px rgba(0,0,0,.15);
        border-radius: 7px;
        overflow: hidden;
        border: none;
    
        .nav-item {
            display: inline-block;
            border-radius: 0;
    
            .nav-link {
                cursor: pointer;
                font-weight: 700;
                color: rgba(4,171,234,.6);
                border-top-right-radius: 0;
                border-top-left-radius: 0;
            }
            .active.nav-link {
                background-color: $cerulean;
                color: $white;
            }
        }
    }
    .relative-slider-loc {
        .rc-slider {
            z-index: 1;
            .rc-slider-track {
                background: $abi-blue;
                height: 6px; 
            }
            .rc-slider-handle {
                height: 20px;
                width: 20px;
                margin-top: -7px;
                margin-left: 0;
                box-shadow: 0 0 5px $black;
                border: 2px solid $white;
                opacity: 1;
                top: 5px;
                z-index: 5;
            }
        }
    }
    .errorHelperTxt {
      font-weight: 600;
      color: $red;
    }
}

.radio-btn-cntr .row>* {
    max-width: auto;
    width: auto;
}

.radio-btn-cntr .radio-buttons {
    padding-left: 0;
}

.btn-project-search {
    font-size: .85rem !important;
    border-color: #d9d9d9 !important;
    font-weight: 400 !important;
    text-align: left !important;
}

.form-check {
    position: relative;
}

.radio-buttons input[type=radio] {
    border: 1px solid #f2413e;
    cursor: pointer;
    height: 18px;
    left: 20px;
    position: absolute;
    width: 18px;
    z-index: 100;
    top: -2px;
}

.form-check-input:checked {
    background-color: #fff !important;
    border-color: #f2413e;
}

.form-check-input:checked[type=radio] {
    --bs-form-check-bg-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='#f2413e'/%3E%3C/svg%3E") !important;
}

input[type=radio]:checked~label {
    background: transparent !important;
    color: #000 !important;
    &::before{
      background-color: $red !important;
    }
}

.form-check-input:focus {
    box-shadow: none !important;
}

.select-input-type button {
    background: #fff;
    color: #000;
    font-size: 0.8rem;
}

.advanced-filter-grid {
    padding: 1rem;
    margin-right: 1rem;
}

.advanced-filter-grid .filterBlock {
    background: #f4f5f9;
    border: 1px solid #e4ecf1;
    padding: 1rem;
}

.advanced-filter-grid .filterBlock .textcontent {
    color: #000;
    font-weight: 400;
}

.advanced-filter-grid .filterBlock .textcontent .switch {
    position: static;
    top: 0;
    right: 0;
}

.advanced-filter-grid .filterBlock .locationFilter {
    margin-left: 0;
    margin-right: 0;
    padding: 10px 0;
}

.advanced-filter-grid .filterBlock .townPostcodeFilter {
  margin-left: 0;
  margin-right: 0;
  padding: 10px 0;
}

.advanced-filter-field-container {
    margin-top: 15px;
    .css-1p3m7a8-multiValue{
      background-color: $grey;
      font-weight: 500;
    }
  .input-container{
    padding: 0;
  }
}

.subsearch-filters .advanced-filter-grid .form-control {
  padding: 0;
  border: 0;
}

.subsearch-filters .form-control input {
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  width: 100%;
  border-radius: 0;
  border: 1px solid #dee2e6!important;
}

.subsearch-filters .form-control input:focus {
  border: 1px solid #dee2e6!important;
  outline: none;
}

.advanced-filter-grid .filterHeading h5 {
    font-weight: 700;
}

.update-location-slider-frames {
    background: $white;
    padding: 12px;
    border: 1px solid $filter-border-color;
    .col-12 {
      span {
        font-weight: 700;
        color: $blue;
      }
    }
}

.summary-col-wrapper {
  .summeryTagsList {
      list-style: none;

      .tag-element {
          font-weight: 600;
          cursor: pointer;
          background: $alice-blue;
          border-radius: 5px;
          padding: 5px 30px 5px 10px;
          color: $abi-blue;
          position: relative;
          float: left;
          border-radius: 5px;

          &:last-child {
              margin-right: 0;
          }

          .tag-title {
              font-weight: 700;
              float: none;
          }
          .tag-content {
          
          }
          .tag-close-button {
              position: absolute;
              top: 3px;
              right: 5px;
          }

          &.bigSummaryTruncated {
            .tag-content {
              width: 200px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              float: left;
            }
            .tag-title{
              float: left;
            }
          }
      }
  }
}

.value-frames {
  .css-1u9des2-indicatorSeparator, .css-1xc3v61-indicatorContainer, .css-15lsz6c-indicatorContainer {
    display: none;
  }
  .value-cross {
      position: absolute;
      top: 15px;
      right: 20px;
      cursor: pointer;

      &:hover {
          color: $abi-red;
      }
  }
}
.filter-select-dropdown {

  .css-13cymwt-control {
      height: 34px;
      min-height: 34px;
  }
}

.input-group-append {
  background: $alto;
  .input-group-text {
      padding: 2px 5px;
      background: $alto;
      font-weight: 600;
  }
}

.projectTimingFilter-frames {

  .range-heading {

      span {
          font-weight: 700;
          color: $blue;
      }
  }
  .divider {
      border-top: 1px solid $grey;
  }
}

.company-location-frames, .project-location-frames {
  .location-field {
      .radial-textfield, .get-location-icon {
          top: 3px !important;
      }
  }
  .css-1wy0on6 {display: none;}
}

.buildPhase-frames {

  .relative-slider-loc {

      .buildPhaseIndicator {
          font-weight: 600;
          font-size: 13px;

          .text-red {color: $red;}
          .text-yellow {
              color: $yellow;
              position: relative;

              &:before {
                  content: "|";
                  overflow: hidden;
                  height: 21px;
                  line-height: 20px;
                  font-size: 20px;
                  top: -36px;
                  color: $gray-dark;
                  position: absolute;
                  left: 0;
              }
              &:after {
                  content: "|";
                  overflow: hidden;
                  height: 21px;
                  line-height: 20px;
                  font-size: 20px;
                  top: -36px;
                  color: $gray-dark;
                  position: absolute;
                  right: 0;
              }
          }
          .text-green {color: $green;}
      }
  }
}

.search-location-place-holder {
  position: relative;
}

.search-location-place-holder .clear-location-field-basic-search {
  height: 30px;
  margin: 0;
  width: 30px;
  background: $white url($iconsprite) no-repeat scroll -180px -30px;
  border: 0;
  position: absolute;
  text-indent: -999px;
  overflow: hidden;
  right: 65px;
  top: 4px;
  cursor: pointer;
  padding-left: 2px;
  padding-right: 2px;
}

.search-location-place-holder .radial-textfield {
height: 30px;
margin: 0;
width: 30px;
background: $white url($iconsprite) no-repeat scroll 0 -360px;
border: 0;
position: absolute;
text-indent: -999px;
overflow: hidden;
right: 35px;
top: 4px;
cursor: pointer;
padding-left: 2px;
padding-right: 2px;
}

.search-location-place-holder .btn.get-location-icon {
width: 30px;
right: 5px;
top: 4px;
padding-left: 2px;
padding-right: 2px;
background: $transparent url($iconsprite) no-repeat scroll -30px -60px;
}

.width-362 {
  height: 38px;
  width: calc(100% - 65px);
  display: inline-block;
  text-align: left;
}

.width-55 {
  width: 65px;
  display: inline-block;
  text-align: left;
}

.keep-left {
  float: left;
}

#regionsFilter {
  margin-left: 0;
  margin-right: 0;
  margin-top: 10px;
}

.select-toggle {
  padding: 0;
  text-align: right;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 15px;
  height: 80px;
  .include-all-checkbox {
    position: absolute;
    right: calc(492px - 414px);
  }
  .include-all-checkbox-deafult {
    position: absolute;
    right: calc(492px - 458px);
  }

  .exclude-all-checkbox {
    position: absolute;
    right: calc(492px - 488px);
  }

  .width-362 {
    padding-left: 0;
    padding-top: 13px;
    text-align: left;
    }
    
    .width-362 a {
      color: #444;
      cursor: pointer;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .expand-all-link {
      margin: 11px 3px;
      display: inline-block;
      color: #444;
      text-decoration: underline;
      font-weight: 600;
    }
    
    .primary-category-section {
      padding-top: 13px;
      padding-left: 0;
      text-align: left;
    }
    
    .primary-category-section a {
      color: #444;
      text-decoration: underline;
      cursor: pointer;
      font-weight: 600;
    }
    
    .dropdown-toggle {
      background: transparent;
      padding: 6px 5px 0px;
      border-color: #ccc;
    }
    
    .check-box-button {
      margin-right: 0 !important;
    }
    
    .btn-check:checked+.btn, 
    .btn.active, 
    .btn.show, 
    .btn:first-child:active {
      background-color: #fff;
      border-color: #ccc;
    }
    
    .btn.active, .btn:focus, .btn:hover {
      background: #fff;
      border-color: #ccc;
    }
    
    .dropdown-toggle::after {
      color: #292b2c;
      top: -2px !important;
      position: relative;
      right: 2px;
    }
    
    .btn-group .no-background {
      background: none;
      padding-left: 5px;
      padding-right: 5px;
      padding-bottom: 2px;
      border-color: #ccc;
    }
    
    .check-box-button label.form-check-label::before {
      border: 2px solid #f2413e;
      content: "";
      cursor: pointer;
      position: absolute;
      width: 17px;
      height: 17px;
      top: 3px;
      left: 0;
      background: #fff;
    }
    
    .check-box-button label::after {
      transform: rotate(-45deg) !important;
      width: 9px !important;
      top: 8px !important;
      height: 5px !important;
      left: 4px !important;
    }
    
    .dropdown-menu .toggle-link {
      display: inline-block;
      font-size: .8rem;
      text-decoration: none!important;
      cursor: pointer;
      padding: 8px 15px;
      background-color: #fff;
      color: #444;
    }
    
    .toggle-link.disabled {
    opacity: .5;
    }
    
    .toggle-link.active {
    opacity: 1;
    background-color: #fff;
    color: #444;
    }
    
    .keep-left .dropdown-menu {
    padding: 0;
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(0px, 38px, 0px);
    border-radius: 0;
    }
    
    .keep-left .dropdown-menu button.dropdown-item {
    text-decoration: none!important;
    cursor: pointer;
    background-color: #fff;
    color: #444;
    font-weight: 600;
    padding: 8px 15px;
    }
    
    .keep-left .dropdown-menu button.dropdown-item:hover {
    background: #e4ecf1;
    }
}

.select-toggle :not(.btn-check)+.btn:active {
  background-color: #fff;
  border-color: #ccc;
}



.advanced-filter-form .advanced-filter-field-container .show>.dropdown-menu {
border-radius: 0;
padding: 0;
display: block;
position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 38px, 0px);
  border-radius: 0;
  z-index: 50;
}


.advanced-filter-grid {
  .filterHeading {
    h5 {
      font-weight: 700;
    }
  }

  .filterBlock {
    background: $whisper;
    border: $filter-border-color solid 1px;
    padding: 1rem;

    &.activeBlock {
      background: $light-blue;
    }

    .select-input-type {
      .dropdown-menu{
        min-width: auto;
        button{
          min-width: auto !important;
        }
      }
      button.btn-secondary {
        border-color: $filter-input-border;
        font-weight: 400;
        font-size: 0.85rem;
        line-height: 1.9;
        min-width: auto;
        padding: 5px 10px;
        .dropdown-item {
          font-weight: 400;
        }
      }
    }

    .textcontent {
      color: $black;
      font-weight: 400;
      padding-bottom: 15px;

      .switch {
        position: static;
        top: 0;
        right: 0px;
      }
    }

    .form-check-label {
      font-weight: 400;
      font-size: 0.9rem;
    }

    .radio-buttons {
      label {
        //padding-left: 1.5rem;
      }

      input[type=radio]:disabled {
        cursor: default;
      }

      input[type=radio]:disabled+label {
        opacity: 0.7;
        cursor: default;
      }

      .form-check {
        margin-bottom: 0;
      }
    }

    .disableBoxfilter {
      cursor: not-allowed;
      opacity: 0.7;
    }

    .disableBlock {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.7;

    }

    .slider-frames{
      .textcontent {
        color: $black;
      }
      .col-12 {
        span {
          font-weight: 700;
          color: $blue;
        }
      }
    }

    .textfield-wrapper {
      border: none;
      .clear-textfield {
        right: 2px;
        top: 5px;
      }
      .clear-textfield:hover {
        right: 2px;
        top: 5px;
      }
    }
  }

  .form-control {
    line-height: 1.5;
    border-color: $filter-input-border;
    padding: 0.5rem 0.75rem;

    &:focus {
      line-height: 1.5;
      border-color: #d9d9d9;
    }
  }

  .rangeIndicator {
    .centerIndicator {
      position: relative;

      &::before {
        content: '|';
        overflow: hidden;
        height: 21px;
        line-height: 20px;
        font-size: 20px;
        top: -28px;
        color: $gray-dark;
        position: absolute;
        left: 50%;
        margin-left: 4px;
      }
      &.centerIndicatorforConsEnd{
        left: -4px;
        &::before {
          left: 21%;
        }
      }
    }
  }

  .filterTabs {

    .nav-tabs,
    .nav-link {
      border: none;
    }

    .nav-tabs {
      display: inline-block;
      background: $white;
      box-shadow: 0px 3px 9px 1px rgba(0, 0, 0, 0.15);
      border-radius: 7px;
      overflow: hidden;

      .nav-item {
        display: inline-block;
        margin-bottom: 0;
        border-radius: 0;

        .nav-link {
          color: rgba(4, 171, 234, 0.6);
          border-radius: 0;
          padding: .5rem;
          cursor: pointer;
          position: relative;

          i.tickIcon {
            position: absolute;
            left: 5px;
            top: 50%;
            width: 15px;
            height: 15px;
            margin-top: -7px;
            background: transparent url($iconsprite) no-repeat scroll -157px -428px;
            display: none;
          }

          &.active {
            background: $cerulean;
            color: $white;

            i {
              background-position: -185px -428px;
            }
          }



          font-weight: 700;
        }
      }
    }
  }

  .withcollapsCheckboxes {
    margin: 0 -15px !important;

    .tree-filter {
      >div.card {
        border-left: none;
        border-right: none;
        border-color: $filter-border-color;
        border-bottom: none;
      }
    }

    .pdrtf-30 {
      padding-right: 30px; 
    }

    .pdrtf-35 {
      padding-right: 33px; 
    }

    .checkbox-lable-include-exclude {
      padding-left: 30px;
    }

    .sub-accordion {
      ul {
        li {
          .checkbox-lable-include-exclude {
            padding-left: 45px;
          }
        }
      }
    }
  }
}

.src-filter-list .include-child ul, .tree-filter .include-child ul {
  left: 0;
  position: relative;
  margin: 0;
  list-style-type: none;
  padding: 0;
}

.src-filter-list .include-child ul li, .tree-filter .include-child ul li {
list-style-type: none;
}

.src-filter-list .include-child .accordion, .tree-filter .include-child .accordion {
border: 0;
border-radius: 2px;
}

.new-filter-wraper .filter-page-wrapper 
.filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #companyRegionsFilter 
.tree-filter .include-child ul.materials-listing li .accordion .accordion-heading, 
.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #materialsFilter 
.tree-filter .include-child ul.materials-listing li .accordion .accordion-heading, 
.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #regionsFilter 
.tree-filter .include-child ul.materials-listing li .accordion .accordion-heading {
padding-left: 5px;
}

.tree-filter .include-child .accordion .accordion__body, 
.tree-filter .include-child .accordion .accordion-heading {
padding: 5px 5px 5px 35px;
background-color: #fff;
animation: none;
}

.tree-filter .include-child .accordion .accordion-heading {
  position: relative;
  margin-left: 0;
  width: auto;
}

.tree-filter .accordion .accordion-button {
padding: 0;
background-color:#e4ecf1 !important;
padding: 5px 5px 5px 30px
}

.tree-filter .accordion .accordion-button.collapsed {
padding: 0;
background-color: #fff !important;
padding: 5px 5px 5px 30px
}

.tree-filter .accordion .accordion-button:focus {
box-shadow: none;
}

.tree-filter .accordion .accordion-button:after {
display: none;
}

.tree-filter .accordion .accordion-button .mb-3 {
margin-bottom: 0 !important;
width: 100%;
}

.tree-filter  .accordion .accordion-button:before {
display: none;
}

.tree-filter .accordion .accordion-button .check-box-button label {
width: 100%;
padding-left: 0;
}

.tree-filter .accordion .accordion-button .check-box-button label:before {
right: 22px;
left: auto !important;
}

.tree-filter .accordion .accordion-button .check-box-button label:after {
right: 26px;
left: auto !important;
}

.tree-filter .accordion .accordion-button .accordion__arrow {
display: block;
position: absolute;
transition: none;
height: 31px;
width: 30px;
top: 0;
left: 0;
}

.tree-filter .accordion .accordion-button .checkbox-grid-wrapper {
width: 100%;
padding-left: 0.5em !important;
}

.tree-filter .accordion .accordion-body .checkbox-grid-wrapper {
padding-left: 0.5em !important;
}

.tree-filter .accordion .accordion-button.collapsed .accordion__arrow:before {
content: "";
background: url($iconsprite) no-repeat scroll -60px -330px;
transform: rotate(-90deg);
display: block;
position: absolute;
transition: none;
height: 31px;
width: 30px;
top: 1px;
left: 0;
}

.tree-filter .accordion .accordion-button .accordion__arrow:before {
  content: "";
  background: url($iconsprite) no-repeat scroll -60px -330px;
  transform: rotate(0deg);
  display: block;
  position: absolute;
  transition: none;
  height: 31px;
  width: 30px;
  top: 0;
  left: 0;
}



.tree-filter .sub-accordion .accordion-body {
padding: 5px 5px 5px 30px;
}

.tree-filter .sub-accordion .accordion-body .mb-3 {
margin-bottom: 0 !important;
}

.tree-filter .sub-accordion .check-box-button label {
width: 100%;
padding-left: 0;
}

.tree-filter .sub-accordion .check-box-button label:before {
left: auto;
right: 22px;
}

.tree-filter .sub-accordion .check-box-button label:after {
left: auto;
right: 26px;
}

.accordion-heading .form-check {
position: relative;
display: block;
margin-bottom: 0.5rem;
}

.accordion-heading .check-box-button {
margin: 0;
position: relative;
}

.accordion-heading .check-box-button input {
display: none;
}

.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #regionsFilter .accordion .form-check-label {
  padding-right: 70px;
  padding-left: 0;
  color: #000;
  font-weight: 400;
  font-size: .9rem;
}

.check-box-button label:before {
  cursor: pointer;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 3px;
  left: 0;
  background: #fff;
  border: 2px solid #f2413e;
  content: "";
}

.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #companyRegionsFilter 
.accordion .form-check-label:before, 
.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #materialsFilter .accordion 
.form-check-label:before, .new-filter-wraper .filter-page-wrapper 
.filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form #regionsFilter 
.accordion .form-check-label:before {
  left: auto;
  right: 50px;
}

.check-box-button label:after {
  opacity: .1;
  content: "";
  position: absolute;
  width: 9px;
  height: 5px;
  background: transparent;
  top: 8px;
  left: 4px;
  border: 3px solid #f2413e;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}

.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #companyRegionsFilter 
.accordion .form-check-label:after, 
.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #materialsFilter 
.accordion .form-check-label:after, 
.new-filter-wraper .filter-page-wrapper .filter-menu-conten-section 
.advanced-filter-form-wrapper .advanced-filter-form #regionsFilter 
.accordion .form-check-label:after {
left: auto;
  right: 54px;
}

.subsearch-filters fieldset {
margin: 15px;
}

.multi-checkbox-list-item {
margin-left: -30px !important;
margin-right: 0 !important;
}

#categoryTypeFilter {
display: flex;
margin-bottom: 0 !important;

.slider-frames {
  padding: 12px;
}
}

.role-Slider {
  height: 80px;
  position: relative;
  background: $white;
  margin-top: 2px;
}

.role-Slider .rc-slider-track{
  z-index: 4;
  background: #aaa !important
}
.role-Slider .rc-slider-rail{
  background: $abi-blue;
  border-radius: 6px;
}

.role-Slider .switch-label {
  color: #000;
  padding-right: 30px;
  margin-top: 3px;
  font-weight: 400;
}

.role-Slider .rc-slider-mark {
  width: calc(100% - 30px);
  left: 15px;
}

.role-Slider .rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}

#rolesFilterFilter .rc-slider-mark-text {
  direction: ltr;
  color: #000;
}

.rc-slider-mark-text-active {
color: #666;
}

.include-child {
height: 250px;
z-index: 2;
}

#project_development_types-filters{
  .include-child {
    height: 190px;
  }
}

.project-info-filter {
  position: relative;
}

.any-all-comp {
  position: absolute;
  top: -4px;
  right: -2px;
}

.css-1wy0on6 {
  display: none !important;
}

.css-13cymwt-control, .css-t3ipsp-control {
  padding-right: 35%;
  height: auto !important;
  border-radius: 0 !important;
}

.css-13cymwt-control:focus, 
.css-t3ipsp-control:focus {
  outline: none !important;
  border-color: $grey;
}

.product-spec-filter .css-13cymwt-control, 
.product-spec-filter .css-t3ipsp-control {
  padding-right: 0 !important;
}

.radio-btn-cntr  {
  position: relative;
}

.clear-button-advanced-filter {
  height: 30px;
    margin: 0;
    width: 30px;
    background: $white url($iconsprite) no-repeat scroll -180px -30px;
    border: 0;
    position: absolute;
    text-indent: -999px;
    overflow: hidden;
    right: 4px;
    top: 4px;
    cursor: pointer;
    z-index: 1003;
}

.switch-with-text{
  input[type=radio]:checked~label {
    color: #fff !important;
  }
  &.disabled{
    opacity: .3;
    cursor: default;
    pointer-events: none;
  }
}  

#new_filter_updated, #new_filter_pl, #new_filter_value,
#new_filter_framework, #new_filter_bp, 
#new_filter_scp, #new_filter_measure, #new_filter_cl {
  .activeBlock {
    background: $light-blue;
  }
}

.DayPickerInput input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  margin: 0;
  font-size: .75rem;
  line-height: 1.25;
  color: #292b2c;
  background-color: transparent;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ccc;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  position: relative;
  z-index: 1;
}

.DayPickerInput .react-datepicker__input-container:after {
    content: "";
    background: $white url($iconsprite) no-repeat scroll -30px -210px;
    position: absolute;
    right: 5px;
    top: 2px;
    width: 30px;
    height: 30px;
    z-index: 0;
}
.react-datepicker-popper {
  z-index: 20 !important;
}

.filter-text {
    border: 1px solid #dee2e6 !important;
    border-radius: 0 !important;
    line-height: 1.5;
    padding: 0.5rem 0.75rem;
    width: 100%;
    font-size: .9rem;
    font-weight: 400;
    padding-right: 0;
}

.filter-text:focus {
  outline: none;
  border: 1px solid #dee2e6 !important;
}

.react-checkbox-tree label {
  // width: 100%;
  //position: relative;
  padding-left: 10px;
  font-weight: 400;
  font-size: .9rem;
}

.rct-node-collapsed .rct-text {
  //background: $white !important;
}

.rct-node-expanded .rct-text {
  //background: #e4ecf1;
}

.rct-node-expanded ol li.rct-node .rct-text {
  //background: $white !important;
}

.rct-text:focus {
  background: #ccc;
}

.react-checkbox-tree label:hover {
  background: transparent !important;
}

.rct-icons-fa4 .rct-icon-check:before,
.rct-icons-fa4 .rct-icon-uncheck:before,
.rct-icons-fa4 .rct-icon-half-check:before {
  content: "" !important;
  border: 2px solid #f2413e;
  height: 17px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 17px;
}

.rct-icons-fa4 .rct-icon-half-check:before {
  opacity: 1 !important;
  background: #f2413e;
}

.rct-icons-fa4 .rct-icon-check:after {
  content: "" !important;
  border: 3px solid #f2413e;
  border-right: none;
  border-top: none;
  height: 5px!important;
  left: 4px!important;
  top: 8px!important;
  transform: rotate(-45deg)!important;
  width: 9px!important;
  display: block;
  opacity: 1;
  position: absolute;
}

.rct-icons-fa4 .rct-icon-half-check:after {
  content: "" !important;
  border: 3px solid #f2413e;
  opacity: 1;
  border-color: #fff;
  border-top: none;
  border-right: none;
  width: 7px !important;
  height: 3px !important;
  transform: rotate(0deg) !important;
  top: 10px !important;
  left: 5px !important;
  display: block;
  position: absolute;
}


.rct-node-icon {
  display: none;
}

.rct-checkbox {
  position: absolute;
  z-index: 4;
  //right: 54px;
}

.includeDiv .rct-checkbox {
  //right: 88px;
  right: calc(492px - 404px);
  top: 5px;
}

.rct-icons-fa4 .rct-icon-expand-open:before {
  content: "" !important;
  transition: none;
  transform: none;
  height: 31px;
  width: 30px;
  background: url($iconsprite) no-repeat scroll -60px -330px;
  top: 4px;
  left: 0;
  display: block;
  position: relative;
}

.rct-icons-fa4 .rct-icon-expand-close:before {
    content: "" !important;
    transition: none;
    transform: rotate(-90deg);
    height: 31px;
    width: 30px;
    background: url($iconsprite) no-repeat scroll -60px -330px;
    top: 4px;
    left: 0;
    display: block;
    position: relative;
}

.includeExcludeDiv {
  .excludeDiv {
    .rct-title {visibility: hidden;}
  }
}

.excludeDiv .rct-icons-fa4 .rct-icon-expand-close:before,
.excludeDiv .rct-icons-fa4 .rct-icon-expand-open:before {
  content: "" !important;
  display: none;
}


.excludeDiv .rct-icons-fa4 .rct-icon-collapse-all:before,
.excludeDiv .rct-icons-fa4 .rct-icon-expand-all:before {
  content: "" !important;
}


.includeDiv .rct-icons-fa4 .rct-icon-expand-all:before {
  content: "Expand all" !important;
  font-size: 14px;
}

.includeDiv .rct-icons-fa4 .rct-icon-collapse-all:before {
  content: "Collapse all" !important;
  font-size: 14px;
}

.excludeDiv .react-checkbox-tree .rct-node-parent label {
  height: 34px;
}

.excludeDiv .react-checkbox-tree .rct-node-leaf label {
  height: auto;
}

.excludeDiv .react-checkbox-tree .rct-node-leaf label span {
  /*top: 0;*/
}

.excludeDiv .rct-checkbox {
  top: 5px;
  //right: 54px;
  right: calc(492px - 435px);
}

.rct-options {
  margin-left: 0 !important;
  position: absolute;
  top: 0;
  left: 10px;
}
.rct-collapse>.rct-icon-expand-close {
  opacity: 1 !important;
}

.primary-category-section .primary-category .check-box-button label {
  margin-bottom: 0.5rem;
}

.primary-category-section .primary-category .check-box-button label:before {
  content: "";
  cursor: pointer;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 3px;
  left: 0;
  background: #fff;
  border: 2px solid #f2413e;
}

.include-exclude-grid .include-checkclear-all p.keep-left,
.include-exclude-grid .exclude-checkclear-all p.keep-left {
  font-size: .9rem;
  font-weight: 600;
}

.textfield-wrapper:after {
  border: 0 !important;
}

li.rct-node.rct-node-leaf {
  height: 30px;
}

.include-exclude-grid .include-checkclear-all {

}

.include-exclude-grid .exclude-checkclear-all {

}

.rct-title {
  font-weight: 400;
  font-size: .9rem;
}

.rct-text {
  position: relative;
}

.react-checkbox-tree {
  flex-direction: row-reverse;
  padding-bottom: 20px;
}

.excludeDiv button.rct-collapse.rct-collapse-btn {
  display: none;
}

.excludeDiv span.rct-text {
  flex-direction: row-reverse;
}

.excludeDiv .react-checkbox-tree ol ol {
  padding-left: 0;
}

.rct-node-clickable:hover {
  background: transparent !important;
}

.rct-node-clickable:focus {
  background: transparent !important;
}

.include-exclude-all .form-check-label .half-check:before {
  content: "" !important;
  border: 2px solid #f2413e;
  height: 17px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 17px;
}

.include-exclude-all .form-check-label .half-check:before {
  opacity: 1 !important;
  background: #f2413e;
}

.include-exclude-all .form-check-label .half-check:after {
  content: "" !important;
  border: 3px solid #f2413e;
  opacity: 1;
  border-color: #fff;
  border-top: none;
  border-right: none;
  width: 7px !important;
  height: 3px !important;
  transform: rotate(0deg) !important;
  top: 10px !important;
  left: 5px !important;
  display: block;
  position: absolute;
}

.check-box-button input[type=checkbox]:checked+label .half-check:before {
  /*background: #fff;*/
}

.check-box-button input[type=checkbox]:checked+label:after {
  /*opacity: 0 !important;*/
}

.save-filter-modal .modal-content .modal-header .btn-close{
  height: 30px;
  margin: 0;
  width: 30px;
  border: 0;
  position: absolute;
  text-indent: -999px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: none;
  right: 5px;
  top: 12px;
  padding: 0;
  background: url($iconsprite) no-repeat scroll -210px -90px;
}

.advance-filter-container{
  background: $white;
}

.show-all-criteria{
  border-top:1px solid #E4ECF1;
  color:$black;
  background: $white;
  text-decoration: underline;
  position: relative;
  z-index: 4;
  a{
    font-weight: 600 !important;
  }
  .show-criteria{
      text-decoration: underline !important;
      cursor: pointer;
      color:$black;
  }
}
.disabled-row {
  opacity: 0.5;
}

.show-all-criteria,.slider-frames,#valueFilter{
  position: relative;
  z-index: 2;
  .feedback-panel {
      position: absolute;
      width: 300px;
      bottom: 50px;
      right: 17px;
      background: $white;
      box-shadow: 0 3px 10px $dark-grey;
      padding: 10px;
      &::before {
        background: $transparent url(#{$iconsprite}) no-repeat scroll -60px -270px;
        content: "";
        position: absolute;
        right: 0;
        width: 30px;
        height: 30px;
        bottom: -19px;
        transform: rotate(-180deg);
      }

      textarea, .note-input{
          width: 100%;
      }

      .text-area-counter {
        font-size: 0.75rem;
        position: absolute;
        bottom: -15px;
        right: 0;
      }

      .btn-send {
        font-size: 0.85rem;
        position: relative;
        line-height: 1em;
        margin: 0;
        left: -5px;
        top: 3px;
        margin-left: 5px;
      }

      .btn-feedback-cancel {
        font-size: 0.85rem;
        position: relative;
        line-height: 1em;
        top: 3px;
      }
  }
}

@media screen and (max-width: 1200px) {
  .includeDiv .rct-checkbox {
    right: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .includeDiv .rct-checkbox {
    right: 70px;
  }
}

.include-child{
  .box {
    & + div{
        display: none;
    }
  }
}


#updatedFilter{
  .rc-slider-mark{
      width:calc(100% - 30px);
      left: 15px;
  }
  .slider-frames{
      padding-bottom: 22px;
  }
  .rc-slider-mark-text{
      color: $black;
      font-weight: bold;
      direction: ltr;
  }
  .rc-slider-dot{
      z-index: 5;
      width: 20px;
      height: 20px;
      border: 6px solid #aaaaaa;
      top: -7px;
      transform: translateX(-6px);
      &:first-child,&:last-child{
          display: none !important;
      }
  }
  .rc-slider-track{
      background: #aaa;
      z-index: 4;
  }
  .rc-slider-step{
      background: $abi-blue;
      border-radius: 6px;
  }
  .feedback-panel {
      top: auto;
      bottom: -125px;
      &.requestdata-modal{
          bottom: -165px;
      }
      &:before{
          display: none;
      }
  }
}

.react-datepicker-wrapper{
  width: 100%;
  .react-datepicker__input-container{
    input{
      width: 100%;
      min-width: 0 !important;
    }
  }
}


.customSliderHandles{
  .rc-slider-handle{
    margin-top: -3px;
    &.rc-slider-handle-1{
      margin-left: 0 !important;
    }
  }
}

/*Framework panel*/
.react-tags {
  padding: 2px;
  background: #fff;
  border-radius: 0;
  margin-right: 10px;
  margin-bottom: 0;
  padding-left: 5px;
  margin-top: 0;
  padding-top: 4px;
  border: #d9d9d9 solid 1px;
  position: relative;
  z-index: 1;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
  vertical-align: top;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 1px 8px;
  border: 1px solid #cbd6e2;
  border-radius: 2px;
  background: #f1f4f7;
  font-size: inherit;
  line-height: inherit;
  margin-right: 5px;
  vertical-align: middle;
  font-size: 12px;
  color: #14437b;
}

.react-tags__selected-tag:after {
  content: '';
  // color: #14437b;
  margin-left: 8px;
  width: 12px;
  height: 12px;
  display: inline-block;
  right: 0;
  background: transparent url($iconsprite) no-repeat scroll -250px -399px;
  cursor: pointer;
  vertical-align: middle;
  margin-right: -4px;
  margin-top: -3px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 0px 2px;
  margin-bottom: 6px;
  margin-top: 3px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
      /* this will become the offsetParent for suggestions */
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  font-size: 0.85rem;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 99%;
  left: 0;
  right: 0;
  height: 300px;
  overflow-y: auto;
  box-shadow: 0 0 3px $grey;
  padding:10px;
  background: $white;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
      // width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
  color: $black-grey;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

#project-region-filter, #company-region-filter, #materials{
  .includeDiv .rct-checkbox{
    right: 12px;
  }
}

.advanced-filter-grid .project-exact-match-radio{
  margin-bottom: 10px !important;
}

.error-field-validation {
  border: 1px solid $abi-red;
}

.stageCheckboxTree{
  .react-checkbox-tree{
    .rct-bare-label{
      height: 34px;
      margin-left: 10px;
      padding: 5px 0;
    }
    .rct-title{
      padding: 2px 5px;
    }
  } 
}

#project_stages-filters{
  .react-checkbox-tree{
    .rct-node{
      &.fastPlan{
        &::before {
          content: "NEW";
          font-size: 9px;
          font-style: normal;
          font-weight: normal;
          padding: 1px 3px;
          margin-left: -32px;
          margin-right: 5px;
          color: white;
          background-color: red;
          border-radius: 3px;
          position: absolute;
          left: 35px;
          top:3px;
      }
        &.rct-disabled{
          &::before{opacity: 0.7;}
        }
        position: relative;
        .rct-title{
            font-weight: 600;
          font-style: italic;
        }
        
      }
    }
  }
}

.text-right {
  text-align: right;
}