
// @include border-radius(10px);
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

// @include transform-rotate(-45deg);
@mixin transform-rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

// @include box-shadow(0, 1px, 2px, rgba(0, 0, 0, 0.2));
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    -ms-box-shadow: $top $left $blur $color;
    -o-box-shadow: $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  }
  @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    -ms-box-shadow: $top $left $blur $color;
    -o-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

// @include border('top', 1px, solid, hexcolour);
@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}

// @include opacity(50);
@mixin opacity($size) {
  opacity: $size;
  -moz-opacity: $size;
}

@keyframes slide_right {
  0% {
    right: 30px;
    top: -3px;
  }
  100% {
    right: 40px;
    top: -3px;
  }
}

@keyframes slide_left {
  0% {
    left: 20px;
    top: -3px;
  }
  100% {
    left: 30px;
    top: -3px;
  }
}

/*Media Queries*/
@mixin for-2560-desktop {
  @media (max-width: 2560px) {
    @content;
  }
}
@mixin for-1920-desktop {
  @media (max-width: 1920px) {
    @content;
  }
}
@mixin for-1800-desktop {
  @media (max-width: 1800px) {
    @content;
  }
}
@mixin for-1700-desktop {
  @media (max-width: 1700px) {
    @content;
  }
}
@mixin for-1600-desktop {
    @media (max-width: 1600px) {
        @content;
    }
}
@mixin for-1400-desktop {
  @media (max-width: 1400px) {
    @content;
  }
}
@mixin for-large-desktop {
  @media (max-width: 1280px) {
    @content;
  }
}
@mixin for-medium-desktop {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin above-tablet-only {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin for-tablet-landscape {
  @media (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet-portrait {
  @media (max-width: 980px) {
    @content;
  }
}
@mixin for-tablet-only {
  @media (max-width: 768px) {
    @content;
  }
}
@mixin for-large-phone {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin for-phone {
  @media (max-width: 568px) {
    @content;
  }
}
/*Media Queries end*/