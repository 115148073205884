@import '../common/base/colors';
@import '../common/base/mixins';

$iconsprite: '../common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../common/images/webapp-icons-sprite@2x-v3.png';
/*----------------------------------------------------------------------------------
   Header
----------------------------------------------------------------------------------*/
#page-topbar {
    background-color: #FFF;
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1002;
    -webkit-box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
    box-shadow: 0 0.75rem 1.5rem rgba(18,38,63,.03);
        .navbar-header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: justify;
            -webkit-box-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -ms-flex-align: center;
            margin: 0 auto;
            padding: 0 calc(24px * .5) 0 0;

            .logo-placeholder {
                background-position: center;
                width: 200px;
            }
        }
        .navbar-brand-box {
            width: auto;
            padding: 0 0.5rem;
            text-align: center;
            border-right: 1px solid #ccc;
        }
        .total-project-universe {
            font-weight: 400;
            text-align: left;
            font-size: 0.825rem;
            padding: 6px 0 6px 10px;
            .universe-caption{
                position: relative;
                &:last-child{
                    &:before{
                        height: 30px;
                        width: 1px;
                        background-color: $grey;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 4px;
                    }
                }
                .universe-count{
                    font-size: 1.25rem;
                    font-weight: 600;
                    line-height: 1;
                }
            }
        }
        .top-right {
            .search-button-div {
                display: flex;
                align-items: center;
                padding-right: 15px;
                button {
                    border-radius: 5px;
                    font-size: .95rem;
                }
            }
            .header-profile-user {max-width: 30px;}
            .profile-links {
                display: flex;
                border-left: 1px solid #ccc;

                .notification-tab-panel {
                    .notification-tab-wrapper {
                        // .panel-scrollbar div:nth-child(2) div {
                        //     background-color: transparent !important;
                        // }
                        .nav-tabs {
                            .nav-link {
                                color: rgba(0,0,0,.5);
                                cursor: pointer;
                                
                                &:hover {
                                    color: rgba(0,0,0,.7);
                                }
                            }
                            .active.nav-link {
                                color: rgba(0,0,0,.9);
                            }
                        }
                    }
                }

                .notification-box {
                    position: absolute;
                    inset: -29px 70px auto auto;
                    margin: 0px;
                    transform: translate3d(0px, 85.6px, 0px);
                    width: 400px;
                    background: rgb(255, 255, 255);
                    padding: 5px 10px;
                    box-shadow: 0 3px 3px #6d6a69;
                    min-height: 210px;

                    &:before {
                        content: '';
                        height: 50px;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        background: transparent url($iconsprite) no-repeat scroll -60px -270px;
                        top: -18px;
                        right: 10px;
                        z-index: 1;
                        font-size: x-small;
                    }
                    .see-all, .view-calendar {
                        color: #053874;
                        text-decoration: underline;
                        font-size: .75rem;
                        font-weight: 700;
                        padding: 5px 0 0;
                        display: inline-block;
                        cursor: pointer;
                    }
                    .panel-scroll-wrapper {
                        position: relative;
                        padding: 5px;
                        background: #eee;
                        box-shadow: inset 0 0 5px #ddd;

                        .panel-scrollbar {
                            min-height: 200px;
                            max-height: 300px;

                            .daily-notification {
                                padding: 0 0 10px;
                                .day{
                                    font-size: 0.9rem;
                                    color: $dove-grey;
                                    padding: 0;
                                    margin: 0;
                                    display: block;
                                    font-weight: 700;
                                }
                                .date{
                                    font-size: 0.70rem;
                                    color: $dark-black-gray;
                                    padding: 0 0 5px;
                                    margin: 0;
                                    display: block;
                                }
                                .notification-block.list-group-item {
                                    border: 0;
                                    padding: 10px;
                                    border-radius: 0;
                                }
                                .list-group-item:last-child {
                                    margin-bottom: 0;
                                }
                                .noti-content {
                                    font-size: .8rem;
                                    color: #333;
                                    display: block;
                                    text-decoration: none;
                                }
                                .noti-hour {
                                    font-size: .75rem;
                                    color: #6d6a69;
                                    display: block;
                                }
                                .event-details, .event-scheduled-time {
                                    font-size: .75rem;
                                    color: #6d6a69;
                                }
                                .event-subject {
                                    font-size: .825rem;
                                    font-weight: 700;
                                    padding-bottom: 4px;
                                    color: #6d6a69;
                                    padding-right: 5px;
                                }
                                .event-details ul {
                                    list-style-position: inside;
                                    list-style-type: square;
                                    padding-left: 0.5rem !important;

                                    li {margin-right: 10px;}
                                }
                            }
                        }
                    }

                }
                
                .noti-icon {
                    position: relative;
                    border: none;
                }

                .noti-icon:hover, .noti-icon:focus {background: unset;}

                .noti-icon .badge {
                    position: absolute;
                    top: 5px;
                    right: 7px;
                }

                .profile-name {
                    border-left: 1px solid #ccc;
                    align-items: center;
                    display: flex;

                    button {border: none;}
                    button:hover, button:focus {background: unset;}
                    .user-icon {
                        background-color: #ddd;
                        padding: 8px 5px;
                        border-radius: 100px;
                        display: table-cell;
                        width: 31px;
                        text-align: center;
                        color: #053874;
                        font-weight: 600;
                        margin: 0 5px 0 0;
                        vertical-align: top;
                        font-size: 12px;
                        height: 30px;
                    }
                    .dropdown-menu-end {
                        width: 250px;
                        font-size: .85rem;
                        box-shadow: 1px 3px 5px #666;
                        border: 0;
                        border-radius: 0;
                        .dropdown-header {display: flex;}
                        
                        .greet-user {
                            font-weight: 700;
                            padding-left: 10px;
                            color: #053874;

                            i {
                                color: #666;
                                font-style: normal;
                                display: block;
                                font-weight: 400;
                            }
                        }
                        .shortcut-group {
                            border: 1px solid #ccc;
                            .license-based{border-bottom: 1px solid #ccc;}
                            a {border-bottom: 1px solid #ccc; text-transform: capitalize; font-weight: 600;}
                            a:last-child {
                                border-bottom: none;
                            }
                            a:hover {
                                text-decoration: none;
                                background-color: #f7f7f9;
                            }
                            a:active {
                                background-color: #053874;
                                color: #ffffff;
                            }
                        }
                    }
                    .dropdown-menu-end:before {
                        content: '';
                        height: 50px;
                        position: absolute;
                        width: 30px;
                        height: 30px;
                        background: transparent url($iconsprite) no-repeat scroll -60px -270px;
                        top: -18px;
                        right: 10px;
                        z-index: 1;
                        font-size: x-small;
                    }
                }
            }
        }
}

.profile-links{
    .noti-icon{
        position: relative;
        span.count{
            background: $red;
            padding: 5px 3px;
            text-align: center;
            border-radius: 50%;
            top: 10px;
            right: 10px;
            position: absolute;
            color: #fff;
            font-size: 9px;
            font-weight: 700;
            width: 22px;
            height: 22px;
            display: block;
            z-index: 1;;
            padding: 5px 3px;
            text-align: center;
            border-radius: 50%;
            top: 10px;
            right: 10px;
            position: absolute;
            color: #fff;
            font-size: 9px;
            font-weight: 700;
            width: 22px;
            height: 22px;
            display: block;
            z-index: 1;
        }
    }
}

.masquerades-batch{
    margin: 0 auto;
    width: 500px;
    background: $black;
    border-radius: 7px;
    color: $white;
    position: relative;
    height: 42px;
    top: 6px;
    left: 45%;
    transform: translateX(-80%);
    .masquerade-user{
        margin: 10px;
        position: absolute;
        font-size: 0.95rem;
    }
    .masquerade-btn{
        float: right;
        margin: 5px;
        border-radius: 4px;
        background: $maroon;
        color: $white;
        border: 0 none;
        padding: 5px 15px;
        &:hover{
            background: $maroon;
            color: $white;
            border: 0 none;
        }
    }
}