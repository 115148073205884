@mixin transition($property) {
  -webkit-transition: $property;
  -moz-transition: $property;
  -ms-transition: $property;
  -o-transition: $property;
  transition: $property;
}
@mixin hotspot-area {
  background: $cerulean;
  width: 20px;
  height: 20px;
  border:2px solid $white;
  position: absolute;
  content:"";
  border-radius: 20px;
  margin: 0 auto;
}
.chardinjs-overlay {
  position: absolute;
  z-index: 999999;
  background-color: $black;
  opacity: 0;
  @include transition(all 0.3s ease-out);
}

.chardinjs-tooltiptext {
  padding: 5px 10px;
  font-size: 0.8rem;
  background: $white;
  color: #333;
  box-shadow: 0 0 15px $black;
  border-radius: 5px;

  ul {
    margin: 0;
    padding: 0 0 0 20px;
  }
}

.chardinjs-helper-layer {
  position: absolute;
  z-index: 9999998;
  color: $white;
  @include transition(all 0.3s ease-out);

  &:hover {
    z-index: 10000000;
  }

  &.chardinjs-left {
    // border-left: solid $white 2px;
    padding-left: 0px;
    // border-radius: 10px;
  }

  &.chardinjs-right {
    // border-right: solid $white 2px;
    padding-right: 0px;
    // border-radius: 10px;
  }

  &.chardinjs-bottom {
    // border-bottom: solid $white 2px;
    padding-bottom: 0px;
    // border-radius: 10px;
    &:after{
      @include hotspot-area();
      right: 0;
      left: 0;
      bottom: 0;
    }
  }

  &.chardinjs-top {
    // border-top: solid $white 2px;
    padding-top: 0px;
    // border-radius: 10px;
    &:after{
      @include hotspot-area();
      right: 0;
      left: 0;
      top: 0;
    }
  }
}

.chardinjs-tooltip {
  position: absolute;
  @include transition(opacity 0.1s ease-out);
  min-width: 200px;
  max-width: 300px;

  &.chardinjs-left {
    margin-left: -65px;
    padding-right: 0px;
  }

  &.chardinjs-right {
    margin-right: -65px;
    padding-left: 0px;
  }

  &.chardinjs-bottom {
    margin-bottom: -30px;
    padding-top: 0px;
  }

  &.chardinjs-top {
    margin-top: -30px;
    padding-bottom: 0px;
  }

  &.chardinjs-right:before,
  &.chardinjs-left:after,
  &.chardinjs-bottom:before,
  &.chardinjs-top:after {
    content: ".";
    display: inline-block;
    background-color: $white;
    height: 2px;
    overflow: hidden;
    position: absolute;
  }

  &.chardinjs-right:before,
  &.chardinjs-left:after {
    width: 30px;
    top: 50%;
  }

  &.chardinjs-bottom:before,
  &.chardinjs-top:after {
    width: 2px;
    height: 30px;
    left: 50%;
  }

  &.chardinjs-bottom:before {
    top: -30px;
  }

  &.chardinjs-top:after {
    bottom: -30px;
  }

  &.chardinjs-right:before {
    left: -30px;
  }

  &.chardinjs-left:before {
    @include hotspot-area();
    right: -50px;
    top: -10px;
  }
  &.chardinjs-bottom:after {
    top: -30px;
  }

  &.chardinjs-top:after {
    bottom: -30px;
  }

  &.chardinjs-right:after {
    @include hotspot-area();
    left: -50px;
    top: -10px;
  }

  &.chardinjs-left:after {
    right: -30px;
  }
}

.chardinjs-show-element {
  z-index: 9999999;
  opacity: 0.8;
}

.chardinjs-relative-position {
  position: relative;
}

.hori-placeholder-tour {
  min-width: 200px;
  position: absolute;
  background: transparent;
  height: 2px;
  bottom: 0;
}
.left-zero {
  left: 0;
  right: auto;
}

.right-zero {
  left: auto;
  right: 0;
}
.left-right-zero{
  left: 0;
  right: 0;
  min-width: inherit;
}
.top-zero {
  top: 0;
  bottom: auto;
}
.bottom-zero {
  top: auto;
  bottom: 0;
}
.top-bottom-zero{
  top: 0;
  bottom: 0;
  min-height: inherit
}

.vert-placeholder-tour {
  min-height: 100px;
  position: absolute;
  background: transparent;
  width: 2px;
  top: 0;
  bottom: 0;
  
  &.w-30px{
    width: 30px;
    height: auto;
    min-height: inherit;
    top: 40px;
  }
  &.height-center{
    top: 0;
    bottom: 0;
    min-height: inherit
  }
}

.cd-toolbar-info {
  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(0px, -70px);
    width: 700px;
  }
}

.cd-notification-info {
  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(-40px, -30px);
    width: 240px;
  }
}
.cd-filteroptions-info{
  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(-20px, -20px);
    width: 240px;
  }
}
.cd-sortorder-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 30px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(20px,0px);
    width: 90px;
  }
}

.cd-savesrc-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 40px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    transform:translate(20px,-5px);
    width: 100px;
  }
}

.cd-runsrc-info {
  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(45px, -30px);
    width: 110px;
  }
}

.cd-filterbtn-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 165px;
    transform: translate(15px, -30px);
  }
}

.cd-sc-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 280px;
    transform: translate(-40px,-15px);
  }
}
.cd-seeproj-info{
  .chardinjs-tooltiptext {
    position: absolute;
    width: 230px;
    transform: translate(-20px,-45px);
  }
}
.cd-mapview-seeproj-info{
  .chardinjs-tooltiptext {
    position: absolute;
    width: 160px;
    transform: translate(30px,-5px);
  }
}

.cd-perfsrc-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 180px;
    transform: translate(10px, -30px);
  }
}

.cd-switch-info {
  &.chardinjs-tooltip.chardinjs-top:after {
    height: 15px;
  }
  .chardinjs-tooltiptext {
    position: absolute;
    width: 210px;
    transform: translate(0px,-30px);
  }
}

.cd-mapbox-toolbar {
  width: 30px;
  position: absolute;
  right: 10px;
  height: 150px;

  .hori-placeholder-tour {
    min-width: 30px;
  }
}

.cd-maptoolbar-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 40px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    width: 280px;
    transform: translate(-170px,-10px);
  }
}

.cd-listmapview-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 30px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    width: 130px;
    transform: translate(30px,0px);
  }
}
.cd-listview-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 30px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    width: 180px;
    transform: translate(90px,-5px);
  }
}

.cd-metadata-info {

  &.chardinjs-tooltip.chardinjs-top:after {
    top: 50px;
  }
  .chardinjs-tooltiptext {
    position: absolute;
    width: 330px;
    transform: translate(-30px,75px);
  }
}

.cd-printview-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 210px;
    transform: translate(-10px, -40px);
  }
}

.meta-data {
  .hori-placeholder-tour {
    min-width: 300px;
  }
}


.cd-tabs-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 350px;
    transform: translate(-190px, 0px);
  }
}
.cd-cotabs-info {
  .chardinjs-tooltiptext {
    position: absolute;
    width: 600px;
    transform: translate(-190px, 0px);
  }
}

.cd-title-info {
  &.chardinjs-tooltip.chardinjs-right:before {
    width: 130px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(100px, -30px);
    width: 520px;
  }
}
.cd-comapview-info {
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 10px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(30px, -20px);
    width: 140px;
    text-align:center;
  }
}


.cd-addpostcode-info{
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 220px;
  }

  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(0px,190px);
    width: 180px;
  }
}
.cd-summarypanel-info{
  .chardinjs-tooltiptext {
    position: absolute;
    width: 160px;
    transform: translate(40px,-30px);
  }
}

.cd-close-info{
  .chardinjs-tooltiptext {
    position: absolute;
    transform: translate(-30px, 0px);
    width: 160px;
  }
  .chardinjs-tooltip.chardinjs-bottom:before {
      top: -15px;
  }
  &.chardinjs-helper-layer.chardinjs-bottom:after{
    bottom: -15px;
  }
}
.cd-nextprev-info{
  .chardinjs-tooltiptext {
    position: absolute;
    width: 400px;
    transform: translate(-85px,-40px);
  }
}
.cd-useractivities-info{
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 230px;
  }
  .chardinjs-tooltiptext {
    position: absolute;
    width: 280px;
    transform: translate(60px,200px);
  }
}
.cd-couseractivities-info{
  &.chardinjs-tooltip.chardinjs-bottom:before {
    height: 30px;
  }
  .chardinjs-tooltiptext {
    position: absolute;
    width: 280px;
    transform: translate(70px,0px);
  }
}
.cd-newsrc-info{
  .chardinjs-tooltiptext {
    width: 220px;
    position: absolute;
    transform: translate(0px,-25px);
  }
}
.cd-enquiry-info{
  &.chardinjs-tooltip.chardinjs-right:after{
    top: 14px;
  }
}
.position-relative{
  position: relative;
}

.position-absolute{
  position: absolute;
}
.cd-actionbutton-info{
  
  .chardinjs-tooltiptext {
    width: 220px;
    position: absolute;
    transform: translate(-110px,0px);
  }
}
@media (min-width: 1280px) {
  .cd-useractivities-info .chardinjs-tooltiptext {
      position: absolute;
      width: 280px;
  }
}