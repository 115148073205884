$white: #ffffff;
$black: #000000;
$black-grey: #292B2C;
$grey: #cccccc;
$dark-grey: #999999;
$blue-grey: #edf2f6;
$transparent: transparent;
$mercury:#e7e7e7;
$gallery:#eeeeee;
$alto:#dddddd;
$boulder:#777777;
$iron: #DDDFE1;
$azure-radiance:#0080FF;
$wildsand:#f7f7f7;
$wildsand-light:#f7f9fb;
$cerulean:#00b1eb;
$whisper:#F4F5F9;
$dove-grey:#666666;
$red:#F04047;
$abi-blue:#053874;
$abi-red:#ED1C24;
$light-grey:#eeeeee;
$blue:#063873;
$light-sea-green:#2ba6ba;
$Tomato:#F46F4C;
$light-grey: #f3f3f3;
$dark-grey: #6D6A69;
$dark-black-gray: #333333;
$dark-gray-78: #c8c3c9;
$alice-blue: #EDF2F6;
$rgb-1: rgba(0, 0, 0, .175);
$amethyst-smoke: #908992;
$green: #59B200;
$dark-green: #3AAB73;
$light-green: #68A325;
$light-red: #F2413E;
$gray-dark: #AAAAAA;
$Selago: #F4F3F4;
$label-green:#35a39c;
$lighter-gray: #dedbdf;
$yellow: #FFB647;
$light-yellow: #f9daab;
$bright-green: #98ce1e;
$dark-yellow: #ffb648;
$error-red: #F0424A;
$athens-gray: #ECEEF4;
$emperor-gray:#555555;
$turquoise:#22D9BB;
$light-turquoise:#1EC1A5;
$onahau: #ccf2ff;
$green: #5cb85c;
$light-green: #86cc86;
$link-water: #E1EBF6;
$perano: #abcaef;
$nepal: #8ba2bd;
$link-water-new: #ccd6e2;
$alice-blue: #F1F4F7;
$air-force-blue: #6887ab;
$botticelli: #CCD6E2;
$Sapphire: #072D5F;
$light-blue: #F1FCFF;
$faded-white: #efe4e4;
$blury-grey: #dedede;
$border-grey: #cfcfcf;
$maroon: #a43a3e;
$read-block:#dff6fe;
$filter-border-color:#E4ECF1;
$fiter-active:#00B0EA;
$filter-active-bg:#C7F1FF;
$filter-input-border:#D9D9D9;
$filter-text-color:#707070;
$warning-bg:#FFFAE5;
$warning-border: #E4D2B6;
