@import '../common/base/colors';
@import '../common/base/mixins';

$iconsprite: '../common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../common/images/webapp-icons-sprite@2x-v3.png';
/*----------------------------------------------------------------------------------
    Export page
----------------------------------------------------------------------------------*/

.export-page {
    top: 50px;
    padding-left: 45px;
    margin-left: 0;
    padding-right: 0;
    left: 13px;
    // position: fixed;
    bottom: 0;

    .page-title-bar {
        .sub-title {
            color: $cerulean;
            font-weight: 700;
            font-size: .75rem;
            margin-top: -8px;
            position: absolute;
        }
    }

    .content-wrapper {
        left: 60px;
        position: fixed;
        right: 0;
        top: 135px;
        transition: all .2s linear;
        bottom: 50px;
        .data-export-tab {
            margin: 0 5px 0 25px;
            position: relative;
            //height: 91%;
            font-size: 13px;
            .data-export-search-panel {
                background: none;
                margin-bottom: 0;
                .media-body {
                    margin: 0;
                    .media-heading {
                        font-size: 1.4em;
                        font-weight: 600;
                        .btn-data-export {
                            text-indent: 0;
                            height: 30px;
                            padding: 0 10px;
                            font-size: .95rem;
                            background: $white;
                            color: $cerulean;
                            border: 1px solid $cerulean;
                            cursor: pointer;
                            font-weight: 500;
                            &:hover {
                                box-shadow: 0 0 5px $cerulean;
                                background: $white;
                                color: $cerulean;
                                border: 1px solid $cerulean;
                            }
                        }
                        .download-help {
                            text-align: center;
                            a {
                                color: $black;
                                text-decoration: underline;
                                font-size: .75rem;
                            }
                        }
                    }
                } 
            }  
        }
    }
    .export-results {
        bottom: 50px;
        left: 60px;
        position: fixed;
        right: 0;
        top: 180px;
        width: 94%;
        margin: 0 auto;
       
        .export-table {
            .export-table-header {
                background-color: $alto;
                font-weight: 700;
                line-height: .5rem;
                padding: 15px 0;
                width: 100%;
                margin: 0 auto;
            }
            .export-details-wrapper {
                height: calc(100vh - 225px);
                transition: all .2s linear;

                .export-table-list-container {
                    background: $white;
                    position: relative;
                    min-height: 52px;
                    .export-detail-block{
                        .export-more {
                            background: $transparent url($iconsprite) no-repeat scroll -66px -306px;
                            content: " ";
                            top: 3px;
                            left:5px;
                            height: 16px;
                            width: 16px;
                            cursor: pointer;
                            display: inline-block;
                        }
                        .show-label-list-wrapper{
                            .label-container{
                                width: 400px;
                                position: absolute;
                                z-index: 1;
                                display: -webkit-inline-box;
                                display: inline-block;
                                margin-left: -20px;
                                top: 30px;
                                bottom: 0;
                                /* right: 0; */
                                background: $white;
                                /* height: 200px; */
                                // background: $white;
                                z-index: 1;
                                // border-top: 1px solid $light-grey;
                                // box-shadow: -2px 10px 12px -5px $dark-grey;
                                &::before {
                                    background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                                    content: " ";
                                    position: absolute;
                                    height: 30px;
                                    width: 30px;
                                    left: 14px;
                                    top: -18px;
                                }
                                .label-container-wrapper {
                                    min-height: 50px;
                                    @include box-shadow(0, 2px, 9px, $dark-grey);
                                }
                                .column-list-container {
                                    position: relative;
                                    min-height: 40px;
                                    height: 430px;
                                    background-color: $white;
                                }
                                .details-panel-scrollbar{
                                    border: 0 none;
                                    left: 0;
                                    right: 0;
                                    bottom: 0;
                                    top: 0;
                                }
                                .fixed-container{
                                    width: 100%;
                                    // border:1px solid $alto;
                                    padding: 12px;
                                    background: $white;
                                    z-index: 999;
                                    border-bottom: 1px solid $light-grey;
                                    span{
                                        color: $dove-grey;
                                        font-weight: 700;
                                    }
                                    .export-label-close{
                                        height: 30px;
                                        width: 30px;
                                        background: $white url(#{$iconsprite}) no-repeat scroll -180px -29px;
                                        border: 0 none;
                                        position: absolute;
                                        text-indent: -999px;
                                        right: 2px;
                                        top: 1px;
                                        cursor: pointer;
                                        overflow: hidden;
                                    }
                                }
                                .export-label-list{
                                    display: block;
                                    background: $white;
                                    span{
                                        padding: 10px 0 10px 10px;
                                        font-weight: 500;
                                        display: block;
                                        border-bottom: 1px solid $light-grey;
                                    }
                                    .ul-list {
                                        font-weight: 500;
                                        margin: 0;
                                        padding: 5px 0 5px 25px;
                                        border-bottom: 1px solid $light-grey;
                                        .li-list {
                                            list-style: none;
                                            font-weight: 700;
                                            &::before{
                                                background: $transparent url($iconsprite) no-repeat scroll -210px -72px;
                                                content: " ";
                                                position: absolute;
                                                height: 15px;
                                                width: 30px;
                                                left: 0;
                                            }
                                        }
                                        ul {
                                            position: relative;
                                            left: -30px;
                                            li {
                                                list-style: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .export-name {
                            padding: 0 0 0 55px;
                            display: inline-block;
                            font-weight: 700;
                            min-height: 9px;
                            font-size: 13px;
                            span.saved-src-namelist {
                                position: relative;
                                display: inline-block;
                                top: 3px;
                                left: 3px;
                            }
                        }
                        .sub-ex-name {
                            padding: 0 0 0 55px;
                            display: block;
                            font-size: 13px;
                            color: $dove-grey;
                            clear: both;
                            line-height: 1rem;
                        }
                        .export-detail-name {
                            font-size: 13px;
                            a{
                                cursor: pointer;
                            }
                            .yellow {
                                color: $yellow;
                            }
                            .red {
                                color: $red;
                                cursor: pointer;
                            }
                            .blue {
                                color: $abi-blue;
                                cursor: pointer;
                            }
                            .green {
                                color: $green;
                            }
                            .record{
                                padding: 0;
                                color: $black;
                                font-size: 20px;
                                font-weight: 400;
                            }
                            
                        }
                    }
                    .export-icon {
                        content: " ";
                        position: absolute;
                        top: 15px;
                        left: 15px;
                        height: 30px;
                        width: 30px;
                        display: block;
                        &.projects-csv {
                            background: transparent url($iconsprite) no-repeat scroll -90px -300px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -240px -330px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -14px;
                                right: -12px;
                            }
                        }
                        &.company-csv {
                            background: transparent url($iconsprite) no-repeat scroll -90px -300px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -270px -330px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -9px;
                                right: -12px;
                            }
                        }
                        &.contacts-csv {
                            background: transparent url($iconsprite) no-repeat scroll -90px -300px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -150px -270px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -9px;
                                right: -12px;
                            }
                        }
                        &.company-pdf {
                            background: transparent url($iconsprite) no-repeat scroll -180px -390px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -270px -330px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -9px;
                                right: -12px;
                            }
                        }
                        &.projects-pdf {
                            background: transparent url($iconsprite) no-repeat scroll -180px -390px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -240px -330px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -12px;
                                right: -12px;
                            }
                        }
                        &.contacts-pdf {
                            background: transparent url($iconsprite) no-repeat scroll -180px -390px;
                            &:after {
                                background: url($iconsprite) no-repeat scroll -150px -270px;
                                height: 30px;
                                width: 30px;
                                content: " ";
                                position: absolute;
                                top: -12px;
                                right: -12px;
                            }
                        }
                    }
                    
                }
            }
        }
    }  
}

.export-new-page {
    top: 40px;
    padding-left: 45px;
    margin-left: 0;
    padding-right: 0;
    left: 0;
    position: fixed;
    bottom: 0;
    .header {
        background: $white;
        height: 130px;
        .page-title-bar {
            .sub-title {
                color: $cerulean;
                font-weight: 700;
                font-size: .75rem;
                margin-top: -8px;
                position: absolute;
            }
        }

        .multi-step {
            padding: 0;
            left: 420px;
            position: absolute;
            .progtrckr {
                list-style-type: none;
                padding: 0;
                top: -50px;
                position: relative;
                left: 50px;
                li {
                    display: inline-block;
                    text-align: center;
                    line-height: 2.5rem;
                    width: 200px;
                    &:last-child {
                        border: 0 !important;
                        &:after {
                            background: transparent !important;
                        }
                    }
                    &.progtrckr-doing {
                        color: $black;
                        border-bottom: none;
                        position: relative;
                        &:after {
                            content: "";
                            background: $grey;
                            display: block;
                            height: 2px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            top: 75px;
                        }
                    }
                    &.progtrckr-done {
                        color: $black;
                        border-bottom: 2px solid $abi-blue;
                        em {
                            width: 35px;
                            background: $abi-blue;
                            height: 35px;
                            &:before {
                                content: "";
                                position: absolute;
                                width: 30px;
                                height: 30px;
                                background: $abi-blue url($iconsprite) no-repeat scroll -180px -300px;
                                top: 2px;
                                left: 3px;
                                border-radius: 50%;
                            }
                        }
                    }
                    &.progtrckr-todo {
                        color: $grey;
                        border-bottom: 2px solid $grey;
                        position: relative;
                    }
                    em {
                        font-style: normal;
                        cursor: default;
                        color: $white;
                        height: 35px;
                        line-height: 2.3em;
                        position: relative;
                        top: 57px;
                        z-index: 99;
                        display: block;
                        font-weight: 700;
                        padding-left: 0;
                        background-color: $white;
                        width: 35px;
                        border-radius: 50%;
                    }
                    span {
                        position: relative;
                        top: 50px;
                        left: -80px;
                    }
                    &:first-child:after {
                        left: 0;
                        width: 200px;
                    }
                    &:after {
                        content: "\00a0\00a0";
                    }
                }
                .progtrckr-doing em {
                    border: 2px solid $abi-blue;
                    color: $abi-blue;
                }
                .progtrckr-todo em {
                    color: #000;
                    line-height: 2.1em;
                    border: 2px solid $grey;
                    left: 0;
                }
            }
        }
    }   

    .content-wrapper {
        .export-summary {
            width: 330px;
            left: 80px;
            position: absolute;
            padding: 0;
            bottom: 10px;
            top: 160px;
            background: $white;
            .export-title {
                background: $link-water-new;
                text-align: left;
                width: 100%;
                position: relative;
                font-weight: 700;
                display: block;
                padding: 5px 10px;
            }
            .export-summary-wrapper {
                position: absolute;
                right: 0;
                left: 0;
                top: 40px;
                bottom: 0;
                .export-content {
                    width: auto;
                    font-size: .75rem;
                    background: $white;
                    border: 1px solid $mercury;
                    .saved-search-name {
                        word-wrap: break-word;
                    }
                    .export-records{
                        .yellow {
                            color: $yellow;
                        }
                        .red {
                            color: $red;
                            cursor: pointer;
                        }
                        .blue {
                            color: $abi-blue;
                            cursor: pointer;
                        }
                        .green {
                            color: $green;
                        }
                        .record{
                            padding: 0;
                            color: $black;
                            font-size: 20px;
                            font-weight: 400;
                        }
                    }
                }
            }
            strong {
                display: block;
            }
        }

        .export-all-fields {
            position: fixed;
            right: 10px;
            left: 420px;
            top: 160px;
            bottom: 10px;
            .export-page-wrapper {
                position: absolute;
                right: 0;
                left: 0;
                top: 40px;
                bottom: 0;
                .box + div{
                    display: none;
                  }
                .input-container {
                    padding: 10px;
                    margin-bottom: 1rem;
                    background: $white;
                    position: relative;
                    .form-control:focus{
                        border: 1px solid $border-grey;
                    }
                    .export-count{
                        display: inline-block;
                        padding: 0;
                        font-weight: bold;
                        width: 196px;
                    }
                    .title {
                        font-weight: 700;
                        padding-bottom: 10px;
                        display: block;
                        font-size: .8rem;
                        color: $dark-black-gray;
                    }
                    .long-input {
                        width: auto;
                        min-width: 330px;
                    }
                    .datepickerbox {
                        cursor: pointer;
                    }
                    .selectBoxAll {
                        margin-top: -5px;
                        select {
                            font-size: 100%;
                        }
                    }
                    .refresh-count-block {
                        border-right: 1px solid $grey;
                        position: relative;
                        &:after {
                            border: solid $grey;
                            border-width: 0 1px 1px 0;
                            display: inline-block;
                            padding: 5px;
                            transform: rotate(-45deg);
                            -webkit-transform: rotate(-45deg);
                            content: "";
                            position: absolute;
                            right: -6px;
                            top: 46%;
                            background: $white;
                        }
                    }
                    .col-md-custom-one {
                        width: 1%;
                        display: flex;
                        align-items: center;
                    }
                    .count-panel {
                        position: relative;
                        .green {
                            color: $green;
                        }
                        .red {
                            color: $red;
                        }
                        .max-count-msg {
                            color: $dove-grey;
                            font-weight: 400;
                            position: absolute;
                            top: -15px;
                            width: 260px;
                            font-size: 12px;
                        }
                        .record{
                            padding: 0;
                            color: $black;
                            font-size: 20px;
                            font-weight: 400;
                        }
                        .no-export {
                            font-size: .85em;
                            color: $red;
                            position: absolute;
                            padding: 12px 32px;
                            top: -40px;
                            width: 190px;
                            left: -10px;
        
                            &::before {
                                background: $transparent url($iconsprite) no-repeat scroll -90px -180px;
                                content: " ";
                                position: absolute;
                                left: 5px;
                                height: 30px;
                                width: 30px;
                                top: 6px;
                            }
                        }
                    }
                }
                .filter-container {
                    .filter-text {
                        width: auto;
                        min-width: 350px
                    }
                    .btn-group {
                        position: absolute;
                        left: 300px;
                    }
                    .role-select-all{
                        background: none;
                        border: none;
                        border-left: 1px solid $grey;
                        height: 39px;
                        &:after {
                            color: $black;
                        }
                    }
                    .check-box-button {
                        display: inline-block;
                    }
                    .react-checkbox-tree {
                        height: auto;
                        max-height: 153px;
                        margin-left: 2px;
                        overflow: auto;
                        padding: 10px 0 0 10px;
                        border: 1px solid #ddd;
                        width: 350px;
                        box-shadow: 0 4px 4px -2px #292b2c;
                        -moz-box-shadow: 0 4px 4px -2px #292b2c;
                        -webkit-box-shadow: 0 4px 4px -2px #292b2c;
                        margin-top: 10px;
                        .rct-node-parent {
                            height: 30px;
                        }
                        .rct-collapse {
                            display: none;
                        }
                        label {padding-left: 0;}
                        .rct-title {margin-left: 20px;}
                    }
                }
                .steps-footer-buttons {
                    margin-bottom: 50px;
                }
                .val-err-tooltip {
                    font-size: .85em;
                    text-align: right;
                    color: $red;
                }
            }
        }
    }
    .next, .previous {padding: 6px 8px;}
}

.bb-1 {
    border-bottom: 1px solid $mercury;
}
.custom-dropdown {
    display: inline-block;
    button {
        position: relative;
        background: none;
        color: $black;
        min-width: 400px;
        text-align: left;
        font-size: .75rem;
        font-weight: 500;
        padding: .5rem .75rem;
        border-top: 1px solid $alto;
        &:hover, &:active, &:focus {
            background-color: transparent !important;
            color: $black !important;
        }
        &.dropdown-toggle {
            border: 1px solid $alto;
        }
        &:after {
            right: 10px;
            position: absolute;
            top: 17px !important;
        }
    }
    .dropdown-menu {
        float: none;
        min-width: 300px;
        padding: 0;
        max-height: 250px;
        overflow-x: hidden;
        border-radius: 0;
        margin: 0;
        width: 400px;
    }
}

.checkbox-tree-dropdown {
    margin-bottom: 5px;
    button {
        min-width: 350px;
    }
}

.export-list-container {
    padding: 0 0 30px;
    margin: 0 15px 10px 0;
    position: relative;
    .placeholder{
        width: 100%;
        display: flex;
        border: none;
        background: transparent;
        opacity: 1;
        div{
            min-height: 10px;
        }
        .list-group-item{
            border: 0;
            padding: 10px 0 0;
            .result-block{
                padding: 10px 0 0;
            }
        }
        .list-header.placeholder{
            min-height: 5px;
            padding: 0;
            border: 0;
            background: $white;
            .user-names{
                .list-group-item{
                    h5{
                        width:200px;
                    }
                }
            }
            .user-licenses{
                &.col-12{
                    padding: 0;
                }
                ul{
                    display: inline-block;
                    h5{
                        width:auto;
                    }
                }

            }
            .user-teamnames{
                // animation: h 1s infinite;
                // border-radius: 14px;
                ul{
                    display: inline-block;
                    h5{
                        width:auto;
                    }
                }

            }
            .user-status{
                // animation: h 1s infinite;
                // border-radius: 14px;
                ul{
                    display: inline-block;
                    h5{
                        width:auto;
                    }
                }

            }
            .user-action{
                // animation: h 1s infinite;
                // border-radius: 14px;
                ul{
                    display: inline-block;
                    h5{
                        width:auto;
                    }
                }

            }
        }
    }
}


.check-box-button {
    input{
        display: none;
    }
    .roles-search-list{
        .roles-search-filed-btn{
            height: 30px;
            margin: 0;
            width: 30px;
            background: $white url($iconsprite) no-repeat scroll -180px -29px;
            border: 0 none;
            position: absolute;
            text-indent: -999px;
            overflow: hidden;
            right: 135px;
            top: 9px;
            cursor: pointer;
            z-index: 1003;
        }
        &.subsearch-filters-wrapper{
            .roles-search-filed-btn{
                right: 65px;
                top: 3px;
            }
        }
        input{
            display: block;
        }
        .check-box-button{
            input{
                display: none;
            }
        }
    }
    .field-panel{
        margin-left: 25px;
        width: 350px;
        .auto-complete{
            border: 1px solid $alto;
            background: $white;
            min-height: 30px;
            padding: 7px 25px 7px 10px;
            margin-bottom: 5px;
            position: relative;
            font-size: 0.75rem;
            &::after{
                background: $transparent url($iconsprite) no-repeat scroll -218px -75px;
                content: " ";
                position: absolute;
                top: 35%;
                right: 5px;
                height: 15px;
                width: 15px;
            }
        }
        input{
            width: 100% !important;
            display: block;
            &::after{
                background: $alto url($iconsprite) no-repeat scroll -30px -150px;
                content: " ";
                position: absolute;
                top: 3px;
                left: 15px;
                height: 30px;
                width: 30px;
                display: block;
                text-align: center;
                border-radius: 22px;
                padding: 7px 2px;
            }
        }
        .role-dropdown{
            .role-filter-block{
                fieldset{
                    position: absolute;
                    background: $white;
                    width: 100%;
                    top: -7px;
                    z-index: 999;
                    box-shadow: 0 4px 4px -2px $black-grey;
                    -moz-box-shadow: 0 4px 4px -2px $black-grey;
                    -webkit-box-shadow: 0 4px 4px -2px $black-grey;
                    .with-com-toggle{
                        display: none;
                    }
                    .select-toggle{
                        position: absolute;
                        top: 20px;
                        right: 20px;
                        font-size: .8rem;
                        z-index: 1;
                        text-align: right;
                        left:auto;
                        padding-top: 0;
                        width: 200px;
                        @media only screen and (min-width: 1650px) {
                            top: 15px;
                        }
                        a{
                            border-left: 1px solid $mercury;
                            padding-left: 5px;
                        }
                    }
                    .roles-search-list{
                        position: relative;
                        input{
                            margin: 7px 0px 0 2px;
                            padding-right: 170px;
                            font-size: .9rem;
                            font-weight: 400;
                            width: 99%!important;
                        }
                    }
                }
                .border-alto{
                    border: 1px solid $alto;
                }
            }
        }
        .include-stages{
            height: auto;
            max-height: 153px;
            margin-left: 2px;
            overflow: auto;

        }
    }
}

.create-template-block{
    border:2px dashed $alto;
}

.input-container{
    // border-bottom: 1px solid $alto;
    padding: 10px;
    margin-bottom: 1rem;
    background: $white;
    .reset{
        top: 11px;
        position: absolute;
        left: 180px;
        color:$red;
        text-decoration: underline;
        font-size: 0.7rem;
        cursor: pointer;
    }
    &.save-template{
        background: $athens-gray !important;
    }
    .roles-selector-section{
        top: 8px !important;
        right: 0 !important;
        .btn-group{
            width: 50px;
        }
        input{
            display: none;
            &:after{
                content: initial;
            }
        }
        label{
            opacity: 1;
            &:before{
                content: "";
                border: 2px solid #f2413e;
                cursor: pointer;
                position: absolute;
                width: 17px;
                height: 17px;
                top: 3px;
                left: 0;
                background: #fff;
                box-sizing: inherit;
            }
            &:after{
                border: 3px solid #f2413e;
                border-top: none;
                border-right: none;
                transform: rotate(-45deg);
                box-sizing: inherit;
                opacity: .1;
                content: "";
                position: absolute;
                width: 9px;
                height: 5px;
                background: transparent;
                top: 8px;
                left: 4px;
                transform: rotate(-45deg);
                box-sizing: inherit;
            }
        }
        .check-box-button {
            &.child-selected{
                label {
                    &:before {
                        background: $light-red;
                    }
                    &:after {
                        opacity: 1;
                        content: "";
                        position: absolute;
                        width: 7px;
                        height: 3px;
                        background: transparent;
                        top: 10px;
                        left: 5px;
                        border: 3px solid $white;
                        border-top: none;
                        border-right: none;
                        transform: rotate(0deg);
                    }
                }
            }
        }
        
        input[type=checkbox]:checked+label:after{
            opacity: 1 !important;
        }
        .btn-group button{
            border-top: none;
            border-bottom: none;
            height: 33px;
            padding-top: 4px;
            padding-left: 9px;
            width: 100%;
            min-width: auto;
        }
        .dropdown-menu{
            float: left;
            max-height: initial;
            overflow-x: auto;
            border-radius: .25rem;
            margin: .125rem 0 0;
            position: absolute;
            left: -60px;
            width: 100px;
            min-width: 113px;
            top: 31px;
        }
        .dropdown-toggle{
            &:after{
                right: 7px;
            }
        }
    }
    .required-drop{
        position: absolute;
        top: 2px;
        display: inline-block;
        margin-left: 5px;
        color: $red;
        font-weight: 500;
        font-size: .75rem;
    }
    .select-ss-input, .select-tag {
        position: relative;
        .btn-link{
            color: $cerulean;
            text-decoration: none;
            &:hover,
            &:focus{
                background-color: $transparent;
                color: $cerulean;
            }
        }
        .btn-link.red-link{
            color: $abi-red;
            &:hover,
            &:focus{
                background-color: $transparent;
                color: $abi-red;
            }
        }
    }
    .export-count{
        position: absolute;
        display: inline-block;
        left: 410px;
        top: 46px;
        padding: 0;
        width: 196px;
    }
    .no-error, .has-error{
        margin: 0;
        padding: 0;
        display: table;
        .val-err-tooltip{
            font-size: .85em;
            text-align: right;
            color: $red;
        }
    }
    .dropdown-menu{
        float: none;
        min-width: 300px;
        padding: 0;
        max-height: 250px;
        overflow-x: hidden;
        border-radius: 0;
        margin: 0;
        width: 400px;
    }
    span {
        font-weight: 700;
        padding-bottom: 10px;
        display: block;
        font-size: .8rem;
        color: $dark-black-gray;
        &.hint-text {
            font-size: 12px;
            display: block;
            color: $dove-grey;
            font-weight: 400;
        }
    }
    input {
        width: auto;
        min-width: 330px;
        // display: block;
    }
    .btn-group{
        display: inline-block;
        button {
            min-width: 400px;
            text-align: left;
            font-size: 0.75rem;
            color: $black;
            font-weight: 500;
            background-color: $white;
            padding: .5rem .75rem;
            border: 1px solid $alto;
            &::after{
                right: 10px !important;
                position: absolute;
                top: 17px !important;
            }
            &:hover, &:focus, &:active{
                background: $white;
                color: $black;
            }
            &.dropdown-header{
                background: $light-grey;
                padding-left: 10px;
                color: $black;
            }
        }
        h6{
            background: $light-grey;
            padding-left: 10px;
            color: $black;
        }
    }
    .check-box-button{
        label{
            cursor: pointer;
            font-weight: 500;
            span {
                display: inline;
                color: $dark-grey;
                font-weight: 500;
            }
        }
        .textfield-wrapper{
            &::after{
                border: 0 none;
            }
        }
        &.disabled{
            label{
                cursor: default;
            }
        }
    }
    .role-container{
        .field-panel{
            margin-left: 0;
        }
    }
    .date-container {
        position: relative;
        display: flex;
        background: $white;
        .InputFromTo {
            background: $white;
            padding: 0;
            display: flex;
            position: relative;
            
            .DayPickerInput {
                font-size: 0.85rem;
                position: relative;
                input {
                    min-width: 220px;
                    padding: .5rem .75rem;
                    margin: 0;
                    font-size: 0.75rem;
                    line-height: 1.25;
                    color: $black-grey;
                    background-color: $transparent;
                    background-image: none;
                    background-clip: padding-box;
                    border: 1px solid rgba(0, 0, 0, .15);
                    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                    z-index: 1;
                    position: relative;
                }
                &:after{
                    content: "";
                    background: $white url($iconsprite) no-repeat scroll -30px -210px;
                    position: absolute;
                    right: 6px;
                    top: 2px;
                    width: 30px;
                    height: 30px;
                    z-index: 0;
                }
            }

            .required {
                font-size: .85em;
                text-align: right;
                color: $red;
                float: left;
                position: absolute;
                top: 50px;
                display: block;
                left: 253px;
                bottom: 0;
            }
        }

        .count-container {
            position: relative;
            background: $white;
            padding: 0 10px;
            // margin-left: 10px;
            // border-left: 15px solid $whisper;

            .count-panel {
                position: relative;
                top: -2px;
                width: 100%;
                div{
                    display: flex;
                    align-items: baseline;
                }

                .green {
                    color: $green;
                    margin-right: 5px;
                }

                .red {
                    color: $red;
                    margin-right: 5px;
                }

                .record {
                    padding: 0;
                    color: $black;
                    font-size: 20px;
                    font-weight: 400;
                }

                
            }
        }
    }
    .clearDateBtn {
        font-size: .8rem;
        color: $abi-red;
        text-decoration: underline;
        cursor: pointer;
    }
    .refresh-count-block{
        border-right:1px solid $grey;
        position: relative;
        &:after{
            border: solid $grey;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 5px;
            transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);      
            content: "";
            position: absolute;
            right:-6px;
            top:46%;   
            background: $white;                   
        }
    }
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
    }
}

.select-toggle {
    .toggle-link{
        color: #444;
        .active {
            background-color: #fff;
        }
        .disabled{
            opacity: .5;
        }
    }
}

.dropdownOverlay {
    position: fixed; 
    top: 0; 
    right: 0; 
    bottom: 0; 
    left: 0; 
    z-index: 9;
    background: transparent;
  }

/* custom style to date range picker */

.react-datepicker{
    .react-datepicker__header{
        background: $white;
        border-bottom: none;
        .react-datepicker__current-month{
            margin-bottom: 7px;
            text-align: left;
            padding: 0 15px;
        }
        .react-datepicker__day-name{
            font-weight: 500;
            color: $emperor-gray;
        }
    }
    .react-datepicker__navigation{
        top:8px;
        &.react-datepicker__navigation--previous{
            left: auto;
            right: 32px;
        }
    }
}