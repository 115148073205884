@import '../../components/common/base/colors';
@import '../../components/common/base/mixins';

$iconsprite: '../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../components/common/images/webapp-icons-sprite@2x-v3.png';

/*------------------------------------------------------------
    My Leads custom css
------------------------------------------------------------*/

.myleads-page {
    padding-left: 40px !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    left: 13px;
    position: relative;
    max-width: 100% !important;

    .tags-pane-mylead {
        .top-10 {
            .page-title-bar {
                .top-5 {
                    .right-save-col {
                        .over-map-panel {

                            // top: 5px;
                            // right: 100px;
                            .sort-results {
                                .dropdown {
                                    border: none;

                                    button.btn.btn-secondary {
                                        margin-top: 0px;
                                        margin-left: 16px;
                                    }

                                    .dropdown-menu {
                                        left: 16px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .result-list-container li.list-group-item {
        margin-top: 0px;
        &:first-child{
            border: 1px solid $alto;
        }
        .company-container, .project-container{
            width: 100%;
            .linkedin-list-view {
                left: 24%;
            }
            .linkWrapper{
                position: relative;
            }
            .toggleButtonAllUpdate{
                position: absolute;
                width: 25px;
                height: 100%;
                right: 16px;
                top: 0;
                background: #EBEBEB;
                border: none;
                cursor: pointer;
                z-index: 4;
                &:after{
                    width: 25px;
                    height:25px;
                    position: absolute;
                    top: 50%;
                    left: 2px;
                    transform: translateY(-50%);
                    content: "";
                    background: $transparent url($iconsprite) no-repeat scroll -64px -242px;
                }
                &.expanded{
                    &:after{
                        transform: rotate(90deg) translateX(-50%);
                    }
                }
            }
            @-moz-document url-prefix() {
                .toggleButtonAllUpdate {
                    right: 0px; /* Override for Firefox */
                }
            }
            .updates-details {
                border: 1px solid $grey;
                background: $whisper;
                border-left: 0;
                border-right: 0;
                &.scrollbar{
                    height: 207px;
                    padding-right: 17px;
                    position: relative;
                    z-index: 4;
                    margin-left: -26px;
                }
                .update-list {
                  padding: 10px;
                  border-bottom: 1px solid $grey;
                  display: flex;
                  .update-desc {
                    padding-left: 30px;
                    width: 70%;
                    &:before {
                        background: url($iconsprite) no-repeat scroll -90px -330px;
                        content: "";
                        position: absolute;
                        height: 30px;
                        left: 6px;
                        margin-top: 0;
                        width: 30px;
                    }
                  }
                  .time {
                    position: absolute;
                    right: 40px;
                  }
                }
              }
              .updateDetailsEnable {
                
                &:after {
                    top: calc(120px - 60px);
                    transform: rotate(90deg) translateX(-80%);
                }
              }
        }

        .team-member-list {
            position: absolute;
            right: 5px;
            bottom: 0;
            float: right;
            z-index: 1000;

            span {
                display: inline-block;
                margin: 0 0 0 5px;
                float: left;
            }

            .team-icon {
                position: relative;
                display: block;
                height: 30px;
                width: 30px;
            }

            .team-member-name {
                position: relative;
                background: $grey;
                color: $black;
                font-weight: 600;
                padding: 4px 0;
                border-radius: 50%;
                z-index: 1;
                font-size: 13px;
                height: 30px;
                width: 30px;
                display: inline-block;
                text-align: center;
                margin-left: 0;
                top: -9px;

                &.team-ss-icon {
                    &::after {
                        position: absolute;
                        content: "";
                        top: -5px;
                        right: -5px;
                        height: 20px;
                        width: 20px;
                        background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                        @include border-radius(50px);
                    }
                }
            }

            .share-everyone {
                position: relative;
                margin-left: 0;
                height: 30px;
                width: 30px;
                top: -9px;

                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    right: 0;
                    height: 30px;
                    width: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                    @include border-radius(50px);
                }
            }

            .more-team-member {
                position: relative;
                background: $grey;
                color: $black;
                font-weight: 600;
                padding: 5px 0;
                border-radius: 50%;
                z-index: 1;
                font-size: 15px;
                height: 30px;
                width: 30px;
                display: inline-block;
                text-align: center;
                margin-left: 0;
                top: -9px;
            }

            .team-icon {
                &:before {
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -120px -150px;
                    left: 0px;
                    position: absolute;
                    background-color: $grey;
                    border-radius: 50%;
                    top: 0px;
                }
            }
        }
    }

    .result-list-container .update-date+li.list-group-item {
        border-top: 1px solid #ddd;
    }

    .my-leads-sort {
        position: relative;
        right: 10px;
        float: right;
        top: 10px;
        z-index: 999;
        background: $white;
        padding: 0;

        .sort-results {
            position: absolute;
            right: 0;
            top: 0;
            width: 230px;
            font-weight: 600;
            font-size: .80rem;
            background: $white;
            padding: 10px 0 10px 5px;

            .dropdown {
                float: left;
                left: 50px;
            }
        }
    }

    .top-10 {
        //padding: 0 20px;
        color: $red;
        font-weight: 600;
        position: relative;
        height: 0;

        // width: 357px;
        // background: $white;
        p {
            color: $black;
            font-weight: normal;
            padding-top: 10px;
            padding-left: 65px;
        }

        .dropdown {
            // border: 1px solid #eceef4;
            top: 0;
            background: $white;
            height: 39px;
            padding: 5px 12px;
            width: 110px;

            .btn {
                left: 0;
                margin: 0 12px;
            }
        }

        .select-drop-count {
            z-index: 999;
            position: absolute;
            top: 6px;
            left: 10px;
            display: inline-flex;
            background-color: $white;

            button.active {
                &::before {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll 0 -270px;
                }
            }

            button {
                position: relative;
                // left: -3px;
                background: none;
                color: $black;
                border: 1px solid $lighter-gray;
                width: 50px;
                height: 30px;
                padding: 0;
                margin: 0;
                border-radius: 0;

                &::before {
                    content: "";
                    position: absolute;
                    top: -1px;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -30px -270px;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 8px;
                    right: 2px;
                    width: 15px;
                    height: 15px;
                    border: 0;
                    background: $transparent url($iconsprite) no-repeat scroll -220px -75px;
                }
            }

            .select-drop-list {
                z-index: 100000;
                left: 1px;
                top: 30px;
                margin: 0;
                padding: 0;
                border-radius: 0;
                box-shadow: 1px 1px 5px $dove-grey;
                border: 0 none;

                &:before {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                    top: -18px;
                    left: 0;
                    z-index: 1;
                    right: auto;
                }

                button {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-left: 30px;
                    border: none;
                    border-bottom: 1px solid $mercury;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: $black-grey;

                    &::before {
                        left: 0;
                    }

                    &::after {
                        background: none;
                    }

                    &:hover {
                        background-color: $mercury;
                    }
                }
            }
        }

        .page-title-bar {
            padding: 0;
            height: 0;

            .top-5 {
                .searchList-header {
                    // background-color: $whisper;
                    // padding: 10px 0 0 50px;
                    // width: 350px;
                    // height: 56px;
                    // position: absolute;
                    // left: -16px;
                    // top: 78px;
                    // display: table;
                    // vertical-align: middle;
                    display: block;
                    position: relative;
                    top: 0;
                    height: auto;
                    width: 450px;
                    left: 7px;
                    background: $white;
                    padding: 5px 0px;

                    .result-count {
                        position: absolute;
                        top: 0;
                        right: 20px;
                        font-weight: 700;
                        font-size: 0.85rem;
                    }

                    .shift {

                        .btn-secondary,
                        .dropdown-menu {
                            left: 50px;
                        }
                    }
                }

                .right-save-col {
                    right: 175px;
                    position: absolute;
                    top: 7px;

                    .over-map-panel {
                        top: 0;
                        right: 15px;
                        // width: 190px; 
                        // background: $white;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        position: relative;

                        .sort-results {
                            right: 0;
                            top: 0;
                            background: none;
                            position: relative;
                            float: left;

                            .dropdown {
                                float: left;
                                width: 140px;
                                padding: 0;
                                height: auto;

                                .btn {
                                    height: auto;
                                    padding: 7px;
                                }

                                .dropdown-menu {
                                    left: 12px;
                                    width: 115px;
                                    border: 1px solid $lighter-gray;
                                    box-shadow: none;
                                }

                                .dropdown-toggle {
                                    &:after {
                                        top: 8px;
                                    }
                                }
                            }

                        }

                        .maplist-container {
                            right: 0;
                            background: $white;
                            padding: 5px;
                            width: 128px;
                            height: 39px;
                            top: 0;
                            position: relative;
                            left: auto;

                            .listview {
                                right: 7px;
                                position: absolute;
                            }
                        }
                    }
                }

                .map-back {
                    position: absolute;
                    display: inline-block;
                    top: 4px;
                    color: $black;
                    font-size: 0.8rem;
                    cursor: pointer;
                    font-weight: 400;
                    left: 7px;
                    border: 1px solid $grey;
                    padding: 14px 10px 0;
                    z-index: 999;
                    background: $white;

                    &:before {
                        content: '';
                        background: $transparent url($iconsprite) no-repeat scroll 0px -240px;
                        height: 30px;
                        position: absolute;
                        right: 11px;
                        top: -6px;
                        width: 30px;
                    }
                }
            }

            .header-second-level {
                display: flex;
                position: relative;
                top: 0;
                height: 45px;
                width: 450px;
                left: 7px;
                background: #fff;

                .map-back {
                    position: absolute;
                    display: inline-block;
                    top: 4px;
                    color: $black;
                    font-size: 0.8rem;
                    cursor: pointer;
                    font-weight: 400;
                    left: 7px;
                    border: 1px solid $grey;
                    padding: 14px 10px 0;
                    z-index: 999;
                    background: $white;

                    &:before {
                        content: '';
                        background: $transparent url($iconsprite) no-repeat scroll 0px -240px;
                        height: 30px;
                        position: absolute;
                        right: 11px;
                        top: -6px;
                        width: 30px;
                    }
                }
            }
        }
    }

    .my-favourite-wrapper {
        position: absolute;
        width: 100%;
        z-index: -1;
        height: 0;
        @include box-shadow(0, 2px, 8px, rgba(0, 0, 0, 0.2));

        .my-leads-fav {
            top: 187px;
            padding-top: 42px;

            &.hierarchyTrue {
                padding-top: 0;
            }

        }

        .my-leads-archive {
            top: 187px
        }

        .py-3 {
            padding: 20px;
        }

        .searchList-header {
            .dropdown {
                width: auto;
                padding: 0;

                .btn {
                    top: -9px;
                    left: -5px;
                }

                .btn.active,
                .btn:focus,
                .btn:hover {
                    background: $white !important;
                }

                .btn-secondary {
                    border: 0 none;
                    padding: 7px 14px 7px 30px;
                    font-size: 1em;
                    top: 0;
                }

                .dropdown-toggle {
                    &:after {
                        border: 0;
                        width: 10px;
                        height: 10px;
                        content: "";
                        position: absolute;
                        top: 14px;
                        right: 0;
                        background: transparent url($iconsprite) no-repeat scroll -220px -77px;
                    }
                }

                &.show {
                    .dropdown-toggle {
                        &:after {
                            border: 0;
                            width: 10px;
                            height: 10px;
                            content: "";
                            position: absolute;
                            top: 14px;
                            right: 0;
                            background: transparent url($iconsprite) no-repeat scroll -220px -65px;
                        }
                    }
                }

                .projects-icon {
                    position: relative;
                    color: $blue;
                    cursor: pointer;
                    outline: none;

                    &:before {
                        width: 30px;
                        height: 30px;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $transparent url($iconsprite) no-repeat scroll -123px -240px;
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }

                .companies-icon {
                    position: relative;
                    color: $abi-red;
                    cursor: pointer;

                    &:before {
                        width: 30px;
                        height: 30px;
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        background: $transparent url($iconsprite) no-repeat scroll -150px -240px;
                    }
                }

                .dropdown-menu {
                    border: 0 none;
                    @include box-shadow(0, 0, 5px, rgba(0, 0, 0, 0.2));
                    padding: 10px 10px 0;
                    @include border-radius(0px);
                    top: 96%;
                    font-size: 1em;

                    &:before {
                        content: "";
                        position: absolute;
                        top: -19px;
                        left: 8px;
                        width: 30px;
                        height: 30px;
                        background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                    }

                    .dropdown-item {
                        margin: 0 0 10px 0;
                        width: 140px;
                        padding: 5px 5px 5px 56px;
                        @include border-radius(5px);
                        @include border('top', 1px, solid, $alto);
                        @include border('left', 1px, solid, $alto);
                        @include border('bottom', 1px, solid, $alto);
                        @include border('right', 1px, solid, $alto);

                        &.active,
                        &:active {
                            background-color: $wildsand;
                        }

                        &:after {
                            width: 30px;
                            height: 30px;
                            content: "";
                            position: absolute;
                            top: 0px;
                            left: 0;
                            background: $transparent url($iconsprite) no-repeat scroll -30px -270px;
                        }

                        &.selected {
                            &:after {
                                width: 30px;
                                height: 30px;
                                content: "";
                                position: absolute;
                                top: 0px;
                                left: 0;
                                background: $transparent url($iconsprite) no-repeat scroll 0px -270px;
                            }
                        }

                        &.project-icon {
                            position: relative;
                            color: $blue;
                            cursor: pointer;

                            &:before {
                                width: 30px;
                                height: 30px;
                                content: "";
                                position: absolute;
                                top: 0px;
                                left: 27px;
                                background: $transparent url($iconsprite) no-repeat scroll -120px -240px;
                            }
                        }

                        &.company-icon {
                            position: relative;
                            color: $abi-red;
                            cursor: pointer;

                            &:before {
                                width: 30px;
                                height: 30px;
                                content: "";
                                position: absolute;
                                top: 0px;
                                left: 27px;
                                background: $transparent url($iconsprite) no-repeat scroll -150px -240px;
                            }
                        }

                        &.Contact-icon {
                            position: relative;
                            color: $dark-black-gray;
                            cursor: pointer;

                            &:before {
                                width: 30px;
                                height: 30px;
                                content: "";
                                position: absolute;
                                top: 0px;
                                left: 27px;
                                background: $transparent url($iconsprite) no-repeat scroll -150px -270px;
                            }
                        }
                    }

                    .dropdown-divider {
                        display: none;
                    }
                }
            }
            .archive-shift {
                margin-left: 60px !important;
            }
            .projects-icon {
                position: relative;
                color: $blue;
                cursor: pointer;
                outline: none;
                width: auto;
                padding: 7px 0 7px 30px;
                margin: 0 12px;
                display: inline-block;
                &:before {
                    width: 30px;
                    height: 30px;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $transparent url($iconsprite) no-repeat scroll -123px -240px;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }

        &.myleadsupdateTab{
            .list-view-panel{
                span.update-date{display: none !important;}
            }
        }
    }

    .history-wrapper {
        .history-list {
            .fav-unavailable {
                top: 0;
                position: absolute;
                left: 21px;
            }
        }
    }

    .my-share-wrapper {
        .result-list-container {
            .result-block {
                // z-index: 1000;
                //padding: .75rem 30px 50px 0;
                
                .team-member-list {
                    //right: 35px;
                    position: relative;
                    margin-top: 15px;
                }
            }
        }
    }

    .my-leads-fav {
        width: 450px;
        position: relative;

    }

    .show-full {
        .map-view {
            .my-leads-map-view {
                .mapboxgl-map {
                    margin-left: 0;
                    left: 65px;
                }
            }
        }
    }

    .map-view {
        bottom: 0;
        position: fixed;
        right: 0;
        transition: all .2s linear;
        border: 1px solid #e7e7e7;
        top: 250px !important;
        width: 100%;
        z-index: -1;
        background-size: cover;

        .mapboxgl-map {
            top: 143px !important;
            left: 512px !important;
        }

        .my-leads-map-view {
            position: fixed;
            top: 118px;
            left: 515px;
            right: 0;
            bottom: 0;
            z-index: -1;

            .mapboxgl-map {
                .mapboxgl-ctrl-top-right {
                    top: 235px;
                }

                .map-style-toggle {
                    top: 142px;
                }
            }
        }
    }

    .result-wrapper {
        position: relative;
        bottom: 0;
        left: 65px;
        position: fixed;
        right: 0;
        top: 250px; //transition: all 0.2s linear;
        width: auto;

        h4 {
            margin: 10px 0 10px;
            font-weight: 600;
        }

        .content-panel {
            padding: 0 10px 0 10px;

            .result-list-wrapper {
                .list-group {
                    .list-block {
                        margin: 0 0px;

                        .update-date {
                            margin: 10px 0 5px;
                            display: block;
                            font-weight: 600;
                            font-size: 1rem;
                        }

                        .update-date+li.list-group-item {
                            border-top: solid 1px $alto;
                        }

                        &:first-child {
                            .update-date {
                                margin-top: 0;
                            }
                        }
                    }

                }

            }
        }

        .no-result-panel {
            left: 6px;
            position: relative;
        }
    }

    .search-list-content {
        .search-result.list-view-panel  {
          .result-wrapper {
            top: 240px;
          }
        }
      }
      
      .search-list-content  {
        .search-result {
          .result-wrapper.gridSearchView {
            top: 0;
            .result-list-wrapper{
                .list-group-item{
                    .updates-details{
                        &.scrollbar{
                            margin-left: 0;
                            padding-right: 0;
                        }
                    }
                    .updateDetailsEnable{
                        &:after{
                            transform: rotate(90deg) translateX(-50%);
                        }
                    }
                }
            }
          }
        }
      }

      .search-list-content  {
        .search-result {
          .list-panel.gridSearchView {
             .result-wrapper {
               width: 450px;
               z-index: 2;
             }
          }
        }
      }

    .myleads-tab-wrapper {
        box-shadow: none;
        .result-list-container .searchlist-wrapper{
            height: calc(100vh - 300px);
        }
        .tab-content {
            background: $whisper;

            .my-favourite-wrapper {
                display: inline-block;
            }
        }

        .nav-tabs {
            border-bottom: 5px solid $abi-blue;
            background: $white;

            .nav-link:hover {
                border: 1px solid transparent;
            }

            .nav-link.active {
                background-color: $abi-blue;
                border: 0 none;
                color: $white !important;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                -ms-border-radius: 0px;
                -o-border-radius: 0px;
                border-radius: 0px;
            }

            li:first-child {
                margin-left: 20px;
            }

            .nav-item {
                margin-bottom: 0px;
                cursor: pointer;

                .show .nav-link {
                    background-color: $abi-blue;
                    border: 0 none;
                    color: $white;
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                    -ms-border-radius: 0px;
                    -o-border-radius: 0px;
                    border-radius: 0px;
                }
            }
        }
        .mapboxgl-ctrl-top-right{
            right: 11px !important;
            top: 261px !important;;
            .mapboxgl-ctrl-group{
                margin:0 !important;;
            }
        }
        .map-style-toggle{
            top:144px !important
        }
    }

    .my-leads-updates-wrapper {
        .result-wrapper {
            left: 90px;
            top: 147px;
            .update-list {
                margin: 10px 0;

                .time {
                    display: block;
                    font-size: 1.2rem;
                }

                .date {
                    font-size: 0.7rem;
                    display: block;
                    padding: 0 0 5px;
                }

                .company-row,
                .project-row {
                    background: $white;
                    padding: 8px 20px;
                    margin-bottom: 5px;
                    margin-right: 10px;
                    position: relative;
                    display: block;
                    cursor: pointer;

                    .name {
                        padding-left: 15px;
                        display: block;
                        font-weight: 700;
                    }

                    .desc {
                        padding-left: 15px;
                        font-size: 0.8em;
                        color: $dark-grey;
                        display: block;
                    }

                    .update-time {
                        position: absolute;
                        right: 25px;
                        color: $dark-grey;
                        top: 30%;

                        &::before {
                            background: url($iconsprite) no-repeat scroll -30px -210px;
                            content: "";
                            position: absolute;
                            height: 30px;
                            left: -26px;
                            margin-top: -4px;
                            width: 30px;
                        }
                    }
                }

                .company-row {
                    &::before {
                        background: url($iconsprite) no-repeat scroll -150px -330px;
                        content: "";
                        position: absolute;
                        height: 30px;
                        left: 5px;
                        margin-top: 0;
                        width: 30px;
                    }
                }

                .project-row {
                    &::before {
                        background: url($iconsprite) no-repeat scroll -120px -330px;
                        content: "";
                        position: absolute;
                        height: 30px;
                        left: 5px;
                        margin-top: 0;
                        width: 30px;
                    }
                }

            }
        }
    }

    .label-list-wrapper {

        //position: relative; // @include box-shadow(0, 0, 5px, $grey);
        &.label-project-list {
            p.show {
                margin: 15px;
            }
        }

        // .result-wrapper {
        //     top: 230px;
        // }

        .top-10 .page-title-bar .top-5 {

            // .right-save-col{
            // 	right: 11px;
            // }
            .tag-back {
                top: 4px;
                left: 14px;
            }
        }

        .textfield-wrapper {
            position: relative;
            top: 20px;
            left: 0;

            .label-search {
                margin-left: 30px;
                position: relative;

                &:before {
                    background: $transparent url($iconsprite) no-repeat scroll -90px 0;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    position: absolute;
                    top: 2px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                    z-index: 999;
                    left: 2px;
                }

            }

            .clear-textfield {
                position: absolute;
                right: 2px;
                top: 2px;
                float: left;
                margin-top: 1px;
                height: 29px;

                &:hover {
                    top: 2px;
                }
            }

            &:after {
                border-left: none;
            }

            input {
                width: 100%;
                border: 0;
                height: 35px;
                margin: 0;
                border: 1px solid $grey;
                padding-left: 30px;
                float: left;
                -webkit-appearance: none;
                &[type=search]{
                    outline-offset: 0;
                }

                &::placeholder {
                    color: $grey;
                    font-weight: 400;
                    //@extend %reference-font;
                }

                &::-webkit-input-placeholder {
                    color: $grey;
                    font-weight: 400;
                    //@extend %reference-font;
                }

                &::-moz-placeholder {
                    color: $grey;
                    font-weight: 400;
                    //@extend %reference-font;
                }

                &:-moz-placeholder {
                    color: $grey;
                    font-weight: 400;
                    //@extend %reference-font;
                }

                &:-ms-input-placeholder {
                    color: $grey;
                    font-weight: 400;
                    //@extend %reference-font;
                }
            }
        }

        input.form-control {
            height: 55px;
            border: 0 none;
            font-weight: 400;
            font-size: 1.2em;
            line-height: 2.8em;
        }

        .src-button {
            position: absolute;
            right: 0;
            top: 0;

            button {
                height: 55px;
                text-indent: 15px;
                width: 111px;
                overflow: hidden;
                position: relative;
                background: $red;
                color: $white;
                padding: 0 10px 0 16px;
                cursor: pointer;
                text-transform: capitalize;
                @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));

                &:after {
                    background: $transparent url($iconsprite) no-repeat scroll -180px -60px;
                    bottom: 0;
                    content: "";
                    height: 30px;
                    left: 5px;
                    position: absolute;
                    top: 12px;
                    width: 30px;
                    overflow: hidden;
                    display: block;
                }

                &:focus {
                    border: 1px solid $abi-blue;
                }
            }
        }

        .shared-labels-list {
            a {
                background-color: $link-water-new;
                border: 1px solid $link-water-new;
                color: $dark-black-gray;

                &:before {
                    background: $transparent url($iconsprite) no-repeat scroll -150px -210px;
                }
            }
        }

        .labels-list {
            span {
                a {
                    border: 1px solid $alice-blue;
                    background-color: $alice-blue;
                }
            }
        }

        .shared-labels-list {
            span {
                a {
                    border: 1px solid $link-water-new;
                    background-color: $link-water-new;
                }
            }
        }

        .labels-list,
        .shared-labels-list {
            margin: 45px 15px 10px 0;
            background: $white;
            left: -10px;
            position: relative;
            padding: 10px 15px 10px;
            margin-left: 45px;
            display: block;

            span {
                display: inline-block;
                position: relative;

                a {
                    padding: 3px 10px 3px 27px;
                    color: $abi-blue;
                    border-radius: 3px;
                    margin: 5px;
                    display: inline-block;
                    font-weight: 600;
                    cursor: pointer;
                    position: relative;
                    min-height: 26px;
                    font-size: 0.75rem;
                    min-width: 89px;

                    &:before {
                        background: $transparent url($iconsprite) no-repeat scroll -180px -180px;
                        content: "";
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        left: -3px;
                        top: -3px;
                    }
                }

                &:hover {
                    .delete-button.btn.btn-secondary {
                        left: 7px;
                        top: 7px;
                        transition: none;
                        position: absolute;
                        border-radius: 50%;
                        background-color: $white;
                        width: 22px;
                        height: 22px;
                        padding: 6px 0;
                    }
                }

                

                .hide {
                    display: none;
                }

                .show {
                    position: absolute;
                    top: 6px;
                    z-index: 999;
                    left: 6px;
                    display: inline-flex;
                    background: $white;
                    border-radius: 3px;
                    padding-top: 3px;
                    color: $dark-grey;

                    .btn-label-cancel {
                        color: $green;
                    }

                    .btn-label-delete {
                        color: $red;
                    }

                    .btn-label-cancel,
                    .btn-label-delete {
                        background: $transparent none repeat scroll 0 0;
                        border: 0 none;
                        font-size: 0.7rem;
                        font-family: arial;
                        cursor: pointer;
                        padding: 4px 4px 4px 2px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    span {
                        &:before {
                            content: "/";
                            position: relative;
                            right: 1px;
                            font-size: 0.5rem;
                            font-size: 0.75rem;
                            top: -2px;
                        }
                    }
                }
            }

            p {
                margin-top: -40px;
                margin-bottom: 21px;
                margin-left: -11px;
                font-size: 1.1em;
                font-weight: 700;
                position: absolute;
            }
        }

        .label-back {
            padding-left: 7px;
            color: $black;
            margin-top: 0px;

            >button {
                position: relative;
                padding-left: 30px;
                top: 5px;
                left: 5px;

                &:before {
                    background: $transparent url($iconsprite) no-repeat scroll -90px -150px;
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 30px;
                    height: 30px;
                    top: 3px;
                }
            }

            >p {
                // margin-left: 7px;
                // margin-top: 15px;
                font-weight: 600;
                width: 350px;
                padding: 20px;
                background: $white;
            }

            .__react_component_tooltip {
                padding: 8px;

                p {
                    margin-top: 0px;
                }
            }

            .hide {
                display: none;
            }

            .label-member-list {
                position: absolute;
                top: 8px;
                right: 310px;
                height: 39px;
                padding-top: 2px;
                background: $white;
                padding-right: 10px;
                padding-left: 7px;

                .visible-save-search-form {
                    padding: 0;
                    height: 41px;
                    overflow: inherit;
                    position: absolute;
                    opacity: 1;
                    background: $white;
                    // padding: 7px 4px;
                    border: 1px solid $lighter-gray;
                    z-index: 1000;
                    width: 550px;
                    top: -1px;
                    right: 0;

                    .btn-cancel {
                        height: 30px;
                        margin: 0;
                        width: 30px;
                        background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                        border: 0 none;
                        position: absolute;
                        text-indent: -999px;
                        overflow: hidden;
                        cursor: pointer;
                        right: 3px;
                        box-shadow: none;
                        top: 5px; // &:focus, &:active, &:hover{
                        //     background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                        // }
                    }

                    .share-details {
                        padding: 0;
                    }
                }

                .hide-save-search-form {
                    width: 0;
                    overflow: hidden;
                    opacity: 0;
                    top: -9px;
                    position: absolute;
                }

                .label-src-form-group {
                    .input-group {
                        right: 35px;

                        .Select {
                            width: 100%;
                        }
                    }

                    .btn-share {
                        padding: 8px 20px;
                    }
                }

                .team-member-name {
                    position: relative;
                    background: $grey;
                    color: $black;
                    font-weight: 600;
                    padding: 5px 0;
                    border-radius: 50%;
                    z-index: 1;
                    margin-left: 5px;
                    height: 30px;
                    width: 30px;
                    display: block;
                    text-align: center;
                    font-size: 13px;

                    &.team-ss-icon {
                        &::after {
                            position: absolute;
                            content: "";
                            top: -5px;
                            right: -5px;
                            height: 20px;
                            width: 20px;
                            background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                            @include border-radius(50px);
                        }
                    }
                }

                .share-everyone {
                    // position: relative;
                    margin-left: 0;
                    height: 30px;
                    width: 30px;
                    margin-right: 0px;
                    display: inline-block;
                    
                    &::after {
                        position: absolute;
                        content: "";
                        top: 0;
                        right: 0;
                        height: 30px;
                        width: 30px;
                        background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                        @include border-radius(50px);
                    }
                }

                .all-team-member {
                    color: $cerulean;
                    padding: 0 10px;
                    text-decoration: underline;
                    font-size: .75rem;
                    font-weight: 600;
                    cursor: pointer;
                    //width: 70px;
                    display: inline-block;
                }

                .more-team-member {
                    position: relative;
                    background: $grey;
                    color: $black;
                    font-weight: 600;
                    padding: 5px 0;
                    border-radius: 50%;
                    z-index: 1;
                    font-size: 15px;
                    height: 30px;
                    width: 30px;
                    display: inline-block;
                    text-align: center;
                    margin-left: 5px;
                }

                .shared-user-blocks {
                    // display: inline-block;
                    width: 35px;
                    float: left;
                    // margin-right: 5px;
                    top: 2px;
                    position: relative;

                    .team-icon {
                        position: relative;
                        display: block;
                        margin: 0 0 0 5px;

                        &:before {
                            content: "";
                            width: 30px;
                            height: 30px;
                            background: $transparent url($iconsprite) no-repeat scroll -120px -150px;
                            left: 0px;
                            position: absolute;
                            background-color: $grey;
                            border-radius: 50%;
                            top: -21px;
                        }
                    }
                }
            }

            .show-label-list-wrapper {
                z-index: 99;
                position: absolute;
                background-color: $white;
                width: 500px;
                float: right;
                top: 60px;
                right: 65px;

                &:before {
                    // bottom: 100%;
                    // left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: rgba(194, 225, 245, 0);
                    border-bottom-color: $white;
                    border-width: 20px; // margin-left: 200px;
                    right: 30px;
                    z-index: 999;
                    top: -30px;
                    float: right;
                }

                .label-container {
                    overflow: auto;
                    position: absolute;
                    background-color: $white;
                    //border: 1px solid $grey;
                    @include box-shadow(0, 2px, 8px, rgba(0, 0, 0, 0.2));
                    border-top: 0 none;
                    width: inherit;
                    max-height: 220px;

                    .users-list {
                        padding: 0;
                        .delete-button.btn.btn-secondary {
                            background: $transparent none repeat scroll 0 0;
                            border: 0 none;
                            color: $white;
                            font-size: 0.7rem;
                            transition: none;
                            position: absolute;
                            cursor: pointer;
                            line-height: 1.35em;
                            height: 22px;
                            width: 22px;
                            margin: 0;
                            text-indent: -9999px;
                            left: 6px;
                            top: 6px;
        
                            &:focus {
                                left: 7px;
                                top: 7px;
                                transition: none;
                                position: absolute;
                                border-radius: 50%;
                                background-color: $white;
                                width: 22px;
                                height: 22px;
                                padding: 6px 0;
        
                                &:before {
                                    content: "";
                                    width: 30px;
                                    height: 30px;
                                    background: $transparent url($iconsprite) no-repeat scroll -180px -90px;
                                    left: -4px;
                                    top: -4px;
                                    position: absolute;
                                }
                            }
                        }
                        .user-block {
                            display: inherit;
                            padding: 5px 10px;
                            position: relative;

                            .user-profile-icon {
                                background-color: $light-grey;
                                border-radius: 50%;
                                padding: 2px 0;
                                margin-right: 10px;
                                font-size: 16px;
                                height: 35px;
                                font-weight: 600;
                                border: 2px solid $white;
                                width: 35px;
                                display: inline-block;
                                text-align: center;
                            }

                            .team-name-class {
                                position: absolute;
                                top: 2px;
                            }

                            .team-icon {
                                background-color: $light-grey;
                                border-radius: 50%;
                                padding: 10px 0;
                                margin-right: 7px;
                                height: 35px;
                                font-weight: 700;
                                border: 2px solid $white;
                                width: 35px;
                                display: inline-flex;
                                text-align: center;

                                &:before {
                                    content: "";
                                    width: 30px;
                                    height: 30px;
                                    background: transparent url($iconsprite) no-repeat scroll -120px -150px;
                                    left: 12px;
                                    top: 9px;
                                    position: absolute;
                                }
                            }

                            .team-member-name {
                                position: relative;
                                background: $grey;
                                color: $black;
                                font-weight: 600;
                                padding: 5px 0;
                                border-radius: 50%;
                                z-index: 1;
                                font-size: 13px;
                                height: 30px;
                                width: 30px;
                                display: inline-block;
                                text-align: center;
                                margin: 0 10px 0 2px;

                                &.team-ss-icon {
                                    &::after {
                                        position: absolute;
                                        content: "";
                                        top: -5px;
                                        right: -5px;
                                        height: 20px;
                                        width: 20px;
                                        background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                                        @include border-radius(50px);
                                    }
                                }
                            }

                            .share-everyone {
                                position: relative;
                                margin-left: 0;
                                height: 30px;
                                width: 30px;
                                margin-right: 0px;
                                display: inline-block;

                                &::after {
                                    position: absolute;
                                    content: "";
                                    top: 0;
                                    right: 0;
                                    height: 30px;
                                    width: 30px;
                                    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                                    @include border-radius(50px);
                                }
                            }

                            .name-class {
                                top: 5px;
                                position: absolute;
                                float: left;
                            }

                            
                        }

                        .email-address {
                            font-size: 0.75rem;
                            margin: -5px 0 0 0;
                            font-weight: 400;
                        }

                        .delete-button.btn.btn-secondary {
                            background: transparent none repeat scroll 0 0;
                            border: 0 none;
                            color: $white;
                            font-size: 0.75rem;
                            position: relative;
                            cursor: pointer;
                            line-height: 1.35em;
                            height: 30px;
                            width: 30px;
                            margin-top: 0;
                            text-indent: -9999px;
                            float: right;

                            &:after {
                                content: "";
                                width: 30px;
                                height: 30px;
                                background: transparent url($iconsprite) no-repeat scroll -180px -90px;
                                left: 0;
                                top: 0;
                                position: absolute;
                            }
                        }

                        .share-date {
                            float: right;
                            width: 100px;
                            font-size: 0.75rem;
                            margin-top: 6px;
                            position: relative;
                        }
                    }
                }
            }
        }
    }

    .page-title-bar {
        margin: 0;
        background: #fff;
        padding-top: 10px;
        &.map-view-header{
            width: 450px;
        }
        .project-build-phase-pro-flag{
            display: inline-block !important;
        }
        // background: $white;
        // padding: 10px 0;
        div:first-child {
            display: block;
        }
    }

    .my-leads-fav {
        .fav-unavailable {
            top: 0;
            position: absolute;
            left: 55px;
        }

        .py-3 {
            left: 0;
            padding: 20px;
        }
    }

    /*myleads listview*/
    .gridSearchView {
        .result-list-wrapper {
            .searchlist-wrapper{
                height: calc(100vh - 242px);
                .project-container{
                    padding-left: 0;
                }
                .update-date {
                    margin: 10px 0 5px;
                    display: block;
                    font-weight: 600;
                    font-size: 1rem;
                }
            }
            // padding-top: 0;
            .selector-container {
                padding-top: 8px;
            }
        }
    }

    .maplist-container {
        margin-right: 0 !important;
        top: -4px;
        left: auto;
        right: 0;
        float: right;
        position: absolute;
    }

    .my-favourite-wrapper {
        .search-result {
            &.list-view-panel {
                .searchList-header {
                    background: none;
                    width: 100%;

                    .select-drop-count,
                    .select-actions {
                        top: 12px;
                    }

                    .myLeaderTypeHeader {
                        border-bottom: none;
                    }

                    .dropdown {
                        width: 135px;
                        display: inline-block;
                        border: $alto solid 1px;
                        top: 0px;
                        height: auto;
                        margin-top: 2px;
                        &.shift{
                            margin-left: 60px;
                            margin-top: 0px;

                            .btn {
                                left: 0;
                            }
                        }
                    }

                    .result-count {
                        position: static;
                        padding-left: 0;
                        vertical-align: middle;
                        padding-top: 0;
                    }

                    .tagNameCntr {
                        margin-right: 70px !important;
                        margin-top: 8px;
                    }
                }

                ul.list-group {
                    top: 290px;
                }

                .history-list {
                    ul.list-group {
                        .list-block:first-child {
                            .update-date {
                                margin-top: 5px;
                            }
                        }
                        .list-block{
                            .update-date {
                                display: none;
                            }
                        }
                    }
                }

                .content-panel {
                    padding-right: 10px;
                }

                .result-wrapper {
                    width: calc(100% - 65px);
                }

                .listviewtable-block.leftright-bothspace {
                    margin-left: 0;
                }

                .label-back>p {
                    width: 100%;
                }

                .label-back .label-member-list {
                    top: 4px;
                }

                &.mytagTab {
                    .searchList-header {
                        left: 0;
                    }
                }
                .myLeadsShareViewList {
                    .team-member-list {
                        float: none;
                        margin-left: 30px;
                        &:after{content: ''; clear: both; float: none; display: block;}
                    }
                }
                .selector-container{
                    .search-selector{margin-top: 5px;}
                }
                .linkedin-list-view{left: 24%;}
                .projectCount-linkedin-view{left: 22.5%;}
            
            }

            &.myleads-map-view {
                .top-10 {
                    .select-drop-count {
                        top: 11px;
                    }

                    &.withUpdateLabelTab {
                        .selector-container {
                            padding-top: 3px;
                        }
                    }
                }

                .gridSearchView {
                    .favourite-panel-scrollbar {
                        margin-top: 10px;
                    }

                    .result-list-wrapper {
                        padding-top: 0;
                    }
                }

               
            }
        }

        &.label-list-wrapper {
            .selector-container {
                padding-bottom: 7px;
            }
        }
    }

    /******** Tags */

    .tag-tab {
        .searchBox {
            margin: 0 15px 22px 15px;
            .textfield-wrapper {
                border-left: 1px solid $alto;
            }
            .search-icon {
                display: inline-block;
                stroke: currentcolor;
                fill: currentcolor;
                width: 30px;
                height: 30px;
                left: 1px;
                position: absolute;
                top: 2px;
                z-index: 1;
            }
            .textfield-wrapper input[type=text] {
                border: 0;
                width: 100%;
                padding-left: 20px;
                outline-offset: 0;
                &:focus-visible {
                    outline: none;
                    border: 0;
                }
            }
            .clear-textfield {
                top: 2px;
                &:hover {
                    top: 2px; 
                }
            }
        }
        
        .tag-heading {
            display: block;
            left: -10px;
            margin: 0px 15px 0px 35px;
            position: relative;
            font-size: 1.1em;
            font-weight: 700;
        }

        .list-wrapper {
            background: $white;
            left: -10px;
            position: relative;
            padding: 10px 15px;
            margin: 0px 15px 10px 35px;
            display: block;

                .labels-list-wrapper {
                    display: inline-block;
                    position: relative;

                    &:hover {
                        .remove-label {
                            display: block;
                        }
                    }

                    .remove-label {
                        display: none;
                        left: 10px;
                        top: 7px;
                        transition: none;
                        position: absolute;
                        border-radius: 50%;
                        background-color: $white;
                        width: 22px;
                        height: 22px;
                        padding: 6px 0;
                        cursor: pointer;
                        &:before {
                            content: "";
                            width: 30px;
                            height: 30px;
                            background: transparent url($iconsprite) no-repeat scroll -180px -90px;
                            left: -4px;
                            top: -4px;
                            position: absolute;
                        }
                    }

                    a {
                        padding: 3px 10px 3px 10px;
                        color: $abi-blue;
                        border-radius: 3px;
                        margin: 5px;
                        display: inline-block;
                        font-weight: 600;
                        cursor: pointer;
                        position: relative;
                        min-height: 26px;
                        font-size: .75rem;
                        min-width: 52px;
                       
                    }
                    .labels-list {
                        border: 1px solid $alice-blue;
                        background-color: $alice-blue;

                    }
                    .shared-labels-list {
                        border: 1px solid $link-water-new;
                        background-color: $link-water-new;
                    }
                    .delete-cancel {
                        .show {
                            position: absolute;
                            top: 6px;
                            z-index: 999;
                            left: 6px;
                            display: inline-flex;
                            background: $white;
                            border-radius: 3px;
                            padding-top: 3px;
                            color: $dark-grey;
                            .btn-label-cancel {
                                background: transparent none repeat scroll 0 0;
                                border: 0;
                                font-size: .7rem;
                                font-family: arial;
                                cursor: pointer;
                                padding: 4px 4px 4px 2px;
                                text-decoration: none;
                                color: $green;
                            }
                            .btn-label-delete {
                                background: transparent none repeat scroll 0 0;
                                border: 0;
                                font-size: .7rem;
                                font-family: arial;
                                cursor: pointer;
                                padding: 4px 4px 4px 2px;
                                text-decoration: none;
                                color: $red;
                            }
                        }
                    }
                }
        }
    }
    .label-container {
        background: $white;
        -ms-box-shadow: 0 2px 8px rgba(0,0,0,.2);
        -o-box-shadow: 0 2px 8px rgba(0,0,0,.2);
        box-shadow: 0 2px 8px rgba(0,0,0,.2);
        display: inline-block;
        max-height: 220px;
        position: absolute;
        top: 140px;
        right: 41px;
        width: 480px;
        z-index: 4;
        overflow: auto;
        .users-list {
            padding: 0;
            position: relative;
            display: grid;
            &:nth-of-type(even) {
                background: $light-grey;
            }
            .user-block {
                display: flow-root;
                padding: 5px 10px;
                position: relative;
                .delete-container {
                    display: inline;

                    .share.show {
                        position: absolute;
                        top: 3px;
                        z-index: 999;
                        background: $white;
                        border-radius: 3px;
                        padding: 8px 5px;
                        color: $dark-grey;
                        right: 82px;
                        float: right;
                        border: 1px solid $grey;

                        .btn-label-cancel {
                            color: $green;
                        }

                        .btn-label-delete {
                            color: $red;
                        }

                        .btn-label-cancel,
                        .btn-label-delete {
                            background: $transparent none repeat scroll 0 0;
                            border: 0 none;
                            font-size: 0.7rem;
                            font-family: arial;
                            cursor: pointer;
                            padding: 4px 4px 4px 2px;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        span {
                            &:before {
                                content: "/";
                                position: relative;
                                right: 1px;
                                font-size: 0.5rem;
                            }
                        }
                    }
                }
                a.user-profile-icon {
                    background-color: $light-grey;
                    border-radius: 50%;
                    float: left;
                    padding: 2px 0;
                    margin-right: 10px;
                    font-size: 16px;
                    height: 35px;
                    font-weight: 600;
                    border: 2px solid $white;
                    width: 35px;
                    display: inline-block;
                    text-align: center;
                    color: inherit;
                }
                a.share-everyone {
                    position: absolute;
                    margin-left: 0;
                    height: 30px;
                    width: 30px;
                    margin-right: 0px;
                    display: inline-block;

                    &::after {
                        position: absolute;
                        content: "";
                        top: 0;
                        right: 0;
                        height: 30px;
                        width: 30px;
                        background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                        @include border-radius(50px);
                    }
                }
                a.team-member-name {
                    position: relative;
                    background: $grey;
                    color: $black;
                    font-weight: 600;
                    padding: 5px 0;
                    border-radius: 50%;
                    z-index: 1;
                    margin-left: -85px;
                    height: 30px;
                    width: 30px;
                    display: inline-block;
                    text-align: center;
                    font-size: 13px;

                    &.team-ss-icon {
                        &::after {
                            position: absolute;
                            content: "";
                            top: -5px;
                            right: -5px;
                            height: 20px;
                            width: 20px;
                            background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                            @include border-radius(50px);
                        }
                    }
                }
                .name-class {
                    float: left;
                }
                .team-name-class {
                    float: left;
                    margin-left: 35px;
                }
                .email-address {
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 0.75rem;
                    line-height: 1;
                }
                span.share-date {
                    float: right;
                    width: 80px;
                    font-size: .75rem;
                    margin-top: 6px;
                }
            }
        }
    }

    .note-list {
        .note-details-wrapper {
            position: fixed;
            right: -1px;
            left: 75px;
            top: 200px;
            bottom: 0;
            .note-block {
                cursor: pointer;
                background: $white;
                .more-actionsheet {
                    right: 20px;
                }
                .note-block-wrapper {
                    flex: 1 0 100%;
                }
                a {
                    color: $dark-black-gray;
                }
            }
        }
        .notes-unavailable {
            top: 60px;
            position: absolute;
            left: 15px;
        }
    }
    
    .updatesTxt {
        color: $black;
        display: block;
        font-weight: 700;
        padding-left: 5px;
        margin-top: -5px;
    }
    .top-10 {
        .page-title-bar {
            .top-5 {
                .myLeaderTypeHeader {
                    border-bottom: $alto solid 1px;
                }

                .search-selector {
                    &:after {
                        clear: both;
                        float: none;
                        display: block;
                        content: '';
                    }
                }

                .select-drop-count {
                    z-index: 999;
                    position: relative;
                    top: 7px;
                    left: 10px;
                    display: block;
                    background-color: $white;
                    float: left;
                    margin-left: 0;
                    margin-right: 10px;

                    button.active {
                        min-width: 145px;
                        width: auto;

                        // border-right: 0 none; 
                        &::before {
                            content: "";
                            position: absolute;
                            top: -1px;
                            left: 0;
                            width: 30px;
                            height: 30px;
                            background: $transparent url($iconsprite) no-repeat scroll 0 -270px;
                        }

                        &::after {
                            // right: 23px;                        
                        }
                    }

                    button {
                        position: relative;
                        background: none;
                        color: $black;
                        border: 1px solid $lighter-gray;
                        width: 50px;
                        height: 30px;
                        padding: 0;
                        margin: 0;
                        border-radius: 0;

                        p {
                            font-size: 0.9rem;
                            color: $abi-blue;
                            top: 4px;
                            position: inherit;
                            font-weight: 600;
                            text-align: left;
                            padding-left: 30px;
                            padding-top: 0;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            top: -1px;
                            left: 0;
                            width: 30px;
                            height: 30px;
                            background: $transparent url($iconsprite) no-repeat scroll -30px -270px;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 8px;
                            right: 2px;
                            width: 15px;
                            height: 15px;
                            border: 0;
                            background: $transparent url($iconsprite) no-repeat scroll -220px -75px;
                        }
                    }

                    .select-drop-list {
                        z-index: 100000;
                        left: 1px;
                        top: 30px;
                        margin: 0;
                        padding: 0;
                        border-radius: 0;
                        box-shadow: 1px 1px 5px $dove-grey;
                        border: 0 none;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                            top: -18px;
                            left: 0;
                            z-index: 1;
                            right: auto;
                        }

                        button {
                            position: relative;
                            display: block;
                            width: 100%;
                            padding-left: 30px;
                            border: none;
                            border-bottom: 1px solid $mercury;
                            font-size: 0.8rem;
                            font-weight: 400;
                            color: $black-grey;

                            &::before {
                                left: 0;
                            }

                            &::after {
                                background: none;
                            }

                            &:hover {
                                background-color: $mercury;
                            }
                        }
                    }
                }

                .select-actions {
                    position: relative;
                    top: 11px;
                    left: 10px;
                    display: inline-block;
                    border: 1px solid $lighter-gray;
                    right: 2px;
                    background: $white;
                    border-left: 0 none;
                    z-index: 1001;
                    top: 11px;
                    left: 0px;
                    float: left;

                    button {
                        color: $transparent;
                        background-color: $transparent;
                        background-image: none;
                        border-color: $transparent;
                        // text-indent: -999px;
                        overflow: hidden;
                        background: $transparent url($iconsprite) no-repeat scroll -210px -240px;
                        width: 30px;
                        height: 28px;
                        cursor: pointer;
                        border: 0;
                        z-index: 9999;
                        position: relative;
                    }

                    .action-list {
                        margin: 0;
                        padding: 0;
                        border-radius: 0;
                        box-shadow: 1px 1px 5px $dove-grey;
                        border: 0 none;
                        top: 35px;
                        right: 0;
                        width: 175px;
                        left: auto!important;

                        &:before {
                            content: "";
                            position: absolute;
                            width: 30px;
                            height: 30px;
                            background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                            top: -18px;
                            right: 0;
                            z-index: 1;
                            left: auto;
                        }

                        button {
                            color: $black-grey;
                            text-indent: 0;
                            background: none;
                            border-bottom: 1px solid $mercury;
                            width: 100%;
                            margin: 0;
                            padding: 5px 8px;
                            font-size: .9rem;
                            font-weight: 400;

                            &:last-child {
                                border-bottom: none;
                            }

                            &:hover {
                                background-color: $mercury;
                            }
                        }

                        .disabled {
                            color: $grey;
                            cursor: default;

                            &:hover {
                                background-color: $white;
                            }
                        }
                    }
                }

                .selector-container {
                    position: relative;
                    z-index: 99;

                    .tagNameCntr {
                        text-align: right;

                        span {
                            font-size: 12px;
                            color: $dove-grey;
                        }

                        .tagNameTxt {
                            color: $black;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }
    .my-archive-wrapper{
        .top-10 .page-title-bar .top-5 .right-save-col {
            right: 43px;
        }
        &.my-favourite-wrapper .searchList-header .projects-icon:before{
            top:2px;
        }
    }
    .sidebar-mini .show-half .my-leads-map-view .map-view .mapboxgl-map {
        left: 515px;
        top: 140px;
    }
    
    .main-label-wrapper {
        // position: fixed;
        // right: -1px;
        // // left: 50px;
        // // top: 210px;
        // // bottom: 0;
        // &.tag-default {
        //     top: 160px;
        // }
        .details-panel-scrollbar{
            height: calc(100vh - 220px);
            padding-right: 12px;
        }
    }
    
    .list-panel {
        .myleads-wrapper {
            background-color: $white;
            width: 100%;
            .searchlist-wrapper {
                height: calc(100vh - 230px);
            }
        } 
    }
    
    .objectType-dropdown {
        display: flex;
        &.gridViewHeader{
            width: 420px;
            justify-content: space-between;
        }
        .dropdown {
            button {
                background: $white;
                color: $blue;
                font-size: 1em;
                padding: 5px 25px 5px 10px;
                border: 1px solid $alto;
            }
    
            .dropdown-menu {
                padding: 10px 10px 15px;
            }
    
            .btn:active {
                background-color: $white;
                border-color: $alto;
                color: $blue;
            }
        }
    
        .result-count {
            position: relative;
            padding-left: 0;
            vertical-align: middle;
            padding-top: 0;
            top: 8px;
            right: 0;
            font-weight: 700;
            font-size: .85rem;
            margin-bottom: 0;
            left: 10px;
        }
    
    }
    
    .myLeaderTypeHeader {
        position: relative;
        z-index: 10;
        .back-to-tagDetails-button {
            margin: 0px 5px;
            padding: 5.5px; 
            &+{
                .gridViewHeader{
                    width: 340px;
                }
            }        
        }
        .tag-share {
            display: flex;
            position: absolute;
            right: 280px;
            top: -2px;
            background-color: $white;
            .shared-user-blocks {
                width: 35px;
                float: left;
                top: 2px;
                position: relative;
    
                .team-member-name {
                    position: relative;
                    background: $grey;
                    border: none;
                    color: $black;
                    font-weight: 600;
                    padding: 5px 0;
                    border-radius: 50%;
                    z-index: 1;
                    margin-left: 5px;
                    height: 30px;
                    width: 30px;
                    display: block;
                    text-align: center;
                    font-size: 13px;
                    &.team-ss-icon {
                        &::after {
                            position: absolute;
                            content: "";
                            top: -5px;
                            right: -5px;
                            height: 20px;
                            width: 20px;
                            background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                            @include border-radius(50px);
                        }
                    }
                }
            }

            .share-everyone {
                position: relative;
                margin-left: 5px;
                height: 30px;
                width: 30px;

                &::after {
                    position: absolute;
                    content: "";
                    top: 0;
                    right: 0;
                    height: 30px;
                    width: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                    @include border-radius(50px);
                }
            }
    
            .tag-icon {
                padding: 5px 3px;
                background: $white;
                border: 1px solid $grey;
                border-right: 0;
            }
            .remove-icon {
                padding: 7px 10px;
                background: $white;
                border: 1px solid $grey;
                cursor: pointer;
            }
            .all-team-member {
                color: $cerulean;
                padding: 0 10px;
                text-decoration: underline;
                font-size: .75rem;
                font-weight: 600;
                cursor: pointer;
                display: inline-block;
                line-height: 2.7;
            }
            .share-input-box {
                display: grid;
                border: none;
    
                .css-1dyz3mf {
                    padding: 2px 8px 2px 0;
                }
                .css-1p3m7a8-multiValue {
                    padding: 3px 0;
                }
                .list-user-team {
                    .user-dropdown-icon {
                        display: initial;
                        font-size: 10px;
                        margin-right: 5px;
                        padding: 2px 3px;
                    }
                    .users-teams-title {
                        float: none;
                        position: static;
                    }
                }
    
                .css-1p3m7a8-multiValue .list-user-team .users-teams-title .email-address {
                    display: none;
                }
            }
        }
            .share-select-dropdown {
    
                .css-13cymwt-control {
                    width: 430px;
                    border-left: 0;
                    padding-right: 0;
                }
                .css-t3ipsp-control {
                    width: 430px;
                    border-left: 0;
                    padding-right: 0;
                    box-shadow: none;
                }
                .css-hlgwow {
                    padding-left: 0;
                }
        }
    }
    
    .myleads-page .maplist-container {
        top: -3px;
        width: 130px;
    }
    
    .sort-results {
        position: absolute;
        right: 160px;
        top: 2px;
    
        .dropdown {
            .dropdown-toggle.btn {
                top: -5px;
                height: 38px;
                position: relative;
                right: -15px;
            }
        }
    }
    
    .maplist-container .listview {
        height: 38px !important;
        top: 0;
    }
    
    .nav-link {
        padding: 0.5em !important;
        color: #292b2c !important;
    }
    
    .tab-archive {
        .myLeaderTypeHeader {
            .sort-results {
                right: 20px;
            }
        }
    }
    
    .tab-notes {
        .objectType-dropdown {
            .dropdown button {
                padding: 11px 25px 11px 10px;
            }
        }
    }
    
    .tab-notes {
        .search-notes-container {
            position: relative;
    
            .clear-textfield {
                height: 30px;
                margin: 0;
                width: 30px;
                background: url($iconsprite) no-repeat scroll -180px -29px;
                border: 0;
                position: absolute;
                text-indent: -999px;
                overflow: hidden;
                right: 2px;
                top: 7px;
                cursor: pointer;
                z-index: 1003;
            }
        }
    }
    
    .btn-notes {
       &.primary-btn {
        padding: 10px 20px;
       }
    }
    
    #notesText {
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
        font-weight: 400;
        font-size: 1rem;
        padding-right: 30px;
    }
    
    .showmore-result {
        height: 70px;
        .btn-primary {
            height: 35px;
        }
    }
    
    .tagNameCntr {
        span {
            font-size: 12px;
            color: #666;
        }
        .tagNameTxt {
            color: #000;
            line-height: 12px;
            font-weight: 600;
        }
    }
       
    @media (max-width: 767px) {}
    
    
    
    @media (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 192dpi) {
    
        .myleads-page .label-list-wrapper .shared-labels-list a:before,
        .myleads-page .label-list-wrapper .textfield-wrapper .label-search:before,
        .myleads-page .label-list-wrapper .labels-list span a:before,
        .myleads-page .label-list-wrapper .shared-labels-list span a:before {
            background-image: url($retina-icon-sprite);
            background-size: 300px;
        }
    
        .myleads-page .label-list-wrapper .shared-labels-list a:before,
        .myleads-page .label-list-wrapper .labels-list span .delete-button.btn.btn-secondary:after,
        .myleads-page .label-list-wrapper .shared-labels-list span .delete-button.btn.btn-secondary:after,
        .myleads-page .result-list-container li.list-group-item .team-member-list .team-member-name.team-ss-icon:after {
            background-image: url($retina-icon-sprite);
            background-size: 300px;
        }
    }
}

.share-input-box{
    .email-address{
        text-decoration: none;
        color:$dark-grey;
    }
    .css-10wo9uf-option,.css-d7l1ni-option{
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 0.9rem;
    }
    .css-1p3m7a8-multiValue{
        .block-title.everyone-icon{
            margin-left: 23px;
            &:before{
            width: 15px;
            height: 15px;
            background-position: -68px -427px;
            left: -21px;
            }
            .select-header{
                padding: 0;
            }
        }
        .list-user-team{
            .team-ss-icon,.user-dropdown-icon{
                font-size: 0 !important;
                width: 12px;
                height: 12px;
                vertical-align: middle;
                margin:-4px 5px 0 2px;
            }
            .user-dropdown-icon{
                &::before{
                    content: "";
                    height: 20px;
                    left: -3px;
                    position: absolute;
                    top: 1px;
                    width: 20px;
                    background: url($iconsprite) no-repeat -5px -456px;;
                }
                
            }
            .users-teams-title{
                position: static;
                float: none;
                .email-address{
                    display: none;
                }
            }
            
        }
    }
}

.delete-container {
    display: inline;
    .btn-label-cancel {
        color: $green;
    }

    .btn-label-delete {
        color: $red;
    }

    .delete-button.btn-secondary {
        background: transparent none repeat scroll 0 0;
        border: 0 none;
        color: $white;
        position: relative;
        cursor: pointer;
        line-height: 1.35em;
        height: 30px;
        width: 30px;
        margin-top: 0;
        text-indent: -9999px;

        &:after {
            content: "";
            width: 30px;
            height: 30px;
            background: transparent url($iconsprite) no-repeat scroll -180px -90px;
            left: 0;
            top: 0;
            position: absolute;
        }
    }

    span {
        &:before {
            content: "/";
            position: relative;
            right: 1px;
            font-size: 0.5rem;
        }
    }
    .share.show {
        position: absolute;
        top: 3px;
        z-index: 999;
        background: $white;
        border-radius: 3px;
        padding: 8px 5px;
        color: $dark-grey;
        right: 82px;
        float: right;
        border: 1px solid $grey;

        
    }
}