body {
  background: $whisper;
  overflow: hidden;
}

button:focus {
  // box-shadow: none !important;
  outline: none;
}

a{
  text-decoration: none;
  &:hover, &:active{
    text-decoration: none;
  }
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.outline-red-btn {
  background: $white;
  color: $abi-red;
  border: 1px solid $abi-red;
  cursor: pointer;
  padding: 6px 20px;
  font-weight: 500;

  &:hover,
  &:after,
  &:focus {
    background: $white;
    color: $abi-red;
    border: 1px solid $abi-red;
    box-shadow: 0 0 5px rgba(237, 28, 36, 0.35)
  }

  &:disabled {
    background: $white;
    color: $abi-red;
    border: 1px solid $abi-red;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.outline-btn {
  background: $white;
  color: $abi-blue;
  border: 1px solid $abi-blue;
  cursor: pointer;
  padding: 6px 20px;
  font-weight: 500;
  &.save-search-button{
    font-size: 0.85rem;
    height: 36px;
    border-radius: 3px;
  }

  &:hover,
  &:after,
  &:focus {
    background: $white;
    color: $abi-blue;
    border: 1px solid $abi-blue;
    box-shadow: $abi-blue 0 0 5px;
  }

  &:disabled {
    background: $white;
    color: $abi-blue;
    border: 1px solid $abi-blue;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.outline-cta-btn {
  background: $white;
  color: $cerulean;
  border: 1px solid $cerulean;
  cursor: pointer;
  padding: 6px 20px;
  font-weight: 500;

  &:hover,
  &:after,
  &:focus {
    background: $white;
    color: $cerulean;
    border: 1px solid $cerulean;
    box-shadow: $cerulean 0 0 5px;
  }

  &:disabled {
    background: $white;
    color: $cerulean;
    border: 1px solid $cerulean;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.primary-cta-btn {
  background: $cerulean !important;
  color: $white !important;
  border: 1px solid $cerulean !important;
  cursor: pointer !important;
  padding: 6px 20px !important;
  font-weight: 500 !important;
  border-radius: 0 !important;

  &:hover,
  &:after,
  &:focus {
    background: $cerulean;
    color: $white;
    border: 1px solid $cerulean;
    box-shadow: $cerulean 0 0 5px;
  }

  &:disabled {
    background: $cerulean;
    color: $white;
    border: 1px solid $cerulean;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.primary-btn {
  background: $abi-blue;
  color: $white;
  border: 1px solid $abi-blue;
  cursor: pointer;
  padding: 6px 20px;
  font-weight: 500;

  &:hover,
  &:after,
  &:focus {
    background: $abi-blue;
    color: $white;
    box-shadow: $abi-blue 0 0 5px;
    border: 1px solid $abi-blue;
  }

  &:disabled {
    background: $abi-blue;
    color: $white;
    opacity: 0.5;
    border: 1px solid $abi-blue;
    cursor: not-allowed;
  }
}

.btn-block{
  width:100%;
  display: block;
}

.user-ctrl-btn {
  background-color: $white;
  border: 1px solid $alto;
  color: $emperor-gray;
  cursor: pointer;
  font-weight: 500;
  display: flex;
  &.label-favourite-icon{
    background-color: transparent;
    border: none;
    &:hover,
    &:after,
    &:focus {
      background-color: transparent;
      box-shadow: none;
    }
  }
  &:hover,
  &:after,
  &:focus {
    background-color: $light-blue;
    color: $emperor-gray;
    border: 1px solid $alto;
  }

  &:disabled {
    background: $white;
    color: $emperor-gray;
    border: 1px solid $alto;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

#intercom-container .intercom-messenger-frame,
#intercom-positioner-tree .intercom-messenger-frame {
  bottom: 10px !important;
}

.hide-temporary {
  display: none !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  display: none;
}

.show {
  display: block;
}

.team-name-class {
  margin-top: 5px;
}

.hide {
  display: none !important;
}

.opacity50 {
  @include opacity(0.5);
}

button {
  -webkit-appearance: none;
}

.dropdown-menu {
  min-width: 8rem;
}

.pl-2, .px-2 {
  padding-left: 0.5rem!important;
}

.text-left {
  text-align: left!important;
}

.box-view {
  border: 1px solid $alto;
  position: relative;

  .nav-col {
    border-right: 1px solid $alto;

    .select-col {
      background: $alto;
      margin: 0;
      padding: 10px 15px;
      line-height: 1em;
      display: block;
      align-items: center;
      font-size: 14px;
    }
  }
  .second-col{
    border-right: 1px solid $alto;
    .selected-col {
      background: $mercury;
    }
    .search-selector{
      margin-bottom: 10px;
    }
  }
  .selected-col {
    background: $gallery;
    margin: 0;
    padding: 10px 15px;
    line-height: 1em;
    display: block;
    align-items: center;
    font-size: 14px;
    position: relative;

    .other-pro-sort {
      position: absolute;
      right: 38px;
      top: auto;
      bottom: -45px;
      width: 185px;
      display: -webkit-inline-box;

      .dropdown {
        margin-left: 10px;
      }
    }
  }

  .list-wrapper-other-pro {
    margin-top: 60px;
    min-height: 350px;
  }

  .list-wrapper-other-pro.placeholder {
    margin-top: -52px;

    .selector-placeholder {
      width: 100%;
      height: 32px;
      padding: 0px 15px;

      .select-control {
        width: 40px;
        display: inline-block;
        animation: j 1s infinite;
        border-radius: 24px;
        min-height: 25px;
        margin: 0 0 5px;
      }

      .sort-control {
        width: 130px;
        display: inline-block;
        animation: j 1s infinite;
        border-radius: 24px;
        min-height: 25px;
        margin: 0 0 5px;
        float: right;
      }
    }
  }

  .works-with-company-tab {
    .list-wrapper-other-pro {
      margin-top: -10px;
    }

    .list-wrapper-other-pro.placeholder {
      margin-top: 8px;
    }
  }
  &.works-with-companies{
    .nav-col {
      border-right: 0 none;
    }
  }
  &.company-project-list {
    
    .nav-col {
      border-right: 0 none;
    }
    .list-wrapper-other-pro {
      .list-wrapper-other-pro {
        margin-top: 0px;
      }
    }
    .list-wrapper-other-pro.placeholder {
      margin-top: 8px;
    }
  }
}

.share.show {
  position: relative;
  top: 6px;
  z-index: 999;
  background: $white;
  border-radius: 3px;
  padding-top: 3px;
  color: $dark-grey;
  right: -23px;
  float: right;

  .btn-label-cancel {
    color: $green;
  }

  .btn-label-delete {
    color: $red;
  }

  .btn-label-cancel,
  .btn-label-delete {
    background: $transparent none repeat scroll 0 0;
    border: 0 none;
    font-size: 0.7rem;
    cursor: pointer;
    padding: 4px 4px 4px 2px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    &:before {
      content: "/";
      position: relative;
      right: 1px;
      font-size: 0.5rem;
    }
  }
}

.btn-form-cta {
  font-size: 0.85rem;
  position: relative;
  line-height: 1.5em;
  margin: 0;
  left: -5px;
  margin-left: 5px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
}

// react-select drowndown
.Select-menu-outer {
  border: 1px solid $grey;
  box-shadow: 0 3px 10px $dark-grey;
  z-index: 99999;
}

.Select-option {
  padding: 2px 5px;
  border-bottom: 1px solid $light-grey;
}

.Select-option:last-child {
  border-bottom: 0 none;
}

.block-title .team-dropdown-icon {
  background-color: inherit;
  border-radius: 0;
  border: 0 none;
  margin-left: -40px;
  position: relative;

  &:before {
    background: none;
  }
}

.label-member-list {
  .team-name-class {
    &.users-teams-title {
      display: inline-block;
      padding: 2px 8px 8px 40px;
      margin: 0;
      line-height: 1.3em;
    }
  }
}

.label-project-list {
  .share-details-wrapper {
    .block-title {
      .team-member-name {
        &.team-ss-icon {
          display: none;
        }
      }
    }

    .team-name-class {
      &.users-teams-title {
        display: inline-block;
        padding: 5px 8px 8px 45px;
      }
    }

    .list-user-team {
      .team-member-name {
        &.team-ss-icon {
          padding: 5px;
          height: 35px;
          width: 35px;
          margin: 0;
          display: block;
        }
      }
    }
  }
}

.list-user-team {
  display: block;
  position: relative;

  .users-teams-title {
    top: 2px;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500;

    .email-address {
      font-size: 0.6rem;
      font-weight: 400;
      display: block;
    }
  }

  .team-ss-icon {
    background-color: $grey;
    border-radius: 50%;
    padding: 2px 0;
    margin-right: 10px;
    font-size: 16px;
    height: 35px;
    font-weight: 600;
    border: 2px solid $grey;
    width: 35px;
    display: inline-block;
    text-align: center;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      top: -5px;
      right: -5px;
      height: 20px;
      width: 20px;
      background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
      @include border-radius(50px);
    }
  }
}

.block-title {
  cursor: default;
  position: relative;
  display: block;

  .select-header {
    font-weight: 600;
    padding: 5px;
    display: block;
  }

  .team-member-name.team-ss-icon {
    display: none;
  }
}

.everyone-icon {
  margin-left: 30px;

  &::before {
    position: absolute;
    content: "";
    top: 2px;
    left: -28px;
    height: 30px;
    width: 30px;
    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
    @include border-radius(50px);
  }
}

.share-everyone {
  margin-left: 5px;
  width: 30px;

  &::before {
    position: absolute;
    content: "";
    height: 30px;
    width: 30px;
    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
    @include border-radius(50px);
  }
}

.user-dropdown-icon {
  background-color: $grey;
  color: $black;
  border-radius: 50%;
  padding: 2px 0;
  margin-right: 10px;
  font-size: 16px;
  height: 35px;
  font-weight: 600;
  border: 2px solid $grey;
  width: 35px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
}

.team-dropdown-icon {
  background-color: $light-grey;
  border-radius: 50%;
  padding: 10px 0;
  margin-right: 7px;
  height: 35px;
  border: 2px solid $white;
  width: 35px;
  display: inline-flex;
  text-align: center;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;

  
}



.label-sharing-cta {
  // position: absolute;
  // left: 143px;
    z-index: 1;
    justify-content: flex-end;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start !important;
      .detail-action-buttons{
        justify-content: flex-start !important;
        margin-top: 10px;
      }
    }
    
  .print-btn {
    margin-left: 0;
    padding: 3px 5px;
    margin: 0 -1px 0 0;
    height: 32px;
    font-size: 14px;
    align-items:center;
    line-height: 32px;
    cursor: pointer;
    @media only screen and (max-width: 1024px) {
      font-size: 12px;
    }
  }

  .label-count-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 45px;
      color: $black;
      text-decoration: none;
    }

  }

  .label-calendar-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }

    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 45px;
      text-decoration: none;
      color: $black;
      cursor: pointer;
    }

  
  }
  

  .mail-date-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0 5px;
    display:flex;
    align-items: center;
    font-size: 0.8rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: default;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 3px 5px 0 30px;
      display: block;
      font-size: 14px;
      font-weight: 400;
      cursor: default;
      
      &:before {
        background: $transparent url(#{$iconsprite}) no-repeat scroll -178px -450px;
        content: "";
        position: absolute;
        left: 0;
        width: 30px;
        height: 32px;
        top: 0;
        @media (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
            background: $transparent  url($retina-icon-sprite) no-repeat scroll -178px -450px;
            background-size: 300px;
            height:28px;
        }
      }
    }
  }

  .share-count-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 45px;
      text-decoration: none;
      color: $black;
      cursor: pointer;
    }
  }

  .notes-count-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 45px;
      color:$black;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .label-favourite-icon {
    position: relative;
    color: $black;
    background-color: $white;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    padding: 0 3px;
    width: 35px;
    justify-content: center;
    align-items: center;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
  }

  .archive-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 38px;
      text-decoration: none;
      color: $black;
      cursor: pointer;
    }
  }

  .unarchive-icon {
    position: relative;
    color: $black;
    background-color: $white;
    padding: 0;
    font-size: 0.7rem;
    font-weight: 500;
    margin: 0 -1px 0 0;
    cursor: pointer;
    height: 32px;
    border: 1px solid $alto;
    z-index: 1;
    &:hover,&:active,&:focus{
      background-color: $light-blue;
    }
    a {
      padding: 0 3px 0 2px;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      justify-content: space-evenly;
      align-items: center;
      justify-self: baseline;
      width: 38px;
      text-decoration: none;
      color: $black;
      cursor: pointer;

    }
  }
}

.archive-patch {
  background-color: $warning-bg;
  margin: 30px 0 10px 0;
  padding: 20px 70px;
  position: relative;
  font-weight: 600;
  border-top:1px solid $warning-border;
  border-bottom:1px solid $warning-border;

  &::before {
      content: "";
      width: 30px;
      height: 30px;
      background: transparent url($iconsprite) no-repeat scroll -210px -360px;
      left: 30px;
      top: 15px;
      position: absolute;
      @media (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
            background:transparent url($retina-icon-sprite) no-repeat scroll  -210px -360px;
            background-size: 300px;
        }
  }
}

.archive-footer {
  border: none;
  margin-bottom: 25px;
}

.main-content {
  padding: 57px 0 0 10px;
}

.circle {
  background: $bright-green;
  border-radius: 50%;
  padding: 7px 15px;
  font-weight: 700;
}

.react-mapbox-ac-suggestion:hover {
  background-color: $Selago;
}

.navigate-icon,
.share-icon,
.addcontact-icon,
.result-list-container .navigate-icon,
.result-list-container .share-icon,
.result-list-container .addcontact-icon {
  display: none;
}

.main-title-container {
  background: $white;
}

.main-container {
  .details-page {
    margin-top: 0;

    .feedback-cta {
      position: relative;
      // right: 205px;
      z-index: 1;

      .feedback-panel {
        position: absolute;
        width: 300px;
        top: 30px;
        right: 0;
        background: $white;
        box-shadow: 0 3px 10px $dark-grey;
        padding: 10px;

        &::before {
          background: $transparent url(#{$iconsprite}) no-repeat scroll -60px -270px;
          content: "";
          position: absolute;
          right: 0;
          width: 30px;
          height: 30px;
          top: -19px;
        }

        .text-area-counter {
          font-size: 0.75rem;
          position: absolute;
          bottom: -15px;
          right: 0;
        }

        .btn-send {
          font-size: 0.85rem;
          position: relative;
          line-height: 1em;
          margin: 0;
          left: -5px;
          top: 3px;
          margin-left: 5px;
        }

        .btn-feedback-cancel {
          font-size: 0.85rem;
          position: relative;
          line-height: 1em;
          top: 3px;
        }

        .note-list-wrapper {
          .note-form {
            .note-input {
              width: 100%;

              textarea {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .company-details {
    .location-name-distance {
      top: 159px;
    }
    .top-info {
      position: relative;
    }
    .company-overview {
        bottom: 0;
        min-height: 50px;
        position: relative;
        background: rgba(255, 255, 255, 0.85);
        padding: 5px 0 0 50px;
        left: 0;
        right: 0;
        display: block;
        .contacts-company, .contacts-subheader{
            font-size: 1rem;
        }
        .contacts-company{
            position: relative;
            padding-left: 20px;
            &:before{
                content: "";
                width: 30px;
                height: 30px;
                background: $transparent url($iconsprite) no-repeat scroll -120px -360px;
                left: -8px;
                position: absolute;
                top: -6px;
            }
            .company-redirection{
                cursor: pointer;
            }
        }
    }
  }
}

.details-page {
  .box {
    padding: 0 15px 0 17px;
  }
}

.people-empty-message {
  position: relative;
      // right: 205px;
  z-index: 1;
  height: 365px;
  .company-enquiry-link {
    text-decoration: underline;
    cursor: pointer;
  }
  .feedback-panel {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    left: 120px;
    right: 0;
    top: 43px;
    background: $white;
    box-shadow: 0 3px 10px $dark-grey;
    padding: 10px;
    z-index: 999;
  
    &::before {
      background: $transparent url(#{$iconsprite}) no-repeat scroll -60px -270px;
      content: "";
      position: absolute;
      right: 0;
      width: 30px;
      height: 30px;
      top: -19px;
    }
  
    .text-area-counter {
      font-size: 0.75rem;
      position: absolute;
      bottom: -15px;
      right: 0;
    }
  
    .btn-send {
      font-size: 0.85rem;
      position: relative;
      line-height: 1em;
      margin: 0;
      left: -5px;
      top: 3px;
      margin-left: 5px;
    }
  
    .btn-feedback-cancel {
      font-size: 0.85rem;
      position: relative;
      line-height: 1em;
      top: 3px;
    }
  
    .note-list-wrapper {
      .note-form {
        .note-input {
          width: 100%;
  
          textarea {
            width: 100%;
          }
        }
      }
    }
  }
  .empty-people-image {
    position: relative;
    width: 285px;
    margin: auto;
    padding-top: 240px;
    background-size: 100% auto;
    background-image: url($people-empty);
    background-repeat: no-repeat
  }
}


.dp-nav-buttons {
  padding: 0;
  position: relative;
}

.dp-nav-buttons-wrapper.row {
  width: 100%;
  margin: 0;
}


.back-to-resultpage {

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: $white;
    display: table;
    font-size: 0.75rem;
    margin: 0 0 0 15px;
    padding: 7px 0 5px 40px;
    line-height: 1.4em;
    background: $transparent url(#{$iconsprite}) no-repeat scroll 0px -240px;
  }
}

.feedback-btn {
  font-size: 0.85rem;
  position: relative;
  line-height: 1em;
  height: 32px;
  justify-content: center;
  align-items: center;
}

.dp-next-button{
  margin-left: -1px;
}

.back-to-resultpage-button{
  margin: 0 10px 0 0;
}

.back-to-resultpage-button, .dp-prev-button, .dp-next-button {
  font-size: 0.85rem;
  position: relative;
  line-height: 1em;
  height: 30px;
  padding-bottom: 2px;
  justify-content: center;
  align-items: center;
}

.dp-prev-button{
  .flip-vertically{
    transform: rotate(180deg);
  }
}

.br-1 {
  border-right: 1px solid $grey;
}

.src-location-placeholder,
.hint-label,
.show-stages-label,
.show-month-label,
.src-date {
  color: $dove-grey;
  line-height: 1rem;
}

.new-pro-flag {
  background: $cerulean;
  padding: 0 5px;
  border-radius: 3px;
  font-size: .7rem;
  color: $white;
  display: inline-block;
  position: relative;
  margin: 0 0 5px;
  right: 0px;
  // bottom: 1px;
}

.project-build-phase-pro-flag {
  background: $dark-green;
  padding: 0 5px;
  border-radius: 3px;
  font-size: .7rem;
  color: $white;
  display: inline-block;
  position: relative;
  margin: 0px 3px;
}

.archive-badge-flag {
  background: $boulder;
  padding: 1px 5px;
  border-radius: 3px;
  font-size: .7rem;
  color: $white;
  display: inline;
  position: relative;
  margin: 0px 6px;
}

.on-hold-flag{
  background: #F04046;
}

.list-flag {
  padding: 0;
}

.listviewtable-block {

  .new-pro-flag,
  .project-build-phase-pro-flag,
  .archive-badge-flag {
    float: none;
    position: static;
    margin-bottom: 0;
    display: inline-block;
    margin: 0 auto;
  }
  .list-flag.w-5{text-align: center;}
}

.share-details {
  padding: 1rem;

  &.label-list {
    .Select--multi {
      .Select-control {
        .Select-multi-value-wrapper {
          .Select-value {
            .Select-value-label {
              line-height: 1.2rem;

              &:before {
                background: $transparent url($iconsprite) no-repeat scroll -180px -180px;
                content: "";
                height: 30px;
                width: 30px;
                position: absolute;
                left: -3px;
                top: -3px;
              }
            }
          }
        }
      }
    }
  }

  .label-block {
    background: $white;
    padding: 5px;
    margin: 0;
    position: relative;

    &:hover {
      border: 0;
      margin: 0;
      background: $grey;
    }

    span {
      display: block;
      padding: 5px;
      margin: 0;
      font-size: 0.85rem;
    }
  }

  .shared-label-block {
    background: $link-water-new;
    padding: 5px;
    margin: 0;
    position: relative;

    &:hover {
      border: 0;
      margin: 0;
      background: $grey;
    }

    span {
      display: block;
      padding: 5px;
      margin: 0;
    }
  }

  .label-details-wrapper {
    margin: 10px 0 0;

    span.shared-labels-list-wrapper {
      display: inline-block;
      position: relative;

      .remove-label {
        display: none;
      }

      &:hover {
        .remove-label {
          display: block;
          position: absolute;
          left: 0;
          text-align: center;
          width: 22px;
          top: 7px;
          color: $abi-blue;
          cursor: pointer;
          background: $link-water-new;
          font-weight: 700;
          font-size: 1rem;
        }
      }

      .shared-labels-list {
        padding: 3px 5px 3px 0;
        border: 2px solid $link-water-new;
        color: $dark-black-gray;
        border-radius: 3px;
        background-color: $link-water-new;
        margin: 5px 5px 0 0;
        display: inline-block;
        font-weight: 500;
        cursor: default;
        position: relative;
        min-height: 26px;
        font-size: 0.8rem;
        min-width: 82px;
        text-decoration: none;
      }
    }

    span.labels-list-wrapper {
      display: inline-block;
      position: relative;

      .remove-label {
        display: none;
      }

      &:hover {
        .remove-label {
          display: block;
          position: absolute;
          left: 0;
          text-align: center;
          width: 22px;
          top: 6px;
          color: $abi-blue;
          cursor: pointer;
          background: $alice-blue;
          font-weight: 700;
          font-size: 1rem;
        }
      }

      .labels-list {
        padding: 3px 5px 3px 0;
        border: 2px solid $alice-blue;
        color: $abi-blue;
        border-radius: 3px;
        background-color: $alice-blue;
        margin: 5px 5px 0 0;
        display: inline-block;
        font-weight: 500;
        cursor: default;
        position: relative;
        min-height: 26px;
        font-size: 0.8rem;
        min-width: 82px;
        text-decoration: none;
      }
    }
  }

  .input-group {
    input {
      width: 100%;
      border: 0 none;
      height: 35px;
      padding: 0;
    }

    .btn-share {
      position: relative;
      left: -5px;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      padding: 9px 20px;
    }
  }

  .users-list {
    // padding: 10px 0;
    border: 1px solid $gallery;

    &.sharesaveSearchBar {
      height: 276px;
    }

    &:empty {
      display: none;
    }

    .user-block {
      display: inherit;
      padding: 5px 10px;
      position: relative;

      .user-profile-icon {
        background-color: $grey;
        border-radius: 50%;
        padding: 2px 0;
        margin-right: 10px;
        font-size: 16px;
        height: 35px;
        font-weight: 500;
        border: 2px solid $grey;
        width: 35px;
        display: inline-block;
        text-align: center;
        margin-top: 4px;
      }

      .team-ss-icon {
        background-color: $grey;
        border-radius: 50%;
        padding: 2px 0;
        margin-right: 10px;
        font-size: 16px;
        height: 35px;
        font-weight: 600;
        border: 2px solid $grey;
        width: 35px;
        display: inline-block;
        text-align: center;
        position: relative;

        &::after {
          position: absolute;
          content: "";
          top: -5px;
          right: -5px;
          height: 20px;
          width: 20px;
          background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
          @include border-radius(50px);
        }
      }

      .team-icon {
        background-color: $grey;
        border-radius: 50%;
        padding: 10px 0;
        margin-right: 7px;
        height: 35px;
        font-weight: 600;
        border: 2px solid $white;
        width: 35px;
        display: inline-flex;
        text-align: center;
        text-transform: uppercase;

        &:before {
          content: "";
          width: 30px;
          height: 30px;
          background: transparent url($iconsprite) no-repeat scroll -120px -150px;
          left: 12px;
          top: 9px;
          position: absolute;
        }
      }

      .name-class {
        top: 4px;
        position: absolute;
        font-weight: 600;
      }

      .team-name-class {
        top: 2px;
        position: absolute;
        font-weight: 600;
      }

      .delete-container {
        display: inline;
        position: relative;
      }

      .share-everyone {
        position: relative;
        margin-left: 0;
        height: 35px;
        width: 35px;
        margin-right: 10px;
        display: inline-block;
        border-radius: 50%;
        background-color: #5C5C5C;

        &::after {
          position: absolute;
          content: "";
          top: 0;
          right: 0;
          height: 35px;
          width: 35px;
          background: $transparent url($iconsprite) no-repeat scroll -207px -328px;
          @include border-radius(50px);
        }
      }
    }

    .odd {
      background: $light-grey;
    }

    .email-address {
      font-size: 0.75rem;
      margin: -5px 0 0 0;
      font-weight: 400;
    }

    .delete-button.btn.btn-secondary {
      background: transparent none repeat scroll 0 0;
      border: 0 none;
      color: $white;
      font-size: 0.75rem;
      position: relative;
      cursor: pointer;
      line-height: 1.35em;
      height: 30px;
      width: 30px;
      margin-top: 2px;
      text-indent: -9999px;
      float: right;
      z-index: 999;

      &:after {
        content: "";
        width: 30px;
        height: 30px;
        background: transparent url($iconsprite) no-repeat scroll -180px -90px;
        left: 0;
        top: 0;
        position: absolute;
      }
    }

    .share-date {
      float: right;
      width: 100px;
      font-size: 0.85rem;
      margin-top: 8px;
      position: relative;
    }
  }

  &.transfer-selectshare {
    padding-left: 2.7rem;
  }
}

.note-list {
  .note-list-wrapper {
    .note-form {
      position: relative;
      .circle-border{
        left: 7px;
        position: absolute;
        top: 8px;
        z-index: 1;
        pointer-events: none;
      }
      &.input-group {
        display: block;
        .note-input {
          width: 90%;
          display: block;
          position: relative;
          .text-area-counter {
            position: absolute;
            right: 0;
          }

          textarea {
            padding: 10px 10px 10px 38px;
            width: 100%;
            border-radius: 0;
            &:focus,&:active{
              box-shadow: none;
              outline: none;
            }
          }
        }

        .btn-share {
          width: 10%;
          left: auto;
          margin: 0;
          padding: 10px 0;
          height: 40px;
        }
      }
    }
  }

  .note-details-wrapper {
    display: block;
    clear: both;
    padding: 20px 0 0 0;

    .notes-list {
      padding: 5px;
      margin: 0;
      line-height: 1.25rem;
      font-size: 0.85rem;
      font-weight: 400;
      overflow: initial;
      white-space: pre-wrap;
      font-family: "Source Sans Pro", arial, sans-serif;
      word-wrap: break-word;
    }

    .note-listing {
      position: relative;

      span.notes-updated-date {
        position: relative;
        display: inline-block;
        margin-bottom: 5px;
        font-weight: 600;
        color: $black;
      }

      .btn-holder {
        padding: 5px 0;
        display: block;
        clear: both;

        button {
          margin: 0 5px 0 0;
          padding: 2px 5px;
          font-size: 13px;
          cursor: pointer;
        }
      }

      .note-block {
        position: relative;
        padding: 5px 35px 5px 5px;
        border: 1px solid $white;
        margin: 0 0 10px;
        min-height: 50px;
        justify-content: start;
        cursor: default;
        @include border-radius(0px);

        &:hover {
          @include box-shadow(0, 0, 10px, $grey);
          border: 1px solid $white;
        }

        .note-list-wrapper {
          .note-form {
            position: relative;
            &.input-group {
              .note-input {
                width: 100%;

                &:before {
                  content: none;
                }

                textarea {
                  padding: 5px;
                  min-height: 200px;
                  border-radius: 0;
                  &:focus,&:active{
                    box-shadow: none;
                  }
                }
              }
            }
          }
        }

        .note-block-wrapper {
          p {
            margin-bottom: 0;
          }

          .update-time {
            padding-left: 15px;
            font-size: 0.75rem;
            position: relative;

            &::before {
              background: url($iconsprite) no-repeat scroll -30px -210px;
              content: "";
              position: absolute;
              height: 30px;
              left: -10px;
              margin-top: -4px;
              width: 30px;
            }
          }

          .pro,
          .com {
            position: absolute;
            margin-left: 40px;
            font-size: 0.75rem;
            padding-left: 3px;

            &::after {
              background: $dark-grey;
              content: "";
              position: absolute;
              height: 4px;
              left: -30px;
              margin-top: 0;
              width: 4px;
              border-radius: 50%;
              top: 8px;
            }

            &::before {
              content: "";
              position: absolute;
              height: 30px;
              left: -25px;
              margin-top: -4px;
              width: 30px;
              top: -2px;
            }
          }

          .pro {
            &::before {
              background: url($iconsprite) no-repeat scroll -90px -360px;
            }
          }

          .com {
            &::before {
              background: url($iconsprite) no-repeat scroll -120px -360px;
            }
          }

          strong {
            display: block;
          }

          .notes-list {
            padding: 0;
          }
        }
      }
    }
  }
}

.toggle-text {
  text-decoration: underline;
}

.inline-pop {
  &.show {
    position: absolute;
    top: 12px;
    z-index: 999;
    background: $white;
    border-radius: 3px;
    padding: 0px 5px;
    color: $dark-grey;
    right: 30px;
    border: 1px solid $grey;

    .btn-label-cancel {
      color: $green;
    }

    .btn-label-delete {
      color: $red;
    }

    .btn-label-cancel,
    .btn-label-delete {
      background: $transparent none repeat scroll 0 0;
      border: 0 none;
      font-size: 0.7rem;
      font-family: arial;
      cursor: pointer;
      padding: 4px 4px 4px 2px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      &:before {
        content: "/";
        position: relative;
        right: 1px;
        font-size: 0.5rem;
      }
    }
  }
}

.result-wrapper {
  background-color: $white;
  width: 100%;
  &.gridSearchView{
    width: 450px;
    position: relative;
    left: 0 !important;
    z-index: 2;
    .searchlist-wrapper{
      height: calc(100vh - 165px);
      overflow-y: auto;
    }
  }
}

#justAskContainer {
  .result-wrapper {
    background-color: $white;
    width: 100%;
    &.gridSearchView{
      width: calc(100vw - 75vw);
      position: relative;
      left: 0 !important;
      z-index: 2;
      .searchlist-wrapper{
        // height: calc(100vh - 165px);
        overflow-y: auto;
      }
    }
  }
}

.forNestedprojects {
  border: solid 1px $alto;
  border-bottom: none;
}

/* media query for all devices and Desktops */

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {}

/* Smartphones (landscape) ----------- */

@media only screen and (min-width: 321px) {}

/* Smartphones (portrait) ----------- */

@media only screen and (max-width: 320px) {}

@media only screen and (max-width: 768px) {
  .note-list {
    .note-list-wrapper {
      .note-form.input-group {
        .note-input {
          width: 100%;
          float: none;
        }

        .btn-share {
          width: auto;
          padding: 10px;
        }
      }
    }
  }
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {}

/* iPads (landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {}

/* iPads (portrait) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {}

/* Desktops and laptops ----------- */

@media only screen and (min-width: 1224px) {}

@media only screen and (max-width: 1650px) {
  .roles-search-list {
    margin-top: 5px;
  }

  .role-filter-block .select-toggle {
    top: auto;
    text-align: left;
    position: relative;
    left: 0;
  }
}

input[type="radio"]:checked~label {
  color: $white;
  background: $cerulean;
}

.alert-light {
  color: $dark-grey;
  background-color: $whisper;
}

.alert {
  margin-bottom: 0;
}

.role-filter-block {
  .switch-toggle {
    background: $abi-blue;
    margin-right: 5px;
    width: auto;
    padding: 0px;

    input+label {
      text-align: center;
    }
  }

  .with-com-toggle {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 2px;
    top: 3px;
    margin-bottom: 5px;
    visibility: hidden;
  }
}

.switch-toggle {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0;
  border-radius: 5px;
}

.switch-end {
  position: absolute;
  top: 14px;
}

.switch-toggle input:focus~span a,
.switch-toggle input:focus+label {
  outline-color: -webkit-focus-ring-color;
}

.role-filter-block {
  .switch-toggle input {
    position: absolute;
    left: 0;
    opacity: 0 !important;
    height: 10px;
    width: auto;
  }

  .switch-toggle input+label {
    position: relative;
    z-index: 2;
    display: block;
    margin: 2px;
    padding: 0 8px;
    border-radius: 5px;
    color: $white;
  }

  .switch-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
  }
}

.warning-msg{	
  background: $warning-bg;	
  display: block;	
  box-shadow: 0 0 5px grey;	
  border-radius: 5px;	
  display: flex;	
  line-height: 1rem;	
  padding: 15px 15px 15px 60px;	
  position: relative;	
  &:before {	
    background: $transparent url($iconsprite) no-repeat scroll -210px -360px;	
    bottom: 0;	
    content: "";	
    top: 10px;	
    left: 15px;	
    height: 30px;	
    position: absolute;	
    width: 30px;	
    overflow: hidden;	
    display: block;	
    border-radius: 50%;	
  }	
}

.processing-request{
  position: relative;
  .warning-msg{
    position: absolute;
    bottom: 51px;
    &:after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 45%;
        right: 45%;
        width: 0px;
        height: 0px;
        border-left: 10px solid $transparent;
        border-right: 10px solid $transparent;
        border-top: 10px solid $warning-bg;
    }
  }
}

/* Large screens ----------- */

@media only screen and (min-width: 1824px) {}

/* iPhone 5 (portrait &amp; landscape)----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {}

/* iPhone 5 (landscape)----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: landscape) {}

/* iPhone 5 (portrait)----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (orientation: portrait) {}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {

  .dp-next-button,
  .dp-prev-button,
  .back-to-resultpage-button {
    &:before {
      background-image: url($retina-icon-sprite);
      background-size: 300px;
    }
  }

  .delete-button.btn.btn-secondary {
    &:after {
      background-image: url($retina-icon-sprite);
      background-size: 300px;
    }
  }

  .more-actionsheet .btn-secondary,
  .more-actionsheet .btn-secondary.active,
  .more-actionsheet .btn-secondary:active,
  .note-list .note-details-wrapper .note-listing span.notes-updated-date:before,
  .note-list .note-details-wrapper .note-listing .note-block:before,
  .list-user-team .team-ss-icon:after,
  .myleads-page .label-list-wrapper .label-back .label-member-list .team-member-name.team-ss-icon:after,
  .label-sharing-cta .notes-count-icon a:before,
  .share-details .users-list .user-block .team-ss-icon:after,
  .myleads-page .label-list-wrapper .shared-labels-list a:before,
  .share-details .label-details-wrapper span.labels-list-wrapper .labels-list:before,
  .search-button .delete-button.btn.btn-secondary:after,
  .team-admin-page .content-wrapper .team-user-tab .team-list-container .media-left:after,
  .team-admin-page .content-wrapper .team-tab .team-list-container .media-left:after,
  .updated-date:before,
  .show-password .display-password:after,
  .user-admin-page .content-wrapper .user-tab .user-list-container .user-list-block .row .col-12.user-email a.email-address:before,
  .share-details .users-list .delete-button.btn.btn-secondary:after,
  .notification-wrapper .notification-bell:after,
  .notification-wrapper .notification-list .title .noti-setting,
  .notification-wrapper .notification-list:before,
  .note-list .note-details-wrapper .note-listing .note-block .note-block-wrapper .update-time:before,
  .note-list .note-details-wrapper .note-listing .note-block .note-block-wrapper .com:before,
  .note-list .note-details-wrapper .note-listing .note-block .note-block-wrapper .pro:before,
  .myleads-page .label-list-wrapper .label-back .label-member-list .visible-save-search-form .btn-cancel,
  .team-admin-page .content-wrapper .team-tab .media .media-body .team-input:before,
  .team-admin-page .content-wrapper .team-user-tab .media .media-body .team-input:before,
  .user-admin-page .content-wrapper .user-tab .user-list-container .edit-new-user .email-icon:before,
  .user-admin-page .content-wrapper .user-tab .user-list-container .edit-new-user .mobile-icon:before,
  .user-admin-page .content-wrapper .user-tab .user-list-container .edit-new-user .company-icon:before,
  .user-admin-page .content-wrapper .user-tab .user-list-container .edit-new-user .row .col-12.user-names .user-icon:after,
  .search-result .page-title-bar .top-5 .select-actions button,.save-container .column-block .team-member-list .share-everyone:after,
  .everyone-icon:before,.search-selector .select-drop-count button.active:before,
  .warning-msg:before,
  .collaborationBox .collab-filters .btn-cb-filter:before,
  .location-field .dropdown .dropdown-toggle:after,
  .location-field .input-group.has-more .Select.is-clearable.is-searchable.Select--multi.is-focused:after,
  .location-field .input-group.has-more .Select.is-clearable.is-searchable.Select--multi .Select-control:after,
  .location-field .input-group .Select.is-clearable.is-searchable.Select--multi .Select-control:after {
    background-image: url($retina-icon-sprite);
    background-size: 300px;
  }

  .notification-wrapper .notification-list .no-notifications .nothing-found {
    //background-image: url(../images/no-notifications@2x.png);
    background-size: 53px;
  }

}

.iframe-help-pop {
  position: fixed;
  left: 80px;
  bottom: 15px;
  width: 400px;
  top: 20%;
  background: $white;
  padding: 10px;
  z-index: 1000;
}

.overlay {
  content: "";
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;
  cursor: pointer;
}

.help-wrapper {
  -webkit-transition: 3s ease-in;
  -moz-transition: 3s ease-in;
  -o-transition: 3s ease-in;
  -ms-transition: 3s ease-in;
  transition: 3s ease-in;

  .help-content {
    position: fixed !important;
    user-select: auto;
    align-items: center;
    justify-content: center;
    border: 0 none;
    background: $white;
    width: 405px;
    height: 846px;
    box-sizing: border-box;
    bottom: 10px;
    left: 78px;
    z-index: 10000;

    .close-help {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      background: $transparent url($iconsprite) no-repeat scroll -240px -180px;
      width: 30px;
      height: 30px;
      position: absolute;
      overflow: hidden;
      text-indent: -999px;
    }

    h3 {
      display: block;
    }

    .iframe-container {
      padding: 0;
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: 10px;
      top: 40px;

      iframe {
        border: 1px solid #e7e7e7;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        right: 0;
      }
    }
  }

  .resize-handle {
    background: $transparent url($iconsprite) no-repeat scroll -150px -360px;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 10px;
  }
}

/*Collaboratio Tab*/
.collab-wrapper{
  padding: 0 15px 0 17px;
  .box {
      overflow: auto!important;
  }
}
.collaborationBox {
  .collab-filter-panel {
    border:1px solid $alto;
  }
  .collab-filters{
    position: relative;
    .btn-cb-filter{
      font-size:0.85rem;
      position: relative;
      padding:8px 20px 8px 30px;
      &:before{
        content: "";
        background: url($iconsprite) no-repeat scroll -210px -480px;
        position: absolute;
        left:0;
        top:2px;
        height: 30px;
        width: 30px;
        overflow: hidden;
      }
    }
    .cb-filter-panel-wrapper{
    }
    .collab-filter-panel {
        box-shadow: 0 0 5px #ccc;
        position: absolute;
        right: 7px;
        width: 150px;
        top: 45px;
        &:before{
          content: "";
          position: absolute;
          width: 30px;
          height: 30px;
          background: transparent url($iconsprite) no-repeat scroll -60px -270px;
          top: -18px;
          left: auto;
          z-index: 1;
          right: 0;
        }
    }
  }
  .collaborationBy {
    .collabtn-updated-date {
      position: relative;
      margin-bottom: 15px;
      font-weight: 600;
      color: $black;
    }

    .collabtnBox {
      margin-bottom: 15px;

      .user-name-icon {
        background-color: $grey;
        border-radius: 50%;
        margin-right: 10px;
        font-size: 16px;
        height: 35px;
        width: 35px;
        font-weight: 500;
        text-align: center;
        line-height: 33px;
      }

      .collabtnContent {
        width: calc(100% - 10px - 35px);

        .update-time {
          padding-left: 20px;
          font-size: 0.75rem;
          position: relative;
          color: $black;

          &::before {
            background: url($iconsprite) no-repeat scroll -37px -216px;
            content: "";
            position: absolute;
            height: 18px;
            left: 0px;
            top: 0px;
            width: 18px;
          }
        }

        .update-activityby {
          font-weight: 600;
          color: $black;
          font-size: 0.95rem;
        }

        .shared-activity {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              background: $alice-blue;
              border-radius: 3px;
              padding: 4px 10px 6px 30px;
              position: relative;
              display: inline-block;
              font-size: 0.8rem;
              color: $abi-blue;
              margin: 6px 12px 0px 0;
              font-weight: 500;
              overflow: hidden;
              line-height: 19px;
              &:before {
                width: 20px;
                height: 20px;
                content: '';
                background-image: url($iconsprite);
                background-repeat: no-repeat;
                background-attachment: scroll;
                position: absolute;
                left: 6px;
                top: 5px
              }

              &.user-icon {
                &:before {
                  background-position: -5px -456px;
                }
              }

              &.teams-icon {
                &:before {
                  background-position: -35px -456px;
                }
              }

              &.everyone-icon {
                &:before {
                  background-position: -65px -456px;
                }
              }

              &.sharetoremove {
                min-width: 99px;
                .deleteShare {
                  display: none;
                  height: 100%;
                  width: 30px;
                  background: #f1f4f7 url($iconsprite) no-repeat scroll -180px -90px;
                  cursor: pointer;
                  position: absolute;
                  left: 0;
                  top: 0;
                }

                &:hover {
                  .deleteShare {
                    display: block;
                  }
                }
              }

              .share.show {
                position: absolute;
                right: auto;
                top: 4px;
                float: none;
                padding-top: 0;
                left: 5px;
              }
            }
          }
        }

        .note-activity {

          .note-activityby,
          .note-actitvyContent h2 {
            font-weight: 600;
            color: $black;
            font-size: 0.95rem;
          }

          .note-actitvyContent {
            padding-top: 5px;

            h2 {
              font-size: 0.8rem;
              margin: 0;
              padding: 0;
              padding-bottom: 3px;
            }

            p {
              font-size: 0.75rem;
              font-weight: 400;
              color: $black;
              line-height: 0.9rem;
              margin-bottom: 0.5rem;
            }
            .notes-list {
              margin: 0;
              line-height: 1.25rem;
              font-size: 0.85rem;
              font-weight: 400;
              overflow: initial;
              white-space: pre-wrap;
              font-family: "Source Sans Pro", arial, sans-serif;
              word-wrap: break-word;
            }
          }
        }

        .tags-activity {
          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              background-color: $alice-blue;
              border-radius: 3px;
              padding: 4px 10px 6px 30px;
              position: relative;
              display: inline-block;
              font-size: 0.8rem;
              color: $abi-blue;
              margin: 6px 12px 0px 0;
              font-weight: 500;
              overflow: hidden;
              cursor: pointer;
              line-height: 19px;

              &.share-tag {
                background-color: $link-water-new;
              }

              &:before {
                width: 20px;
                height: 20px;
                content: '';
                background: $transparent url($iconsprite) no-repeat scroll -185px -185px;
                position: absolute;
                left: 6px;
                top: 4px
              }
            }
          }
        }
      }
    }
  }
  
  .input-group-addon {
    padding: 0.3rem;
    border-left: none;
    border-radius: 0;
    // border-right: none;
    background-color: #ffffff;
  }
  input[type="text"] { 
    border-right: none;
  }
  
}

/*Collaboration Tab*/
/*Shared Tab in Activities of Details*/
.share-tabShareDetails {
  .shareTabSelector {
    .input-group {

      .shareWithInput {
        border: $grey solid 1px;
        width: 100%;
        margin-right: 5px;

        .Select {
          border: none
        }

        .shareMessage {
          margin: 0 10px;
          border-top: $grey solid 1px;
          font-family: $font-name;

          input {
            font-family: $font-name;

          }

          ::-webkit-input-placeholder {
            opacity: 1;
            font-family: $font-name;
            color: $grey;
            font-weight: 400;
          }

          ::-moz-placeholder {
            opacity: 1;
            font-family: $font-name;
            color: $grey;
            font-weight: 400;
          }

          :-ms-input-placeholder {
            opacity: 1;
            font-family: $font-name;
            color: $grey;
            font-weight: 400;
          }

          input:-moz-placeholder {
            opacity: 1;
            font-family: $font-name;
            color: $grey;
            font-weight: 400;
          }
        }
      }
    }
  }
}

/*Share Tab in Activities of Details*/
/*Group company tab select controler with dropdown*/
.selectorAndDropdown {
  .sort-results {
    position: static;
    width: auto;

    .search-selector {
      margin: 7px 0 17px 0;

      &::after {
        content: '';
        float: none;
        clear: both;
        display: block;
      }

      .search-selector-position {
        margin-left: 0;
      }
      // .dropdown-menu{min-width: 11.5rem;}
    }
    .sort-order-selector {
      margin-right: 25px;
      margin-top: 5px;
      .dropdown{
        margin-left: 5px;
        margin-top: 3px;
        .dropdown-menu{
          top: 24px;
        }
      }
    }
  }
}
.showIconCounts {
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      background: $alice-blue;
      border-radius: 4px;
      a {
        display: block;
        position: relative;
        padding-left: 24px;
        padding-right: 5px;
        font-size: 0.8rem;
         border-bottom: none !important;
         color: $abi-blue;
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          background: $transparent url($iconsprite) no-repeat scroll;
          content: "";
          position: absolute;
          left: 0;
          top: 0;
        }
        &.tagCountBx{
          &::before {
            background-position: -185px -185px;
          }
        }
        &.shareCountBx{
          &::before {
            background-position: -95px -455px;
          }
        }
        &.noteCountBx{
          &::before {
            background-position: -124px -455px;
          }
        }
        &.mailDateBx{
          &::before {
            background-position: -154px -455px;
          }
        }
      }
    }
  }
}
/*Group company tab select controler with dropdown*/
.my-leads-notes .box{overflow: auto!important;}
/* Retina-specific stuff here */

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi) {

  .side-navigation .side-nav-button button.search-nav.nav-active:after,
  .side-navigation .side-nav-button button.search-nav:after,
  .side-navigation .side-nav-button button.saved-search-nav.nav-active:after,
  .side-navigation .side-nav-button button.saved-search-nav:after,
  .side-navigation .side-nav-button button.mylead-nav:after,
  .side-navigation .side-nav-button a:after,
  .icon-searchfilter,
  .icon-unfavourite,
  .icon-read,
  .right-block .unfavourite-icon,
  .right-block .favourite-icon,
  .src-location-placeholder:before,
  .filter-navigation .custom-sidebar button.toggle-filters.active,
  .filter-navigation .custom-sidebar button.toggle-filters,
  .textfield-wrapper .btn.clear-textfield,
  .textfield-wrapper .btn.clear-textfield:hover,
  .textfield-wrapper .btn.clear-textfield:visited,
  .textfield-wrapper .btn.clear-textfield:focus,
  .textfield-wrapper .btn.radial-textfield,
  .textfield-wrapper .btn.radial-textfield:hover,
  .textfield-wrapper .btn.radial-textfield:visited,
  .textfield-wrapper .btn.radial-textfield:focus,
  .viewdetails-icon,
  .details-page .favourite-icon,
  .Select--multi .Select-control .Select-multi-value-wrapper:before,
  .comp-location:before,
  .search-button .edit-button.btn.btn-secondary:after,
  .search-button .delete-button.btn.btn-secondary:after,
  .save-block .save-title:before,
  .save-search-form .visible-save-search-form .btn-cancel,
  .search-result .top-10 button span,
  .btn.get-location-icon,
  .btn.get-location-icon:hover,
  .btn.get-location-icon:visited,
  .btn.get-location-icon:focus,
  .btn.radial-textfield,
  .btn.radial-textfield:hover,
  .btn.radial-textfield:visited,
  .btn.radial-textfield:focus,
  .note-list .note-details-wrapper .note-listing .note-block:before,
  .note-list .note-details-wrapper .note-listing .note-block .note-block-wrapper .com:before,
  .help-wrapper .help-content .close-help,
  .help-wrapper .resize-handle,
  .user-admin-page .content-wrapper .user-tab .media .media-left:after,
  .user-admin-page .content-wrapper .user-tab .add-new-user-wrapper .add-new-user .email-icon:before,
  .user-admin-page .content-wrapper .user-tab .add-new-user-wrapper .add-new-user .mobile-icon:before,
  .user-admin-page .content-wrapper .user-tab .add-new-user-wrapper .add-new-user .company-icon:before,
  .search-result .page-title-bar .top-5 .select-drop-count .select-drop-list:before,
  .team-admin-page .content-wrapper .team-tab .add-new-team-wrapper .media-left:after,
  .team-admin-page .content-wrapper .team-user-tab .add-new-team-wrapper .media-left:after,
  .print .overlay-print .back-btn:before,
  .dp-next-button:before,
  .dp-prev-button:before,
  .myleads-page .my-leads-updates-wrapper .content-wrapper .update-list .company-row .update-time:before,
  .myleads-page .my-leads-updates-wrapper .content-wrapper .update-list .project-row .update-time:before,
  .search-result .page-title-bar .top-5 .select-actions button,
  .search-button .feed-button.btn.btn-secondary:after,
  .search-button .share-button.btn.btn-secondary:after,
  .save-container .column-block .team-member-list .team-member-name.team-ss-icon:after,
  .search-container .search-container-wrapper .pro-comp-field .dropdown.show .dropdown-toggle:after,
  .search-container .search-container-wrapper .right-save-col .btn-primary:before,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .dropdown-menu .dropdown-item.project-icon::before,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .dropdown-menu .dropdown-item.selected::after,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .dropdown-menu .dropdown-item::after,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .dropdown-menu .dropdown-item.company-icon::before,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .dropdown-toggle::after,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown.show .dropdown-toggle::after,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown .dropdown-toggle::after,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown.show .dropdown-toggle::after,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown .dropdown-menu .dropdown-item.selected::after,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown .dropdown-menu .dropdown-item.project-icon::before,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown .dropdown-menu .dropdown-item::after,
  .myleads-page .my-leads-notes .note-list .searchList-header .dropdown .dropdown-menu .dropdown-item.company-icon::before,
  .linkedin-icon::after,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .companyLocationFilter .search-location-place-holder .radial-textfield,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .locationFilter .search-location-place-holder .radial-textfield,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .companyLocationFilter .search-location-place-holder .clear-location-field-basic-search,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .locationFilter .search-location-place-holder .clear-location-field-basic-search,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .companyLocationFilter .search-location-place-holder .btn.get-location-icon,
  .new-filter-wraper .filter-page-wrapper .filter-menu-conten-section .advanced-filter-form-wrapper .advanced-filter-form .locationFilter .search-location-place-holder .btn.get-location-icon,
  .collaborationBox .collaborationBy .collabtnBox .collabtnContent .update-time:before,
  .collaborationBox .collaborationBy .collabtnBox .collabtnContent .shared-activity ul li:before,
  .collaborationBox .collaborationBy .collabtnBox .collabtnContent .tags-activity ul li:before,
  .showIconCounts ul li a:before,.collaborationBox .collaborationBy .collabtnBox .collabtnContent .shared-activity ul li.sharetoremove .deleteShare,.myleads-page .my-favourite-wrapper .searchList-header .dropdown .projects-icon:before,
  .myleads-page .my-favourite-wrapper .searchList-header .dropdown .companies-icon:before,.myleads-page .top-10 .page-title-bar .top-5 .select-drop-count button:before,.myleads-page .top-10 .page-title-bar .top-5 .select-drop-count button:after,
  .myleads-page .top-10 .page-title-bar .top-5 .select-drop-count button.active:before,.map-view .satellite:after,.search-selector .select-drop-count button:before,.myleads-page .top-10 .page-title-bar .top-5 .map-back:before,
  .myleads-page .label-list-wrapper .label-back .label-member-list .team-member-name.team-ss-icon:after,.modal-dialog .modal-content .modal-header .btn-cancel,
  .search-container.new-filter .new-filter-wraper .filter-page-wrapper .summeryTags .filterTagsSummery .summeryTagsList .tag-element span.tag-close-button,.top-pagination-button .prev-records.btn.btn-secondary i,
  .search-container.new-filter .new-filter-wraper .filter-page-wrapper .summeryTags .summeryActions .closeFilterBtn button.btn.btn-close,
  .save-model-body.modal-body.dialog-warning-msg:before,
  .bottom-pagination-button .next-records.btn.btn-secondary i,
  .search-container .search-container-wrapper .advanced-search-form-field .advanced-filters-tags .tag-element span.tag-close-button, .calendar-icon-big :after,
  .event-details-wrapper .clock-icon,.event-details-wrapper .edit-button{
    background-image: url($retina-icon-sprite);
    background-size: 300px;
  }
}

/* toggle truncate text */
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}

.read-more-wrap {
  max-height: 60px;
  overflow: hidden;
}

.read-more-state:checked~a .notes-list .read-more-wrap {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state:checked~.read-more-wrap {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state~.read-more-trigger:before {
  content: "Show more";
}

.read-more-state:checked~.read-more-trigger:before {
  content: "Show less";
}

.read-more-trigger {
  cursor: pointer;
  color: $cerulean;
  text-decoration: underline;
  font-size: 0.8rem;
  display: inline;
  padding: 0;
  line-height: 1rem;
  border-radius: 0.25em;
  background: $white;
}

/*Sizing css*/
.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-19 {
  width: 19.8%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

/*Sizing Css*/

/* toggle truncate text */
.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-wrap {
  max-height: 60px;
  overflow: hidden;
}

.read-more-state:checked~a .notes-list .read-more-wrap {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state:checked~.read-more-wrap {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state~.read-more-trigger:before {
  content: 'Show more';
}

.read-more-state:checked~.read-more-trigger:before {
  content: 'Show less';
}

.read-more-trigger {
  cursor: pointer;
  color: $cerulean;
  text-decoration: underline;
  font-size: .80rem;
  display: inline;
  padding: 0;
  line-height: 1rem;
  border-radius: .25em;
  background: $white;
}

/*Read and Unread Bold*/
.read-block {
  .result-client-name-value {
    font-weight: 600;

    span.rs-client-name,
    span.rs-value {
      color: $dark-black-gray;
    }
    .updatesTxt{
      color: $black;
      display: inline-block;
      font-weight: 600;
      &.font-weight-bold{ padding-left: 5px;}
    }
  }

  .src-date,
  .src-location-placeholder,
  .rs-start-date,
  .rs-end-date {
    font-weight: 600;
  }

  .sub-resultdetails-content {

    .rs-roles span,
    .rs-address,
    .rs-phone {
      font-weight: 600;
      color: $dark-black-gray;
    }
    .updatesTxt{
      color: $black;
      display: inline-block;
      font-weight: 600;
      &.font-weight-bold{ padding-left: 5px;}
    }
  }

  .rs-address,
  .rs-phone,
  .src-date {
    font-weight: 600;
  }

  .list-col {

    .rs-client-name,
    .rs-value,
    .rs-stages,
    span {
      font-weight: 600;
      color: $dark-black-gray;
      font-size: .85rem;
      display: inline-block;
      color: #292b2c;

    }
    .updatesTxt{
      display: inline-block;
      font-weight: 600;
      &.font-weight-bold{ padding-left: 5px; font-weight: bold;}
    }
  }

}

.read-popup {
  .result-row-overview {

    .rs-address,
    .rs-phone,
    .rs-client-name,
    .rs-value,
    .rs-stages {
      font-weight: 600;
      color: $dark-black-gray;
    }

    .src-location-placeholder,
    .src-date,
    .rs-start-date,
    .rs-end-date {
      font-weight: 600;
    }

    .sub-resultdetails-content {

      .rs-roles span,
      .rs-address,
      .rs-phone {
        font-weight: 600;
        color: $dark-black-gray;
      }
    }
  }

  &.mapboxgl-popup-anchor-bottom {
    .mapboxgl-popup-tip {
      border-top-color: $read-block;
    }
  }

}
.updatesTxt{
  color: $black;
  display: inline-block;
  &.font-weight-bold{ padding-left: 5px;}
}
/*Read and Unread Bold*/
/*select dropdown*/
.select-input-type {
  .btn-group {
    button {

      &:hover,
      &:focus,
      &:active {
        background: $white;
        color: $black;
      }

      &:after {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -3px;
      }
    }
  }
}

/*Select dropdown*/
.text-black {
  color: $black;
}

.settings-page .notification-wrapper .content-panel ul li .accordion .accordion__item {
  .no-padding {
    div {
      padding: 0
    }

    .share-details-wrapper .Select .Select-control .Select-multi-value-wrapper {
      margin: 7px 7px 0 35px
    }
  }
}

.save-model-body.modal-body.dialog-warning-msg{
  background:$warning-bg;
  border-top:1px solid $warning-border;
  border-bottom:1px solid $warning-border;
  padding: 20px 20px 20px 90px;
  margin: 10px 0 0;
  &:before {
      content: "";
      background: $warning-bg url($iconsprite) no-repeat scroll -210px -360px;
      left: 32px;
      top: 15px;
      width: 30px;
      height: 30px;
      z-index: 1;
      position: absolute;
  }
}

.donutchart-track{
  fill: transparent;
  stroke: #DAE2E5;
  stroke-width: 26;
}
.donutchart-indicator {
  fill: transparent;
  stroke: #3AAB73;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray .3s ease;
}

.donutchart {
  margin: 0 auto;
  border-radius: 50%;
  display: block;
}

.video-react.video-react-4-3, .video-react.video-react-16-9, .video-react.video-react-fluid{
  padding-top: 50%!important;
}

.video-slide{
  > div{
    position: relative;
    cursor: pointer;
    
    &::after {
      background-color: rgba(0,0,0,0.8);
      content: " ";
      position: absolute;
      top: 40%;
      right: 0;
      left: 0;
      bottom: 0;
      margin: 0 auto;
      width: 70px;
      height: 50px;
      border: 2px solid $white;
      border-radius: 10px;
      box-shadow: 0 0 5px $black;
    }
    &::before {
      content: " ";
      z-index: 100000;
      border: 0;
      box-shadow: none;
      width: 30px;
      height: 30px;
      left: -20px;
      position: absolute;
      top: 45%;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      background: $transparent url($iconsprite) no-repeat scroll -260px -120px;
    }
}
}

.cursor-default{
  cursor: default;
}

.delete-warning {
  background-color: $warning-bg;
  margin: 30px 0 10px 0;
  padding: 20px 70px;
  position: relative;
  font-weight: 600;
  border-top:1px solid $warning-border;
  border-bottom:1px solid $warning-border;

  &::before {
      content: "";
      width: 30px;
      height: 30px;
      background: transparent url($iconsprite) no-repeat scroll -210px -360px;
      left: 30px;
      top: 15px;
      position: absolute;
  }
}
.no-result-emptystate{
  margin-top: 8vh;
  width: 350px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1400px){
    margin-top: 0;
  }
  .empty-info{
    margin-top:-60px;
  }
}
.refresh-icon{
  min-width: 300px;
  padding: 10px;
  i{
    //background: url(../images/refresh-icon.svg) no-repeat center center;
    width: 20px;
    display: inline-block;
    height: 20px;
    margin-right: 10px;
  }
}
.tps-badge{
  background:$alto;
  border:1px solid $grey;
  color:$black;
  padding: 0 5px;
  display: inline-block;
  border-radius: 3px;
  margin-left: 8px;
  font-size: 11px;
  font-weight: 500;
}

.select-toview-placeholder {
  // position: relative;
  position: absolute;
  right: 0;
  background: $white;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  span {
     // background: url(../images/select-options-icon.png) top center no-repeat transparent;
      position: absolute;
      width: 50%;
      top: 45%;
      margin: 0 auto;
      display: block;
      text-align: center;
      left: 0;
      right: 0;
      color: $dark-grey;
      padding-top: 40px;
  }
}

.div-table {
  .div-table-header {
    background: $alto;
    padding-left: 50px;
  }
  .div-table-row {
    color: $black-grey;
    border-left: 1px solid $alto;
    border-right: 1px solid $alto;
    border-bottom: 1px solid $alto;
    padding-left: 50px;
    position: relative;
    min-height: 50px;
    &:before {
        content: "";
        position: absolute;
        top: 10px;
        left: 10px;
        width: 30px;
        height: 30px;
        background: $transparent url($iconsprite) no-repeat scroll -120px -210px;
    }
  }
  .truncate-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.person-designation{
  font-size: 12px;
}
.person-date-added{
  padding: 10px 0 0 0;
  font-size: 12px;
  span{
    color: $dove-grey;
  }
  &:before{
    display: inline-block;
    content: 'Added';
    border: 1px solid #000;
    padding: 4px 5px;
    background: none;
    width: auto;
    line-height: 1;
    color: #000;
    font-weight: normal;
    margin-right:5px;
    height:auto;
    top:1px;
  }
}
.text-break {
  word-break: break-word!important;
  overflow-wrap: break-word!important;
}

.chevron-right{
  position: relative;
  width: 30px;
  height: 30px;
  display: inline-block;
  &::after {
    background: url($iconsprite) no-repeat scroll -60px -240px;
    content: "";
    height: 30px;
    left: 0;
    position: absolute;
    top: 40%;
    width: 30px;
  }
}

.event-details-wrapper{
  .event-listing{
    background: $read-block;
    padding-left:50px !important;
    font-size: 0.825rem;
    position: relative;
    border-bottom: 1px solid $border-grey;
    p{
      margin-bottom:5px;
    }
    .calendar-icon-big{
      &::after {
        background: url($iconsprite) no-repeat scroll -148px -538px;
        content: "";
        height: 30px;
        left: 10px;
        position: absolute;
        top: 17px;
        width: 30px;
      }
    }
    .event-subject{
      font-size: 1rem;
      font-weight: 700;
    }
    .clock-icon{
      background:url($iconsprite) no-repeat scroll -123px -542px;
      content: "";
      height: 24px;
      width: 24px;
      margin-right: 5px;
    }
    .event-details{
      margin-bottom: 10px;
      span{
        padding: 3px;
        line-height: 1;
        &.high{
          background: #FECECE;
        }
        &.normal{ background: #C3C8DD;}
        &.low{
          background: #EAEDFA;
        }
      }
      ul{
        list-style: disc;
        list-style-position: inside;
        margin: 0;
        padding: 0;
        li{
          padding-right: 22px;
        }
      }
    }
    .edit-button{
      position: absolute;
      right:10px;
      top:10px;
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
  
}

.event-type-icon{
  &:after{
      width: 26px;
      height: 26px;
      content:"";
      display: inline-block;
      background: transparent url($retina-icon-sprite) no-repeat scroll  -212px -543px;
      background-size:300px;
  }
  &.email-icon{
      &:after{
          background: transparent url($retina-icon-sprite) no-repeat scroll  -212px -543px;
          background-size:300px;
      }
  }
  &.call-icon{
      &:after{
          background: transparent url($retina-icon-sprite) no-repeat scroll  -182px -543px;     
          background-size:300px;   
      }
      
  }
  &.online-meeting-icon{
      &:after{
          background: transparent url($retina-icon-sprite) no-repeat scroll  -241px -543px;
          background-size:300px;
      }
      
  }
  &.task-icon{
      &:after{
          background: transparent url($retina-icon-sprite) no-repeat scroll  -2px -572px;
          background-size:300px;
      }
  }
  &.face-to-face-meeting-icon{
      &:after{
          background: transparent url($retina-icon-sprite) no-repeat scroll -271px -543px;
          background-size:300px;
      }
     
  }
}

@media (-webkit-min-device-pixel-ratio: 2),
(min-resolution: 192dpi)  {
  .event-details-wrapper{
    .event-listing{
      .calendar-icon-big{
        &::after {
          background:  url($retina-icon-sprite) no-repeat scroll -148px -538px;
          background-size:300px;
        }
      }
      .clock-icon{
        background: url($retina-icon-sprite) no-repeat scroll -123px -542px;
        background-size:300px;
      }
      .edit-button{
        &:after{
          background: $transparent  url($retina-icon-sprite) no-repeat scroll -157px -88px;
          background-size:300px;
        }
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2)  {
  .event-details-wrapper{
    .event-listing{
      .calendar-icon-big{
        &::after {
          background:  url($retina-icon-sprite) no-repeat scroll -148px -538px;
          background-size:300px;
        }
      }
    }
  }
}

.index-module_main__1fmTB {
  .index-module_track__BIi6w{
    width: 10px;
    background: $abi-blue !important;
    border-radius: 0;
  }
}

.form-control{
  font-size: 0.85rem;
}

#addEeventsModal.modal-dialog{
  @media (min-width: 576px){
      max-width: 800px;
  }
  .modal-content{
      .modal-header{
          color: #fff;
          position: relative;
          padding-bottom: 15px;
          .close{
              position: absolute;
              right: 20px;
              top: 50%;
              height: 30px;
              width: 30px;
              opacity: 1 !important;
              border-radius: 50%;
              background: #fff;
              transform: translateY(-50%);
              display: flex;
              justify-content: center;
              align-items: center;
              border: none;
              span{
                  margin: -5px 0 0;
                  color: $abi-blue;
                  font-size: 1.8rem;
              }
          }
          &.blueHeaderBg{
              background: $abi-blue;
              border-radius: 0;
          }
      }
  }   
  .modal-footer{
      .btn-delete{
          border-color: #F14A47;
          color:#F14A47;
      }
  }
}

.event-form{
  padding: 0 15px;
  .event-input{
      position: relative;
      input, textarea{
          border-radius: 0;
          border-color: $border-grey;
          &:focus,&:active{
              border-color: $border-grey;
              box-shadow: none;
          }
      }
      label{
          font-weight: 600;
          span{
              font-weight: 400;
          }
      }
      &.auto-width{
          position: absolute;
          z-index: 1;
          left: -8px;
          top: 5px;
      }
      .dropdown{
          button{
              width: 100%;
              text-align: left;
              font-weight: 400;
              position: relative;
              color:#464a4c;
              padding: .5rem .75rem;
              font-size: .85rem;
              background:$white;
              border-color:$border-grey;
              &:hover,&:focus{
                  background: transparent;
              }
              &:after{
                  position: absolute;
                  right: 10px;
                  top: 50%;
                  transform: translateY(-50%)
              }
          }
          .dropdown-menu{
              button{
                  cursor: pointer;
                  &:hover{
                      background-color: $wildsand-light;
                      color: #1d1e1f;
                  }
              }
          }
      }
  }
  p.error-text{
      font-size: .85em;
      color: $error-red;
      margin: 0
  }

  button{
      &.clearButton{
          background: #ffffff url($iconsprite) no-repeat scroll -247px -187px;
          border: none;
          cursor: pointer;
          width: 16px;
          height: 16px;
          position: absolute;
          right: 55px;
          top: 7px;
          @media (-webkit-min-device-pixel-ratio: 2),
          (min-resolution: 192dpi) {
              background: #ffffff url($retina-icon-sprite) no-repeat scroll -247px -187px;
              background-size: 300px;
          }
          @media only screen and (-webkit-min-device-pixel-ratio: 2) {
              background: #ffffff url($retina-icon-sprite) no-repeat scroll -247px -187px;
              background-size: 300px;
          }
      }
  } 
  .react-datepicker__input-container{
      border-color: $border-grey;
      border-radius: 0;
      legend{
          display: none;
      }
  }
  .react-datepicker__input-container input{
      border:1px solid $border-grey !important;
      color: #464a4c;
      padding: .5rem .75rem;
      font-size: .85rem;
      font-weight: 400;
      height: auto;
      line-height: 1;
      width:100%;
  }
  .react-datepicker__input-container{
      margin-top:-2px;
          &:after{
              background: $transparent url(#{$iconsprite}) no-repeat scroll -60px -538px;
              content: "";
              display: inline-block;
              width: 30px;
              height: 35px;
              pointer-events: none;
              position: absolute;
              right: 7px;
              top:0;
              @media (-webkit-min-device-pixel-ratio: 2),
              (min-resolution: 192dpi) {
                  background: $transparent url($retina-icon-sprite) no-repeat scroll -60px -538px;
                  background-size: 300px;
              }
              @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                  background: $transparent url($retina-icon-sprite) no-repeat scroll -60px -538px;
                  background-size: 300px;
              }
          }
  }
  .react-datepicker-popper{
      width:330px;
  }
  .react-datepicker__navigation{
      top:7px;
  }
  
}

.btn.btn-primary {
  background-color: #053874;
  border-color: #053874;
  color: #ffffff;
}
.select-drop-count{
  &.move-selectControl{margin-left: 60px;}
  position: relative;
  display: block;
  button.active{
      background: $white;
      min-width: 145px;
      width: auto;
      // border-right: 0 none; 
      &::after{                        
          // right: 23px;                        
      }
  }
  button {
      position: relative;
      background: none;
      color: $black;
      border: 1px solid $lighter-gray;
      width: 50px;
      height: 35px;
      padding: 10px 0;
      margin: 0;
      border-radius: 0;
      p{
          font-size: 0.9rem;
          color: $abi-blue;
          top: -2px;
          position: inherit;
          font-weight: 600;
          text-align: left;
          padding-right: 25px;
      }
      &::after{
          content: "";
          position: absolute;
          top: 12px;
          right: 2px;
          width: 15px;
          height: 15px;
          border: 0;
          background: $transparent url($iconsprite) no-repeat scroll -220px -75px;
      }
      // &.dropdown-toggle {
      //   height: 35px;
      //   padding: 10px 0;
      //   &::before {
      //     top:2px;
      //   }
      //   &::after {
      //     top: 12px;
      //   }
      //   p{
      //     top: -2px;
      //   }
      // }
  }
  .select-drop-list{
      z-index: 100000;
      left: 1px;
      top: 35px;
      margin: 0;
      padding: 0;
      border-radius: 0;
      box-shadow: 1px 1px 5px $dove-grey;
      border: 0 none;
      width: 100%;
      button {
          position: relative;
          display: block;
          width: 100%;
          border: none;
          border-bottom: 1px solid $mercury;
          font-size: 0.8rem;
          font-weight: 400;
          color: $black-grey;
          padding-top:0;
          padding-bottom: 0;
          height: 30px; 
          min-width: inherit;
          background: $white;
          &::before{
              left:0;
          }
          &::after{
              background: none;
          }
          &:hover{
              background-color: $mercury;
           }
      }
  }
}
.error-page-content {
  width: 400px;
  margin: auto;
  padding-top: 40px;
  background-size: 100% auto;
  text-align: center;
  background-position-x: 43px;
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
  p {
    font-weight: 600;
    font-size: 13px;
  }
}
.noscroll{
  position: fixed;
  height: 100%;
  width: 100%;
}

.react-tooltip {
  z-index: 1200;
  p {
    margin-bottom: 0;
  }
}
.no-bg {
  background-image: none!important

}