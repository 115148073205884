/* ============================================================
  CHECKBOX
============================================================ */

.check-box-button {
    margin: 0;
    position: relative;
    &.disabled{
        opacity: 0.3;
        label{
            cursor: default;
            &:before{
                cursor: default;
            }
        }
    }
    input {
        display: none;
    }
    label {
        padding-left: 1.5rem;
        line-height: 16px;
        font-weight: 500;
        font-size: 0.80rem;
        color: $dark-black-gray;
        &:before {
            cursor: pointer;
            position: absolute;
            width: 17px;
            height: 17px;
            top: 3px;
            left: 0;
            background: $white;
            border: 2px solid $light-red;
            content: '';
        }
        &:after {
            opacity: 0.1;
            content: '';
            position: absolute;
            width: 9px;
            height: 5px;
            background: $transparent;
            top: 8px;
            left: 4px;
            border: 3px solid $light-red;
            border-top: none;
            border-right: none;
            @include transform-rotate(-45deg);
        }
        &:hover::after {
            @include opacity(0.2);
        }
    }

    &.child-selected{
        label {
            &:before {
                background: $light-red;
            }
            &:after {
                opacity: 1;
                content: "";
                position: absolute;
                width: 7px;
                height: 3px;
                background: transparent;
                top: 10px;
                left: 5px;
                border: 3px solid $white;
                border-top: none;
                border-right: none;
                transform: rotate(0deg);
            }
        }
    }
    input[type=checkbox]:checked+label:after {
        @include opacity(1);
    }
}

.select-toggle {
    .check-box-button {
        display: inline-block;
        margin-right: 10px;
        label {
            /*opacity: 0.5;*/
            &::after {
                border: 3px solid $light-red;
                border-top: none;
                border-right: none;
            }
            &:before {
                border: 2px solid $light-red;
                content: none;
            }
        }
    }
    .check-box-button {
        input[type=checkbox] {
            &:checked+label {
                opacity: 1;
            }
        }
    }
    .check-radio-button {
        input[type=radio] {
            &:checked+label {
                opacity: 1;
            }
        }
    }
}


.makedefault-ss-button {
    margin: 0;
    position: relative;
    &.disabled{
        opacity: 0.3;
        label{
            cursor: default;
            &:before{
                cursor: default;
            }
        }
    }
    input {
        width: 30px;
        height: 30px;
        opacity: 0;
    }
    label {
        padding-left: 1.5rem;
        &:before {
            cursor: pointer;
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            left: 0;
            background: $transparent url($iconsprite) no-repeat scroll -150px -90px;
            content: "";
        }
        &:after {
            opacity: 0.1;
            content: '';
            position: absolute;
            width: 9px;
            height: 5px;
            background: $transparent;
            top: 8px;
            left: 4px;
            border: 3px solid $light-red;
            border-top: none;
            border-right: none;
            @include transform-rotate(-45deg);
        }
        &:hover::after {
            @include opacity(0.2);
        }
    }
    input[type=checkbox]:checked+label:after {
        @include opacity(1);
    }
}