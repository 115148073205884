
 .settings-page Col {
     padding-left: 15px;
     padding-right: 15px;
 }

 .settings-page {
	top: 40px;
	padding-left: 45px !important;
	margin-left: 0 !important;
	padding-right: 0 !important;
	left:13px;
	.show-password {
		top: 0;
		background: none;
		color: #000;
		width: 250px;
	}
	.hide-password {
		width: 250px;
	}
    .btn-save-cancel{
		height: 35px;
		font-size: .95em;
		margin-left: 36px;
		margin-top: 10px;
	}
	.notification-wrapper{
		.share-details{
			padding: 0;
		}		
	}
	.notification-wrapper, .content-wrapper {
		position: relative;
		bottom: 0;
		left: 45px;
		position: fixed;
		right: 0;
        top: 170px; //transition: all 0.2s linear;
        .content-panel {
            background: #fff;
			margin: -10px 16px 20px 35px;
			padding: 15px 0px 1px 0px;
			width: calc(100% - 65px);
			.notification-shares{
				margin: 0px;
				border: 1px solid #ddd;
				padding: 5px;
				padding-bottom: 6px;
				.form-control-label{
					margin: 0px;
    				padding-left: 33px;
				}
			}
			.font-bold{
				font-weight: 700;
				margin: 0;
			}
			.display-block{
				margin-bottom: 8px;
			}
			ul{
				padding: 0px;
				margin: 0px;
				li{
					&.border-top{border-top: 1px solid #ddd;}
					&.border-bottom{border-bottom: 1px solid #ddd;}
					list-style-type: none;
					.accordion{
						border: 1px solid #ddd;
						.accordion__item{
							padding-left: 10px;
							div {
								background: #fff;
								padding: 3px 0;
								margin: 0px;
								.check-box-button{
									label{
										&:before{
											//top: 5px;
										}
									}
								}
							}
							.accordion__title{
								padding-left: 5px;
								pointer-events: none;
								.check-box-button{
									label {
										pointer-events: visible;
									}
								}
							}
							.accordion__body{
								ul{
									padding: 0;
									li{
										border-bottom: 1px solid #ddd;
										padding-left: 50px;
										margin-left: -11px;
										&:first-child{
											border-top:  1px solid #ddd;
										}
										&:last-child{
											//border: 0 none;
										}
									}
								}
							}
						}
					}
					&:first-child{
						.accordion{
							border-bottom: 0 none;
						}
					}
					&:last-child{
						.accordion{
							border-top: 0 none;
						}
					}
				}
			}
		}
		.switch-panel {
			background: #fff;
			padding: 10px 0 0 0;
			height: 60px;
			.switch-notification{
				position: absolute;
				label{
					margin-top: 5px;
				}
			}
			.notification-switch-text{
				position: relative;
				left: 40px;
				top: 0;
				font-weight: 700;
				p{
					margin: 0;
				}
				span{
					display: block;
					font-weight: 400;
				}
			}
		}
		&.notificationTabBlock{
			.notoficatnSelector{
				ul{
					li{
						border:solid 1px #eee;
						border-top: none;
						border-bottom: none;
						padding: 0 40px;
						padding-bottom: 15px;
					}
				}
				
			}
		}
    }
	.page-title-bar {
		margin: 0;
		background: #fff;
		padding: 10px 0;
		div:first-child {
			display: block;
		}
    }
    .icon-addon{
        input {
            padding-left: 10px;
        }
    }
    .error{
        font-size: .85em;
        text-align: right;
        color: #f04047;
    }
    .display-password,
    .display-password:after{
        top: 15px;
    }
	.settings-tab-wrapper {
		box-shadow: none;
		.tab-content {
			background: #f4f5f9;
			.my-favourite-wrapper {
				display: inline-block;
			}
		}
		.nav-tabs {
			border-bottom: 5px solid #053874;
			background: #fff;
			.nav-link:hover {
				border: 1px solid transparent;
			}
			.nav-link.active {
				background-color: #053874;
				border: 0 none;
				color: #fff;
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				-ms-border-radius: 0px;
				-o-border-radius: 0px;
				border-radius: 0px;
			}
			li:first-child {
				margin-left: 45px;
			}
			.nav-item {
				margin-bottom: -2px;
				cursor: pointer;
				.show .nav-link {
					background-color: #053874;
					border: 0 none;
					color: #fff;
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					-ms-border-radius: 0px;
					-o-border-radius: 0px;
					border-radius: 0px;
				}
			}
		}
	}
}

 .form-control {
     border-radius: 0 !important;
     font-weight: 400;
     color: #464a4c;
 }

 .form-control:focus {
    color: #464a4c;
    background-color: #fff;
    border: 1px solid #5cb3fd !important;
    box-shadow: none !important;
    outline: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out
}

.pl-3, .px-3 {
    padding-left: 1rem!important;
}


@media (min-width: 1400px) {
    .container {
        max-width: 100% !important;
    }
}

 @media (min-width: 1200px) {
    .col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        padding-right: 15px;
        padding-left: 15px;
    }

    .container {
        max-width: 100% !important;
    }
    
 }
 