@import '../common/base/colors';
@import '../common/base/mixins';

$login-bg: '../common/images/backdrop-login2.jpg';
$login-back: '../common/images/backdrop-sep.png';
$iconsprite: '../common/images/webapp-icons-sprite-v3.png';
$ios: '../common/images/appstore.png';
$android: '../common/images/playstore.png';
$abi-logo: 'https://barbourabi-production-platform-web-images.s3.eu-west-1.amazonaws.com/static/barbourabi-logo-new.svg';
$abi-logo2x: '../common/images/logo@2x-v2.png';

a:focus,
a:hover {
    text-decoration: none;
}

.container {
    width: 100%;
    padding: 0;
}

.login-page {
    overflow: auto;
    //background: url($login-back) repeat-y top center $white;
    position: relative;
    height: 100vh;
   

    .container {
        width: 100%;
        padding: 0;
        border: 1px solid red;

        .reset-password{
            background: #fff;
        }
    }
}

// body.login-page:after {
//     content: "";
//     position: absolute;
//     background: $mercury;
//     position: absolute;
//     right: 0;
//     left: 50%;
//     top: 0;
//     bottom: 0;
//     z-index: -1;
//     height: 100vh;
// }
#maps {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-size: cover;
    background-image: url($login-bg);
    object-fit: center;
    object-position: center;
    background-repeat: no-repeat;
    background-position: top center;
    height: 47vh;
}

@media (max-width: 990px) {
    #maps {
        background-size: cover;
    }
}

.loginContainer {
    bottom: 0;
    left: 0;
    margin: 0;
    overflow: visible;
    // position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}

.login-main-container {
    height: 100%;
    z-index: 1;
    //background: url($login-back) repeat-y top center $white;

    .main-row-container {
        height: 100%;

        .resetForm{
            .logo-container{
                background-size: 200px;
            }
        }

        .main-login {
            height: 100%;
        }
    }
}

.reset-password {
    background: $white;

    .loginContainer {
        background: $white;
        position: absolute;
        left: 20%;
        right: 20%;
        top: 20px;
    }

    &:after {
        content: "";
        position: fixed;
        background: $white;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }
}

.logo-container {
    text-align: center;
}

.loginForm.col {
    margin: 0 auto;
}

@media (max-width: 480px) {
    .loginForm.col {
        margin: 0 auto;
        width: 100%;
    }
}

img {
    max-width: 100%;
}

/*
.show-password {
    font-size: 0.75em;
    padding: 7px 10px;
    position: absolute;
    right: 1px;
    top: 2px;
}
.show-password input {
    position: relative;
    top: 2px;
}*/

.display-password {
    display: block;
    height: 60px;
    position: absolute;
    width: 60px;
    overflow: hidden;
    right: 0;
    top: 0;
    text-indent: -99999px;

    &:after {
        background: $transparent url($iconsprite) no-repeat scroll -90px -90px;
        bottom: 0;
        content: "";
        height: 30px;
        width: 30px;
        top: 15px;
        right: 15px;
        position: absolute;
    }
}

.show-password {
    top: 0;
    background: $transparent none repeat scroll 0 0;
    color: $amethyst-smoke;

    .display-password {
        display: block;
        height: 60px;
        position: absolute;
        width: 60px;
        overflow: hidden;
        right: 0;
        top: 0;
        text-indent: -99999px;

        &:after {
            background: $transparent url($iconsprite) no-repeat scroll -120px -90px;
            bottom: 0;
            content: "";
            height: 30px;
            width: 30px;
            top: 15px;
            right: 15px;
            position: absolute;
        }
    }
}

.masquerade {
    p {
        padding-top: 7px;
    }

    .form-group {
        margin: 20px 0 0;

        span {
            font-size: 0.95rem;
            line-height: 40px;
        }

        .dropdown {
            button {
                background: $white;
                border: 1px solid #e3e3e3;
                width: 100%;
                text-align: left;
                padding-left: 10px;
                font-size: .85rem;
                font-weight: 500;
                height: 50px;
                margin: 0;

                &:hover {
                    color: $black;
                }

                &::after {
                    right: 15px;
                    position: absolute;
                    top: 24px;
                }

                &.btn-secondary {
                    color: $dark-grey;
                }
            }

            .dropdown-menu {
                padding: 0;
                margin: 0;
                border: 0;
                width: 100%;
                height: 240px;
                overflow-y: scroll;
            }
        }
    }

    .info {
        p.info-sub {
            border-bottom: 1px solid $grey;
            padding-bottom: 10px;
        }
    }
}

.loginForm {
    .form-group {
        margin-bottom: 0;

        button {
            margin: 10px 0;
            padding: 10px 0;
            font-weight: 700;
            font-size: 18px;

            &.btn-block {
                max-width: 200px;
            }

            &.btn-forgotpassword {
                text-align: left;
                padding: 0;
                font-weight: 400;
                color: $abi-blue;
                font-size: 0.9rem;
            }
        }
    }

    p {
        font-size: 1.5rem;
        font-weight: 700;
    }

    input {
        border-color: $grey !important;
        border-width: 0 0 3px 0 !important;
        padding: 1.0rem .75rem !important;
        line-height: 2rem !important;
        background: $transparent;
        font-size: 1rem;
        color: $black;
        border-radius: 0 !important;

        &:focus {
            background: $transparent;
            border-color: $grey !important;
            border-width: 0 0 3px 0 !important;
            outline: none !important;
            box-shadow: none !important;
        }

        &::-webkit-input-placeholder {
            /* Edge */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $dark-grey;
        }

        &::placeholder {
            color: $dark-grey;
        }
    }

    input[name='password'] {
        background: $transparent;
        font-size: 1rem;
        color: $black;

        &:focus {
            background: $transparent;
            border-color: $grey;
            border-width: 0 0 3px 0;
            outline: none;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            /* Edge */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $dark-grey;
        }

        &::placeholder {
            color: $dark-grey;
        }
    }

    // input[name='password']:focus{
    //     border-top:1px solid; 
    // }
    .btn-secondary {
        border-color: $amethyst-smoke;
    }
}

.resetForm {
    .logo-container {
        background-position: center center;
    }

    .form-group {
        margin-bottom: 0;

        button {
            margin: 10px 0;
            padding: 10px 0;
            font-weight: 700;
            font-size: 18px;
        }
    }

    p {
        font-size: 1.5rem;
        font-weight: 700;
    }

    input {
        border-color: $grey;
        border-width: 0 0 3px 0;
        padding: 1.0rem .75rem;
        line-height: 2rem;
        background: $transparent;
        font-size: 1rem;
        color: $black;

        &:focus {
            background: $transparent;
            border-color: $grey;
            border-width: 0 0 3px 0;
            outline: none;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            /* Edge */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $dark-grey;
        }

        &::placeholder {
            color: $dark-grey;
        }
    }

    input[name='confirm_password'] {
        background: $transparent;
        font-size: 1rem;
        color: $black;

        &:focus {
            background: $transparent;
            border-color: $grey;
            border-width: 0 0 3px 0;
            outline: none;
            box-shadow: none;
        }

        &::-webkit-input-placeholder {
            /* Edge */
            color: $dark-grey;
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $dark-grey;
        }

        &::placeholder {
            color: $dark-grey;
        }
    }

    .btn-secondary {
        border-color: $amethyst-smoke;
    }
}

.login-page {
    .info {
        display: block;
        font-size: 13px;
        margin: 15px 15px 15px 0;
        font-weight: normal;

        .info-sub {
            padding-bottom: 5px;
        }

        .imp {
            color: $abi-blue;
            font-weight: 700;
            padding-right: 5px;
        }
    }

    .app-btn {
        display: block;
        margin: 20px 0;

        a {
            content: "";
            height: 40px;
            width: 120px;
            position: relative;
            display: inline-block;
            margin-right: 15px;
        }

        .ios {
            background: $transparent url($ios) no-repeat scroll;
        }

        .android {
            background: $transparent url($android) no-repeat scroll;
        }
    }
}

.social-media {
    display: inline-block;

    a {
        height: 30px;
        width: 30px;
        position: relative;
        display: inline-block;
        margin-right: 15px;
    }

}

.call-us {
    color: $dark-grey;
    font-size: 1.3rem;
}

.back-button {
    margin: 0px 15px 0 5px;

    a {
        display: block;
        padding: 10px;

        &:hover,
        &:focus {
            background: white;
            color: $black;
        }
    }
}

.partner-info {
    font-size: 0.65rem;
}

@media (max-width: 990px) {
    .password-form {
        .text-right {
            text-align: center
        }

        .btn-forgotpassword {
            display: table;
            float: none;
            margin: 0 auto;
        }
    }

    .loginForm .password-form {
        display: table;
        margin: 0 auto;
    }

    .empty-div {
        display: none;
    }

    .social-media {
        position: absolute;
        right: 0;
        top: 15px;
    }
}

@media (max-width: 767px) {
    .login-page .info {
        margin-left: 0;
        margin-right: 0;
    }

    .login-page {
        background: $white;
    }

    .social-media {
        position: fixed;
        padding: 15px;
        background: $white;
        bottom: 0;
        right: 0;
        left: 0;
        top: auto;
        box-shadow: 0 0 5px $grey;
    }

    #maps {
        position: fixed;
    }

    .login-main-container {
        background: $white;
        margin: 90px 25px;
    }

    .reset-password {
        .login-main-container {
            margin: 30px 25px;
        }

        .loginContainer {
            left: 10%;
            right: 10%;
            top: 40px;
        }

        #maps {
            position: inherit;
        }
    }

    .login-info {
        order: 2;
    }

    .loginForm {
        order: 1;
    }
}


.device-redirection {
    .row-title {
        margin: 0;
        font-weight: 600;
        line-height: 1.3em;
        font-size: 0.85rem;
        text-align: center;
    }

    .webapp-icon,
    .ios-mobileapp-icon,
    .and-mobileapp-icon {
        display: block;
        margin: 10px auto 0;
        padding: 90px 0 10px 0;
        border: 1px solid $grey;
        text-align: center;
        min-height: 100px;
        color: $black;
        font-weight: 600;
        width: 100px;
        cursor: pointer;

        &:hover {
            @include box-shadow(0, 0px, 10px, rgba(0, 0, 0, 0.3));
        }
    }

    .webapp-icon {
        background: $gallery url('../common/images/webapp-icon.png') no-repeat scroll center 20px;
    }

    .ios-mobileapp-icon {
        background: $gallery url('../common/images/ios-mobile-icon.png') no-repeat scroll center 20px;
    }

    .and-mobileapp-icon {
        background: $gallery url('../common/images/and-mobile-icon.png') no-repeat scroll center 20px;
    }

}

/* login page navigation css */

.navbar {
    padding: 0;

    &.top-navigation {
        background: $white;
        border-bottom: 1px solid $alto;
    }

    ul.navbar-nav {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        flex-direction: row;
    }

    .user-icon {
        background-color: $alto;
        padding: 5px 0;
        @include border-radius(50%);
        display: block;
        width: 30px;
        text-align: center;
        color: $abi-blue;
        font-weight: 600;
        margin: 0;
        font-size: 12px;
        height: 30px;
    }

    .nav-item {
        position: relative;
        border-left: 1px solid $alto;
        width: 60px;

        .user-profile-settings {
            top: 0;
            left: 0;

            .link-user-setting {
                display: block;
                padding: 13px;
                border: 0 none;

                &:hover,
                &:focus {
                    background: $transparent;
                }
            }
        }
    }
}

.logo-placeholder {
    background: $white url($abi-logo) no-repeat scroll center left;
    height: 56px;
    width: 260px;
    background-size: contain;
    margin: 0 10px;
    
}

.login-container {
    background: $white;
}

.login-container .logo-container {
    border-bottom: 1px solid $alto;
}

.logo-container {
    background: $transparent url($abi-logo) no-repeat scroll;
    height: 56px;
    background-position: 0;
    width: 202px;
    background-position: center;
    background-size: contain;
}

.help-content h5 {
    color: $abi-blue;
}