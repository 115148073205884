@import '../common/base/colors';
@import '../common/base/mixins';

$login-bg: '../common/images/backdrop-login2.jpg';
$login-back: '../common/images/backdrop-sep.png';
$iconsprite: '../common/images/webapp-icons-sprite-v3.png';
$ios: '../common/images/appstore.png';
$android: '../common/images/playstore.png';
$abi-logo: '../common/images/logo-v2.png';
$abi-logo2x: '../common/images/logo@2x-v2.png';

.saved-search-page {
    top: 40px;
    padding-left: 75px !important;
    margin-top: 10px;
    left: 13px;
    transition: all .2s linear;
    z-index: 0;
    position: fixed;
    width: auto;

    .content-wrapper {
        bottom: 0;
        left: 75px;
        position: fixed;
        right: 0;
        top: 90px;
        transition: all .2s linear;

        .savedSearch-tab-wrapper {

            .custom-one {
                top: 165px;
            }

            .nav-tabs {
                border-bottom: 5px solid $abi-blue;

                .nav-link {
                    padding: 5px 15px;
                    border: none;
                    cursor: pointer;
                    font-weight: 600;
                    color: $black;

                    &:hover {
                        color: $black;
                    }
                }

                .nav-link.active {
                    background-color: $abi-blue;
                    color: $white;
                    border-radius: 0;
                }
            }
        }

        .save-container {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            padding: 0 .5em;

            .column-block {
                position: relative;
                display: flex;
                width: 99%;
                border-bottom: 2px solid $border-grey;

                .save-block-wrapper {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    background: $white;
                    width: 100%;

                    &:hover {
                        -webkit-box-shadow: 0 0 15px #ccc;
                        -moz-box-shadow: 0 0 15px #ccc;
                        -ms-box-shadow: 0 0 15px #ccc;
                        -o-box-shadow: 0 0 15px #ccc;
                        box-shadow: 0 0 15px #ccc;
                    }

                    .save-block {
                        margin: 0;
                        padding: 10px;

                        .save-title {

                            span {
                                color: $abi-blue;
                                font-size: 1.1em;
                                font-weight: 600;
                                padding: 0;
                                cursor: pointer;
                                border: 0;
                                white-space: normal;
                                text-align: left;
                                line-height: 1.1em;
                                background: none;
                                margin: 0 15px 0 5px;
                                position: relative;
                            }
                        }


                        .saved-search-tag-listing {
                            margin: 0 15px;
                            position: relative;

                            .saved-search-tag {
                                width: auto;
                                padding: 10px 15px;
                                position: relative;
                                color: $abi-blue;
                                background: $alice-blue;
                                margin: 5px 10px 10px;
                                border-radius: 5px;
                                height: 42px;

                                .saved-search-tag-lable {
                                    padding-right: 10px;
                                    float: left;
                                    position: relative;
                                    font-weight: 700;
                                    z-index: 1;

                                    &:after {
                                        content: ":";
                                        right: 5px;
                                        position: static;
                                    }
                                }

                                .saved-search-tag-text {
                                    width: 200px;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }

                                &.tile-project-info {
                                    .saved-search-tag-text {
                                        width: inherit;
                                    }
                                }
                            }

                            .saved-conversation-tag {
                                width: auto;
                                padding: 10px 15px;
                                position: relative;
                                color: $abi-blue;
                                background: $alice-blue;
                                margin: 5px 10px 10px;
                                border-radius: 5px;

                                .saved-conversation-tag-text {
                                    width: 400px;
                                }
                            }
                        }
                    }
                }

                .save-search-action {
                    position: absolute;
                    right: 10px;
                    z-index: 4;
                    top: 20px;

                    .search-button {
                        list-style: none;
                        justify-content: end;

                        li {
                            border: 1px solid #ddd;
                            border-right: 0;

                            &:last-child {
                                border-right: 1px solid #ddd;
                            }

                            .btn-default-timer {
                                background: #fff;
                                border: 0;
                                height: 30px;
                                width: 30px;
                                padding: 0.25rem !important;

                                svg {
                                    position: relative;
                                    top: -3px;
                                }
                            }

                            .btn-default-savesearch {
                                background: #fff;
                                border: 0;
                                height: 30px;
                                width: 30px;
                                padding: 0.25rem !important;

                                svg {
                                    position: relative;
                                    top: -4px;
                                }
                            }

                            .btn-default-share {
                                background: #fff;
                                border: 0;
                                height: 30px;
                                width: 30px;
                                padding: 0.25rem !important;

                                svg {
                                    position: relative;
                                    top: -4px;
                                }
                            }

                            .btn-default-trash {
                                background: #fff;
                                border: 0;
                                height: 30px;
                                width: 30px;
                                padding: 0.25rem !important;

                                svg {
                                    position: relative;
                                    top: -4px;
                                }
                            }
                        }
                    }
                }
            }

            .showmore-result {
                width: 250px;
                margin: 0 auto;
            }
        }
    }
}

.deleteModalBox .modal-title {
    font-size: 1.3em;
}

.shareModal {
    .modal-title {
        font-size: 1.3em;
    }

    .modal-body {
        .input-group {
            border: 1px solid #ccd6e2;
            flex-wrap: nowrap;

            .input-group-text {
                padding: 2px 0px 2px 5px;
                background-color: transparent;
                border: none;
            }

            .share-input-box {
                display: grid;
                border: none;

                .css-13cymwt-control {
                    border: none;
                }

                .css-1dyz3mf {
                    padding: 2px 8px 2px 0;
                }

                .css-1p3m7a8-multiValue {
                    padding: 3px 0;
                }

                .list-user-team {
                    .user-dropdown-icon {
                        display: initial;
                        font-size: 10px;
                        margin-right: 5px;
                        padding: 2px 3px;
                    }

                    .users-teams-title {
                        float: none;
                        position: static;
                    }
                }

                .css-1p3m7a8-multiValue .list-user-team .users-teams-title .email-address {
                    display: none;
                }
            }
        }

        .collaborationBox {
            border: 1px solid #eee;

            .user-name-icon {
                color: inherit;
            }

            .row {

                &:nth-of-type(odd) {
                    background-color: #f3f3f3;
                }
            }
        }

        .collaborationBox .collaborationBy .collabtnBox .user-name-icon {
            background: $grey;
            color: $black;
            font-weight: 600;
            border-radius: 50%;
            height: 30px;
            width: 30px;
            display: block;
            text-align: center;
            font-size: 13px;

        }

        .shared-activity {
            justify-content: end;
            position: relative;

            .teams-icon {
                position: relative;
                display: block;
                margin: 0 0 0 5px;

                &:before {
                    position: absolute;
                    content: "";
                    top: -5px;
                    left: -32px;
                    height: 20px;
                    width: 20px;
                    background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                    @include border-radius(50px);
                }
            }

            ul {
                list-style: none;
            }

            .delete-icon.show {
                position: absolute;
                left: 350px;
                top: 2px;
                cursor: pointer;
            }

            .share.show {
                display: flex;
                position: absolute;
                left: -25px;
                top: -5px;
                right: auto;
            }

            .everyone {
                &:before {
                    position: absolute;
                    content: "";
                    left: -40px;
                    height: 30px;
                    width: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -210px -330px;
                    @include border-radius(50px);
                }
            }
        }

        .share-modal-main {
            bottom: 0;
            left: 519px;
            position: fixed;
            right: 519px;
            top: 214px;
            transition: all .2s linear;
            background: $white;
        }
    }
}

.shared-user-blocks {
    // display: inline-block;
    float: left;
    // margin-right: 5px;
    top: 2px;
    position: relative;

    .team-icon {
        position: relative;
        display: block;
        margin: 0 0 0 5px;

        &:before {
            content: "";
            width: 30px;
            height: 30px;
            background: $transparent url($iconsprite) no-repeat scroll -120px -150px;
            left: 0px;
            position: absolute;
            background-color: $grey;
            border-radius: 50%;
            top: -21px;
        }
    }
}

.team-member-name {
    position: relative;
    background: $grey;
    color: $black;
    font-weight: 600;
    padding: 5px 0;
    border-radius: 50%;
    z-index: 1;
    margin-left: 5px;
    height: 30px;
    width: 30px;
    display: block;
    text-align: center;
    font-size: 13px;

    &.team-ss-icon {
        &::after {
            position: absolute;
            content: "";
            top: -5px;
            right: -5px;
            height: 20px;
            width: 20px;
            background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
            @include border-radius(50px);
        }
    }
}

.saved-search-page {
    .placeholder {
        .top-margin {
            margin-top: 53px;
        }
    }
}

.over-map-panel {
    position: relative;

    .visible-save-search-form {
        overflow: inherit;
        position: absolute;
        opacity: 1;
        // background: $white;
        // padding: 7px 4px;
        // border: 1px solid $lighter-gray;
        z-index: 1000;
        width: 390px;
        top: -13px;
        right: -6px;

        input[type=text] {
            width: 280px;
            line-height: 1.35;
        }

        .btn-secondary-save {
            font-size: 0.9em;
            margin-right: 10px;
            padding: .5rem 1rem;
        }

        .btn-cancel {
            height: 30px;
            margin: 0;
            width: 30px;
            background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
            border: 0 none;
            position: absolute;
            text-indent: -999px;
            overflow: hidden;
            cursor: pointer;
            right: -24px;
            box-shadow: none; // &:focus, &:active, &:hover{
            //     background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
            // }
        }
    }

    .save-search-form {
        right: 5px;
        width: 103px;
        height: 37px;
        top: 2px;
        position: relative;

        .btn-primary-save {
            right: 2px;
            position: absolute;
            height: 35px;
            padding: 5px;
            font-size: 0.95em;
            top: 0;
            border: 1px solid $lighter-gray;
            width: 110px;
        }

        .src-name-field,
        .src-filter-field,
        .src-default-field {
            display: inline-block;
            margin: 0 20px 0 0;
        }

        .button-blocks {
            position: absolute;
            right: 22px;
            top: 5px;
        }

        .save-search-form-wrapper {
            overflow: hidden;
            width: 390px;
            position: relative;
            background: $white;
            padding: 5px 4px 5px 7px;
            border: 1px solid $lighter-gray;

            .check-box-button {
                top: 3px;
            }
        }

        .hide-save-search-form {
            width: 0;
            overflow: hidden;
            opacity: 0;
            top: -9px;
            position: absolute;
        }

        .visible-save-search-form {
            overflow: inherit;
            position: absolute;
            opacity: 1;
            // background: $white;
            // padding: 7px 4px;
            // border: 1px solid $lighter-gray;
            z-index: 1000;
            width: 390px;
            top: -8px;
            right: -6px;

            input[type=text] {
                width: 280px;
                line-height: 1.35;
                height: 37px;
            }

            .btn-secondary-save {
                font-size: 0.9em;
                margin-right: 10px;
                padding: .5rem 1rem;
            }

            .btn-cancel {
                height: 30px;
                margin: 0;
                width: 30px;
                background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                border: 0 none;
                position: absolute;
                text-indent: -999px;
                overflow: hidden;
                cursor: pointer;
                right: -24px;
                box-shadow: none; // &:focus, &:active, &:hover{
                //     background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                // }
            }
        }
    }
}

#justAskContainer {
    .over-map-panel {
        .save-search-form {
            right: 5px;
            width: 103px;
            height: 37px;
            top: 2px;
            position: relative;

            .btn-primary-save {
                right: 2px;
                position: absolute;
                height: 35px;
                padding: 0 10px;
                font-size: 0.95em;
                top: 0;
                border: 1px solid $lighter-gray;
                width: 140px !important;
            }

            .visible-save-search-form {
                overflow: inherit;
                position: absolute;
                opacity: 1;
                // background: $white;
                // padding: 7px 4px;
                // border: 1px solid $lighter-gray;
                z-index: 1000;
                width: 390px;
                top: -6px !important;
                right: 0px !important;

                input[type=text] {
                    width: 280px;
                    line-height: 1.35;
                    height: 37px;
                }

                .btn-secondary-save {
                    font-size: 0.9em;
                    margin-right: 10px;
                    padding: .5rem 1rem;
                }

                .btn-cancel {
                    height: 30px;
                    margin: 0;
                    width: 30px;
                    background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                    border: 0 none;
                    position: absolute;
                    text-indent: -999px;
                    overflow: hidden;
                    cursor: pointer;
                    right: -24px;
                    box-shadow: none; // &:focus, &:active, &:hover{
                    //     background: $transparent url($iconsprite) no-repeat scroll -210px -90px;
                    // }
                }
            }

            .outline-btn {
                background: $white;
                color: $abi-blue;
                border: 1px solid #dedbdf;
                cursor: pointer;
                padding: 6px 4px;
                font-weight: 500;

                &.save-search-button {
                    font-size: 0.85rem;
                    height: 36px;
                    border-radius: 3px;
                }

                &:hover,
                &:after,
                &:focus {
                    background: $white;
                    color: $abi-blue;
                    border: 1px solid #dedbdf;
                    box-shadow: #dedbdf 0 0 3px !important;
                }

                &:disabled {
                    background: $white;
                    color: $abi-blue;
                    border: 1px solid $abi-blue;
                    opacity: 0.5;
                    cursor: not-allowed;
                }
            }
        }
    }
}