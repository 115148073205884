.dropnav {
    >li>a,
    button.dropdown-item {
        display: block;
        padding: 8px 20px;
        cursor: pointer;
    }
    button.dropdown-item svg {
        top: -1px;
        position: relative;
    }
}
.dropdown-item:active{
    color: $black;
}
.topmenu-dropdown {
    position: relative;
    .dropnav {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        left: auto;
        margin: 0;
        padding: 0;
        right: 0;
        top: 43px;
        width: auto;
        position: absolute;
        min-width: 100px;
        &:before{
            content:"";
            position: absolute;
            width: 30px;
            height: 30px;
            background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
            top: -18px;
            right: 2px;
        }
        .nav-item {
            width: 100%;
        }
        >li>a {
            display: block;
            padding: 8px 20px;
        }
    }
    .dropdown-toggle {
        &:after {
            display: none;
        }
    }
}
.dropdown-item.disabled, .dropdown-item:disabled{
    color: $gray-dark;
}