@import "../common/base/colors";
@import "../common/base/mixins";

$iconsprite: "../common/images/webapp-icons-sprite-v3.png";
$retina-icon-sprite: "../common/images/webapp-icons-sprite@2x-v3.png";

/*----------------------------------------------------------------------------------
    Teams page
----------------------------------------------------------------------------------*/

.team-admin-page {
    padding-left: 65px;
    margin-left: 0;
    padding-right: 0;
    padding-top: 45px;
    background-color: $white;
    .form-control {
      font-size: 0.75rem;
    }
    .sort-results {
      top: 25px;
      right: 11px;
    }
    .top-10 {
      left: 17px;
      color: $abi-red;
      font-weight: 600;
      p {
        height: 20px;
        padding-top: 10px;
      }
    }
    .content-wrapper {
      position: relative;
      h4 {
        margin: 5px 0;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        padding-right: 20px;
      }
      .content-panel {
        padding: 0 25px 0 30px;
      }
      .team-user-tab {
        .media {
          .media-body {
            margin-left: 0 !important;
            input {
              display: block;
            }
          }
        }
      }
      .team-user-tab,
      .team-tab {
        position: relative;
        height: 100%;
        display: table;
        width: 100%;
        padding-bottom: 10px;
        font-size: 13px;
        .media {
          padding: 10px 10px 25px;
        }
        .hide {
          display: none;
          overflow: hidden;
          opacity: 0;
        }
        .show {
          display: block;
          opacity: 1;
        }
        .add-new-team-user {
          background: $white;
          padding: 10px 10px 15px;
        }
        .list-header {
          background: $alto;
          margin: 0 15px 2px 0;
          font-weight: 700;
          padding: 15px 0;
          line-height: 0.85rem;
          span {
            display: block;
            font-size: 11px;
          }
        }
        .list-wrapper {
          .details-panel-scrollbar {
            // position: fixed!important;
            // right: 6px;
            // bottom: 0;
            // left: 85px;
            // top: 240px;
            // width: auto !important;
            // height: auto !important;
          }
        }
        .team-user-list-container {
          margin: 0 15px 10px 0;
          position: relative;
          font-size: 13px;
          line-height: 1.3em;
          .user-profile-icon-wrapper {
            background-color: $grey;
            border-radius: 50%;
            padding: 8px 0;
            margin-right: 10px;
            font-size: .7rem;
            font-weight: 700;
            height: 30px;
            width: 30px;
            display: inline-block;
            text-align: center;
          }
          .show {
            display: block;
            position: relative;
            padding: 0 10px;
            float: left;
            top: 10px;
            color: $grey;
          }
          .hide {
            display: none;
            opacity: 0;
          }
          .team-user-block {
            margin: 0 0 2px;
            .row {
              background: $white;
              padding: 8px 0 10px 0;
              min-height: 52px;
              .col-12 {
                &.user-names {
                  position: relative;
                  line-height: 1.0rem;
                  .user-icon {
                    position: absolute;
                    top: 3px;
                    left: 15px;
                    height: 30px;
                    width: 30px;
                    background: $alto;
                    display: block;
                    text-align: center;
                    border-radius: 22px;
                    padding: 7px 2px;
                    font-weight: 600;
                  }
                  .user-detail-wrapper {
                    padding: 0 0 0 40px;
                    .fl-name {
                      padding: 3px 20px 0 0;
                      display: inline-block;
                      font-weight: 700;
                      position: relative;
                      &:after {
                        content: "";
                        position: absolute;
                        top: 10px;
                        right: 8px;
                        width: 4px;
                        height: 4px;
                        border-radius: 50px;
                        background: $dove-grey;
                        display: block;
                      }
                    }
                    .user-name {
                      padding: 0;
                      display: inline-block;
                      color: $dove-grey;
                    }
                    a.email-address {
                      padding: 0 0 0 0;
                      color: $dove-grey;
                      display: block;
                      text-decoration: underline;
                    }
                  }
                }
                &.user-contacts {
                  .user-mobile {
                    margin-left: 15px;
                    margin-top: 0;
                    margin-bottom: 5px;
                    position: relative;
                    font-weight: 400;
                    display: block;
                    &:before {
                      background: $transparent url($iconsprite) no-repeat scroll 0 -150px;
                      content: "";
                      height: 30px;
                      position: absolute;
                      width: 30px;
                      left: -25px;
                      top: -5px;
                    }
                  }
                  .user-landline {
                    margin-left: 15px;
                    position: relative;
                    margin-top: 0;
                    font-weight: 400;
                    display: block;
                    &:before {
                      background: $transparent url($iconsprite) no-repeat scroll -60px -150px;
                      content: "";
                      height: 30px;
                      position: absolute;
                      width: 30px;
                      left: -25px;
                      top: -5px;
                    }
                  }
                }
                &.user-email {
                  a.email-address {
                    text-decoration: underline;
                    color: $black;
                  }
                }
                &.user-licenses {
                  padding-right: 30px;
                  .user-license {
                    span {
                      display: inline-block;
                      margin: 0 5px 0 0;
                      &:after {
                        content: ",";
                      }
                      &:last-child:after {
                        content: "";
                      }
                    }
                  }
                }
                &.user-teamnames {
                  .user-team-name {
                    span {
                      display: inline-block;
                      margin: 0 5px 0 0;
                      &:after {
                        content: ",";
                      }
                      &:last-child:after {
                        content: "";
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .team-list-container {
          margin: 0 15px 10px 0;
          position: relative;
          .media-left {
            position: absolute;
            background: $alto;
            color: $black;
            font-weight: 600;
            padding: 5px 0;
            border-radius: 50%;
            z-index: 1;
            margin-left: 5px;
            height: 30px;
            width: 30px;
            display: block;
            text-align: center;
            top: 15px;
            &:after {
              position: absolute;
              content: "";
              top: -5px;
              right: -5px;
              height: 20px;
              width: 20px;
              background: transparent url($iconsprite) no-repeat scroll -215px -215px;
              border-radius: 50px;
            }
          }
          .edit-new-user {
            display: block;
            background: $white;
            border: 2px dashed $grey;
            .container {
              top: -15px;
              margin-left: 55px;
              .form-control:disabled {
                background: none;
              }
            }
            input {
              border: 0;
              border-bottom: 1px solid $mercury;
              padding: 0.5rem 0;
              &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                color: $alto;
                font-weight: 400;
              }
              &::-moz-placeholder {
                /* Firefox 19+ */
                color: $alto;
                font-weight: 400;
              }
              &:-ms-input-placeholder {
                /* IE 10+ */
                color: $alto;
                font-weight: 400;
              }
              &:-moz-placeholder {
                /* Firefox 18- */
                color: $alto;
                font-weight: 400;
              }
            }
            .email,
            .mobile,
            .company-phone {
              padding-left: 25px;
            }
            .email-icon {
              &:before {
                background: $transparent url($iconsprite) no-repeat scroll -30px -180px;
                content: "";
                height: 30px;
                left: 9px;
                position: absolute;
                z-index: 9999;
                width: 30px;
              }
              input {
                padding-left: 25px;
              }
            }
            .mobile-icon {
              &:before {
                background: $transparent url($iconsprite) no-repeat scroll 0 -180px;
                content: "";
                height: 30px;
                left: 9px;
                position: absolute;
                z-index: 9999;
                width: 30px;
              }
              input {
                padding-left: 25px;
              }
            }
            .company-icon {
              &:before {
                background: $transparent url($iconsprite) no-repeat scroll -60px -180px;
                content: "";
                height: 30px;
                left: 9px;
                position: absolute;
                z-index: 9999;
                width: 30px;
              }
              input {
                padding-left: 25px;
              }
            }
            form {
              padding: 0;
              .form-group {
                //padding: 0;
                margin-bottom: 5px;
                .form-control-feedback {
                  font-size: .85em;
                  position: absolute;
                  bottom: -11px;
                  right: 15px;
                  z-index: 999;
                  color: $error-red;
                }
              }
            }
            .btn-edit-user {
              background: $yellow;
              padding: 5px 15px 5px 15px;
              font-size: 0.95em;
              margin-right: 15px;
              cursor: pointer;
              border: 0;
              &:focus,
              &:hover {
                color: $black;
              }
            }
            .btn-cancel-user {
              text-decoration: underline;
              padding: 5px 0;
              border: 1px solid $black;
              color: $black;
            }
            .form-button-row {
              margin-top: 10px;
              padding-left: 15px;
            }
          }
          .add-new-team-user {
            .media-body {
              margin: 0;
              div {
                display: block !important;
                width: 100%;
                input {
                  padding-left: 35px;
                  margin-right: 0.25rem !important;
                  margin-bottom: 0.25rem !important;
                  display: block;
                  width: 100%;
                  padding-bottom: 10px;
                  padding-top: 10px;
                  font-size: 0.75rem;
                  line-height: 1.25;
                  color: $black-grey;
                  background-color: $white;
                  background-image: none;
                  -webkit-background-clip: padding-box;
                  background-clip: padding-box;
                  border: 1px solid $grey;
                  border-radius: 0.25rem;
                  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
                  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
                  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
                  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
                }
                div {
                  display: block !important;
                  background: $white !important;
                  z-index: 9999;
                  width: auto;
                  font-size: 0.75rem !important;
                  .Select-multi-value-wrapper {
                    padding: 0 35px;
                  }
                  span {
                    display: inherit;
                    font-size: 0.85rem;
                    margin-left: 30px;
                    &:hover {
                      cursor: pointer;
                    }
                    .user-profile-icon {
                      background-color: $grey;
                      border-radius: 50%;
                      padding: 2px 0;
                      margin-right: 10px;
                      font-size: 16px;
                      font-weight: 600;
                      height: 25px;
                      width: 25px;
                      display: inline-block;
                      text-align: center;
                      border: 1px solid $white;
                      color: #2c292b;
                    }
                  }
                }
              }
              h4 {
                &:before {
                  background: $transparent url($iconsprite) no-repeat scroll 0 0;
                  bottom: 0;
                  content: "";
                  height: 30px;
                  left: 13px;
                  position: absolute;
                  top: 77px;
                  width: 30px;
                  z-index: 99999;
                  overflow: hidden;
                  display: block;
                }
                .Select-menu-outer {
                  display: block;
                  width: 100%;
                }
              }
            }
            .users-list {
              border: 1px solid $mercury;
              padding: 0;
              margin-top: 10px;
              span {
                display: inherit;
                border-bottom: 1px solid $light-grey;
                padding: 5px;
                .delete-box {
                  position: relative;
                  display: inline-block;
                  .show {
                    position: absolute;
                    width: 128px;
                    font-size: 11px;
                    top: -8px;
                    padding: 1px;
                    border: 1px solid $alto;
                    background: white;
                    @include border-radius(3px);
                    z-index: 100;
                    left: 5px;
                    .btn{
                      font-size: 12px;
                      padding: 3px 10px;
                      margin: 0;
                      @include border-radius(3px);
                      cursor: pointer;
                      border: 0 none;
                      &:focus,
                      &:hover{
                        background: $white;
                        border: 0 none;
                      }
                      &.btn-save-cancel{
                        color: $green;
                      }
                      &.btn-save-delete{
                        background-color: $white;
                        color: $red;
                      }
                    }
                  }
                  .delete-button.btn.btn-secondary {
                    background: transparent none repeat scroll 0 0;
                    border: 0 none;
                    color: $white;
                    font-size: 0.75rem;
                    position: relative;
                    cursor: pointer;
                    line-height: 1.35em;
                    height: 30px;
                    width: 30px;
                    margin: 0;
                    text-indent: -9999px;
                    left: -5px;
                    margin-left: 5px;
                    top: -8px;
                    &:after {
                      content: "";
                      width: 30px;
                      height: 30px;
                      background: transparent url($iconsprite) no-repeat scroll -180px -90px;
                      left: 0;
                      top: 0;
                      position: absolute;
                    }
                  }
                }
                .user-name {
                  margin-top: 4px;
                  position: relative;
                  font-size: .85rem;
                  top: -7px;
                  font-weight: 600;
                  color: #2c292b;
                }
                .user-profile-icon {
                  background-color: $grey;
                  border-radius: 50%;
                  padding: 2px 0;
                  margin: 3px 5px 0;
                  font-size: 16px;
                  font-weight: 600;
                  height: 30px;
                  width: 30px;
                  display: inline-block;
                  text-align: center;
                  color: #2c292b;
                } // .show{
                //   position: relative;
                //   top: 0;
                //   display: inline-block;
                //   right: 100px;
                // }
              }
              span:last-child {
                border-bottom: none;
              }
              .email-address {
                color: $emperor-gray;
                font-size: 0.75rem;
                margin: -16px 0 0 40px;
                font-weight: 400;
              }
            }
          }
          .edit-team-container {
            display: inherit;
            top: 6px;
            .edit-text {
              height: 30px;
              width: inherit;
              float: left;
              position: relative;
            }
            .edit-title-button {
              // background: $grey;
              // border: 1px solid $black;
              // padding: 4px;
              .save-team,
              .cancel-team {
                color: $white;
                font-size: 0.75rem;
                position: relative;
                cursor: pointer;
                line-height: 1.35em;
                height: 30px;
                width: 30px;
                margin: 0;
                text-indent: -9999px;
                top: 0;
                margin-right: 5px;
                border-radius: 5px;
                &:hover {
                  border: 1px solid $black;
                }
              }
              .save-team {
                background: transparent url($iconsprite) no-repeat scroll -210px -180px;
              }
              .cancel-team {
                background: transparent url($iconsprite) no-repeat scroll -240px -180px;
              }
            }
            .text-error{
              margin:2px 0 8px;
              position: static !important;
            }
          }
        }
        .media {
          background: $white;
          padding: 10px;
          min-height: 50px;
          a{
              color: #2c292b;
          }
          .media-body {
            margin-left: 45px;
            position: relative;
            margin-top: -10px;
            .team-name {
                color: $black;
                font-weight: 600;
                top: 3px;
                border: 1px $white solid;
                font-size: 13px;
                height: 30px;
                padding: 3px 0 0 2px;
                margin-top: -2px;
                .edit-button.btn.btn-secondary {
                  background: transparent none repeat scroll 0 0;
                  border: 0 none;
                  color: $white;
                  font-size: 0.75rem;
                  position: relative;
                  cursor: pointer;
                  line-height: 1.35em;
                  height: 24px;
                  width: 30px;
                  margin: 0;
                  text-indent: -9999px;
                  left: -5px;
                  &:focus {
                    border: 0 none;
                  }
                }
                &:hover {
                  border: 1px solid $grey;
                  padding-left: 2px;
                  .edit-button.btn.btn-secondary {
                    &:after {
                      content: "";
                      width: 30px;
                      height: 30px;
                      background: transparent url($iconsprite) no-repeat scroll -150px -90px;
                      left: 7px;
                      top: -4px;
                      position: absolute;
                    }
                  }
                }
              }
            .team-input{
              &:before{
                background: $transparent url($iconsprite) no-repeat scroll -240px -90px;
                content: "";
                height: 30px;
                left: 0;
                position: absolute;
                top: 2px;
                width: 30px;
              }
              input{
                padding-left:30px;
                height: 34px;
              }
            }
            .text-error{
              color: $abi-red !important;
              font-size: 0.75rem;
              line-height: 1;
              margin-top: 5px;
              position: absolute;
              top:28px;
            }
            .media-heading {
              font-weight: 600;
              .flex-wrapper{width: 100%;}
              span {
                margin-left: 5px;
                font-weight: 400;
                &.user-license {
                  float: none;
                  display: inline;
                  margin-left: 20px;
                  font-weight: 400;
                  span {
                    padding: 3px 8px;
                  }
                }
              }
              .email-address {
                color: $emperor-gray;
                font-size: 0.75rem;
                margin: 5px 0 0 30px;
                font-weight: 400;
                position: relative;
                &:before {
                  background: $transparent url($iconsprite) no-repeat scroll -30px -150px;
                  content: "";
                  height: 30px;
                  position: absolute;
                  width: 30px;
                  left: -28px;
                  top: -9px;
                }
              }
              .team-member {
                margin: 0;
                cursor: pointer;
                left: 0;
                font-size: 13px;
              }
              .user-name {
                margin-left: 30px;
                margin-top: 5px;
                position: relative;
                font-weight: 400;
                color: #2c292b;
                &:before {
                  background: $dark-grey;
                  content: "";
                  height: 6px;
                  position: absolute;
                  width: 6px;
                  left: -15px;
                  top: 3px;
                  border-radius: 50%;
                }
              }
              .user-mobile {
                margin-left: 30px;
                margin-top: 5px;
                position: relative;
                font-weight: 400;
                &:before {
                  background: $transparent url($iconsprite) no-repeat scroll 0 -150px;
                  content: "";
                  height: 30px;
                  position: absolute;
                  width: 30px;
                  left: -25px;
                  top: -10px;
                }
              }
              .user-landline {
                margin-left: 30px;
                position: relative;
                margin-top: 5px;
                font-weight: 400;
                &:before {
                  background: $transparent url($iconsprite) no-repeat scroll -60px -150px;
                  content: "";
                  height: 30px;
                  position: absolute;
                  width: 30px;
                  left: -25px;
                  top: -10px;
                }
              }
              .team-member-list {
                right: 85px;
                top: 8px;
                z-index: 3;
                margin-top: -5px;
                .team-member-name {
                  position: relative;
                  background: $grey;
                  color: $black;
                  font-weight: 600;
                  padding: 8px 0;
                  border-radius: 50%;
                  z-index: 1;
                  font-size: 13px;
                  height: 30px;
                  width: 30px;
                  display: inline-block;
                  text-align: center;
                  margin-left: 0;
                  top:2px;
                }
                .more-team-member {
                  position: relative;
                  background: $grey;
                  color: $black;
                  font-weight: 600;
                  padding: 8px 0;
                  border-radius: 50%;
                  z-index: 1;
                  font-size: 0.80rem;
                  height: 30px;
                  width: 30px;
                  display: inline-block;
                  text-align: center;
                  top:2px;
                }
                .react-tooltip{
                  font-size: 0.75rem;
                }
              }
              .user-button {
                .edit-button.btn.btn-secondary {
                  background: transparent none repeat scroll 0 0;
                  border: 0 none;
                  color: $white;
                  font-size: 0.75rem;
                  position: relative;
                  cursor: pointer;
                  line-height: 1.35em;
                  height: 30px;
                  width: 30px;
                  margin: 0;
                  text-indent: -9999px;
                  left: -5px;
                  &:after {
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: transparent url($iconsprite) no-repeat scroll -150px -90px;
                    left: 0;
                    top: 0;
                    position: absolute;
                  }
                }
                .delete-button.btn.btn-secondary {
                  background: transparent none repeat scroll 0 0;
                  border: 0 none;
                  color: $white;
                  font-size: 0.75rem;
                  position: relative;
                  cursor: pointer;
                  line-height: 1.35em;
                  height: 30px;
                  width: 30px;
                  margin: 5px 0 0;
                  text-indent: -9999px;
                  left: -5px;
                  &:after {
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: transparent url($iconsprite) no-repeat scroll -180px -90px;
                    left: 0;
                    top: 0;
                    position: absolute;
                  }
                }
                .add-team-user {
                  text-indent: 0;
                  padding: 6px;
                  margin-right: 0;
                  margin-top: 5px;
                  font-size: .7rem;
                  text-transform: none;
                  font-weight: 600;
                }
                .add-team-user.active {
                  background: $abi-blue;
                  color: $white;
                  border: 1px solid $abi-blue;
                  padding: 6px 12px;
                }
              }
            }
            .user-team {
              display: block;
              margin-left: 35px;
              margin-top: 15px;
              float: left;
              padding-right: 15px;
              border-right: 1px solid $grey;
              &:before {
                background: $transparent url($iconsprite) no-repeat scroll -60px 0px;
                content: "";
                height: 30px;
                position: absolute;
                width: 30px;
                margin-left: -35px;
                margin-top: -5px;
              }
            }
            .user-team-name {
              display: block;
              color: $black;
              margin-top: 10px;
              float: left;
              font-size: 0.75rem;
              margin-left: 35px;
              span {
                margin-right: 45px;
                display: inline-block;
                margin-bottom: 15px;
                &:before {
                  background: $light-grey url($iconsprite) no-repeat scroll -120px -150px;
                  content: "";
                  height: 30px;
                  position: absolute;
                  width: 30px;
                  margin-left: -35px;
                  margin-top: -5px;
                  border-radius: 50%;
                }
              }
              span:last-child {
                margin: 0;
              }
            }
            .user-license {
              display: block;
              color: $cerulean;
              margin-top: 10px;
              float: left;
              font-size: 0.75rem;
              span {
                background: $alice-blue none repeat scroll 0 0;
                border: 1px solid $alto;
                border-radius: 5px;
                margin: 0 5px 5px 3px;
                padding: 0px 8px;
                font-size: 0.70rem;
                display: inline-block;
                color: $black-grey;
              }
            }
          }
        }
        .user-team-search-panel {
          background: none;
          margin-bottom: 0;
          padding: 10px 0 0;
          display: table-row;
          .media-body {
            margin: 0;
            .textfield-wrapper {
              float: left;
              &:after {
                border-left: none;
              }
            }
          }
          .search-user-textbox {
            float: left;
            height: 35px;
            font-weight: 400;
            font-size: .80em;
            line-height: 2.8em;
            input {
              width: 100%;
              border: 0;
              margin: 0;
              top: 0;
              height: 35px;
              position: absolute;
              left: 0;
              padding: 0 10px;
              border: 1px solid $alto;
              padding-left: 30px;
              line-height: 2.0em;
              &::placeholder {
                color: $grey;
                font-weight: 400;
                line-height: 2.0em;
              }
              &::-webkit-input-placeholder {
                color: $grey;
                font-weight: 400;
                line-height: 2.0em;
              }
              &::-moz-placeholder {
                color: $grey;
                font-weight: 400;
                line-height: 2.0em;
              }
              &:-moz-placeholder {
                color: $grey;
                font-weight: 400;
                line-height: 2.0em;
              }
              &:-ms-input-placeholder {
                color: $grey;
                font-weight: 400;
                line-height: 2.0em;
              }
            }
            &:before {
              background: $transparent url($iconsprite) no-repeat scroll -90px 0;
              bottom: 0;
              content: "";
              height: 30px;
              position: absolute;
              top: 2px;
              width: 30px;
              overflow: hidden;
              display: block;
              z-index: 999;
              left: 2px;
            }
          }
          .clear-textfield {
            right: 2px;
            top: 2px;
          }
          .src-button {
            // button {
            //   height: 34px;
            //   text-indent: 15px;
            //   overflow: hidden;
            //   position: relative;
            //   background: $red;
            //   color: $white;
            //   padding: 0 10px 0 16px;
            //   cursor: pointer;
            //   text-transform: capitalize;
            //   font-size: 0.8rem;
            //   @include box-shadow(0, 0, 0, rgba(0, 0, 0, 0));
            //   float: left;
            //   border: 1px solid $abi-blue;
            //   &:focus {
            //     border: 1px solid $abi-blue;
            //   }
            // }
            .user-search {
              margin-right: 10px;
              &:after {
                background: $transparent url($iconsprite) no-repeat scroll -180px -60px;
                bottom: 0;
                content: "";
                height: 30px;
                left: 5px;
                position: absolute;
                top: 0;
                width: 30px;
                overflow: hidden;
                display: block;
              }
            }
            .add-user {
              font-size: .8rem;
              padding: 9px 20px;
            }
          }
        }
        .add-new-team-wrapper {
          margin: 10px 16px 10px 0;
          border: 2px dashed $grey;
          -webkit-transition: width 0.6s;
          padding: 15px;
          display: table-row;
          .media-left {
            position: relative;
            display:block;
            &:after {
              background: $light-grey url($iconsprite) no-repeat scroll -120px -180px;
              content: "";
              height: 30px;
              position: absolute;
              width: 30px;
              border-radius: 50%;
              top: 10px;
              left: 10px;
            }
          }
          h4 {
            margin: 0;
            position: relative;
            display: flex;
          }
          .media-body {
            margin: 10px 30px 10px 55px;
          }
          .team-button {
            position: absolute;
            right: -43px;
            .add-team {
              top: 0;
              font-size: .85rem;
              position: relative;
              line-height: 1.4em;
              margin: 0;
              margin-left: 0;
              text-align: center;
              white-space: nowrap;
              vertical-align: middle;
              right: 16px;
            }
            .delete-button.btn.btn-secondary {
              background: transparent none repeat scroll 0 0;
              border: 0 none;
              color: $white;
              font-size: 0.75rem;
              position: relative;
              cursor: pointer;
              line-height: 1.35em;
              height: 30px;
              width: 30px;
              margin: 0;
              text-indent: -9999px;
              right: 15px;
              &:after {
                content: "";
                width: 30px;
                height: 30px;
                background: transparent url($iconsprite) no-repeat scroll -210px -90px;
                left: 0;
                top: 0;
                position: absolute;
              }
            }
          }
        }
        .list-wrapper{
          display: table-row;
          height:100%;
          .scroll-area{
            padding:10px 0 0;
          }
          &.teams-list-wrapper{
            height: calc(100vh - 200px) !important;
            display: block;
          }
          &.users-list-wrapper{
            height: calc(100vh - 250px) !important;
            display: block;
          }
        }
      }
  
      .team-user-tab{
        .list-wrapper {
          .scroll-area{
            padding:0;
          }
        }
      }
    }
    .company-tab-wrapper {
      box-shadow: none;
      border: 0 none;
      .tab-content{
          background: $whisper;
      }
      .nav-tabs {
        border-bottom: 5px solid $abi-blue;
        .nav-item{
            &:first-child {
                margin-left: 25px;
              }
        }
        .nav-link {
            padding: 5px 15px;
            border: none;
            cursor: pointer;
            font-weight: 600;
            color: $black;
            &:hover {
                color: $black;   
            }
        }

        .nav-link.active {
            background-color: $abi-blue;
            color: $white;
            border-radius: 0;
        }
    }
    }
    .page-title-bar {
      margin: 0;
      background: $white;
      padding: 10px 0;
      div:first-child {
        display: block;
      }
    }
  }

 .teams-list-wrapper{
  .share-details-wrapper{
      .css-b62m3t-container{
        .css-13cymwt-control{
            &:before {
                background: $transparent url($iconsprite) no-repeat scroll 0 0;
                content: "";
                height: 30px;
                width: 30px;
                position: absolute;
                left: 1px;
                top: 4px;
              }
            .css-hlgwow{
                padding-left: 35px;
            }
        }
        .css-1nmdiq5-menu{
          z-index: 4;
        }
      }
  }
 }

  