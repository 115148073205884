.placeholder-loader h2 {
    animation: 1s ease 0s normal none infinite running pulse;
    min-height: 1em;
    text-indent: -99999px;
}

@keyframes pulse {
    0% {
        background-color: rgba(235, 235, 235, 0.5);
    }
    50% {
        background-color: rgba(245, 245, 245, 0.5);
    }
    100% {
        background-color: rgba(235, 235, 235, 0.5);
    }
}

.placeholder {
    .address{
        float: none;
    }
    .project-overview {
        background: $white;
        .icon-project {
            background: $mercury;
            @include border-radius(50px);
        }
        .proj-location {
            width: 200px;
            &:before {
                background: transparent;
            }
        }
        h2,
        .proj-location,
        .proj-ref,
        .updated-date {
            animation: pulse 1s infinite;
            @include border-radius(24px);
            min-height: 15px;
            margin: 3px 0 3px 5px;
            display: block;
            &:before {
                content: none;
            }
        }
        h2 {
            width: 300px;
            display: block;
            height: 25px;
            margin: 3px 0 5px;
            float: none;
        }
        .proj-location{
            width: 75px;
            display: block;
            float:left;
        }
        .proj-ref{
            width: 50px;
            display: block;
            float:left;
        }
        .updated-date{
            width: 50px;
            display: block;
            float:left;
            span{
                display: none;
            }
        }
    }
    .favourite-icon {
        background: $mercury; //@include border-radius(50px);
    }
    .dp-nav-buttons {
        // background: $grey;
    }
    .project-information {
        .field-name {
            width: 100px;
        }
        .field-value,
        .field-name {
            animation: pulse 1s infinite;
            @include border-radius(24px);
            min-height: 15px;
            margin: 0 0 10px;
            display: block;
        }
    }
    .icon-project {
        animation: pulse 1s infinite;
        @include border-radius(24px);
        min-height: 15px;
        margin: 0 0 10px;
        display: block;
    }
    .feedback-btn{
            animation: pulse 1s infinite;
            text-indent: -999px;
            overflow: hidden;
            width: 75px;
            margin-right: 5px;
            &:before{
                content: none;
            }
    }
    .print-btn{
        margin-left: 55px;
    }
    .dp-next-button,
    .dp-prev-button,
    .print-btn,
    .back-to-resultpage-button{
            animation: pulse 1s infinite;
            text-indent: -999px;
            overflow: hidden;
            width: 75px;
            &:before{
                content: none;
            }
    }
    .meta-data{
        .col{

            span{
                animation: pulse 1s infinite;
                @include border-radius(24px);
                min-height: 15px;
                margin: 0 0 10px;
                display: block;
                width: 50px;
            }
            div{
                animation: pulse 1s infinite;
                @include border-radius(24px);
                min-height: 15px;
                margin: 0 0 10px;
                display: block;
            }
        }
    }
    .location-name-distance{
        .proj-location, .distance{
                animation: pulse 1s infinite;
                @include border-radius(24px);
                min-height: 20px;
                margin: 0 10px 0 0;
                padding: 5px;
                display: inline-block;
                width: 50px;
                &:before{
                    content: none;
                }
        }
    }

}
.print{
    .placeholder{
        .print-loader{
            color: $white;
            position: fixed;
            font-size: 0.95rem;
            font-weight: 500;
            left: 50%;
            top: 40%;
        }
        .preloader{
            background:url('../images/print-loader.gif') no-repeat left center;
            position: fixed;
            top: 32%;
            width: 64px;
            height: 64px;
            left: 47%;
        }
        .detail-page-wrapper{
            width: 21cm;
            margin: 0 auto;
            top: 10px;
            left: 0;
            background: $white;
            opacity: 0.2;

        }
    }
}

.placeholder {
    .list-group-item {
        background: $white;
        .icon-project {
            background: $mercury;
            @include border-radius(50px);
        }
        .src-location-placeholder {
            width: 200px;
            &:before {
                background: transparent;
            }
        }
        h5,
        .src-location-placeholder,
        .rs-client-name,
        .rs-value {
            animation: pulse 1s infinite;
            @include border-radius(24px);
            min-height: 10px;
            margin: 0 0 5px;
            display: block;
        }
        h5 {
            width: 300px;
        }
        .rs-client-name {
            width: 100px;
            display: block;
            margin: 0 5px 0 0;
            float: left;
        }
        .rs-value {
            width: 50px;
            display: inline-block;
        }
    }
    &.details-page{
        .favourite-icon {
            animation: pulse 1s infinite;
            text-indent: -999px;
            overflow: hidden;
            background: $mercury;
        }
        .print-btn{
            animation: pulse 1s infinite;
            text-indent: -999px;
            overflow: hidden;
            background: $mercury;
            width: 80px;
            margin-left:15px;
        }
        .feedback-icon{
            animation: pulse 1s infinite;
            text-indent: -999px;
            overflow: hidden;
            background: $mercury;
            width: 120px;
            margin-left:15px;
            height: 30px;
        }
    }
    &.team-list-container{
        height: 50px;
        width: 100%;
        background: $mercury;
        animation: pulse 1s infinite;
        .right {
            margin-left: 32%;
            .btn {
                width: 100px;
                height: 40px;
                margin: 5px;
                background: $mercury;
                border: 0px;
            }
        }
        .circle {
            margin: 5px 5px 5px 20px;
            height: 36px;
            width: 36px;
            border-radius: 100px;
            background: $mercury;
        }
        .header {
            margin: 10px 5px 5px 5px;
            height: 15px;
            width: 30%;
            background: $mercury;
            .sub-header {
                margin-top: 20px;
                height: 10px;
                width: 20%;
                background: $mercury;
            }
        }
    }
    .right-block .favourite-icon {
        background-image: none;
        background: $mercury;
        @include border-radius(50px);
        display: none;
    }
    .dp-nav-buttons {
        //background: $grey;
    }
    .project-information {
        .field-name {
            width: 100px;
        }
        .field-value,
        .field-name {
            animation: pulse 1s infinite;
            @include border-radius(24px);
            min-height: 15px;
            margin: 0 0 10px;
            display: block;
        }
    }
    .icon-project {
        animation: pulse 1s infinite;
        @include border-radius(24px);
        min-height: 15px;
        margin: 0 0 10px;
        display: block;
    }
    .address{
        animation: pulse 1s infinite;
        @include border-radius(24px);
        min-height: 15px;
        margin: 0 0 10px;
        display: block;
        width: 50%;
    }
}

.placeholder{
    #searchlistAccordion{
        // position: absolute;
        position: relative;
        .list-group{
            margin: 0 15px 15px;
        }
    }
    .save-container{
        background: $white;
        animation: pulse 1s infinite;
        min-height: 15px;
        margin: 0 0 5px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .column-block{
            position: relative;
            display: flex;
            padding: 0.5em;
            .save-block-wrapper{
                padding-bottom: 13px;
                .save-block{
                    .save-title-placeholder{
                        animation: pulse 1s infinite;
                        @include border-radius(24px);
                        min-height: 25px;
                        margin: 0 0 15px;
                        display: block;
                    }
                    .save-content{
                        animation: pulse 1s infinite;
                        @include border-radius(24px);
                        min-height: 25px;
                        margin: 0 0 15px;
                        display: block;
                    }
                    .btn.btn-secondary{
                        background: $mercury;
                        @include border-radius(50px);
                    }
                }
            }
            .search-button{
                .edit-button-placeholder{
                    animation: pulse 1s infinite;
                    @include border-radius(24px);
                    min-height: 15px;
                    margin: 0 0 10px;
                    display: block;

                    background: transparent none repeat scroll 0 0;
                    border: 0 none;
                    color: $white;
                    font-size: 0.75rem;
                    position: relative;
                    cursor: pointer;
                    line-height: 1.35em;
                    height: 30px;
                    width: 60px;
                    margin: 0;
                    text-indent: -999px;
                    left: -5px;

                }

            }
        }

    }
}


.placeholder{
    .project-show-roles{
        background: $white;
        border: 1px solid $athens-gray;
    }
    .related-Company{
        &:last-child{
            > div{
                border-left: 2px solid $whisper;
            }
        }
        // background: $white;
        .pro-related-company{
            background: $white;
            animation: pulse 1s infinite;
            border-radius: 0;
            margin-bottom: 5px;
            border: 2px solid $athens-gray;
            .result-block{
                background: $white;
                margin-bottom: 5px;
            }
            > span{
                background: $light-grey;
                padding: 0px 5px 5px;
            }
            .result-row-icon-placeholder {
                left: 10px;
                top: 10px;
                position: absolute;
                width: 30px;
                .icon-company{
                    animation: pulse 1s infinite;
                    background: $mercury;
                    @include border-radius(50px);
                }
                .icon-read{
                    animation: pulse 1s infinite;
                    background-color: $mercury;
                    animation: pulse 1s infinite;
                }
            }
        }
        .company-group-name{
            padding: 5px 0 10px;
            display: block;
            position: relative;
            margin-left: 20px;
            border-left: 2px solid $grey;
            padding-left: 15px;
            font-weight: 600;
            &:before{
                animation: pulse 1s infinite;
                background: $white;
                content: "";
                height: 16px;
                left: -9px;
                position: absolute;
                top: 10px;
                width: 16px;
                border: 2px solid $grey;
                border-radius: 50%;
            }
            span{
                background: $white;
                animation: pulse 1s infinite;
                display: inline-block;
                padding: 2px 5px 2px 0;
                background: $white;
                border: 1px solid $athens-gray;
                margin-left: 5px;
                position: relative;
                height: 27px;
                width: 100%;
            }
        }
        .company-role-name{
            background: $white;
            animation: pulse 1s infinite;
            padding: 0 0 0;
            display: block;
            position: relative;
            font-weight: 600;
            &:before{
                background: $red;
                content: "";
                height: 12px;
                left: -22px;
                position: absolute;
                top: 5px;
                width: 12px;
                border: 2px solid $white;
                border-radius: 50%;
            }
        }
        > div{
            border-left: 2px solid $grey;
            padding: 0 0 10px 15px;
            margin: -5px 0 0 20px;
        }
    }
    .result-row-button-block{
        display: none;
    }
}
.my-archive-wrapper{
    .placeholder{
        #searchlistAccordion{
            .list-group {
                margin: 0;
            }
        } 
        .list-group-item{
            padding-top:10px;
        }
    }

}
.updates-tab{
    .placeholder{
        #searchlistAccordion{
            .list-group {
                margin: 0;
            }
        } 
        .list-group-item{
            padding-top:0px;
        }
        .src-location-placeholder,
        .sub-resultdetails-content{
            display: none;
        }
        .result-row-overview{
            margin-top: 10px;
        }
        .result-list-container{
            .result-block{
                padding:0.75rem 0;
            }
        } 
    }

}

.toggle-truncate {
    color: #00b1eb;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 4px;
  }

  .nested-values {
    .list-group-wrapper {
        padding: 0;
        margin: 0;
        list-style: none;

        .list-block {
            clear: both;
            // margin: 0 0 10px;
        }

        label {
            color: $dove-grey;
            font-size: 0.8rem;
            font-weight: 400;
            display: inline-block;
            width: 175px;
            line-height: 1.0em;
        }

        span {
            color: $black;
            font-size: 0.8rem;
            font-weight: 400;
            display: inline-block;
            line-height: 1.0rem;
        }
    }
}

@media (max-width: 767px) {
    .project-information .row .col {
        flex-basis: auto;
    }
}

@media (max-width: 980px) {
    .project-information .row .col {

        &.proj-type,
        &.proj-timing,
        &.proj-planningstages,
        &.proj-contractstages,
        &.proj-sizes,
        &.proj-useclass,
        &.comp-value,
        &.comp-timing,
        &.comp-planningstages,
        &.comp-contractstages,
        &.comp-sizes {
            border-left: 0 none $iron;
        }
    }
}


  .project-information {
    .row .col {
        border-bottom: 1px solid $iron;
        padding: 10px 15px;

        &.proj-type,
        &.proj-planningstages,
        &.proj-contractstages,
        &.proj-sizes,
        &.proj-useclass {
            border-left: 1px solid $iron;

            &.no-contract-stage {
                border-bottom: 0 none
            }
        }

        &.proj-sizes {
            &.col-lg-12 {
                border-left: 0 none;
            }
        }

        &.proj-sizes,
        &.proj-type {
            label {
                color: $dove-grey;
                font-size: 0.8rem;
                font-weight: 400;
                display: inline-block;
                width: 80px;
                line-height: 1.0em;
            }
        }

        &.proj-timing {
            label {
                color: $dove-grey;
                font-size: 0.8rem;
                font-weight: 400;
                display: inline-block;
                width: 80px;
                line-height: 1.0em;
            }
        }

        &.proj-url,
        &.proj-planningstages,
        &.proj-contractstages {
            border-left: 0 none;
        }

        &.proj-contractstages {
            border-bottom: 0 none;
            min-height: 150px;
        }

        &.proj-size-block {
            border-left: 1px solid $iron;
        }

        &.proj-url a {
            word-break: break-all;
            text-decoration: underline;
            display: table;
        }

        &.proj-size-block .proj-sizes {
            border-left: 0 none;
            border-bottom: 0 none;
        }

        &.proj-dev-category {
            border-bottom: 0 none;

            .proj-category,
            .proj-dev-type {
                border-bottom: 1px solid $iron;
                padding: 10px 15px;
            }
        }

        &.proj-planningstages {
            .list-block {
                &.blockLevelStatus {
                    margin-top: 5px;
                    ;

                    label {
                        float: left;
                    }

                    span {
                        display: block;
                        margin-bottom: 5px;
                        padding-left: 180px;
                    }

                    &:after {
                        clear: both;
                        content: '';
                        float: none;
                        display: block;
                    }
                }
            }
        }
    }

    .row:last-child .col {
        //border-bottom:0 none;
    }

    .field-name {
        color: $black; // text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 600;
    }

    .field-value {
        color: $black;
        font-size: 0.8rem;
        font-weight: 400;
        line-height: 1.0rem;
    }

    .mat-cat-container {
        border-left: 1px solid $iron;
        padding-left: 15px;

        .settings-tab-wrapper {
            margin: 10px;
            padding-bottom: 10px !important;

            a {
                padding: 10px 5px;
                margin: 0 5px;
                font-weight: 700;
                color: $abi-blue;
                opacity: 0.5;
                cursor: pointer;
            }

            a.active {
                border-bottom: 3px solid $cerulean;
                opacity: 1;
            }
        }

        .mat-cat-panel {
            height: 426px;
            overflow: hidden;
            margin-bottom: 10px;
            position: relative;
            top: 0;
            padding-left: 10px;

            .material,
            .category {
                .input-group {
                    margin-bottom: 10px;

                    &::before {
                        background: $transparent url($iconsprite) no-repeat scroll -90px 0px;
                        content: "";
                        height: 30px;
                        left: 0;
                        position: absolute;
                        top: 2px;
                        width: 30px;
                        z-index: 999;
                    }

                    input {
                        padding-left: 30px;
                    }

                    .form-control {
                        padding: 0;

                        input {
                            margin: 0;
                            padding: 0;
                            padding-left: 31px;
                            border: 0;
                            height: 32px;
                        }
                    }

                    .clear-textfield {
                        right: 2px;
                        top: 2px;
                    }
                }

                .tree-list {
                    height: 390px;
                    .details-panel-scrollbar {
                        position: absolute !important;
                        right: 0;

                        .box {
                            overflow-x: hidden !important;
                        }
                    }

                    ul {
                        background-color: #fff !important;

                        li {
                            div {
                                //border-bottom: 1px solid red; 
                                background-color: #fff !important;
                                font-size: .8rem;
                                line-height: 28px;
                                color: $black;

                                svg {
                                    border-color: transparent black;
                                    border-style: solid;
                                    border-width: 4px 0px 4px 6px;
                                    height: 0px;
                                    width: 0px;
                                    left: 4px;
                                    position: absolute;
                                    top: 4px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }

}

.proj-sizes {
    .nested-values {
        .list-group-wrapper {
            .list-block {
                display: inline-block;
                width: 50%;
                clear: none;
                min-height: 24px;
            }

            label {
                width: 80px;
                line-height: 1.0em;
            }
        }
    }

    &.lessthan12 .nested-values .list-group-wrapper .list-block {
        min-height: 24px;
        // margin: 0 0 5px;
    }

    &.morethan12 .nested-values .list-group-wrapper .list-block {
        min-height: 24px;
        // margin: 0 0 5px;
    }
}


@keyframes pulse {
    0% {
        background-color: $dark-gray-78;
    }
    50% {
        background-color: $grey;
    }
    100% {
        background-color: $dark-gray-78;
    }
}
.preloader{
    background:url(../images/preloader.gif) no-repeat left center;
    position: absolute;
    left: 60px;
    top: 25px;
    overflow: hidden;
    width: 100px;
    text-indent: -999px;
}