@import '../../components/common/base/colors';
@import '../../components/common/base/mixins';

$iconsprite: '../../components/common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../../components/common/images/webapp-icons-sprite@2x-v3.png';

.event-table-page{
    li{
        &.list-group-item {
            input[type=checkbox]:checked{
                ~.checkmark{
                    &:after{
                        left: 9px !important;
                        top: 5px !important;
                        width: 6px !important;
                        height: 13px !important;
                    }
                }
            }
            .checkmark{
                width: 26px !important;
                height: 26px !important;
                left: 13px !important;
                top: -1px !important;
                &:hover{
                    &:after{
                        left: 9px !important;
                        top: 5px !important;
                        width: 6px !important;
                        height: 13px !important;
                    }
                }
                &:checked{

                }
            }
            .clock-icon{
                background:url($iconsprite) no-repeat scroll -123px -542px;
                content: "";
                height: 24px;
                width: 24px;
                margin-right: 5px;
                @media (-webkit-min-device-pixel-ratio: 2),
                      (min-resolution: 192dpi) {
                          background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                          background-size: 300px;
                      }
                  @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                      background: #ffffff url($retina-icon-sprite) no-repeat scroll -123px -542px;
                      background-size: 300px;
                  }
              }
        }
    }
    &.main-container{
        top: 55px;
        padding-left: 45px;
        margin-left: 0;
        padding-right: 0;
        left: 13px;
        position: fixed;
    }
    .search-result{
        &.list-view-panel {
            .result-wrapper{
                left: 65px;
                overflow: auto;
                top: 205px;
                bottom: 0;
                position: fixed;
                transition: all .2s linear;
                opacity: 1;
                width: calc(100% - 66px);
            }
            ul.list-group{
                top:266px;
                position: fixed;
                left: 79px;
                right: 10px;
                bottom: 0;
                z-index: 1;
            }
            .event-empty-state{
                img{
                    max-width: 300px;
                }
            }
        }
        
    }
    .multiselect-container{
        button{
            width:170px;
            font-weight: 400;
            font-size: .9rem;
            position: relative;
            padding: 6px 8px;
            border: 1px solid $border-grey;
            box-shadow: none;
            & + div{
                border:1px solid rgba(0,0,0,.15);
                .css-1vr111p-option{
                    &:hover{
                        background-color: $wildsand-light;
                        cursor: pointer;
                    }
                }
                & + div{
                    display: none;
                }
            } 
        }
    }
    .event-filter{
        .dropdown{
            button{
                width:170px;
                font-weight: 400;
                font-size: .9rem;
                position: relative;
                padding: 7px 30px 7px 8px;
                font-weight: 400;
                text-align: left;
                color: #292b2c;
                background-color: #fff;
                border-color: #ccc;
                &:hover,&:focus{
                    background: $white;
                    color: $black-grey;
                }
                &:after{
                    border: 0;
                    background: transparent url($iconsprite) no-repeat scroll -220px -73px;
                    height: 15px;
                    width: 11px;
                    right: 8px;
                    top: 10px;
                    position: absolute;
                }
            }
            .dropdown-menu{
                button{
                    cursor: pointer;
                    &:hover{
                        background-color: $wildsand-light;
                        color: #1d1e1f;
                    }
                }
            }
        } 
    }
    
    
    .react-add-to-calendar__button{
        display: block;
        background: #fff;
        border-color: $border-grey;
        border-radius: 2px;
        span{
            font-size: 0.825rem;
        }
    }

    .filter-button-cnt{
        padding-top: 22px;
        .delete-selected{
            background: none;
            border: none;
            color: $black-grey;
            position: relative;
            cursor: pointer;
            padding-left: 25px;
            &:before{
                content:"";
                height: 20px;
                width: 20px;
                background: transparent url($iconsprite) no-repeat scroll -183px -95px;
                position: absolute;
                left:0;
                top: 9px;
                @media (-webkit-min-device-pixel-ratio: 2),
                (min-resolution: 192dpi) {
                    background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                    background-size: 300px;
                }
                @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                    background: $transparent url($retina-icon-sprite) no-repeat scroll -183px -95px;
                    background-size: 300px;
                }
            }
        }
        .btn.btn-primary {
            padding: 7px 15px;
        }
    }

    .mr-auto {
        margin-right: auto;
    }
    
    .ml-auto, .mx-auto {
        margin-left: auto!important;
    }
    
    .mr-2 {
        margin-right: 0.5rem!important;
    }
    
    .pl-4, .px-4 {
        padding-left: 1.5rem!important;
    }
    
    .pr-4 {
        padding-right: 1.5rem!important;
    }

    .result-list-container {
        padding: 10px 8px 10px 15px;
    }

    .calendar-container {
        .calendar-name {
            margin-bottom: 0;
            padding-left: 30px;
            color: #000;
            a {
                color: #000;
                cursor: pointer;
            }
        }
        .calendar-description {
            margin-bottom: 0;
            padding-left: 30px;
            font-size: .75rem;
        }
        .edit-button {
            background: transparent;
            border: none;
            cursor: pointer;
        }
        .list-table {
            .list-tablerow {
                .list-col {
                    .dropdown {
                        button {
                            width: 100%;
                            min-width: 140px;
                            text-align: left;
                            font-weight: 400;
                            position: relative;
                            color: #464a4c;
                            padding: .4rem .75rem;
                            font-size: .825rem;
                            border-radius: 3px;
                            background: #fff;

                            &:after {
                                right: 10px;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                            }

                            a {
                                color: #000;
                            }
                        }
                    }
                    
                    span {
                        &.low {
                            background: rgb(234, 237, 250);
                            padding: 3px 5px;
                            line-height: 1;
                        }
                        &.high {
                            background: rgb(254, 206, 206);
                            padding: 3px 5px;
                            line-height: 1;
                        }
                        &.normal {
                            background: rgb(195, 200, 221);
                            padding: 3px 5px;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }
}