@import '../common/base/colors';
@import '../common/base/mixins';

$iconsprite: '../common/images/webapp-icons-sprite-v3.png';
$retina-icon-sprite: '../common/images/webapp-icons-sprite@2x-v3.png';
/*----------------------------------------------------------------------------------
    Project details page
----------------------------------------------------------------------------------*/
.details{
    padding: 57px 0 0 63px;
}
.details-page {
    .top-info {
        position: relative;
    }

    .detail-page-wrapper{
        height: calc(100vh - 57px) !important;
    }

    .project-overview {
        bottom: 0;
        min-height: 50px;
        position: relative;
        background: rgba(255, 255, 255, 0.85);
        padding: 5px 0 0 50px;
        left: 0;
        right: 0;
        display: block;
        @media only screen and (max-width: 768px) {
            padding-left: 0;
            .project-details{
                height: 190px;
                padding-left: 60px;
                margin-top: 10px;
            }
          }
        
    }


    .project-info{
        flex-direction: row-reverse;
        @media (max-width: 820px) {
            flex-direction: row;
        }

    }
    .header-section {
        background: $white;
        border-bottom: 2px solid $alto;
    }

    .project-overview {
        h2 {
            color: $black-grey;
            font-size: 1.5rem;
            font-weight: 700;
            line-height: 1;
            @media (max-width: 820px) {
                line-height: 1;
            }
            svg{
                margin-left:-50px;
                vertical-align: top;
            }
            strong{
                margin-right: 10px;
                margin-bottom: 5px;
                display: block;
                margin-top: 2px;
                display: inline-block;
                line-height: 1;
            }
        }
    
        .new-pro-flag,
        .project-build-phase-pro-flag,
        .archive-badge-flag {
            font-size: .8rem;
            padding: 2px 3px 3px;
            min-width: 58px;
            text-align: center;
            vertical-align: middle;
            margin:0 0 5px;
            line-height: 1;
            display: inline-block;
        }

        .planning-portal-badge {
            width: 136px;
            height: 35px;
            margin-left: 10px;
        }
    
        .icon-project {
            @include border-radius(80px);
            height: 30px;
            left: 13px;
            position: absolute;
            width: 30px;
            margin: 0;
            top: 5px;
        }
    }

    .address {
        font-size: 12px;
        color: $black-grey;
        width: 100%;
        margin-top:-5px;
        line-height: 1.2;
        span {
            color: $dark-grey;
        }
    }

    .meta-data {
        .col {
            border-right: 1px solid $alto;
            font-size: 18px;
            max-width: 140px;
            line-height: 1rem;
            font-weight: 500;
            padding:3px 10px;
            span {
                font-size: 12px;
                color: $dark-grey;
                display: block;
                line-height: 1em;
                margin: 0 0 5px;
            }
    
            &:last-child {
                border-right: 0 none;
            }
        }
    
        .next-research-call {
            border: 0 none;
        }
    
        .researcher {
            max-width: 200px;
            padding-left: 55px;
            position: relative;
            img {
                position: absolute;
                left: 15px;
                top: 4px;
            }
        }
    
        .site-progression {
            min-width: 140px;
            position: relative;
            display: flex;
    
            div {
                color: #3AAB73;
                font-weight: 600;
            }
        }
    }

    /*Location project detail slider*/
    .imageSlider {
        position: relative;
        margin-right: 20px;
        z-index: 1000;
        height: 230px;

        @media (max-width: 820px) {
            position: absolute;
            right: 0;
            top:20px;
            height: 180px;
            width:35%;
        }
    
        .location-projectImg {
            >div {
                height: 220px;
                background: none center top no-repeat;
                // background-size: 100% auto;
                position: relative;
                background-color: #ccc;
                // background-size: 100% 100%;
                background-size:cover;
    
            }
    
            img {
                width: 100%;
                // opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                object-fit: cover;
            }
    
            .image-message {
                background: $white;
                position: absolute;
                left: 0;
                right: 0;
                top: 175px;
                padding: 5px 10px;
                height: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    
        .slick-next,
        .slick-prev {
            background-image: url($iconsprite);
        }
    
        .slick-next.slick-disabled,
        .slick-prev.slick-disabled {
            opacity: 0.4;
        }
    
        .overlayCntr {
            display: none;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 9999;
            background: rgba(0, 0, 0, 0.75);
    
            .overlayBox {
                width: 100vh;
                height: 100vh;
                margin: 50px auto 0;
    
                .overlayBody {
                    width: 100%;
                }
    
                .overlayContent {
                    max-width: 1024px;
                    min-height: 236px;
                    position: relative;
                    margin: 0 auto;
    
                    //background: url(images/map-placeholder.jpg) center center no-repeat;
                    .fill-img,
                    .overlayimg {
                        width: 100%;
                    }
    
                    .overlayimg {
                        position: relative;
                        background: $dark-black-gray;
                        img {
                            max-width: 90%;
                            max-height: 70vh;
                        }
                        .img-src-attribution{
                            position: static;
                            z-index: 1;
                            background: $white;
                            padding: 10px;
                            text-align: left;
                            line-height: 1.0rem;
                            &:empty{
                                display: none;
                            }
                        }
                    }
                    .prev,
                    .next {
                        position: absolute;
                        top: 50%;
                        background-image: url($retina-icon-sprite);
                        width: 45px;
                        height: 45px;
                        z-index: 1;
                        margin-top: -25px;
                    }
    
                    .prev {
                        left: -50px;
                        background-position: -496px -67px;
                    }
    
                    .next {
                        right: -50px;
                        background-position: -539px -67px;
                    }
    
                    .closeoverlay {
                        color: #fff;
                        font-size: 60px;
                        line-height: 28px;
                        position: absolute;
                        right: -40px;
                        top: -40px;
                        height: 38px;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                }
            }
        }
    
        .openOverlay {
            display: block;
        }
    
        .animated {
            -webkit-animation-duration: 1s;
            animation-duration: 1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    
        @-webkit-keyframes fadeIn {
            from {
                opacity: 0;
            }
    
            to {
                opacity: 1;
            }
        }
    
        @keyframes fadeIn {
            from {
                opacity: 0;
            }
    
            to {
                opacity: 1;
            }
        }
    
        .fadeIn {
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
        }
    }
    #slideshow{
        .react-slideshow-container {
            .nav{
                &.disabled{
                    opacity: 0.3;
                }
            }
        }
        .react-slideshow-wrapper{
            height: 220px;
            .each-slide{
                position: relative;
                .img-src-attribution{
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: auto;
                    z-index: 1;
                    background: rgba(0,0,0,0.6);
                    color: $white;
                    padding: 10px;
                    text-align: left;
                    line-height: 1.0rem;
                    &:empty{
                        display: none;
                    }
                }
            }
        }
    }

.project-tab-wrapper, .company-tab-wrapper {
    .framework-container {
        border: none;

        .tab-content {
            width: 100%;

            .list-wrapper-other-pro {
                margin: 0px;

                .user-ctrl-btn {
                    border: none;
                    margin-top: 10px;
                    margin-bottom: 15px;
                    text-align: right;
                    
                    &:hover {
                        border: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
    .call-info-Added{
        color: $dark-green;
        span{
            color: $dark-green !important;
        }
        p{
            margin-bottom: 0;
            color: $dark-green;
        }
        .call-info-added-icon{
            background-color:$dark-green;
            border-radius: 50%;
            display: inline-block;
            height: 30px;
            width: 30px;
            text-align: center;
            margin-right: 5px !important;
            svg{
                margin-top:5px;
            }
        }
    }

    .dp-nav-buttons-wrapper {
        width: 100%;
        margin: 0;
        @media only screen and (max-width: 820px) {
            justify-content: flex-start !important;
            .right-side-button{
                justify-content: flex-end;
                width: 360px;
                display: inline-flex;
            }
          }
      }

      .feedback-cta {
        position: relative;
        // right: 205px;
  
        .feedback-panel {
          position: absolute;
          width: 300px;
          top: 30px;
          right: 0;
          background: $white;
          box-shadow: 0 3px 10px $dark-grey;
          padding: 10px;
          z-index:2;
  
          &::before {
            background: $transparent url(#{$iconsprite}) no-repeat scroll -60px -270px;
            content: "";
            position: absolute;
            right: 0;
            width: 30px;
            height: 30px;
            top: -19px;
          }
  
          .text-area-counter {
            font-size: 0.75rem;
            position: absolute;
            bottom: -15px;
            right: 0;
          }
  
          .btn-send {
            font-size: 0.85rem;
            position: relative;
            line-height: 1em;
            margin: 0;
            left: -5px;
            top: 3px;
            margin-left: 5px;
          }
  
          .btn-feedback-cancel {
            font-size: 0.85rem;
            position: relative;
            line-height: 1em;
            top: 3px;
          }
  
          .note-list-wrapper {
            .note-form {
              .note-input {
                width: 100%;
  
                textarea {
                  width: 100%;
                  font-size: 0.9rem;
                }
              }
            }
          }
        }
      }

.updates-details {
        border: 1px solid $grey;
        .update-list {
          padding: 5px 10px;
          align-items: center;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid $grey;
            .update-desc {
                font-size: 0.85rem;
            }
            .time {
            font-size: 0.85rem;
          }
        }
      }
    
      .project-tab-panel{
        width: 100%;
        background-color:$wildsand-light;
            .print-contact-people{
                width: 100%;
                padding: 0 25px;
                .Project-print-wrapper{
                    // width: 100%;  padding: 0 10px;
                    margin-bottom: 10px;
                    //border-bottom: 5px solid $grey;
                    > strong{
                        padding: 10px 0 5px;
                        display: block;
                        font-size: 1.2rem;
                    }
                    .tab-pane{
                        margin-bottom: 10px;
                        .project-role-wrapper{
                            padding: 0;
                            background: none;
                            box-shadow: none;
                            margin: 0;
                            .prw-heading{
                                background: $mercury;
                                position: relative;
                                top: 0;
                                color: $black;
                                font-size: 1rem;
                                padding: 5px 10px;
                                border-radius: 0;
                                margin: 0 0 15px;
                            }
                            .project-role-name{
                                background: none;
                                position: relative;
                                top:0;
                                color: $black;
                                font-size: 1rem;
                                padding: 0;
                                font-weight:700; 
                            }
                            .people-on-proj-title{
                                color: $black;
                                font-size: 1rem;
                                padding: 0;
                                font-weight:700;
                            }
                            .result-list-container{
                                margin: 0;
                                padding: 0;
                                .company-container.read-block{
                                    background: $white;
                                }
                                #searchlistAccordion{
                                    margin: 0;
                                    padding: 0;
                                    li.list-group-item{
                                        border: none;
                                        margin-bottom: 15px;
                                        border-bottom: 1px solid $mercury;
                                        &:last-child{
                                            border-bottom: 0 none;
                                        }
                                        &:hover{
                                            box-shadow: none;
                                            border: none;
                                        }
                                        .checkmark{
                                            display: none;
                                        }
                                        .company-container{
                                            .result-block{
                                                cursor: default;
                                                pointer-events: none;
                                                padding: 0;
                                                .result-title-name{
                                                    font-size: 0.9rem;
                                                    color: $black;
                                                    font-weight: 700;
                                                }
                                            }
                                            .related-Company-btn{
                                                display: none;
                                            }
                                            .result-row-overview{
                                                padding: 0 0 10px 0;
                                                min-height: auto;
                                                .showIconCounts{
                                                    display: none;
                                                }
                                                .com-address-print{
                                                    margin: 0;
                                                    padding: 0;
                                                }
                                                .comp-data{
                                                    padding-right: 0;
                                                    color: $black;
                                                    // border-right: 1px solid $black;
                                                    padding-left: 0;
                                                    &::after{
                                                        content: "|";
                                                        position: relative;
                                                        margin:0 10px
                                                    }
                                                    &:last-child{
                                                        &::after{
                                                            content: "";
                                                        }
                                                    }
                                                    &:first-child{
                                                        padding-left: 0;
                                                    }
                                                }
                                                .comp-address{
                                                    padding: 0;
                                                    color: $black;
                                                }
                                            }
                                            .team-contact-list{
                                                strong{
                                                    font-size: 0.95rem;
                                                    color: $dark-grey;
                                                }
                                                .row{
                                                    margin: 0;
                                                    padding: 0;
                                                }
                                                .user-contact-details{
                                                    background: $white;
                                                    border: none;
                                                    display: flex;
                                                    flex-direction: column;
                                                    width: 100%;
                                                    color: $black;
                                                    margin: 0 0 10px;
                                                    min-height: auto;
                                                    padding: 3px 0;
                                                    font-weight: 700;
                                                    font-size: .85rem;
                                                    word-wrap: break-word;
                                                    &:before{
                                                        content: none;
                                                    }
                                                    p{
                                                        font-size: .85rem;
                                                        font-weight: 700;
                                                    }
                                                    div{
                                                        padding:0;
                                                        margin:0;
                                                        font-weight: 400;
                                                        font-size: .85rem;
                                                        &:last-child{
                                                            margin-right:0;
                                                            // max-width: 30%;
                                                        }
                                                    }
                                                    .ucd-email{
                                                        &:after{
                                                            content: "|";
                                                            margin: 0 10px;
                                                        }
                                                        &:empty{
                                                            display: none;
                                                            &:after{
                                                                content: none;
                                                            }
                                                        }
                                                    }
                                                    .ucd-mobile,
                                                    .ucd-phone{
                                                        &:empty{
                                                            display: none;
                                                        }
                                                    }


                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .material-print-wrapper{
                    border-bottom: 5px solid $mercury;
                    padding-bottom: 10px;
                    width: 100%;
                    strong{
                        font-size: 1.2rem;
                        padding: 10px 0 5px;
                        display: block;
                    }
                    .material-list-group{
                        margin: 15px 0 30px;
                        padding: 0;
                        border-top: 1px solid #e7e7e7;
                        border-bottom: 0 none;
                        border-left: 0 none;
                        border-right: 0 none;
                        li{
                            padding:0px;
                            border-bottom:1px solid $mercury;
                            page-break-inside: avoid;
                            .hr-material{
                                font-size: .9rem;
                                margin: 10px;
                                color: $black;
                                font-weight: 700;
                            }
                            .material-brand{
                                background: $wildsand;
                                font-size: .9rem;
                                padding:10px;
                                margin-bottom: 0;
                                &:empty{
                                    display: none;
                                }
                            }
                        }
                    
                    }
                    .material-list{
                        -webkit-column-count: 1; /* Chrome, Safari, Opera */
                        -moz-column-count: 1; /* Firefox */
                        column-count: 1;
                        padding: 0;
                        margin: 0;
                        color: $black;
                        li{
                            border-bottom: 1px solid $mercury;
                            list-style-position: inside;
                            margin: 0;
                            list-style: none;
                            &.d-flex{
                                padding:5px 0 10px 0;
                            }
                            .level-one{
                                width: 150px;
                            }
                            .material-list-first{
                                margin: 0;
                                padding: 0;
                                li{
                                    padding-bottom: 0;
                                    border: 0 none;
                                    list-style: none;
                                    .level-two{
                                        width: 150px;
                                    }
                                    .material-list-second{
                                        display: block;
                                        margin: 0;
                                        padding: 0;
                                        width: 430px;
                                        li{
                                            display: inline-block;
                                            &:last-child{
                                                &::after{
                                                    content: none;
                                                }
                                            }
                                            &::after{
                                                content: ", ";
                                                display: inline-block;
                                                background: none;
                                                margin-right: 5px
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child{
                            li{
                                border-bottom:0 none;
                            }
                        }
                    }
                }
            }
    }

    /*------------------------------------------------------------
    Project tab wrapper
------------------------------------------------------------*/

.project-tab-wrapper, .company-tab-wrapper {
    background-color: $white;
    .group-companies-container{
        .tab-content{
            .list-wrapper-other-pro{
                margin-top: 0;;
            }
        }
    }
    
    .nav-tabs {
        border-bottom: 5px solid $abi-blue;
        .nav-link{
            color: $black;
            border-radius: 0;
        }
        .nav-link.active,
        .nav-item.show .nav-link {
            background-color: $abi-blue;
            border: 0 none;
            color: $white;
            font-weight: 600;
            @include border-radius(0px);
        }

        .nav-link,
        .nav-link:focus,
        .nav-link:hover {
            display: block;
            padding: 5px 15px; // text-transform: uppercase;
            border: 0 none;
            cursor: pointer;
            font-size: 0.9rem;
        }

        .nav-item {
            margin-bottom: 0px;
        }

        &.flex-column {

            .nav-link.active,
            .nav-item.show .nav-link {
                background-color: $link-water-new;
                border: 0 none;
                color: $black;
                @include border-radius(0px);
                position: relative;
            }

            .nav-link,
            .nav-link:focus,
            .nav-link:hover {
                display: block;
                font-weight: 400;
                font-size: 0.85rem;
                padding: 10px 15px; // text-transform: uppercase;
                border: 0 none;
                cursor: pointer;
            }

            .nav-item {
                border-bottom: 1px solid $link-water-new;

                &:last-child {
                    // border-bottom: 0 none;
                }
            }

            .nav-link.active::after {
                border-bottom: 6px solid $transparent;
                border-left: 8px solid $white;
                border-top: 6px solid $transparent;
                content: "";
                height: 0;
                position: absolute;
                left: -1px;
                top: 14px;
                width: 0;
            }
        }
    }

    .tab-content {

        // padding: 15px;
        .roles-and-companies {
            .search-selector {
                .select-actions {
                    border-left: 1px solid #dedbdf;
                    .action-list {
                        min-width: 7.7rem;
                    }
                }
            }
        }

        .tab-content {
            padding: 0 15px 0 0;
            height: 100%;
            position: relative;
            .selector-ctrl-fav {
                position: relative;
                top: 0;
                left: 0px;
            }
        }

        .input-group {
            .btn-share {
                font-size: 0.85rem;
                position: relative;
                line-height: 1.50em;
                margin: 0;
                left: 0;
                margin-left: 0;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                padding: 9px 20px;
            }

        }

        // .users-list{
        //   padding: 10px 0;
        //   span{
        //     .user-profile-icon{
        //       background-color: $grey;
        //       border-radius: 50%;
        //       padding: 8px 0;
        //       margin-right: 10px;
        //       font-size: .7rem;
        //       height: 35px;
        //       font-weight: 700;
        //       border: 2px solid $white;
        //       width: 35px;
        //       display: inline-block;
        //       text-align: center;
        //     }
        //   }
        //   .odd{
        //     background: $light-grey;
        //   }
        //   .email-address {
        //     color: $emperor-gray;
        //     font-size: 0.7rem;
        //     margin: 0;
        //     font-weight: 400;
        //   }
        // }
    }

    .flex-column .tab-content {
        padding: 15px;
    }

    .nav.nav-tabs.flex-column {
        padding-bottom: 0;
        background-color: $white;
        border: 0 none;
    }
}

.tab-content .content-panel {
    position: static;
    padding: 0;
    margin: 0 auto;
    right: 0;
    left: 0;
    width: 100%;
}

@media (max-width: 980px) {
    .project-tab-wrapper .tab-content .tab-content {
        padding: 0;
    }
}
.details-page {
    .box {
      padding: 0 15px 0 17px !important;
    }
}

  
.location-name-distance {
    padding: 10px 20px;
    position: absolute;
    bottom: 0;
    background: rgba(0,0,0,.7);
    right: 0;
    left: 0;
    min-height: 40px;
    color: $white;
    .comp-location,
    .proj-location,
    .distance{
        display: inline-block; 
        vertical-align: middle; 
        margin-bottom: 0 !important;
        color: $white;
    }
    .comp-location:before,
    .proj-location:before{
        background-position: -150px -30px;
        left: -15px;
    }
}

.project-info-wrapper {
    background: $white; //@include box-shadow(0, 2px, 5px, $grey);
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: $alto;
}

.project-tab-wrapper {
    background: $white; //@include box-shadow(0, 2px, 5px, $grey);
    border-style: solid;
    border-width: 2px;
    border-color: $alto;
    .contact-panel{
        .linkedin-icon{
            right: 25px;
        }
    }
}

.map-view{
    position: relative;
    img {
        height: 200px;
        object-fit: cover;
        object-position: center center;
        width: 100%;
    }
} 

  &.placeholder{
      display: block;
      background-color: transparent;
      .project-overview {
          display: inline-block;
          width: auto;
          padding-left: 45px;
      }
      .image-curosal{
          position: relative;
      }
      .favourite-icon{
          height:30px;
          width: 40px;
      }
  }

  .details-panel-scrollbar {
        .box {
            //height: calc(100vh - 58px);
            overflow-x: hidden !important;
            padding-left: 0;
            padding: 0 15px 0 17px;
            & + div{
                display: none;
            }
        }
    }

    .tree-list {
        height: 400px;
        .material-list {
            .box {
                padding: 0;
                margin-bottom: 0 !important;
            }
        }

        ul {
            background-color: #fff !important;
            list-style: none;
            padding: 0;
            margin: 0;
            li {

                div {
                    //border-bottom: 1px solid red; 
                    background-color: #fff !important;
                    font-size: .8rem;
                    line-height: 28px;
                    color: $black;

                    svg {
                        border-color: transparent black;
                        border-style: solid;
                        border-width: 4px 0px 4px 6px;
                        height: 0px;
                        width: 0px;
                        left: 4px;
                        position: absolute;
                        top: 4px;
                    }

                }
            }
        }
    }

    .mat-cat-container {
        border-left: 1px solid $iron;
        padding-left: 15px;

        .settings-tab-wrapper {
            margin: 10px;
            padding-bottom: 10px !important;

            a {
                padding: 10px 5px;
                margin: 0 5px;
                font-weight: 700;
                color: $abi-blue;
                opacity: 0.5;
                cursor: pointer;
            }

            a.active {
                border-bottom: 3px solid $cerulean;
                opacity: 1;
            }
        }

        .mat-cat-panel {
            height: 426px;
            overflow: hidden;
            margin-bottom: 10px;
            position: relative;
            top: 0;
            padding-left: 10px;
            input{
                outline: none;
                width: 100%;
            }
            .category {
                .tree-list {
                    ul{
                        padding-left: 20px;
                    }
                }
            }
            .material,
            .category {
                .input-group {
                    margin-bottom: 10px;
                    position:relative;
                    border:none;
                    &::before,&::after{
                        display: none;
                    }

                    input {
                        padding-left: 30px;
                    }

                    .search-icon{
                        position: absolute;
                        top:2px;
                        left:1px;
                        z-index: 1;
                    }

                    .form-control {
                        padding: 0;
                        margin-left: 0 !important;
                        input {
                            margin: 0;
                            padding: 0;
                            padding-left: 31px;
                            border: 0;
                            height: 32px;
                        }
                    }

                    .clear-textfield {
                        right: 2px;
                        top: 2px;
                    }
                }

                .tree-list {
                    height: calc(390px - 35px);
                    .details-panel-scrollbar {
                        position: absolute !important;
                        right: 0;
                        .box {
                            margin-bottom: 0 !important;
                            overflow-x: hidden !important;
                        }
                    }

                    ul {
                        background-color: #fff !important;
                        li {
                            div {
                                //border-bottom: 1px solid red; 
                                background-color: #fff !important;
                                font-size: .8rem;
                                line-height: 28px;
                                color: $black;

                                svg {
                                    border-color: transparent black;
                                    border-style: solid;
                                    border-width: 4px 0px 4px 6px;
                                    height: 0px;
                                    width: 0px;
                                    left: 4px;
                                    position: absolute;
                                    top: 4px;
                                }

                            }
                        }
                    }
                }
            }
        }
    }


    /*------------------------------------------------------------
    Material Tab
------------------------------------------------------------*/
.project-information .mat-cat-container .mat-cat-panel .material {
    .tree-list{
        .box{
            padding:0;
            margin-bottom: 0 !important;
        }
        .category-list-container{
            ul{
                padding-left: 20px;
            }
        }
        .material-list-group{
            li{
                .material-brand{
                    background: $wildsand!important;
                    line-height: 1.3rem;
                }
            }
        }
    }
}
.material-list-group{
    margin: 0 15px 0 0;
    padding:0;
    border:1px solid $mercury;
    li{
        padding:5px 10px;
        border-bottom:1px solid $mercury;
        &:last-child{
            border-bottom:0 none;
        }
        .hr-material{
            font-size: 14px;
            font-weight: 600;
        }
        .material-brand{
            background: $wildsand;
            font-size: 13px;
            padding:10px;
            margin-bottom: 5px;    
            line-height: 1.3rem;
            &:empty{
                display: none;
            }
        }
    }

    

}

    
    

}

.material-list{
    li{
        ul{padding-left: 30px;}
    }
}

.event-listing{
    .event-type-icon{
        position: absolute;
        height: 30px;
        left: 10px;
        position: absolute;
        top: 21px;
        width: 30px;
    }
    .dropdown{
        button{
            width: 100%;
            min-width: 140px;
            text-align: left;
            font-weight: 400;
            position: relative;
            color:#464a4c;
            padding: .4rem .75rem;
            font-size: .825rem;
            border-radius: 3px;
            background: $white;
            &:hover,&:focus{
                background: $white;
            }
            &:after {
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .dropdown-menu {
            border-radius: 0;
            button {
                padding: .1rem .75rem;
            }
        }
        a{
            color: $black;
        }
    }
}

.event-list-wrapper{
    width: 100%;
    position: relative;
    .add-event-button{
        width: auto;
        position: absolute;
        right: 5px;
        top: 3px;
        .primary-btn{
            padding: 7px 20px;
        }
    }
    .event-scheduled-time{
        dropdown-toggle{
            background: $white;
            &:hover,&:active{
                color: $black-grey;
            }
        }
    }
}

.event-list{
    .delete-alert{
        position: fixed;
        top: 61px;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(0,0,0,.8);
        color: #fff;
        padding: 17px;
        min-width: 290px;
        border-radius: 8px;
        z-index: 4;
        text-align: center;
        opacity: 0;
        display: none;
        transition: all 0.5s ease;
        &.show{
            opacity: 1;
            display: block;
        }
        &:before{
            background: #5cb85c url($iconsprite) no-repeat scroll -270px -90px;
            bottom: 0;
            content: "";
            top: 15px;
            left: 30px;
            height: 30px;
            position: absolute;
            width: 30px;
            overflow: hidden;
            display: block;
            border-radius: 50%;
        }
        button{
            background: none;
            border: none;
            cursor: pointer;
            color: $fiter-active;
            text-decoration: underline;
            margin-left: 10px;
            }
        }
        
    }

    .share-details{
        .label-list{
            .share-details-wrapper{
                .label-placeholder{
                    padding-left: 30px;
                }
            }
        }
    }

    .round-border{
        border: 1px solid $border-grey;
        border-radius: 50%;
        padding:3px;
    }


    .more-actionsheet {
        position: absolute !important;
        right: 0;
        top: 10px;
        .btn-secondary,
        .btn-secondary.active,
        .btn-secondary:active {
            color: transparent;
            background-color: transparent;
            background-image: none;
            border-color: transparent;
            text-indent: -999px;
            overflow: hidden;
            background: $transparent url($iconsprite) no-repeat scroll -210px -240px;
            width: 30px;
            height: 30px;
            cursor: pointer;
            border: 0;
            &:hover, &:focus{
                background: $transparent url($iconsprite) no-repeat scroll -210px -240px;
            }
        }
        .dropdown-item {
            cursor: pointer;
            position: relative;
            padding: 10px 15px;
            font-weight: 600;
            font-size: .85rem;
            &:first-child{
                &:before {
                    background: $transparent url($iconsprite) no-repeat scroll -60px -270px;
                    content: "";
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    top: -19px;
                    right: 2px;
                }
            }
        }
        .dropdown-menu {
            padding: 0;
            @include border-radius(0px);
            width: 120px;
            top: 40px;
            right: 0;
        }
    }

    .project-information{
        .row{
            .col{
                border-width: 2px !important;
                &.proj-dev-category{
                    .proj-category, .proj-dev-type{
                        border-width: 2px !important;
                    }
                }
            }
        }
    }

/*==============================================================================================
    Roles tab on ABI project details pages
==============================================================================================*/

    .project-role-wrapper {
        background: $wildsand;
        padding: 20px 20px;
        @include box-shadow(0, 0, 5px, $grey, inset);
        position: relative;
        margin: 0 0 25px 0;
        .result-list-container .related-Company-btn{
            right:0 !important;
            left: auto;
            &:after{
                top:50%;
                transform: translateY(-50%);
            }
        }
        .result-list-container li.list-group-item {
            margin: 0 0 0px;

            .project-container {
                width: 100%;
            }

            .company-container {
                width: 100%;
                padding-left: 0;
            }
        }
    }
    .project-role-name {
        background: $air-force-blue;
        padding: 5px;
        @include border-radius(5px);
        color: $white;
        position: absolute;
        display: block;
        top: -15px;
        font-size: 0.85rem;
        padding: 2px 10px 3px;
        font-weight: 500;
    }

    .result-list-container {

        .favourite-icon,
        .navigate-icon,
        .share-icon,
        .unfavourite-icon,
        .addcontact-icon {
            display: block;
        }

        .user-ctrl-btn {
            &:hover{
                border: none;
            }
        }
    
        .company-container {
            border: 1px solid $alto;
            &.read-block{
                background-color: $read-block;
                .showIconCounts{
                    ul{
                      li{background: $white;}
                    }
                  }
            }
        }
        .simpleListSearch{
            .company-container {
                border:none
            }
        }
    }
    
    .read-block{
        background: $read-block;
        .showIconCounts{
            ul{
              li{background: $white;}
            }
          }
    }
    .toggle-truncate {
        color: $cerulean;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 4px;
    }
    
    .project-information {
        .col {
            border-bottom: 1px solid $iron;
            padding: 10px 15px;
    
            &.proj-type,
            &.proj-planningstages,
            &.proj-contractstages,
            &.proj-sizes,
            &.proj-useclass {
                border-left: 1px solid $iron;
    
                &.no-contract-stage {
                    border-bottom: 0 none
                }
            }

            &.proj-landType {
                border-top: 1px solid $iron
            }
    
            &.proj-sizes {
                &.col-lg-12 {
                    border-left: 0 none;
                }
            }
    
            &.proj-sizes,
            &.proj-type {
                label {
                    color: $dove-grey;
                    font-size: 0.8rem;
                    font-weight: 400;
                    display: inline-block;
                    width: 80px;
                    line-height: 1.0em;
                }
            }
    
            &.proj-timing {
                label {
                    color: $dove-grey;
                    font-size: 0.8rem;
                    font-weight: 400;
                    display: inline-block;
                    width: 80px;
                    line-height: 1.0em;
                }
            }
    
            &.proj-url,
            &.proj-planningstages,
            &.proj-contractstages {
                border-left: 0 none;
            }
    
            &.proj-contractstages {
                border-bottom: 0 none;
                min-height: 150px;
            }
    
            &.proj-size-block {
                border-left: 1px solid $iron;
                
            }
    
            &.proj-url a {
                word-break: break-all;
                text-decoration: underline;
                display: table;
            }
    
            &.proj-size-block .proj-sizes {
                border-left: 0 none;
                border-bottom: 0 none;
            }
    
            &.proj-dev-category {
                border-bottom: 0 none;
    
                .proj-category,
                .proj-dev-type {
                    border-bottom: 1px solid $iron;
                    padding: 10px 15px;
                }
            }
    
            &.proj-planningstages {
                .list-block {
                    &.blockLevelStatus {
                        margin-top: 5px;
    
                        label {
                        }
    
                        span {
                            display: block;
                            margin-bottom: 5px;
                            padding-left: 180px;
                        }
    
                        &:after {
                            clear: both;
                            content: '';
                            float: none;
                            display: block;
                        }
                    }
                }
            }
        }
    
        .row:last-child .col {
            //border-bottom:0 none;
        }
    
        .field-name {
            color: $black; // text-transform: uppercase;
            font-size: 0.8rem;
            font-weight: 600;
        }
    
        .field-value {
            color: $black;
            font-size: 0.8rem;
            font-weight: 400;
            line-height: 1.0rem;
        }
    
        .mat-cat-container {
            border-left: 1px solid $iron;
            padding-left: 15px;
    
            .settings-tab-wrapper {
                margin: 10px;
                padding-bottom: 10px !important;
    
                a {
                    padding: 10px 5px;
                    margin: 0 5px;
                    font-weight: 700;
                    color: $abi-blue;
                    opacity: 0.5;
                    cursor: pointer;
                    text-decoration: none;
                }
    
                a.active {
                    border-bottom: 3px solid $cerulean;
                    opacity: 1;
                }
            }
        }
    
    }

.contact-container{
    position: relative
}
    
.result-row-icon-placeholder {
    left: 10px;
    top: 10px;
    position: absolute;
    width: 30px;
  }
  .result-row-block{
    min-height: 75px;
  }
  .result-row-overview {
    display: inline-block;
    padding: 0 0 0 50px;
    width: 100%;
    // min-height: 115px;
    .rs-address {
      color: $black;
      &::after {
        background: $black;
        content: "";
        height: 4px;
        position: static;
        display: inline-block;
        margin: 3px 10px;
        width: 4px;
        right: 8px;
        bottom: 7px;
        border-radius: 100px;
      }
    }
    .rs-phone {
      color: $black;
      display: inline-block;
    }
  }

  //common company row css
  .result-list-container {
    padding: 10px 12px 10px 10px;
    //background: $white;
    li {
      padding: 0;
      border: 0;
      list-style: none;
      position: relative;
      @include border-radius(0px);
    }
    li.list-group-item {
      border: 1px solid $alto;
      border-top: none;
      margin: 0 0 0px;
      @include border-radius(0px);
      &:hover {
        @include box-shadow(0, 0, 10px, $grey);
        border: 1px solid $alto;
        border-top: none;
        position: relative;
      }
      &:first-child{
        border-top: 1px solid $alto;
      }
      .project-container {
        width: 100%;
      }
      .company-container {
        width: 100%;
      }
      .people-container {
        width: 100%;
        padding-left: 26px;
      }
  
      .container {
        display: block;
        position: absolute;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        top: -12px;
      }
  
      /* Hide the browser's default checkbox */
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        top: 19px;
        width: 25px;
        height: 25px;
        left: 32px;
      }
  
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 24px;
        left: 12px;
        height: 30px;
        width: 30px;
        background-color: $gallery;
        border-radius: 50%;
        z-index: 9;
        opacity: 0;
      }
  
      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: $grey;
        opacity: 0;
        &:hover {
          background-color: $white;
          opacity: 1;
          border: 1px solid $red;
          // transition: transform 0.8s;
          // transform: rotateY(180deg);
          &::after {
            display: block;
            left: 10px;
            top: 5px;
            width: 8px;
            height: 15px;
            border: solid $red;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
  
      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background-color: $cerulean;
        opacity: 1;
      }
  
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
  
      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }
  
      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 10px;
        top: 5px;
        width: 8px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
    .list-group-item {
      -moz-box-align: flex-start;
      align-items: flex-start;
      -webkit-align-items: flex-start;
      &.read-block{
        background-color: $read-block;
        .showIconCounts{
          ul{
            li{background: $white;}
          }
        }
      }
    }
    .result-block {
      border-radius: 0;
      border-style: none;
      border-width: 0;
      padding: 0.75rem 30px 0.5rem 0; // min-height: 100px;
      cursor: pointer;
      display: block;
      position: relative;
      width: 100%;
      .result-title-name {
        color: $black-grey;
        font-size: 1.12rem;
        padding: 2px 0;
      }
      .update-title-name {
        color: $black-grey;
        font-size: 0.9rem;
        padding: 2px 0;
      }
    }
    .full-results-block {
      border-radius: 0;
      border-right-width: 0;
      border-left-width: 0;
      border-bottom-width: 0;
      padding: 0.5rem;
    }
    .related-Company-btn {
      position: absolute;
      right: 0;
      z-index: 999;
      top: 0;
      border: 0;
      cursor: pointer;
      bottom: 0;
      outline: none;
      background-color: transparent;
      &::after {
        background: url($iconsprite) no-repeat scroll -60px -240px;
        content: "";
        height: 30px;
        left: 0;
        position: absolute;
        top: 40%;
        width: 30px;
      }
      &:hover,
      &:focus,
      &:active {
        background: $light-grey !important;
      }
      &:focus{
        outline: none;
        border:0;
        box-shadow: none;
      }
      &.active{
        background-color: $whisper;
      }
    }
    .project-show-roles {
      background: $white;
      border: 1px solid $alto;
      &.read-block{
        background: $read-block;
        .showIconCounts{
          ul{
            li{background: $white;}
          }
        }
      }
    }
    .related-projects,
    .related-Company {
      > div {
        > div {
          border-left: 2px solid $grey;
        }
      }
      &:last-child {
        > div {
          > div {
            border-left: 2px solid $grey;
            &:last-child {
              border-left: 2px solid $grey;
            }
          }
        }
      }
      .project-show-roles{
        margin: 0 0 0px 30px;
        border: 0 none;
        padding: 0;
        border:1px solid  $alto;
        border-bottom: none;
        &:last-child{
          border-bottom:1px solid  $alto;
        }
      }
      .company-container {
        margin: 0 0 0px;
        border: 1px solid $mercury;
        padding-left: 0 !important;
      }
      .pro-related-company {
        border-radius: 0;
        margin-bottom: 0px;
        .result-block {
          // background: $white;
          margin-bottom: 0;
          padding-bottom: 4px;
        }
        > span {
          background: $light-grey;
          padding: 0px 5px 5px;
        }
      }
      .project-group-name,
      .company-group-name {
        padding: 5px 0 10px;
        display: block;
        position: relative;
        margin-left: 20px;
        border-left: 2px solid $grey;
        padding-left: 15px;
        font-weight: 600;
        &:before {
          background: $white;
          content: "";
          height: 16px;
          left: -9px;
          position: absolute;
          top: 10px;
          width: 16px;
          border: 2px solid $grey;
          border-radius: 50%;
        }
        span {
          display: inline-block;
          padding: 2px 5px 2px 0;
          background: $white;
          border: 1px solid $grey;
          margin-left: 5px;
          position: relative;
          height: 27px;
          &::before {
            background: url($iconsprite) no-repeat scroll -90px -270px;
            content: "";
            position: absolute;
            width: 15px;
            height: 30px;
            left: -15px;
            top: -2px;
          }
        }
      }
      .company-role-name {
        padding: 0 0 0;
        display: block;
        position: relative;
        font-weight: 600;
        &:before {
          background: $red;
          content: "";
          height: 12px;
          left: -22px;
          position: absolute;
          top: 5px;
          width: 12px;
          border: 2px solid $white;
          border-radius: 50%;
        }
      }
      > div {
        > div {
          border-left: 2px solid $grey;
          padding: 0 0 4px 15px;
          margin: -5px 0 0 20px;
        }
      }
    }
    .list-group{
      .company-container{
        border: none;
      }
    }
  }

  .result-block {
    border-radius: 0;
    border-style: none;
    border-width: 0;
    padding: 0.75rem 30px 0.5rem 0; // min-height: 100px;
    cursor: pointer;
    display: block;
    position: relative;
    width: 100%;
    .result-title-name {
      color: $black-grey;
      font-size: 1.12rem;
      padding: 2px 0;
    }
    .update-title-name {
      color: $black-grey;
      font-size: 0.9rem;
      padding: 2px 0;
    }
  }

  .sub-resultdetails-content {
    font-size: 0.85rem;
    color: $black;
    .showIconCounts{margin: 5px 0;}
    strong {
      margin: 0 5px 0 0;
      display: none;
    }
    .rs-roles {
      color: $emperor-gray;
      padding: 0 0 1px 0;
      span {
        margin: 0 2px 0 0;
        &:after {
          content: ",";
        }
      }
      span:last-child {
        &:after {
          content: "";
        }
      }
      .roles-count {
        font-weight: 600;
        .plusIcon {
          margin-left: 4px;
          margin-right: -1px;
        }
      }
      .rs-address {
        color: $emperor-gray;
        padding: 0 0 1px 0;
        span {
          margin: 0 2px 0 0;
          &:after {
            background: $black;
            content: "";
            height: 4px;
            position: static;
            display: inline-block;
            margin: 3px 10px;
            width: 4px;
            right: 8px;
            bottom: 7px;
            border-radius: 100px;
          }
        }
      }
    }
    .rs-address {
      &::after {
        background: $black;
        content: "";
        height: 4px;
        position: static;
        display: inline-block;
        margin: 3px 10px;
        width: 4px;
        right: 8px;
        bottom: 7px;
        border-radius: 100px;
      }
    }
    .rs-phone {
      display: inline-block;
      word-break: break-word;
    }
  }

  .company-container.read-block {
    .sub-resultdetails-content {
        .rs-roles {
          color: $emperor-gray;
          padding: 0 0 1px 0;
          font-weight: 600;
          color: $black-grey;
        }
    }
  }

  .src-location-placeholder {
    padding: 4px 15px 5px 20px;
    position: relative;
    font-size: 0.8rem;
    margin: 0 0 0 -1px;
  }
  
  .src-location-placeholder:before {
    background: $transparent url($iconsprite) no-repeat scroll 0px -210px;
    content: "";
    height: 30px;
    left: -8px;
    position: absolute;
    top: -2px;
    width: 30px;
  }
  
  .src-date {
    padding: 4px 5px 5px 23px;
    position: relative;
    font-size: 0.8rem;
    display: inline-block;
    .src-latest {
      &:before {
        background: $transparent url($iconsprite) no-repeat scroll -30px -210px;
        content: "";
        height: 30px;
        left: -4px;
        position: absolute;
        top: -2px;
        width: 30px;
      }
      &.role-added{
        padding-left:34px;
        &:before{
          display: inline-block;
          content: 'Added';
          border: 1px solid #000;
          padding: 4px 5px;
          background: none;
          width: auto;
          line-height: 1;
          color: #000;
          font-weight: normal;
          margin-right:10px;
          height:auto;
          top:1px;
        }
      }
    }
    .src-count {
      &:before {
        background: $transparent url($iconsprite) no-repeat scroll -240px -300px;
        content: "";
        height: 30px;
        left: -4px;
        position: absolute;
        top: -2px;
        width: 30px;
      }
    }
    .src-value {
      &:before {
        background: $transparent url($iconsprite) no-repeat scroll -270px -300px;
        content: "";
        height: 30px;
        left: -4px;
        position: absolute;
        top: -2px;
        width: 30px;
      }
    }
  }

  .css-1jqq78o-placeholder{
      font-size: 0.85rem;
  }

  .proj-ref {
    color: $emperor-gray;
    font-weight: 600;
    font-size: 0.80rem;
    display: inline-block;
    position: relative;
    margin: 0 0 0 25px;

    &:before {
        content: "";
        width: 5px;
        height: 5px;
        background: $black-grey;
        left: -14px;
        position: absolute;
        top: 7px;
        border-radius: 50%;
    }

    strong {
        font-weight: 600;
        display: inline-block;
        padding-right: 5px;
    }
}

.block-title .team-dropdown-icon {
    background-color: inherit;
    border-radius: 0;
    border: 0 none;
    margin-left: -40px;
    position: relative;
  
    &:before {
      background: none;
    }
  }
  
  .label-member-list {
    .team-name-class {
      &.users-teams-title {
        display: inline-block;
        padding: 2px 8px 8px 40px;
        margin: 0;
        line-height: 1.3em;
      }
    }
  }
  
  .label-project-list {
    .share-details-wrapper {
      .block-title {
        .team-member-name {
          &.team-ss-icon {
            display: none;
          }
        }
      }
  
      .team-name-class {
        &.users-teams-title {
          display: inline-block;
          padding: 5px 8px 8px 45px;
        }
      }
  
      .list-user-team {
        .team-member-name {
          &.team-ss-icon {
            padding: 5px;
            height: 35px;
            width: 35px;
            margin: 0;
            display: block;
          }
        }
      }
    }
  }
  
  .list-user-team {
    display: block;
    position: relative;
  
    .users-teams-title {
      top: 2px;
      position: absolute;
      overflow: hidden;
      white-space: nowrap;
      font-weight: 500;
  
      .email-address {
        font-size: 0.6rem;
        font-weight: 400;
        display: block;
      }
    }
  
    .team-ss-icon {
      background-color: $grey;
      border-radius: 50%;
      padding: 2px 0;
      margin-right: 10px;
      font-size: 16px;
      height: 35px;
      font-weight: 600;
      border: 2px solid $grey;
      width: 35px;
      display: inline-block;
      text-align: center;
      position: relative;
  
      &::after {
        position: absolute;
        content: "";
        top: -5px;
        right: -5px;
        height: 20px;
        width: 20px;
        background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
        @include border-radius(50px);
      }
    }
  }

  .placeholder {
    &.list-wrapper-other-pro{
        width: 100%;
        background: transparent;
    }
    .list-group-item{
        border-color: $border-grey;
    }
    }
    .shareWithInput{
        .email-address{
            text-decoration: none;
            color:$dark-grey;
        }
        .css-10wo9uf-option,.css-d7l1ni-option{
            padding-top: 4px;
            padding-bottom: 4px;
            font-size: 0.9rem;
        }
        .css-1p3m7a8-multiValue{
            .list-user-team{
                .team-ss-icon,.user-dropdown-icon{
                    font-size: 0;
                    width: 12px;
                    height: 12px;
                    vertical-align: middle;
                    margin:-4px 5px 0 2px;
                }
                .user-dropdown-icon{
                    &::before{
                        content: "";
                        height: 20px;
                        left: -3px;
                        position: absolute;
                        top: 1px;
                        width: 20px;
                        background: url($iconsprite) no-repeat -5px -456px;;
                    }
                    
                }
                .users-teams-title{
                    position: static;
                    float: none;
                    .email-address{
                        display: none;
                    }
                }
            }
        }
    }

  //-------responsive css start---------//
    @media screen and (max-width: 1024px) {
        .details-page{
            .meta-data{
                flex-wrap: wrap;
                .col{
                    border: 1px solid $alto;
                    flex-basis: 25%;
                    max-width: 145px;
                    padding: 10px;
                    &.researcher{
                        max-width: 290px;
                    }
                    &:last-child{
                        border-right: 1px solid $alto;
                    }
                }
            }
        }
    }
    @media screen and (max-width:991px) {
        .details-page{
            .meta-data{
                padding-left: 15px;
                padding-right: 15px;
                .col{
                    flex-basis: 20%;
                }
            }
        }
        .project-information 
        {
            .row{
                .col.proj-size-block{
                    border-left:none !important;
                }
            }
        }
    }
    .print
    {
        #print-container{
            .box-view{
                border: none;
            }
            .Project-print-wrapper{
                &>strong{
                    padding: 10px 0 5px;
                    display: block;
                    font-size: 1.2rem;
                }
                .project-role-wrapper{
                    padding: 0;
                    background: none;
                    box-shadow: none;
                    margin: 0;
                    
                    .project-role-name{
                        background: none;
                        position: relative;
                        top: 0;
                        color: #000;
                        font-size: 1rem;
                        padding: 10px 15px !important;
                        font-weight: 700;
                    }
                    .people-on-proj-title{
                        color: $black;
                        font-size: 1rem;
                        padding: 10px;
                        font-weight:700;
                    }
                    .result-list-container{
                        margin: 0;
                        padding: 0;
                        .listNameIcon{padding: 10px;}
                        .company-container.read-block{
                            background: $white;
                        }
                        #searchlistAccordion{
                            margin: 0;
                            padding: 0;
                            li.list-group-item{
                                border: none;
                                margin-bottom: 15px;
                                border-bottom: 1px solid $mercury;
                                &:last-child{
                                    border-bottom: 0 none;
                                }
                                &:hover{
                                    box-shadow: none;
                                    border: none;
                                }
                                .checkmark{
                                    display: none;
                                }
                                .company-container{
                                    padding-left: 0;
                                    .result-block{
                                        cursor: default;
                                        pointer-events: none;
                                        padding: 0;
                                        .result-title-name{
                                            font-size: 0.9rem;
                                            color: $black;
                                            font-weight: 700;
                                        }
                                    }
                                    .related-Company-btn{
                                        display: none;
                                    }
                                    .result-row-overview{
                                        padding: 0 0 10px 0;
                                        min-height: auto;
                                        .showIconCounts{
                                            display: none;
                                        }
                                        .com-address-print{
                                            margin: 0;
                                            padding: 0;
                                        }
                                        .comp-data{
                                            padding-right: 0;
                                            color: $black;
                                            // border-right: 1px solid $black;
                                            padding-left: 0;
                                            &::after{
                                                content: "|";
                                                position: relative;
                                                margin:0 10px
                                            }
                                            &:last-child{
                                                &::after{
                                                    content: "";
                                                }
                                            }
                                            &:first-child{
                                                padding-left: 0;
                                            }
                                        }
                                        .comp-address{
                                            padding: 0;
                                            color: $black;
                                        }
                                    }
                                    .team-contact-list{
                                        strong{
                                            font-size: 0.95rem;
                                            color: $dark-grey;
                                        }
                                        .row{
                                            margin: 0;
                                            padding: 0;
                                        }
                                        .user-contact-details{
                                            background: $white;
                                            border: none;
                                            display: flex;
                                            flex-direction: column;
                                            width: 100%;
                                            color: $black;
                                            margin: 0 0 10px;
                                            min-height: auto;
                                            padding: 3px 0;
                                            font-weight: 700;
                                            font-size: .85rem;
                                            word-wrap: break-word;
                                            &:before{
                                                content: none;
                                            }
                                            p{
                                                font-size: .85rem;
                                                font-weight: 700;
                                            }
                                            div{
                                                padding:0;
                                                margin:0;
                                                font-weight: 400;
                                                font-size: .85rem;
                                                &:last-child{
                                                    margin-right:0;
                                                    // max-width: 30%;
                                                }
                                            }
                                            .ucd-email{
                                                &:after{
                                                    content: "|";
                                                    margin: 0 10px;
                                                }
                                                &:empty{
                                                    display: none;
                                                    &:after{
                                                        content: none;
                                                    }
                                                }
                                            }
                                            .ucd-mobile,
                                            .ucd-phone{
                                                &:empty{
                                                    display: none;
                                                }
                                            }


                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }    

    .selector-ctrl-fav{
        .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
            background: none;
        }
    }

    // tag tab, share tab, tag modal css common

    .label-list{
        .input-group {
            .css-b62m3t-container{
                width: calc(100% - 93px);
                min-height: 40px;
            }
            .css-hlgwow{
                height:100%;
                padding: 0 8px 0 35px;
            }
        }
        .css-10wo9uf-option,.css-d7l1ni-option{
            padding: 3px;
            border-bottom: 1px solid $border-grey;
            font-size:0.9rem;
        }
    }
    .tag-listing{
        height: 31px;
    }
    .label-details-wrapper {
        span.shared-labels-list-wrapper {
          display: inline-block;
          position: relative;
    
          .remove-label {
            display: none;
          }
    
          &:hover {
            .remove-label {
              display: block;
              position: absolute;
              left: 0;
              text-align: center;
              width: 22px;
              top: 6px;
              color: $abi-blue;
              cursor: pointer;
              background: $link-water-new;
              font-weight: 700;
              font-size: 1rem;
            }
          }
    
          .shared-labels-list {
            padding: 3px 15px 3px 1px;
            border: 2px solid $link-water-new;
            color: $dark-black-gray;
            border-radius: 3px;
            background-color: $link-water-new;
            margin: 5px 5px 0 0;
            display: inline-block;
            font-weight: 500;
            cursor: default;
            position: relative;
            min-height: 26px;
            font-size: 0.8rem;
            min-width: 82px;
            text-decoration: none;
          }
        }
    
        span.labels-list-wrapper {
          display: inline-block;
          position: relative;
    
          .remove-label {
            display: none;
          }
    
          &:hover {
            .remove-label {
              display: block;
              position: absolute;
              left: 0;
              text-align: center;
              width: 22px;
              top: 7px;
              color: $abi-blue;
              cursor: pointer;
              background: $alice-blue;
              font-weight: 700;
              font-size: 1rem;
            }
          }
    
          .labels-list {
            padding: 3px 15px 3px 1px;
            border: 2px solid $alice-blue;
            color: $abi-blue;
            border-radius: 3px;
            background-color: $alice-blue;
            margin: 5px 5px 0 0;
            display: inline-block;
            font-weight: 500;
            cursor: default;
            position: relative;
            min-height: 26px;
            font-size: 0.8rem;
            min-width: 82px;
            text-decoration: none;
          }
        }
      }


      .share-details-wrapper{
        .css-13cymwt-control,.css-t3ipsp-control{
            min-height: 41px;
            border-radius: 0;
            padding-left: 30px;
            .label-block,.shared-label-block{
                padding:0 !important;
                background: transparent !important ;
                span{
                    padding:0;
                    font-size: 0.8rem;
                    font-weight: 500;
                }
                &::before{
                    top:-3px !important;
                    left:-5px !important;
                }
            }
            
            .css-1dyz3mf{
                height: 100%;
                padding-top: 0;
                padding-bottom: 0;
                min-height: 41px;
                padding-left: 35px;
            }
            .css-19bb58m{
                padding-top:0;
                padding-bottom: 0;
            }
            .css-1p3m7a8-multiValue{
                border: 1px solid #ccc;
                background-color: #ccc;
            }
            input{
                height: auto;
                &:focus{
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        

        .css-t3ipsp-control{
            box-shadow: none;
            border-color: $dark-grey;
        }

        #react-select2-listbox{
            border-radius: 0;

        }
       
        .circle-border{
            position: absolute;
            left: 7px;
            top:8px;
            z-index:1;
            pointer-events: none;
        }

        .css-10wo9uf-option{
            .list-user-team {
                display: block;
                position: relative;
              
                .users-teams-title {
                  top: 2px;
                  position: absolute;
                  overflow: hidden;
                  white-space: nowrap;
                  font-weight: 500;
              
                  .email-address {
                    font-size: 0.6rem;
                    font-weight: 400;
                    display: block;
                  }
                }
              
                .team-ss-icon {
                  background-color: $grey;
                  border-radius: 50%;
                  padding: 2px 0;
                  margin-right: 10px;
                  font-size: 16px;
                  height: 35px;
                  font-weight: 600;
                  border: 2px solid $grey;
                  width: 35px;
                  display: inline-block;
                  text-align: center;
                  position: relative;
              
                  &::after {
                    position: absolute;
                    content: "";
                    top: -5px;
                    right: -5px;
                    height: 20px;
                    width: 20px;
                    background: $transparent url($iconsprite) no-repeat scroll -215px -215px;
                    @include border-radius(50px);
                  }
                }
            }
        }
        
    }  
    .share-tabShareDetails{
        .input-group{
            flex-wrap: nowrap !important;
            .css-13cymwt-control{
                border: none !important;
            }
            .css-t3ipsp-control{
                border: 1px solid $alto;
            }

            .shareWithInput{
                .css-t3ipsp-control{
                    border: none;
                }
            }
        }

        .share-details-wrapper {
            .shareTabSelector {
              .css-13cymwt-control, .css-t3ipsp-control {
                 .css-hlgwow {
                   padding: 2px 8px 0 35px;
                 }
              }
            }
          }
    }


    .height-400{
        height: 400px;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .tag-listing-container {
        padding: 0px;
        position: relative;
    
        #tag-lable-listing {
            padding: 5px 60px 5px 10px;
            margin-right: 65px;
            overflow: hidden;
            height: 40px;
            margin: 0px;
    
            .labels-list-wrapper {
                padding-bottom: 10px;
            }
        }
    }
    
    .label-details-wrapper.show-less-lables {
        position: relative;
    }
    
    #label-show-more {
        top: 13px;
        right: 0;
        cursor: pointer;
        color: $abi-blue;
        position: absolute;
    }

    

.sub-resultdetails-content{
    .result-client-name-value{
        .rs-client-name{
            position: relative;
            color: $emperor-gray;
            padding: 0 0 1px;
            display: inline-block;
            &:after{
                background: $black;
                content: "";
                height: 4px;
                position: static;
                display: inline-block;
                margin: 3px 10px;
                width: 4px;
                right: 8px;
                bottom: 7px;
            }
        }
        .rs-value{
            padding-right: 0;
            position: relative;
            color:$black-grey;
            &:after{
                background: $black;
                content: "";
                height: 4px;
                position: static;
                display: inline-block;
                margin: 3px 10px;
                width: 4px;
                right: 8px;
                top: 7px;
            }
        }
    }
}

.tags-modal{
    .input-group{
        .btn-share{
            left:0 !important;
            padding: 10px 20px !important;
        }
        .css-hlgwow{
            padding: 1px 8px !important;
            min-height: 42px;
        }
        .css-t3ipsp-control{
            min-height: 43px;
        }
    }
}

.companydetails{
    .details-page{
        .map-view{
            background-size: cover;
        }
    }
}

.listviewtable-block{
    .archive-badge-flag {
        margin: 5px 0 0px !important;
        width:57px !important;
        text-align: center;
    }
}

.share-tabShareDetails {
    .css-13cymwt-control, .css-t3ipsp-control {
        .css-1p3m7a8-multiValue {
            .everyone-icon {
                &:before {
                    background: transparent url($iconsprite) no-repeat scroll -218px -335px;
                    border-radius: 50%;
                    content: "";
                    height: 15px;
                    left: -28px;
                    position: absolute;
                    top: 3px;
                    width: 15px;
                }
                .select-header {
                    padding: 0;
                }
            }
        }
    }
}


@media only screen and (max-width: 991px){
    .details-page .project-info{
        flex-direction: inherit;
    }
}

@media only screen and (min-width: 680px) and (max-width: 840px) {
    .proj-sizes .nested-values .list-group-wrapper .list-block { 
        width:100%;
    }
    .project-information .row .col.proj-sizes label, .project-information .row .col.proj-timing label, .project-information .row .col.proj-type label

    {
        width: 45%;
    }

}