.display-none {
    display: none;
}

.display-block {
    display: block;
}

.textfield-wrapper {
    position: relative;
    border: 1px solid $alto;
    border-left: none;
    .btn.clear-textfield,
    .btn.clear-textfield:hover,
    .btn.clear-textfield:visited,
    .btn.clear-textfield:focus {
        height: 30px;
        margin: 0;
        width: 30px;
        background: $white url(#{$iconsprite}) no-repeat scroll -180px -29px;
        border: 0 none;
        position: absolute;
        text-indent: -999px;
        overflow: hidden;
        right: 0;
        top: 7px;
        cursor: pointer;
        z-index: 1003;
    }
    .btn.radial-textfield,
    .btn.radial-textfield:hover,
    .btn.radial-textfield:visited,
    .btn.radial-textfield:focus {
        height: 30px;
        margin: 0;
        width: 30px;
        background: $white url(#{$iconsprite}) no-repeat scroll 0px -360px;
        border: 0 none;
        position: absolute;
        text-indent: -999px;
        overflow: hidden;
        right: 0;
        top: 7px;
        cursor: pointer;
        z-index: 1003;
    }
    input[type=text] {
        padding-right: 0;
        font-size: 0.9rem;
        font-weight: 400;
    }
    &:after {
        content: "";
        position: absolute;
        left: 3px;
        border-left: 1px solid $grey;
        z-index: 1;
        top: 10px;
        bottom: 10px;
    }
}

input[type=input]::-ms-clear,
input[type=password]::-ms-reveal,
input[type=text]::-ms-clear {
    display: none;
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

.filters-container {
    .textfield-wrapper {
        .btn.clear-textfield,
        .btn.clear-textfield:hover,
        .btn.clear-textfield:visited,
        .btn.clear-textfield:focus {
            right: 2px;
            top: 2px;
        }
        &:after {
            content: none;
        }
    }
}


/* ============================================================
  COMMON - SWITCH COMPONENT
============================================================ */

input.cmn-toggle-round:checked+label:before {
    background-color: $fiter-active;
}


/* ============================================================
  COMMON -  TOGGLE SWITCH - COMPONENT
============================================================ */

input.cmn-toggle-switch:checked+label:before {
    background-color: $fiter-active;
}


/* ============================================================
  COMMON -  LABEL - COMPONENT - REACT SELECT
============================================================ */


/*.Select--multi {
  .Select-control{
    border: 1px solid $white;
    display: block;
    height: inherit;
    .Select-placeholder{
      span{
        font-size: 1em;
        color: $label-green;
        font-weight: 600;
        margin-left: 20px;
        top: 2px;
        position: relative;
      }
    }
    .Select-arrow-zone, .Select-clear-zone{
      display: none;
    }
    &:hover{
      box-shadow: none;
    }
    .Select-multi-value-wrapper{
      margin-left: 25px;
      margin-right: 45px;
      &:before{
        background: $transparent url($iconsprite) no-repeat scroll -240px -90px;
        content: "";
        height: 30px;
        left: 0;
        position: absolute;
        top: 2px;;
        width: 30px;
      }
      .Select-value{
        color:$label-green;
        border: 1px solid $label-green;
        background-color:$white;
        .Select-value-label{
          color: $label-green;
          font-size: 0.75rem;
          font-weight: 600;
        }
        .Select-value-icon{
          border-right: 1px solid $label-green;
          padding-top: 3px;
          font-size: 0.75rem;
          font-weight: 600;
        }
      }
    }
    .Select-clear-zone {
      .Select-clear{
        font-size: 1.2em;
        color: $white;
        background: $emperor-gray;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        font-weight: 700;
      }
    }
  }
}
.is-focused{
  .Select-control{
    border: 1px solid $grey;
    border-radius: 0;
    .Select-placeholder{
      span{
        display: none;
      }
    }
    .Select-clear-zone{
      display: block;
      position: absolute;
      right: 24px;
      top: 9px;
    }
    .Select-arrow-zone{
      display: block;
      float: right;
      top: 8px;
      right: 0;
      position: absolute;
    }
  }
}
*/


/* ============================================================
  COMMON -  SHARING - COMPONENT - REACT SELECT
============================================================ */

.share-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:before,
.Labels-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:before {
    background: $transparent url($iconsprite) no-repeat scroll -210px -150px;
}

.Labels-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:before,
.Labels-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value .Select-value-label:before {
    background: $transparent url($iconsprite) no-repeat scroll -180px -180px;
}

.share-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value.everyone-class .Select-value-label:before,
.Labels-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value.everyone-class .Select-value-label:before {
  background: $transparent url($iconsprite) no-repeat scroll -61px -420px;
}

.share-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value.teams-class .Select-value-label:before,
.Labels-details .Select--multi .Select-control .Select-multi-value-wrapper .Select-value.teams-class .Select-value-label:before {
  background: $transparent url($iconsprite) no-repeat scroll -210px -209px
}

.add-new-team-user .share-details-wrapper .Select .Select-control .Select-multi-value-wrapper {
    margin-left: 25px;
    margin-right: 45px;
    &:before {
        background: $transparent url($iconsprite) no-repeat scroll 0 0;
        content: "";
        height: 30px;
        left: 0;
        position: absolute;
        top: 2px;
        width: 30px;
    }
}

.add-new-team-user .share-details-wrapper {
    .user-info-block{
        position: relative;
        display: block;
        .user-info-wrapper {
            left: 45px;
            top: 0;
            display: block;
            position: absolute;
            z-index: 1;
            font-weight: 600;
            .email-address {
                font-size: 0.75rem;
                color: $emperor-gray;
                display: block;
                margin-top: -5px;
            }
        }
    }
}


.share-details .share-details-wrapper .Select-control .Select-clear-zone .Select-clear{
    padding-left: 0;
    line-height: 10px;
}
.share-details,
.Labels-details,
.add-new-team-user {
    .Select {
        width: 100%;
        border: 1px solid $grey;
    }
    .Select-control {
        border: 1px solid $white;
        display: block;
        min-height: 38px;
        height: auto;
        overflow: inherit;
        .Select-placeholder {
            span {
                font-size: 1em;
                color: $grey;
                font-weight: 400;
                margin-left: 20px;
                top: 0;
                position: relative;
            }
        }
        .Select-arrow-zone,
        .Select-clear-zone {
            display: none;
        }
        &:hover {
            box-shadow: none;
        }
        .Select-multi-value-wrapper {
            margin-left: 25px;
            margin-right: 45px;
            &:before {
                background: $transparent url($iconsprite) no-repeat scroll -240px -90px;
                content: "";
                height: 30px;
                left: 0;
                position: absolute;
                top: 2px;
                ;
                width: 30px;
            }
            .Select-value {
                color: $white;
                border: 1px solid $grey;
                background-color: $grey;
                .Select-value-label {
                    color: $black;
                    font-size: 0.75rem;
                    font-weight: 600;
                    position: relative;
                    padding-left: 25px;
                    &:before {
                        content: "";
                        height: 30px;
                        width: 30px;
                        position: absolute;
                        left: -3px;
                        top: -5px;
                    }
                }
                .Select-value-icon {
                    border-right: 1px solid $grey;
                    padding-top: 3px;
                    font-size: 0.75rem;
                    font-weight: 600;
                    float: right;
                    color: $black;
                    &:hover,
                    &:focus {
                        color: $white;
                    }
                }
            }
        }
        .Select-clear-zone {
            width: 15px;
            top:5px !important;
            position: absolute;
            right: 9px;
            .Select-clear {
                font-size: 0.85rem;
                color: $white;
                background:$emperor-gray;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                font-weight: 700;
                // line-height: 0;
                // padding-top: 7px;
                // padding-left: 1px;
            }
        }
    }
    .is-focused {
        .Select-control {
            border: 1px solid $dark-grey;
            border-radius: 0;
            .Select-placeholder {
                span {
                    display: none;
                }
            }
            .Select-clear-zone {
                display: block;
                position: absolute;
                right: 24px;
                top: 9px;
            }
            .Select-arrow-zone {
                display: block;
                float: right;
                top: 8px;
                right: 0;
                position: absolute;
            }
        }
    }
}