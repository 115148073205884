.btn {
    cursor: pointer;
    @include border-radius(0px);
    &.btn-primary {
        background: $white;
        border: 1px solid $black;
        color: $black;
        font-size: 0.85rem;
    }
    &.btn-default {
        background: $white;
        border: 1px solid $black;
        color: $black;
    }
    &:hover {
        background: $black;
        color: $white;
    }
    &:focus {
        background: $black;
        color: $white;
    }
    &.active {
        background: $black;
        color: $white;
    }
}

.btn-forgotpassword:hover,
.btn-forgotpassword:focus {
    outline: 0 none;
    background: $transparent;
    color: $black;
    box-shadow: none;
}

.btn.disabled,
.btn:disabled,
.nav-sort .btn.disabled {
    cursor: default!important;
}

.user-contact-details a,
.user-contact-details a:focus,
.user-contact-details a:hover,
.company-information a,
.company-information a:focus,
.company-information a:hover,
.project-information a,
.project-information a:focus,
.project-information a:hover {
    text-decoration: underline;
    color: $black;
}

.btn-green {
    background: $turquoise;
    color: $black;
    border: 0 none;
   // box-shadow: 0px 2px 5px $dark-grey;
    &:hover,
    &:focus,
    &:active {
        background: $light-turquoise;
        color: $black;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
    }
}

.btn-red {
    background: $red;
    color: $white;
    border: 0 none;
    &:hover,
    &:focus,
    &:active {
        background: $abi-red;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
    }
    &.disabled{
        background: $abi-red;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
        cursor: not-allowed;
        opacity: .65;
        &:hover{
            background: $abi-red;
            color: $white;
            -webkit-box-shadow: 0 0 5px $black;
            -moz-box-shadow: 0 0 5px $black;
            box-shadow: 0 0 5px $black;
            cursor: not-allowed;
            opacity: .65;
        }
    }
}
.btn-blue{
    background: $abi-blue;
    color: $white;
    border: 0 none;
    &:hover,
    &:focus,
    &:active {
        background: $abi-blue;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
    }
    &.disabled{
        background: $abi-blue;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
        cursor: not-allowed;
        opacity: .65;
        &:hover{
            background: $abi-blue;
            color: $white;
            -webkit-box-shadow: 0 0 5px $black;
            -moz-box-shadow: 0 0 5px $black;
            box-shadow: 0 0 5px $black;
            cursor: not-allowed;
            opacity: .65;
        }
    }
}

.btn-white {
    background: $white;
    color: $black;
    border: 0 none;
    &:hover,
    &:focus,
    &:active {
        background: $light-grey;
        color: $black;
    }
    &.disabled{
        background: $light-grey;
        color: $black;
        cursor: not-allowed;
        opacity: .65;
        &:hover{
            background: $light-grey;
            color: $black;
            cursor: not-allowed;
            opacity: .65;
        }
    }
}

.btn-black {
    background: $black-grey;
    color: $white;
    &:hover,
    &:focus,
    &:active {
        background: $black-grey;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
    }
    &.disabled{
        background: $black-grey;
        color: $white;
        -webkit-box-shadow: 0 0 5px $black;
        -moz-box-shadow: 0 0 5px $black;
        box-shadow: 0 0 5px $black;
        cursor: not-allowed;
        opacity: .65;
        &:hover{
            background: $black-grey;
            color: $white;
            -webkit-box-shadow: 0 0 5px $black;
            -moz-box-shadow: 0 0 5px $black;
            box-shadow: 0 0 5px $black;
            cursor: not-allowed;
            opacity: .65;
        }
    }
}

.btn.btn-secondary.btn-outline-red{
    border-color: $abi-red;
    color: $abi-red;
    cursor: pointer;
    &:hover,&:focus{
      background: $abi-red;
      color: $white;
    }
  }

  
.btn.btn-outline-cerulean{
    border-color: $cerulean;
    color: $cerulean;
    background: $white;
    cursor: pointer;
    &:hover,&:focus{
      background: $white;
      color: $cerulean;
    }
  }